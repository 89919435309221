// angular
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AppConfig } from '@aa/common';
// @aa/app/ui
import { ConfirmService, DialogEvent, ToastService } from '@aa/app/ui';
// forms
import { ISelectOption, SelectOptionMapper } from '@aa/app/forms';
// shared
import { ApiClient, ChannelResolverService } from '../../services';
import { Channel, FeedbackModel, FeedbackType, IFeedbackDialogData, Snippet } from '../../models';
import { FeedbackTypeFormatter } from '../../classes';

const appFeedbackTypes = [
  FeedbackType.AppFeatureIdea,
  FeedbackType.AppBugReport,
  FeedbackType.AppHelp,
  FeedbackType.AppAccountSetup,
  FeedbackType.Other,
  FeedbackType.EmptySearch
];

const snippetFeedbackTypes = [
  FeedbackType.SnippetTypo,
  FeedbackType.SnippetOutOfDate,
  FeedbackType.SnippetBadAdvice,
  FeedbackType.SnippetInappropriate,
  FeedbackType.Other
];

@Component({
  selector: 'aa-details-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  public readonly snippetId: Guid = null;
  public readonly snippet: Snippet = null;
  public readonly channel: Channel = null;

  public readonly form: FormGroup;
  public readonly options: ISelectOption[];
  public error: string;

  public loading: boolean = false;

  public readonly feedback: FeedbackModel;


  constructor(private readonly dialogRef: MatDialogRef<FeedbackDialogComponent>,
              private readonly apiClient: ApiClient,
              private readonly toast: ToastService,
              private readonly confirmService: ConfirmService,
              channelResolver: ChannelResolverService,
              appConfig: AppConfig,
              @Inject(MAT_DIALOG_DATA) data: IFeedbackDialogData) {


    // check if snippet data provided
    data = data || {};

    this.snippet = data.snippet || null;

    // resolve channel
    if (this.snippet) {
      this.snippetId = this.snippet.id;
      this.channel = channelResolver.getChannel(this.snippet.channelId);
    }

    const availableTypes = this.snippet ? snippetFeedbackTypes : appFeedbackTypes;

    this.options = SelectOptionMapper.mapValue(availableTypes, FeedbackTypeFormatter.formatName);

    this.feedback = {
      version: appConfig.version,
      snippetId: this.snippetId,
      info: data.info || null,
      feedbackType: data.type || FeedbackType.None
    };

    this.form = new FormBuilder().group({
      'feedbackType': [this.feedback.feedbackType, Validators.required],
      'info': [this.feedback.info]
    });

    // clear errors when any value is changed
    this.form.valueChanges.subscribe(value => {
      this.error = null;
      Object.assign(this.feedback, value);
    });

  }

  public control(key: keyof FeedbackModel): FormControl {
    return <FormControl>this.form.get(key);
  }

  submit() {

    if (this.loading) {
      return;
    }

    this.loading = true;
    this.apiClient
      .post('/api/feedback', {body: this.feedback})
      .subscribe(
        res => {
          this.toast.info('Feedback Sent', 'Thanks for helping to make AdviceAid better!');
          this.close();
        },
        err  => {
          this.loading = false;
          this.error = err.toString();
        }
      );

  }

  ngOnInit() {
  }

  public cancel(event: DialogEvent = null) {
    if (!this.feedback.info) {
      this.close();
      return;
    }

    this.confirmService.show({
      title: 'Discard changes?',
      content: 'Are you sure you want to discard your feedback?'
    }).subscribe(result => {
        if (result) {
          this.close();
        }
      });
  }

  close() {
    this.dialogRef.close();
  }
}
