import { Validators } from '@angular/forms';

// common
import { IFactory, Serializer, TagType } from '@aa/common';

// local
import { IFormSerializer, CustomValidators, FormConfigBuilder} from '../classes';
import { Tag } from '../models';

const fb = new FormConfigBuilder<Tag>([
  { key: 'name', defaultValue: '', validators: [Validators.required, Validators.minLength(2)]  },
  { key: 'tagType', defaultValue: TagType.Topic, validators: [Validators.required] },
  { key: 'description', validators: [Validators.required, Validators.minLength(3)] },
  { key: 'groupId'},
  { key: 'order', defaultValue: 0  },
  { key: 'keywords', validators: [CustomValidators.keywordList] },
]);

export class TagSerializer extends Serializer<Tag> implements IFormSerializer<Tag> {

  public getType(): IFactory<Tag> {
    return Tag;
  }

  public getName(): string {
    return 'Tag';
  }

  public buildForm() {
    return fb.build();
  }
}
