import sortBy from 'lodash-es/sortBy';

// angular
import { DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';

// rxjs
import { BehaviorSubject,  combineLatest,  Observable,  Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// core
import { Item } from '@aa/common';

// local
import { ITableColumn, TableRowType } from '../../models';
import { PaginatedDataSet } from '../../classes';
import { DomSanitizer } from '@angular/platform-browser';


class MappedData<T> {
  get count(): number {
    return this.items.length;
  }

  readonly items: T[] = [];
  readonly renderedItems: any[] = [];
  readonly searchStrings: string[] = [];

  push(item: T, renderedItem: any, searchString: string) {
    this.items.push(item);
    this.renderedItems.push(renderedItem);
    this.searchStrings.push(searchString);
  }
}

declare type RowTypeCallback<T> = (item: T) => TableRowType;

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, ExampleDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
export class TableDataSource<T extends Item> extends DataSource<any> {
  private _subscription: Subscription;

  _filterChange = new BehaviorSubject('');
  get filter(): string { return this._filterChange.value; }
  set filter(filter: string) {
    this._filterChange.next(filter ? filter.trim() : '');
  }

  items: T[] = [];
  filteredData: T[] = [];
  renderedData: any[] = [];

  pageIndex = 0;

  constructor(private readonly _santizer: DomSanitizer,
              private readonly _data: Observable<T[]>,
              private readonly _columns: ITableColumn<T>[],
              private readonly _pagedData: PaginatedDataSet<T>,
              private readonly _sort: MatSort,
              private readonly _rowType: RowTypeCallback<T> = null) {
    super();

    // Reset to the first page when the user changes the filter.
    this._filterChange.subscribe(() => this._pagedData.pageIndex = 0);
  }

  private mapData(items: T[]): MappedData<T> {

    const result = new MappedData<T>();

    for (const item of items) {

      const renderedItem = {id: item.id};
      let searchStr = '';

      for (const column of this._columns) {

        const key: string = column.key;
        let data = item[key];
        let isHtml = false;

        if (column.formatter) {
          isHtml = column.formatter.isHtml;
          data = column.formatter.format(data);
        }

        if (isHtml) {
          renderedItem[key] = this._santizer.bypassSecurityTrustHtml(data);
        } else {
          renderedItem[key] = data;
        }

        if (data != null && !isHtml) {
          searchStr += data.toString().toLowerCase();
        }
      }

      // css style based on row type
      if (this._rowType) {
        const rowType = this._rowType(item);
        if (rowType === TableRowType.Highlight) {
          renderedItem['_class'] = 'highlight';
        }
      }

      result.push(item, renderedItem, searchStr);
    }

    return result;
  }


  updatePagedData(data: MappedData<T>): any[] {

    if (!data) {
      console.error('Invalid table data');
      return [];
    }

    const filterString = this.filter.toLowerCase();

    this.items = data.items;
    this.filteredData = data.renderedItems;

    // apply filter
    if (filterString.length) {
      this.filteredData = [];

      for (let i = 0; i < data.count; i++) {
        if (data.searchStrings[i].indexOf(filterString) !== -1) {
          this.filteredData.push(data.renderedItems[i]);
        }
      }
    }

    // Sort filtered data
    const sortedData = this.sortData(this.filteredData);

    this._pagedData.setData(sortedData);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {

    this.disconnect();

    const mappedDataStream = this._data.pipe(
      map(items => {
        return this.mapData(items);
      })
    );

    const sortStream = this._sort.sortChange.pipe(startWith(this._sort));

    // Listen for any changes in the sorting, filtering, or mappedData
    this._subscription = combineLatest([sortStream, this._filterChange, mappedDataStream])
      .subscribe(([sort, filter, mappedData]) => this.updatePagedData(mappedData));

    return this._pagedData.pagedData$;
  }

  disconnect() {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }
  }

  /**
   * Returns a sorted copy of the given data
   * using params from the sort component
   */
  sortData(data: T[]): T[] {
    if (!this._sort.active || this._sort.direction === '') { return data; }

    let sorted = sortBy(data, [this._sort.active]);

    if (this._sort.direction === 'asc') {
      sorted = sorted.reverse();
    }

    return sorted;
  }
}
