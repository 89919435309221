export interface IFormatter {
  isHtml?: boolean;
  format(data: any): string;
}

/**
 * Base class for data formatters
 */
export abstract class AbstractFormatter<T> implements IFormatter {

  private readonly cache = new Map<T, string>();

  protected abstract formatItemInternal(value: T): string;

  constructor(public readonly isHtml: boolean = false) {
  }

  public formatItem(value: T): string {
    let item = this.cache.get(value);

    // add to cache
    if (!item) {
      item = this.formatItemInternal(value);
      this.cache.set(value, item);
    }

    return item;
  }

  public format(data: T | Array<T>): string {
    if (data instanceof Array) {
      return this.formatList(data);
    }

    return this.formatItem(data);
  }

  public formatList(data: Array<T>): string {
    let result: string = null;

    for (const item of data) {
      const str = this.formatItem(item);

      if (!str) {
        continue;
      }

      if (result === null) {
        result = str;
      } else {
        result += ', ' + str;
      }
    }

    return result || '';
  }
}
