import { Injectable } from '@angular/core';

import { HotkeysService } from './hotkey.service';
import { Command } from '../models';
import { CommandList } from '../classes';

@Injectable({providedIn: 'root'})
export class CommandService {

  private commands: Command[] = [];

  constructor(private hotkeyService: HotkeysService) {
  }


  /**
   * Register a new command list
   * @param list
   */
  public register(list: CommandList) {
    this.reset();

    for (const cmd of list.commands) {
      if (cmd.hotKey != null) {
        this.hotkeyService.add(cmd.hotKey);
      }

      this.commands.push(cmd);
    }
  }

  // services do not have lifecycle hooks so there is no way to call this autoamtically
  //
  protected reset() {
    for (const cmd of this.commands) {
      if (cmd.hotKey != null) {
        this.hotkeyService.remove(cmd.hotKey);
      }
    }
    this.commands.length = 0;
  }
}
