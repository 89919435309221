<table class="govuk-table">
  <tbody class="govuk-table__body">
  <tr class="govuk-table__row">
    <th class="govuk-table__header" scope="row">Date</th>
    <td class="govuk-table__cell">{{today | date:'d/M/yyyy'}}</td>
  </tr>
  <tr class="govuk-table__row" *ngIf="url">
    <th class="govuk-table__header" scope="row">More Advice</th>
    <td class="govuk-table__cell"><a [href]="url">{{url}}</a></td>
  </tr>

  <tr class="govuk-table__row" *ngIf="telephone">
    <th class="govuk-table__header" scope="row">Telephone</th>
    <td class="govuk-table__cell">{{telephone}}</td>
  </tr>
  </tbody>
</table>
