import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// @aa/common
import { AuthService, AppConfig } from '@aa/common';


@Injectable({providedIn: 'root'})
export class NavigationService {

  constructor(auth: AuthService,
              appConfig: AppConfig,
              private router: Router) {

    /*
    if (!appConfig.loaded) {
      this.redirectToErrorPage();
    }
    */

    // watch for logout event and redirect
    auth.loggedIn$.subscribe(user => {
      if (!user) {
        this.redirectToLoginPage().then(() => {
          console.log('logout redirect complete');
        });
      }
    });
  }

  redirectToErrorPage(): Promise<boolean> {
    return this.router.navigateByUrl('/error', { skipLocationChange: true });
  }

  redirectToLoginPage(): Promise<boolean> {
    return this.router.navigateByUrl('/login');
  }
}
