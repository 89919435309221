import { IFormatter } from './abstract_formatter.class';

export class HtmlFormatter implements IFormatter {

  public format(data: string): string {

    if (!data) {
      return '';
    }

    const el = document.createElement('div');
    el.innerHTML = data;

    const result =  el.innerText;

    if (result.length >= 100) {
      return result.substr(0, 100) + '...';
    }

    return result;
  }
}
