import { Inject, Component, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// local
import { AppChannel, ChannelType } from '../../models';

export interface IChannelSelectDialogData {
  prompt: string;
  channels: AppChannel[];
}

@Component({
  selector: 'aa-channel-select-dialog',
  templateUrl: './channel-select-dialog.component.html',
  styleUrls: ['./channel-select-dialog.component.scss']
})
export class ChannelSelectDialogComponent implements AfterViewInit {

  public readonly prompt: string;
  public readonly channels: AppChannel[];

  constructor(private dialogRef: MatDialogRef<ChannelSelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data: IChannelSelectDialogData) {

    this.channels = data.channels || [];
    this.prompt = data.prompt || 'Please select a channel';
  }

  ngAfterViewInit() {
    if (!this.channels || !this.channels.length) {
      console.warn('no channel data available');
      this.cancel();
    }
  }

  selectChannel(channel: AppChannel) {
    this.dialogRef.close(channel);
  }

  cancel() {
    this.dialogRef.close();
  }

  getTooltip(channel: AppChannel) {
    switch (channel.channelType) {
      case ChannelType.Private:
        return 'Items created in this channel are visible only to you';

      case ChannelType.Team:
        return 'Items created in this channel are visible to everyone in your team';

      default:
        return `Create item in the {channel.name} channel`;
    }
  }
}
