
import { IJwtToken } from '../models';

export class JwtHelper {

  static SecondsPerDay = 24 * 60 * 60;

  private static b64EncodeUnicode(str) {
    const regex = /%([0-9A-F]{2})/g;
    return btoa(encodeURIComponent(str).replace(regex, function(match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    }));
  }

  private static urlBase64Encode(str: string): string {
    let jwt = JwtHelper.b64EncodeUnicode(str);

    // Remove padding equal characters
    jwt = jwt.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    jwt = jwt.replace(/\+/g, '-');
    jwt = jwt.replace(/\//g, '_');
    return jwt;

  }

  private static urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: { break; }
      case 2: { output += '=='; break; }
      case 3: { output += '='; break; }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return this.b64DecodeUnicode(output);
  }

  // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
  private static b64DecodeUnicode(str: any) {
    function handler(c: any) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }

    return decodeURIComponent(Array.prototype.map.call(atob(str), handler).join(''));
  }


  public static encodeToken(data: IJwtToken, timeoutSeconds?: number): string {
    const header = JSON.stringify({
      'alg': 'HS256',
      'typ': 'JWT'
    });

    timeoutSeconds = timeoutSeconds || JwtHelper.SecondsPerDay * 7; // 7 days

    data.iat = Math.floor(Date.now() / 1000); // unix epoch
    data.exp = data.iat + timeoutSeconds;
    const payload = JSON.stringify(data);

    const signature = 'mockSignature1234';

    const a = JwtHelper.urlBase64Encode(header);
    const b = JwtHelper.urlBase64Encode(payload);
    const c = JwtHelper.urlBase64Encode(signature);

    return `${a}.${b}.${c}`;
  }

  public static decodeToken(token: string): any {
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    const decoded = JwtHelper.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded);
  }

  public static getTokenExpirationDate(token: string): Date {
    let decoded: any;
    decoded = JwtHelper.decodeToken(token);

    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public static isTokenExpired(token: string, offsetSeconds?: number): boolean {
    const date = JwtHelper.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (date == null) {
      return false;
    }

    // Token expired?
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
  }
}
