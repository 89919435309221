import { FormGroup } from '@angular/forms';

// common
import { Theme, IFactory, Serializer  } from '@aa/common';

// local
import { CustomValidators, FormConfigBuilder, IFormSerializer } from '../classes';

const fb = new FormConfigBuilder<Theme>([
  { key: 'primary', validators: [CustomValidators.color] },
  { key: 'secondary', validators: [CustomValidators.color] },
  { key: 'header', validators: [CustomValidators.color] },
  { key: 'hover', validators: [CustomValidators.color] }
]);

export class ThemeSerializer extends Serializer<Theme> implements IFormSerializer<Theme> {

  public getType(): IFactory<Theme> {
    return Theme;
  }

  public getName(): string {
    return 'Theme';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
