import { ISerializer, StringKey } from '@aa/common';

import { FormBuilder, FormGroup, ValidatorFn, FormControl, Validators, AbstractControl } from '@angular/forms';

export interface IFormSerializer<T> extends ISerializer<T>  {
  buildForm(): FormGroup;
}

export interface  IControlConfigArgs<T> {
  key: StringKey<T>;
  defaultValue?: any;
  validators?: ValidatorFn[];
  control?: AbstractControl;
}

export const fb = new FormBuilder();

export class FormConfigBuilder<T> {

  readonly controlsConfig: {[key: string]: any} = {};

  constructor(args?: IControlConfigArgs<T>[]) {
    if (args) {
      this.addRange(args);
    }
  }

  public build(): FormGroup {
    return fb.group(this.controlsConfig);
  }

  public add(args: IControlConfigArgs<T>) {

    let control: AbstractControl = null;

    if (args.control) {
      control = args.control;
    } else {

      // default value
      const defaultValue = args.defaultValue;

      // validators
      let validator: ValidatorFn = null;
      if (args.validators && args.validators.length) {

        if (args.validators.length === 1) {
          validator = args.validators[0];
        } else {
          validator = Validators.compose(args.validators);
        }
      }

      control = new FormControl(defaultValue, validator);
    }

    this.controlsConfig[args.key] = control;
  }

  public addRange(args: IControlConfigArgs<T>[]) {
    for (const item of args) {
      this.add(item);
    }
  }
}
