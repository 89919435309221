import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { NotificationType, INotification } from '@aa/common';

import { ToastComponent, IToastRef } from './toast.component';

export interface IToastService {
  close(id: string): void;

  notify(data: INotification): IToastRef;
  success(title: string, content: string): IToastRef;
  error(title: string, content: string): IToastRef;
  info(title: string, content: string): IToastRef;
  alert(title: string, content: string): IToastRef;
}

@Injectable({providedIn: 'root'})
export class ToastService implements IToastService {

  // Default duration in seconds
  public defaultDuration: number = 4;

  constructor(private snackBar: MatSnackBar) {
  }

  public close(id: string) {
  }

  private open(title: string, message: string, type: NotificationType, duration?: number): IToastRef {

    duration = duration || this.defaultDuration;

    const css = ToastComponent.getTypeClass(type);

    const data: INotification = {message, title, type};

    const config: MatSnackBarConfig = {
      duration: duration * 1000,
      panelClass: ['toast-container', css],
      data
    };

    return this.snackBar.openFromComponent(ToastComponent, config);
  }

  public notify(data: INotification, duration?: number): IToastRef {
    return this.open(data.title, data.message, NotificationType.Success, duration);
  }

  public success(title: string, message: string, duration?: number): IToastRef {
    return this.open(title, message, NotificationType.Success, duration);
  }

  public error(title: string, message: string, duration?: number): IToastRef {
    return this.open(title, message, NotificationType.Error, duration);
  }

  public info(title: string, message: string, duration?: number): IToastRef {
    return this.open(title, message, NotificationType.Info, duration);
  }

  public alert(title: string, message: string, duration?: number): IToastRef {
    return this.open(title, message, NotificationType.Alert, duration);
  }
}
