

import { Team } from '../models';
import { NamedItemFormatter } from './named-item.formatter';

export class TeamFormatter extends NamedItemFormatter<Team> {

  constructor(public teams: Team[]) {
    super(teams);
  }
}
