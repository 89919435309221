<aa-form-dialog [content]="this">

  <div class="row">

    <!-- Team Select -->
    <aa-select *ngIf="teamOptions.length"
               label="Team"
               [options]="teamOptions"
               [control]="control('teamId')">
    </aa-select>

    <!-- Team Logo -->
    <aa-team-logo class="form-control small" [teamId]="item?.teamId"></aa-team-logo>
  </div>

  <div class="row">

    <!-- Email -->
    <aa-text-input label="Email" [control]="control('email')"></aa-text-input>

    <!-- Roles -->
    <aa-select
      label="Roles"
      hint="Roles control user permissions within the app"
      multiple="true"
      [options]="roleOptions"
      [control]="control('roles')">
    </aa-select>

  </div>


  <div class="row">

    <!-- First Name -->
    <aa-text-input label="First Name" [control]="control('firstName')"></aa-text-input>

    <!-- Last Name -->
    <aa-text-input label="Last Name" [control]="control('lastName')"></aa-text-input>

  </div>


  <div class="row">

    <!-- Job Title -->
    <aa-text-input label="Job Title" [control]="control('jobTitle')"></aa-text-input>

    <!-- Phone -->
    <aa-text-input label="Phone" [control]="control('phoneNumber')"></aa-text-input>
  </div>

  <!-- activation -->
  <div *ngIf="isNew">
    <aa-form-checkbox [control]="control('activationEmail')" label="Activation Email"></aa-form-checkbox>

    <div *ngIf="control('password').enabled">
      <div class="row form-section">
        <aa-password-input label="New Password" [control]="control('password').get('newPassword')"></aa-password-input>
        <aa-password-input label="Confirm Password"
                           [control]="control('password').get('confirmPassword')"></aa-password-input>
      </div>
    </div>
  </div>

  <!-- Password Edit -->
  <div *ngIf="activationRequired">
    <hr>
    <p>User not activated</p>
  </div>

</aa-form-dialog>

