import { Injectable } from '@angular/core';
import { Observable,  ReplaySubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Guid } from '../models/guid.type';

import {
  HttpClient, HttpErrorResponse,
} from '@angular/common/http';

import {
  JwtHelper, HttpUtil
} from '../classes';

import {
  Role,
  ApiResponse,
  IJwtToken,
  IUser,
  ILoginArgs,
  ITokenLoginArgs
} from '../models';



@Injectable({providedIn: 'root'})
export class AuthService {

  public static readonly tokenRefreshInterval: number = 60 * 1000; // 1 minute

  private readonly _roleSet = new Set<number>();
  public user: IUser = null;
  public rememberMe: boolean = true;
  public readonly user$: Observable<IUser>;
  public readonly loggedIn$: Observable<IUser>;

  /**
   * Key for saving jwt token to local/session storage
   */
  private readonly tokenStorageKey: string = 'id_token';

  // fires everytime user is refreshed
  // not behavior subject as we dont want these to fire until first logged in
  private readonly userSource = new ReplaySubject<IUser>(1);

  // fires once at login/logout
  private readonly loggedInSource = new ReplaySubject<IUser>(1);

  private lastUpdated: Date = null;

  static decodeUserToken(token: string): IUser {
    if (token == null) {
      return null;
    }

    try {
      const data: any = JwtHelper.decodeToken(token);

      // pre process roles
      if (data.role) {
        if (data.role instanceof Array) {
          data.roles = data.role;
        } else {
          data.roles = [data.role];
        }
      }

      // cast data to jwt interface
      const jwt: IJwtToken = data;

      if (!jwt.exp) {
        console.log('Invalid token expiration stamp');
        return null;
      }

      // check expiry with a tolerance of 60 seconds i.e. the max diff between server time and client time
      const now = Math.floor(Date.now() / 1000); // Unix Epoch
      const exp = jwt.exp;
      if (now > exp) {
        console.log('token expired @ %s (jwt.exp=%s)', new Date(exp * 1000).toISOString(), jwt.exp);
        console.log('local time = %s', new Date(now * 1000).toISOString());
        return null;
      }

      const timeout = jwt.exp - now;

      return <IUser> {
        id: jwt.sub,
        teamId: jwt.teamId,
        email:  jwt.email,
        firstName:   jwt.firstName || '',
        lastName:   jwt.lastName || '',
        jobTitle: jwt.jobTitle,
        phoneNumber: jwt.phoneNumber,
        roles:  jwt.roles || [],
        daysRemaining: jwt.daysRemaining || 0,
        token:  token,
        timeout: timeout
      };
    } catch {
      console.log('failed to parse token');
      return null;
    }
  }

  constructor(private http: HttpClient) {
    this.user$ = this.userSource.asObservable();
    this.loggedIn$ = this.loggedInSource.asObservable();
  }

  public init() {
    this.loadToken();
    this.invalidateToken();
  }

  public get token(): string {
    return this.user ? this.user.token : null;
  }

  public get loggedIn(): boolean {
    return this.user != null;
  }

  invalidateToken() {
    this.lastUpdated = null;
    this.checkToken();
  }

  checkToken() {
    if (this.lastUpdated) {
      const elapsed = (new Date().getTime() - this.lastUpdated.getTime());
      if (elapsed < AuthService.tokenRefreshInterval) {
        return;
      }
    }

    this.refreshToken();
  }

  private validateUser(user: IUser): boolean {
    if (!user) {
      return false;
    }

    if (!user.id) {
      console.warn('user id is not set');
      return false;
    }

    if (!user.teamId) {
      console.warn('user teamId is not set');
      return false;
    }

    if (!user.email) {
      console.warn('user email is not set');
      return false;
    }

    return true;
  }

  setUser(user: IUser): boolean {



    if (user === this.user) {
      return true;
    }

    this._roleSet.clear();

    if (!this.validateUser(user)) {
      localStorage.setItem(this.tokenStorageKey, null);
      sessionStorage.setItem(this.tokenStorageKey, null);
      this.user = null;
      this.userSource.next(null);
      this.loggedInSource.next(null);
      return false;
    }

    const loggedIn = this.loggedIn;

    this.user = user;
    if (user.roles) {
      user.roles.forEach(role => this._roleSet.add(role));
    }

    this.saveTokenInStorage(sessionStorage,  user.token);
    this.saveTokenInStorage(localStorage, this.rememberMe ? user.token : null);

    this.userSource.next(user);

    if (!loggedIn) {
      this.loggedInSource.next(user);
    }

    return true;
  }

  saveTokenInStorage(storage: Storage, token: string) {
    if (!storage) {
      return;
    }

    storage.setItem(this.tokenStorageKey, token);
  }

  getTokenFromStorage(storage: Storage): string {
    if (!storage) {
      return null;
    }

    return storage.getItem(this.tokenStorageKey);
  }


  public getTeamId(): Guid   {
    return this.user && this.user.teamId;
  }

  loadToken() {
    const token = this.getTokenFromStorage(sessionStorage) || this.getTokenFromStorage(localStorage);
    this.setUser(AuthService.decodeUserToken(token));
  }


  // TODO: make this an event that is handled by the given view
  // or perhaps allow the view to override with a CanDeactivate handler
  logout() {
    this.setUser(null);
    console.log('Logging out');
  }


  login(args: ILoginArgs): Promise<boolean> {

    this.rememberMe = args.rememberMe;
    return this.loginHelper('/api/auth/login', args);
  }

  loginHelper(url: string, args: ILoginArgs | ITokenLoginArgs): Promise<boolean>  {
    return this.http.post(url, args, {observe: 'response'})
      .pipe(
        map(res => this.user != null), // user will set by JWT interceptor
        catchError(err => {
          const errorResponse: ApiResponse = HttpUtil.parseError(err);
          const message = errorResponse.message;
          console.log('Login Failed', errorResponse);
          return throwError(message);
        })
      )
      .toPromise();
  }

  refreshToken() {
    if (!this.user) {
      return;
    }

    this.http.get('/api/auth/refresh')
      .subscribe(
        res => {},
        (err: HttpErrorResponse) => {
          console.log('refresh failed:', err);
          if (err.status === 401) {
            this.logout();
          }
        }
      );
  }

  public hasRequiredRole(requiredRole: Role): boolean {
    return this._roleSet.has(requiredRole);
  }

  public hasRequiredRoles(requiredRoles: Role[]) {
    if (!requiredRoles || !requiredRoles.length) {
      return true;
    }

    return requiredRoles.every(o => this.hasRequiredRole(o));
  }

  public get isTester(): boolean {
    return this.hasRequiredRole(Role.Tester);
  }

  public get isAdmin(): boolean {
    return this.hasRequiredRole(Role.SiteAdmin);
  }

  public get isTeamAuthor(): boolean {
    return this.hasRequiredRole(Role.TeamAuthor);
  }

  public get isTeamAdmin(): boolean {
    return this.hasRequiredRole(Role.TeamAdmin);
  }

  public get canLocalise(): boolean {
    return this.isTeamAdmin || this.isTeamAuthor;
  }
}
