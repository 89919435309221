<aa-channel-logo class="logo small" [channelId]="item.channelId" [watermark]="true">
</aa-channel-logo>

<div *ngIf="showChannelInfo" class="status"  matTooltip="Channel Type">
  <i [aaIcon]="channelIcon" [style.color]="channelColor"></i>
  <span>{{item | channelType}}</span>
</div>

<div *ngIf="isShared" class="status"  matTooltip="Share Status">
  <i [aaIcon]="shareIcon" [style.color]="channelColor"></i>
  <span>{{item | shareStatus:"full"}}</span>
</div>

<div *ngIf="localisation" class="status" matTooltip="Localisation Status">
  <i [aaIcon]="localiseIcon" [style.color]="channelColor"></i>
  <span>{{localisation | localisationFlag}}</span>
</div>

<div *ngIf="showAdviceSheetMessage" class="status">
  Double click or drag the item to create a new Advice Sheet
</div>
