export class EnumHelper {

  /**
   * Check if all bits in flag are present in value
   * @param value Enum value
   * @param flag Enum filter
   * @returns True on success
   */
  public static hasFlag(value: number, flag: number): boolean {
    /* tslint:disable-next-line:no-bitwise */
    return (value & flag) !== 0;
  }

}
