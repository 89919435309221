// angular
import { OnInit, Directive } from '@angular/core';

// core
import { Icon, ConfirmService } from '@aa/app/ui';

// shared
import { User, ITableAction, ITableViewConfig } from '../models';
import { UserApiService} from '../services';

@Directive()
export abstract class AbstractUsersComponent implements OnInit {

  public config: ITableViewConfig<User>;

  protected readonly actions: ITableAction[];

  protected constructor(protected service: UserApiService, private confirmService: ConfirmService) {

    this.actions = [{
      name: 'Resend Activation',
      description: 'Resend activation email',
      icon: Icon.Send,
      callback: item => this.resendActivationEmail(item)
    },
      {
        name: 'Resend Welcome',
        description: 'Resend welcome email',
        icon: Icon.Send,
        callback: item => this.resendWelcomeEmail(item)
      }];
  }

  private resendActivationEmail(item: User) {
    this.confirmService
      .show({title: 'Resend Activation', content: `Resend activation email to ${item.email}`})
      .subscribe(result => {
        if (result) {
          this.service.resendActivationEmail(item.id);
        }
      });
  }

  private resendWelcomeEmail(item: User) {
    this.confirmService
      .show({title: 'Resend Welcome', content: `Resend welcome email to ${item.email}`})
      .subscribe(result => {
        if (result) {
          this.service.resendWelcomeEmail(item.id);
        }
      });
  }

  ngOnInit() {
    this.config = this.buildConfig();
  }

  protected abstract buildConfig(): ITableViewConfig<User>;
}
