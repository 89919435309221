<aa-dialog (keyup.escape)="close()">

  <aa-dialog-title>App Settings</aa-dialog-title>

  <aa-dialog-content>

    <section>

      <h3>Workspace</h3>
      <mat-checkbox class="form-control" [(ngModel)]="settings.teamLogo">Insert Logo</mat-checkbox>
      <div class="checkbox-hint mat-hint">Add team logo to newly created workspace documents</div>
    </section>

    <br>
  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button color="primary" (click)="submit()">
      OK
    </button>
  </aa-dialog-actions>
</aa-dialog>
