// common
import { IFactory, Serializer } from '@aa/common';

// local
import { DocExportRequest } from '@aa/app/shared/models/doc-export-request.model';

export class DocExportSerializer extends Serializer<DocExportRequest> {

  public static default = new DocExportSerializer();

  public getType(): IFactory<DocExportRequest> {
    return DocExportRequest;
  }

  public getName() {
    return 'Document';
  }
}
