import { Injectable } from '@angular/core';

// common
import { AppStorageService, AuthService } from '@aa/common';

// local
import { AbstractSettingsService } from '../classes/abstract_settings_service.class';
import { AppSettings } from '../models/';
import { AppSettingsSerializer } from '../serializers';

const SETTINGS_KEY = 'appSettings';

/**
 * Document settings getters/settings
 */
@Injectable({providedIn: 'root'})
export class AppSettingsService extends AbstractSettingsService<AppSettings> {

  constructor(auth: AuthService, appStorage: AppStorageService) {
    super(auth, appStorage, new AppSettingsSerializer(), SETTINGS_KEY);
  }


  // Team Logo For new documents
  set teamLogo(value: boolean) {
    this._data.teamLogo = value;
    this.notifyUpdate('teamLogo');
  }

  get teamLogo(): boolean {
    return this._data.teamLogo;
  }

  public toggleTeamLogo() {
    this.teamLogo = !this.teamLogo;
  }
}
