// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { AppConfig } from '../services/app-config.service';

/*
 * Formats image paths relative to assets/images
 * Usage:
 *   adviceaid.png | https://aacdn.server.com/v1234/assets/images/adviceaid.png
 */
@Pipe({name: 'imageSrc'})
export class ImageSrcPipe implements PipeTransform {

  constructor(private readonly appConfig: AppConfig) {
  }

  transform(filename: string): string {
    if (!filename) {
      return null;
    }

    return this.appConfig.cdnOrigin + '/assets/images/' + filename;
  }
}
