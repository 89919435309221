import { Component, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'aa-app',
  templateUrl: './app.html'
})
export class AppComponent implements OnInit {

  ngOnInit() {
    window.addEventListener('dragover', function(e) {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', function(e) {
      e.preventDefault();
    }, false);
  }
}
