
import { AbstractFormatter } from './abstract_formatter.class';


export class UserFormatter extends AbstractFormatter<Guid> {

  constructor() {
    super();
  }

  protected formatItemInternal(value: Guid): string {

    if (!value || value === '00000000-0000-0000-0000-000000000000') {
      return 'None';
    }

    return value;
  }
}
