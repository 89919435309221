// external
import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

// rxjs
import { Subject,  Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// common
import { AppStorageService, AuthService, Item } from '@aa/common';

// core
import { Icon, Command } from '@aa/app/ui';

// forms
import { IFormDialogContent} from '@aa/app/forms';

// local
import { ITableViewConfig, ITableColumn, IDataCollection } from '../../models';
import { TableSettingsService } from './table-settings.class';

const DEFAULT_EDITOR_WIDTH: string = '600px';

@Component({
  selector: 'aa-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css']
})
export class TableViewComponent implements OnDestroy {

  readonly closeIcon = Icon.Hide;

  private dataSubscription: Subscription;
  private routeSubscription: Subscription;

  private _config: ITableViewConfig<any> = null;

  private dialogRef: MatDialogRef<IFormDialogContent>;

  private settings: TableSettingsService;

  get config(): ITableViewConfig<any> {
    return this._config;
  }

  @Input() set config(config: ITableViewConfig<any>) {
    this._config = config;

    // use timeout to get round material dialog bug
    // https://github.com/angular/material2/issues/5268
    setTimeout(() => {
      this.init();
    });
  }

  public items: Array<Item> = [];
  public error: any = null;

  public initialFilter: string  = '';
  public filter: string = null;

  public readonly newItemCmd = new Command({name: 'New Item', description: 'Create new item', icon: Icon.Plus});
  public readonly refreshCmd = new Command({name: 'Refresh', description: 'Refresh data', icon: Icon.Refresh});
  public readonly resetColumnsCmd = new Command({name: 'Reset', description: 'Reset column filters to default', icon: Icon.Refresh});

  private readonly filterSource = new Subject<string>();

  selectedColumns: string[] = null;

  constructor(private readonly dialog: MatDialog,
              private readonly auth: AuthService,
              private readonly appStorage: AppStorageService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly location: Location,
              private readonly router: Router,
              private readonly cdRef: ChangeDetectorRef) {

    // TODO: fix these subscriptions
    this.newItemCmd.subscribe(args => {
      this.createNewItem();
    });

    this.refreshCmd.subscribe(args => {
      this.refreshData(true);
    });

    this.resetColumnsCmd.subscribe(args => {
      this.resetColumns();
    });

    this.filterSource.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.filter = value;

      if (this.settings) {
        this.settings.filter = value;
      }
    });
  }

  resetColumns() {
    this.settings.resetColumns(this._config.columns);
    this.rebuildSelectedColumns();
  }

  toggleColumnVisiblity(column: ITableColumn<any>) {
    column.hidden = !column.hidden;

    this.settings.saveColumnState(column);

    this.rebuildSelectedColumns();

    // manually trigger change detection to update the dropdown
    this.cdRef.detectChanges();
  }

  rebuildSelectedColumns() {

    this.selectedColumns = [];

    if (!this._config) {
      return;
    }

    for (const column of this._config.columns) {
      if (column.hidden) {
        continue;
      }
      this.selectedColumns.push(column.key);
    }
  }

  updateFilter(value: string) {
    this.filterSource.next(value);
  }

  ngOnDestroy() {
    this.newItemCmd.destroy();
    this.refreshCmd.destroy();
    this.resetColumnsCmd.destroy();

    this.unsubscribeData();

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = null;
    }

    if (this.dialogRef) {
      this.dialogRef.close();
    }

    if (this.settings) {
      this.settings.ngOnDestroy();
      this.settings = null;
    }
  }

  private unsubscribeData() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
      this.dataSubscription = null;
    }
  }

  private init() {

    this.error = null;

    if (!this._config) {
      this.error = 'Loading...';
      return;
    }
    const data = this._config.data;

    if (!data) {
      throw new Error('Invalid TableConfig: data is not defined');
    }

    // persistent settings
    if (!this.settings) {
      this.settings = new TableSettingsService(this.auth, this.appStorage, this._config.name);
      this.settings.initColumnState(this._config.columns);

      this.initialFilter = this.filter = this.settings.filter || '';
    }

    // set command visibility
    this.newItemCmd.enabled = data.parent.canCreate();
    if (this._config.newItemText) {
      this.newItemCmd.name = this.config.newItemText;
    }

    if (this._config.newItemTooltip) {
      this.newItemCmd.tooltip = this.config.newItemTooltip;
    }

    // subscribe to data stream
    this.unsubscribeData();
    this.dataSubscription = data.data$.subscribe(
      (list: Item[]) => {
        this.items = list.slice(0); // clone array
      },
      err => {
        this.error = err;
      }
    );

    // ui stuff
    this.rebuildSelectedColumns();

    // refresh
    this.refreshData(false);

    // watch the activated route
    if (!this.routeSubscription) {
      this.routeSubscription = this.activatedRoute.params
        .pipe(map((params: Params) => params['id']), distinctUntilChanged())
        .subscribe((id: string) => {
          if (id) {
            this.open(editor => editor.initAsync(id));
          }
        });
    }


  }

  private refreshData(force: boolean) {
    if (!force && this.config.data.hasData) {
      console.log('Using cached data for table');
      return;
    }

    this.config.data.refresh();
  }

  private open(callback: (IFormDialogContent) => void): void {

    if (this.dialogRef) {
      console.warn('table view : dialog is already open');
      return;
    }

    if (!this.config.editor) {
      throw new Error('Editor dialog is not defined');
    }

    let data = null;

    if (this.config.editorData) {
      data = this.config.editorData();
    }

    const dialogConfig: MatDialogConfig = {
      data,
      disableClose: true,
      width: this.config.editorWidth || DEFAULT_EDITOR_WIDTH
    };

    this.dialogRef = this.dialog.open(this.config.editor, dialogConfig);
    const editor = this.dialogRef.componentInstance;
    callback(editor);

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
      this.router.navigate([{}], {relativeTo: this.activatedRoute});
    });
  }

  public edit(value: Item) {
    if (!value) {
      return;
    }

    // edit the item directly
    if (this.config.directEdit) {
      this.open(editor => {
        editor.initClone(value);
      });
      return;
    }

    const id = value.id;
    if (!id) {
      return;
    }

    this.router.navigate([{id: id}], {relativeTo: this.activatedRoute});
  }

  public createNewItem() {
    this.open(editor => {
      editor.initNew();
    });
  }

  public onRowEdit(value: Item) {
    this.edit(value);
  }

  public onRowDelete(value: Item) {
    this.config.data.parent.deleteItem(value);
  }
}



