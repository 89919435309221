import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateAdapter } from '@angular/material/core';


import { AdviceAidCommonModule } from '@aa/common';

/*
 * Platform and Environment providers/directives/pipes
 */

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app_routing.module';
import { UiModule } from './ui';
import { MaterialModule } from './material';
import { CoreModule } from './core';

import { NgIdleModule } from '@ng-idle/core';

import Bugsnag, { BrowserConfig } from '@bugsnag/js'
import BugsnagPerformance, { BrowserConfiguration } from '@bugsnag/browser-performance'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { ErrorHandler } from '@angular/core'
import { environment } from '../environments/environment';

const APP_IMPORTS = [
  UiModule,
  MaterialModule,
  BrowserModule,
  BrowserAnimationsModule,

  // custom modules
  AppRoutingModule,
  AdviceAidCommonModule,
  CoreModule.forRoot(),

  // SharedModule.forRoot(),

  NgIdleModule.forRoot(),
  // SnippetsModule.forRoot()
];


// configure Bugsnag ASAP
const bugsnagConfig: Pick<BrowserConfig, "apiKey"|"appVersion"> = {
  apiKey: '48b0a86a5783ee1c9e6ce926598e74e9',
  appVersion: environment.version
};

Bugsnag.start(bugsnagConfig)

BugsnagPerformance.start(bugsnagConfig)

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: APP_IMPORTS,
  providers: [ { provide: ErrorHandler, useFactory: errorHandlerFactory } ]
})
export class AppModule {

  // inject global services
  constructor(private dateAdapter: DateAdapter<Date>) {

    // this is required for correct date formatting
    dateAdapter.setLocale('en-gb');
  }
}
