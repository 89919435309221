import {
  Directive,
  Component,
  Input,
  Output,
  EventEmitter,
  Injectable,
  ViewChild,
  ElementRef,
  Optional,
  OnDestroy
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export class DialogEvent {
  public isCancelled: boolean = false;

  public preventDefault() {
    this.isCancelled = true;
  }
}

@Injectable()
export class DialogLayoutService {

  public readonly scrollToBottomEvent = new EventEmitter();

  public scrollToBottom() {
    this.scrollToBottomEvent.emit();
  }
}

@Component({
  selector: 'aa-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnDestroy {
  @ViewChild('contentContainer') private contentContainer: ElementRef<HTMLDivElement>;

  @Input() public loading: boolean = false;
  @Input() public hasMenu: boolean = false;
  @Output() public closing = new EventEmitter<DialogEvent>();

  /**
   * Allow content to be initially hidden.
   * This is useful for complex dialogs which cause laggy animation
   * @type {boolean}
   */
  @Input() contentHidden: boolean = false;

  /**
   * Flag set when dialog is fully open
   * @type {boolean}
   */
  isOpen: boolean = false;

  subscription: Subscription;

  constructor(private dialogRef: MatDialogRef<any>,
              @Optional() dialogLayoutService: DialogLayoutService) {

    // this observable completes after first value so no need to unsubscribe
    dialogRef.afterOpened().subscribe(() => {
      this.isOpen = true;
      this.contentHidden = false;
    });

    if (dialogLayoutService) {
      this.subscription = dialogLayoutService.scrollToBottomEvent.subscribe(() => {
        this.scrollToBottom();
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  close() {
    const event = new DialogEvent();
    this.closing.emit(event);

    if (event.isCancelled) {
      return;
    }

    this.dialogRef.close();
  }


  scrollToBottom(): void  {
    try {
      const el = this.contentContainer.nativeElement;

      el.scrollTop = el.scrollHeight;
    } catch (err) {
      console.warn(err);
    }
  }
}

/*
the following templates are only included to keep Webstorm happy
without them it complains about unknown components when using the dialog content projection
*/
/* tslint:disable:directive-selector directive-class-suffix */
@Directive({
  selector: 'aa-dialog-title'
})
export class DialogTitleComponent {
}

@Directive({
  selector: 'aa-dialog-subtitle',
})
export class DialogSubTitleComponent {
}

@Directive({
  selector: 'aa-dialog-menu',
})
export class DialogMenuComponent {
}

@Directive({
  selector: 'aa-dialog-content',
})
export class DialogContentComponent {
}

@Directive({
  selector: 'aa-dialog-actions',
})
export class DialogActionsComponent {
}

@Directive({
  selector: 'aa-dialog-footer',
})
export class DialogFooterComponent {
}

@Directive({
  selector: 'aa-dialog-right',
})
export class DialogRightComponent {
}


export const DIALOG_COMPONENTS = [
  DialogComponent, DialogTitleComponent, DialogSubTitleComponent, DialogMenuComponent,
  DialogContentComponent, DialogActionsComponent, DialogFooterComponent, DialogRightComponent
];
