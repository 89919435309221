import { FormGroup } from '@angular/forms';

export class FormUtil {

  /**
   * Check form for errors and mark all fields as touched.
   * @param form:FormGroup Form to check
   * @returns {Array} list of errors. If list is empty then form is valid
   */
  public static checkForm(form: FormGroup): string[] {
    let results = [];

    if (!form) {
      results.push('Form is not defined');
      return results;
    }

    if (form.valid) {
      return results;
    }

    Object.keys(form.controls).forEach(name => {
      const child = form.controls[name];

      if (!child) {
        return;
      }

      child.markAsTouched({onlySelf: true});

      if (!child.valid) {
        results.push(name);
      }

      // check nested from group
      if (child instanceof FormGroup) {
        const childResults = FormUtil.checkForm(child);

        if (childResults.length > 0) {
          results = results.concat(childResults);
        }
      }
    });

    return results;
  }


  // Modified version of FormGroup.patchValue
  // https://github.com/angular/angular/blob/e9f307f9488e44879dc027e5f4436fb6bc046fa4/modules/%40angular/forms/src/model.ts
  // Adapted to accommodate null objects
  public static patchValue(form: FormGroup, item: any) {

    Object.keys(item).forEach(name => {
      const control = form.controls[name];
      if (!control) {
        return;
      }

      const value = item[name];

      // Nested from groups need special treatment as they do not accept null values
      if (control instanceof FormGroup && !value) {
        return;
      }

      control.patchValue(value, {onlySelf: true});
    });
    form.updateValueAndValidity();
  }

  /*
   private static markAsTouched(item:any, group:FormGroup) {
   Object.keys(item).forEach(name => {
   let child = group.controls[name];

   if (!child) {
   return;
   }
   child.markAsTouched({onlySelf:true});

   if(child instanceof FormGroup) {
   let value = item[name];
   let childResults = ItemEditorComponent.markAsTouched(value, child);
   }
   });
   }
   */


}
