// common
import { IAdviceSet, IQuestionResponse } from '@aa/common';

// local
import { LibraryItem } from './library-item.model';

export class AdviceSet extends LibraryItem implements IAdviceSet {

  name: string;

  description: string;

  slug: string;

  channelId: string;

  tagId: Guid;

  snippetIds: Guid[];

  relatedAdvice: IQuestionResponse[];

  /**
   * CSV list of search keywords
   */
  keywords?: string;

  alwaysInclude?: boolean;
}
