// core
import { Item, EnumHelper } from '@aa/common';

// local
import { ShareStatus } from './share-status.enum';
import { ChannelType } from './channel_type.enum';

export interface IChannelItemRef {
  id: Guid;
  channelId: Guid;
}


export abstract class LibraryItem extends Item {

  libraryId: Guid = null;

  created?: Date;

  createdUserId?: Guid;

  lastEdited?: Date;

  lastEditedUserId?: Guid;
}

export interface ShareRef {
  channelId: Guid;

  channelType: ChannelType;

  shared?: Date;

  sharedUserId?: Guid;
}

export abstract class ChannelItem extends LibraryItem implements IChannelItemRef, ShareRef {

  // Description
  description: string;

  // Team Id
  teamId: Guid;

  // Channel Id
  channelId: Guid;

  // Channel Type
  channelType: ChannelType;

  // id of parent item (ref items only)
  parentId?: Guid;

  // id of parent channel (ref items only)
  parentChannelId?: Guid;

  // share references
  refs?: ShareRef[];

  tags: Guid[] = [];

  /**
   * CSV list of search keywords
   */
  keywords?: string;

  // tslint:disable:no-bitwise
  public static getShareStatus(item: ChannelItem): ShareStatus {

    let status = ShareStatus.None;

    if (!item || !item.refs) {
      return status;
    }

    for (const ref of item.refs) {
      status |= ref.channelType;

      if (item.lastEdited > ref.shared) {
        status |= ShareStatus.OutOfDate;
      }
    }

    return status;
  }

  public static hasRef(item: ChannelItem, shareStatus: ShareStatus): boolean {

    if (!item || item.refs) {
      return false;
    }

    if (item.refs.every(o => !EnumHelper.hasFlag(shareStatus, o.channelType))) {
      return false;
    }

    // check sync status if required
    if (EnumHelper.hasFlag(shareStatus, ShareStatus.OutOfDate) && item.refs.every(o => o.shared >= item.lastEdited)) {
      return false;
    }

    return true;
  }


  public static addRef(item: ChannelItem, ref: ShareRef) {
    if (!item) {
      return;
    }

    ChannelItem.removeRef(item, ref.channelId);

    if (!item.refs) {
      item.refs = [];
    }

    item.refs.push(ref);
  }

  public static removeRef(item: ChannelItem, sharedChannelId: Guid) {
    if (!item || !item.refs) {
      return;
    }
    item.refs = item.refs.filter(o => o.channelId !== sharedChannelId);

    if (!item.refs.length) {
      item.refs = null;
    }
  }
}
