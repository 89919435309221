
/*
Data structure for submitting user activity reports to server
 */
export class UserActivity {

  id: string;

  start: Date;

  end: Date;

  version: string;
}
