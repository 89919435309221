<aa-dialog (keyup.escape)="close()">

  <aa-dialog-title>Channel Info</aa-dialog-title>

  <aa-dialog-content>
    <div class="channel-info-list">

      <div *ngIf="channelGroups; else noChannels">
        <div *ngFor="let group of channelGroups">

          <h6 class="dropdown-header">{{group.name}}</h6>
          <div *ngFor="let channel of group.channels" class="channel-info">

            <div class="channel-name">{{channel.name}}</div>

            <div class="channel-access">
              <span matTooltip="Access that you have for this channel">Access: </span>
              <span class="bold">{{channel | channelAccess}}</span>
            </div>

            <div *ngIf="canShare(channel)" class="channel-accepts">
                <span matTooltip="What types of data you can share into this channel">Sharing: </span>
              <span class="bold">{{channel | channelAccepts}}</span>
            </div>
          </div>

        </div>
      </div>

      <ng-template #noChannels>
        <div>No channels available</div>
      </ng-template>
    </div>
  </aa-dialog-content>

  <aa-dialog-actions>
    <button type="submit" mat-raised-button color="primary" (click)="close()">
      Done
    </button>
  </aa-dialog-actions>

  <aa-dialog>


