<aa-dialog>
  <aa-dialog-title>{{title}}</aa-dialog-title>
  <aa-dialog-content>
    <div [innerHTML]="content"></div>
    <div *ngIf="key" class="row">
      <mat-form-field>
        <input matInput placeholder="Enter the key to continue" [(ngModel)]="keyInput" autocomplete="no">
        <mat-hint>Key: {{key}}</mat-hint>
      </mat-form-field>
    </div>
  </aa-dialog-content>
  <aa-dialog-actions>
    <button [disabled]="key && key !== keyInput" mat-raised-button (click)="hide(true)">
      {{yesText}}
    </button>
    <button  mat-raised-button (click)="hide(false)">
      {{noText}}
    </button>
  </aa-dialog-actions>
</aa-dialog>


