import { Injectable } from '@angular/core';

import { AppMetaDataService, } from './app-meta-data.service';
import { AppMetaData } from '../classes';
import { AppLibrary } from '../models';

@Injectable({providedIn: 'root'})
export class LibraryResolverService {

  private _meta: AppMetaData;
  private _library: AppLibrary;

  constructor(appData: AppMetaDataService) {
    appData.metaData$.subscribe(meta => {
      this._meta = meta;
    });

    appData.currentLibrary$.subscribe(library => {
      this._library = library;
    });
  }

  public getCurrentLibrary() {
    return this._library;
  }

  public getCurrentLibraryId() {
    return this._library ? this._library.id : null;
  }

  public getLibraryOrThrow(libraryId: Guid): AppLibrary {
    if (!this._meta) {
      throw Error('App Meta Data unavailable');
    }

    return this._meta.getLibraryOrThrow(libraryId);
  }

  public getLibrary(libraryId: Guid): AppLibrary {
    if (!this._meta) {
      return null;
    }

    return this._meta.getLibrary(libraryId);
  }
}
