// angular
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

// common
import { SubscriberComponent } from '@aa/common';

// forms
import { ISelectOption } from '@aa/app/forms';

// core
import { ConfirmService } from '@aa/app/ui';


@Component({
  selector: 'aa-id-list-item',
  templateUrl: './id-list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class IdListItemComponent extends SubscriberComponent implements OnInit {

  private _itemOptions: ISelectOption[];

  public showToolbar = false;
  public idControl = new FormControl();

  @Input() set id(value: Guid) {
    this.idControl.setValue(value);
  }

  @Input() itemType: string = 'Item';

  @Input() set itemOptions(value: ISelectOption[]) {
    this._itemOptions = value;
  }

  get itemOptions(): ISelectOption[] {
    return this._itemOptions;
  }

  @Output() changed = new EventEmitter();
  @Output() deleted = new EventEmitter();

  @HostListener('mouseenter') onMouseEnter() {
    this.showToolbar = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.showToolbar = false;
  }

  constructor(private readonly fb: FormBuilder, private readonly confirm: ConfirmService) {
    super();
  }

  public ngOnInit() {
    // subscribe to changes
    this.subscribe(this.idControl.valueChanges, data => {
      this.changed.emit(data);
    });
  }

  public notifyDelete() {
    this.confirm.show({title: 'Confirm Delete', content: `Are you sure want to delete this ${this.itemType}?`}).subscribe(result => {
      if (result) {
        this.deleted.emit();
      }
    });
  }
}

