import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IConfirmDialogData } from './confirm-dialog-data.interface';


@Component({
  selector: 'aa-confirm',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public readonly title: string;
  public readonly content: string;

  public readonly yesText: string;
  public readonly noText: string;
  public readonly key: string;

  public keyInput: string;

  constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: IConfirmDialogData) {
    this.title = data.title;
    this.content = data.content;
    this.yesText = data.yes || 'Yes';
    this.noText = data.no || 'No';
    this.key = data.key;
  }

  public hide(result: boolean) {
    this.dialogRef.close(result);
  }
}
