import {
  AfterViewInit,
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation, OnDestroy
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

// ui
import { TextEditor, TextEditorFactory } from '@aa/app/ui';

// local
import { AbstractInputComponent } from '../abstract-input.component';

@Component({
  selector: 'aa-html-input',
  templateUrl: './html-input.component.html',
  styleUrls: ['./html-input.component.scss'],
  host: {
    'class': 'html-input'
  },
  encapsulation: ViewEncapsulation.None
})
export class HtmlInputComponent extends AbstractInputComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

  // @ViewChild('toolbarContainer') toolbarContainer: ElementRef;

  @ViewChild('dataElement') dataElement: ElementRef;

  @Input() label: string;
  @Input() hint: string;
  @Input() control: AbstractControl;

  @Input() maxLength: number = 3000;
  @Input() maxRows: number = 10;

  private initialized: boolean = false;

  public textEditor: TextEditor;

  get charsRemaining() {
    const val = this.control.value;

    if (!val) {
      return this.maxLength;
    }

    return this.maxLength - val.length;
  }

  private reloadData() {
    const el = this.dataElement.nativeElement;
    if (!el) {
      return;
    }

    const value = this.control.value;
    el.innerHTML = value;
  }


  protected onValueChanged(value: any) {
    const el = this.dataElement.nativeElement;
    if (!el) {
      return;
    }
    if (!this.control.dirty) {
      this.reloadData();
    }
  }

  ngOnDestroy(): void {
    if (this.textEditor) {
      this.textEditor.destroy();
      this.textEditor = null;
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.initEditor();
    }, 500);
  }

  initEditor() {
    const el = this.dataElement.nativeElement;

    this.textEditor = TextEditorFactory.create({

      // toolbarContainer: this.toolbarContainer.nativeElement
    });

    this.reloadData();
    this.textEditor.init(el);


    // change event
    this.textEditor.onChange.subscribe(editor => {
      const data = editor.getData();

      this.control.markAsDirty();
      this.control.setValue(data, {emitEvent: true});
    });
  }

  ngAfterViewChecked() {
    // mark component as initialized after the final lifecycle hook
    // otherwise we get funny behaviour with the mouse events where mouseleave is not triggered as expected
    // https: // angular.io/docs/ts/latest/guide/lifecycle-hooks.html
    this.initialized = true;
  }
}
