// common
import { IFactory, Serializer } from '@aa/common';

import { FilterSettings } from '../models';

export class FilterSettingsSerializer extends Serializer<FilterSettings> {

  public getType(): IFactory<FilterSettings> {
    return FilterSettings;
  }

  public fixUp(data: FilterSettings): FilterSettings {
    const result = Object.assign(new FilterSettings(), data);

    return result;
  }

  public getName() {
    return 'FilterSettings';
  }
}
