// external
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

// core
import { Icon } from '@aa/app/ui';
import { AbstractInputComponent } from './abstract-input.component';

@Component({
  selector: 'aa-password-input',
  templateUrl: './password-input.component.html',
  host: {
    'class': 'form-control'
  }
})
export class PasswordInputComponent extends AbstractInputComponent {
  @Input() control: AbstractControl;
  @Input() label: string;

  public readonly viewIcon = Icon.View;

  public showText: boolean = false;

  public get inputType(): string {
    return this.showText ? 'text' : 'password';
  }

  public get isEyeHidden(): boolean {
    return !this.control.value;
  }

  public toggleText() {
    this.showText = !this.showText;
  }

  protected onValueChanged(value: any) {

    // disable show text if value is undefined
    if (!value) {
      this.showText = false;
    }
  }
}
