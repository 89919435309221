<div (click)="dismiss()" class="container noselect" matTooltip="Click to dismiss" matTooltipShowDelay="1000">
  <div>
    <i [aaIcon]="icon"></i>
  </div>
  <div>
    <div class="title">{{title}}</div>
    <div class="message" [innerHtml]="message"></div>
  </div>
</div>


