<div class="header">
  <div class="label">{{label}}</div>
  <button tabindex="-1" mat-raised-button (click)="addNewItem()"  matTooltip="Add new item">
    Add
  </button>
</div>

<div class="list" cdkDropList (cdkDropListDropped)="onDrop($event)">
    <aa-id-list-item class="list-item" *ngFor="let item of list; let i = index"
        [id]="item.id"
        [itemOptions]="itemOptions"
        (deleted)="deleteItem(i)"
        (changed)="updateItem(i, $event)">
    </aa-id-list-item>
</div>
