import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// local
import { AppSettingsService } from '../../services';


@Component({
  selector: 'aa-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.css']
})
export class SettingsDialogComponent implements OnInit {

  constructor(public readonly settings: AppSettingsService,
              private readonly dialogRef: MatDialogRef<SettingsDialogComponent>) {

  }

  ngOnInit() {
  }

  submit() {
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
