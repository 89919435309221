import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OnInit, Directive } from '@angular/core';

// common
import { SubscriberComponent } from '@aa/common';

@Directive()
export abstract class AbstractInputComponent extends SubscriberComponent implements OnInit {

  public control: AbstractControl = null;
  private subscription: Subscription = null;

  public errors: string[] = null;

  public get hasError() {
    return this.errors && this.errors.length > 0;
  }

  public get visible(): boolean {
    return this.control.invalid && this.control.touched;
  }

  public static formatErrors(errors: any): string[] {

    if (!errors) {
      return null;
    }

    const messages: string[] = [];

    for (const key in errors) {
      if (!errors.hasOwnProperty(key)) {
        continue;
      }

      const message = AbstractInputComponent.formatError(key, errors[key]);

      if (message) {
        messages.push(message);
      }
    }



    if (messages.length === 0) {
      messages.push('Invalid field');
    }
    return messages;
  }

  public static formatError(key: string, value: any): string {
    switch (key) {
      case 'required':
        return 'Field is required';

      case 'minlength':
        const minLength = value.requiredLength;
        return `Value must be at least ${minLength} characters long`;

      case 'maxlength':
        const maxLength = value.requiredLength;
        return `Value must be less than ${maxLength} characters long`;

      case 'email':
        return 'Invalid email address';

      case 'https':
        return 'Url must start with https://';

      case 'color':
        return 'Invalid hex color';

      case 'keyword':
        return 'Keyword list contains invalid characters. Please use letters and numbers only separated by commas';
    }

    if (value instanceof String) {
      value = value.valueOf();
    }

    if (typeof value === 'string' && value.length > 4) {
      return value as string;
    }

    return `Invalid value (${key})`;
  }

  protected onValueChanged(value: any) {}

  ngOnInit() {
    if (!this.control) {
       throw new Error('Invalid control passed to form component');
    }

    super.subscribe(this.control.statusChanges, o => this.update());
    super.subscribe(this.control.valueChanges, o => this.onValueChanged(o));

    this.update();
  }

  private update() {
    if (this.control.invalid) {
      this.errors = AbstractInputComponent.formatErrors(this.control.errors);
    } else {
      this.errors = [];
    }
  }
}
