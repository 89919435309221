// external
import { Component } from '@angular/core';

// core
import { AuthService, IUser } from '@aa/common';

@Component({
  selector: 'aa-profile-info',
  templateUrl: './profile_info.component.html',
  styleUrls: ['./profile_info.component.css']
})
export class ProfileInfoComponent {

  constructor(private auth: AuthService) {
  }

  public get user(): IUser {
    return this.auth.user;
  }
}
