/**
 * Created by Tom Makin on 23/10/2016.
 */
export enum Icon {
  None = 0,
  View,
  File,
  Upload,
  Spinner,
  Help,
  Send,

  Print,
  Word,
  Pdf,
  Code,
  Save,
  Clone,
  Hide,
  Clear,

  Search,
  Copy,

  More,
  Less,

  Undo,
  Redo,
  Edit,
  Delete,
  Action,
  Table,
  Image,
  Intro,
  Signature,
  Plus,
  Refresh,
  Feedback,

  Private,
  Team,

  External,
  Share,
  Publish,
  Unpublish,

  Checked,
  Unchecked,

  Alert,
  Error,
  Success,
  Info,

  List,
  Channel,
  Tag,
  Localise,
  AdviceSet
}
