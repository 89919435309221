// external
import { Pipe, PipeTransform } from '@angular/core';

import { TagType } from '@aa/common';

// shared
import { Tag } from '../models';

/*
 * Formats access for an AppChannel model
 * Usage:
 *   item | channelAccess
 */
@Pipe({name: 'tagClass'})
export class TagClassPipe implements PipeTransform {

  public static getCssClass(type: TagType) {
    switch (type) {
      case TagType.Cat:
        return 'tag-1';
      case TagType.Section:
        return 'tag-2';
      case TagType.Topic:
        return 'tag-3';
      default:
        return 'tag-none';
    }
  }

  transform(tag: Tag): string {
    return TagClassPipe.getCssClass(tag ? tag.tagType : TagType.None);
  }
}
