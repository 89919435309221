// external
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

// local
import { Hotkey } from '../models';

const INVALID_TARGETS = ['INPUT', 'SELECT', 'TEXTAREA'];

// https://netbasal.com/diy-keyboard-shortcuts-in-your-angular-application-4704734547a2
@Injectable({providedIn: 'root'})
export class HotkeysService {
    private readonly hotkeyMap = new Map<Hotkey, Function>();

    constructor(private eventManager: EventManager,
                @Inject(DOCUMENT) private document: HTMLElement) {
    }


    public add(hotkey: Hotkey): void {
        this.remove(hotkey);

        const eventName = `keydown.${hotkey.combo}`;



        const unsubscribeFunc = this.eventManager.addEventListener(this.document, eventName, (ev: KeyboardEvent) => {
            const target = <HTMLElement>ev.target;
            const nodeName = target.nodeName.toUpperCase();

            if (INVALID_TARGETS.indexOf(nodeName) >= 0) {
              console.log('hotkey blocked', eventName, nodeName);
              return;
            }

            console.log('hotkey trigger: ' + eventName);

            return hotkey.callback(ev);
          }
        );

        this.hotkeyMap.set(hotkey, unsubscribeFunc);
    }

    public remove(hotkey: Hotkey): void {

      const unsubscribeFunc = this.hotkeyMap.get(hotkey);

      if (unsubscribeFunc) {
        unsubscribeFunc();
        this.hotkeyMap.delete(hotkey);
      }
    }
}
