// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { VariableType } from '@aa/common';
import { VariableTypeFormatter } from '../classes';

/*
 * Formats VariableType properties
 * Usage:
 *   item.varType | teamPlanType
 */
@Pipe({name: 'variableType'})
export class VariableTypePipe implements PipeTransform {

  private readonly formatter = new VariableTypeFormatter();

  transform(varType: VariableType): string {
    return this.formatter.format(varType);
  }
}
