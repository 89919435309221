import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { TeamApiService } from '../../services';
import { Team } from '../../models';

@Component({
  selector: 'aa-team-logo',
  templateUrl: './team-logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class TeamLogoComponent {

  private _team: Team = null;
  private _teamId: string = null;
  private subscription: Subscription;

  public loading: boolean = false;
  public name: string;
  public logoImage: string;

  constructor(private teamService: TeamApiService) {
  }

  get team() {
    return this._team;
  }

  @Input()
  set team(value: Team) {
    this._team = value;

    if (!value) {
      this.name = this.logoImage = null;
      return;
    }

    this.name = value.name;
    this.logoImage = value.logoImage;
    this.loading = false;
  }


  @Input('teamId')
  set teamId(id: Guid) {
    if (id === this._teamId) {
      return;
    }

    this._teamId = id;
    this.team = null;

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    if (!id) {
      this.loading = false;
      return;
    }

    this.loading = true;

    this.subscription = this.teamService.getCachedItem(id)
      .subscribe(
        team => { // success
          this.team = team;
        },
        err => { // error
          console.log('failed to get logo for team ' + id, err);
          this.loading = false;
        }
      );
  }
}
