import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import Cookies from 'js-cookie';

// @aa/common
import { AppConfig, IAnalyticsContext } from './app-config.service';

export interface IAnalyticsEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

export type CookiePref = 'accepted' | 'rejected';

interface CookiePrefWithDate {
  value: CookiePref;
  date: string|Date;
}

interface DefaultEventParams {
  teamName?: string;
  domain?: string;
}

const COOKIE_PREF_KEY = 'cookiePref';
const YEAR_IN_MILLISECONDS = 31556952000;

@Injectable({providedIn: 'root'})
export class AnalyticsService {

  public readonly appOrigin: string;

  private start: NavigationStart = null;
  private startTime: number = 0;

  public cookiePref: CookiePref| null = null;

  private readonly gtag: Gtag.Gtag = window.gtag;
  private gtagInitialised = false;

  private analyticsContext: IAnalyticsContext = {};

  constructor(private readonly appConfig: AppConfig,
              location: LocationStrategy,
              private readonly router: Router,
              private readonly ngZone: NgZone) {

    this.appOrigin = window.location.origin + location.getBaseHref();

    // listen to router events
    this.router.events.subscribe(val => {

      if (val instanceof NavigationStart) {
        this.setNavigationStart(val);
      } else if (val instanceof NavigationCancel) {
        this.clearNavigationStart();
      } else if (val instanceof NavigationEnd) {
        this.trackPageView(val);
        this.clearNavigationStart();
      }
    });

    // listen to print events
    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('print');

      const callback = (mql: MediaQueryListEvent) => {
        if (!mql.matches) {
          this.trackPrintEvent();
        }
      };

      /* tslint:disable:deprecation */
      if (mediaQueryList.addEventListener) { // everyone else
        mediaQueryList.addEventListener('change', callback);
      } else if (mediaQueryList.addListener) { // IE 11 & safari
        mediaQueryList.addListener(callback);
      }
      /* tslint:enable:deprecation */
    }

    this.appConfig.analyticsContext$.subscribe(ctx => {
      this.analyticsContext = ctx || {};
      this.initGoogleAnalytics();
    });

    window.onafterprint = (ev) => this.trackPrintEvent();

    this.cookiePref = this.readCookiePref();
    this.updateGoogleAnalyticsConsent();

    this.initHotjar();
  }

  private readCookiePref(): CookiePref | null {
    try {
      const json = window.localStorage.getItem(COOKIE_PREF_KEY);
      if (!json) {
        return null;
      }

      const data: CookiePrefWithDate = JSON.parse(json);

      // check time remaining
      const timeRemaining = Date.now() - new Date(data.date).getTime();
      if (timeRemaining > YEAR_IN_MILLISECONDS) {
        console.log('CookiePref expired');
        return null;
      }

      return data.value;
    } catch (err) {
      console.warn('Failed to read cookie preference');
      return null;
    }
  }

  get hasCookiePref(): boolean {
    return !!this.cookiePref;
  }

  public setCookiePref(cookiePref: CookiePref) {
    this.cookiePref = cookiePref;

    try {
      const data: CookiePrefWithDate = {
        value: cookiePref,
        date: new Date()
      };
      window.localStorage.setItem(COOKIE_PREF_KEY, JSON.stringify(data));
    } catch (e) {
      console.warn('Failed to save cookie preference');
    }

    this.updateGoogleAnalyticsConsent();
  }

  private setNavigationStart(value: NavigationStart) {
    this.start = value;
    this.startTime = new Date().getTime();
  }

  private clearNavigationStart() {
    this.start = null;
    this.startTime = 0;
  }

  private trackPageView(end: NavigationEnd) {
    if (!this.start) {
      return;
    }

    if (this.start.id !== end.id) {
      console.warn('Analytics: Navigation ID mismatch', this.start, end);
      return;
    }

    const url = end.urlAfterRedirects.split('?')[0];
    const duration = new Date().getTime() - this.startTime;

    this.ngZone.runOutsideAngular(() => {
      if (this.gtag) {
        //https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
        this.gtag('event', 'page_view', {
          page_location: this.appOrigin,
          page_path: url,
        })

        this.gtag('event', 'timing_complete', {
          'name' : 'load',
          'value' : duration,
          'event_category' : 'Page Load'
        });
      }
    });
  }

  public trackTiming(e: IAnalyticsEvent) {
    console.warn('Timing events not yet implemented');
  }

  public trackPrintEvent() {
    this.trackExportEvent('Print');
  }

  public trackExportEvent(action: 'Print'|'Email'|'Pdf'|'Word'|'Html', label?: string) {
    this.trackEvent({
      category: 'Export',
      action: action,
      label: label
    });
  }

  public trackErrorEvent(name: string, message: string) {
    this.trackEvent({
      category: 'Error',
      action: name,
      label: message
    });
  }

  public trackOutboundLink(href: string, useBeacon: boolean = true) {

    if (this.gtag) {
      gtag("event", "Outgoing Links", {
        "to": href
      });
    }
  }

  public trackEvent(e: IAnalyticsEvent) {

    // log events to console in debug mode
    if (!this.appConfig.production) {
      console.log('Analytics Event', e);
    }

    // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
    if (this.gtag) {
      const params = {
        'event_category': e.category,
        ...(e.label ? {'event_label': e.label} : null),
        ...(e.value != undefined ? {'value': e.value} : null),
      }

      this.gtag('event', e.action, params);
      console.log("gtag event", e.action, params);
    }
  }

  private initHotjar(): boolean {
    if (window['hj']) {
      return true;
    }

    const hjid = this.appConfig.hotjarId;
    if (!hjid) {
      return false;
    }

    // modified version of hotjar snippet with hjid injected
    /* tslint:disable:max-line-length */
    (function(h, o, t, j, a, r) {

      // @ts-ignore
      h.hj = h.hj || function() {(h.hj.q = h.hj.q || []).push(arguments); }; h._hjSettings = {hjid: hjid, hjsv: 6}; a = o.getElementsByTagName('head')[0]; r = o.createElement('script'); r.async = 1; r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }

  // https://stackoverflow.com/a/65865934
  private updateGoogleAnalyticsConsent() {
    if(!this.gtag) {
      return;
    }

    const cookiesEnabled = (this.appConfig.appName== "app" || this.cookiePref === 'accepted');
    const consent = cookiesEnabled ? 'granted' : 'denied';

    this.gtag('consent', 'update', {
      'analytics_storage': consent
    });

    console.log(`Analytics cookies: ${consent}`);

    // if rejected then delete all ga cookies
    if(!cookiesEnabled) {
      const options = { path: '/', domain: document.domain };
      Cookies.remove('_ga', options);

      const cookieSuffix = this.appConfig.gaKey.substring(2);
      Cookies.remove(`_ga_${cookieSuffix}`, options);
    }
  }

  private initGoogleAnalytics(): boolean {
    if(!this.gtag) {
      console.warn("gtag not loaded");
      return false;
    }

    const measurementId = this.appConfig.gaKey;
    if (!measurementId) {
      console.warn('GTag measurement ID undefined');
      return false;
    }

    this.gtag('config', measurementId, {
      custom_map: {
        dimension1: 'aa_team',
        dimension2: 'aa_domain'
      },
      send_page_view: false,
      app_version: this.appConfig.version,
      app_name: this.appConfig.appName,
      aa_team: this.analyticsContext.teamName,
      aa_domain: this.analyticsContext.domain
    });

    console.log('Analytics Context', this.analyticsContext);

    return true;
  }
}
