import { Component, Input, OnInit } from '@angular/core';
import { IFormDialogContent, IFormSubmitOptions } from '../models';

@Component({
  selector: 'aa-form-dialog',
  templateUrl: './form-dialog.component.html',
})
export class FormDialogComponent implements OnInit {
  @Input() content: IFormDialogContent;

  ngOnInit() {
    if (!this.content) {
      throw Error('IFormDialog not defined');
    }
  }

  save($event: MouseEvent) {

    const options: IFormSubmitOptions = $event.ctrlKey ? {
      close: false,
      invalidate: true
    } : null;

    this.content.submit(options);
  }

}
