import { HeaderAlignment } from '../models';
import { AbstractFormatter } from './abstract_formatter.class';

export class HeaderAlignmentFormatter extends AbstractFormatter<HeaderAlignment> {

  private static readonly nameMap = new Map<HeaderAlignment, string>([
    [HeaderAlignment.Right, 'Right'],
    [HeaderAlignment.Left, 'Left'],
    [HeaderAlignment.Center, 'Center']
  ]);

  public static formatName(value: HeaderAlignment): string {
    if (!value) {
      value = HeaderAlignment.Right;
    }

    const name = HeaderAlignmentFormatter.nameMap.get(value);
    return name || `HeaderAlignment ${value}`;
  }

  protected formatItemInternal(value: HeaderAlignment): string {
    return HeaderAlignmentFormatter.formatName(value);
  }
}
