<aa-dialog>
  <aa-dialog-title>{{title}}</aa-dialog-title>
  <aa-dialog-content><div [innerHTML]="content"></div></aa-dialog-content>
  <aa-dialog-actions>
    <button  mat-raised-button (click)="hide(true)">
      OK
    </button>
  </aa-dialog-actions>
</aa-dialog>


