import { IFormatter } from './abstract_formatter.class';

export class ArrayFormatter implements IFormatter {

  public format(data: Array<any>): string {

    if (!data || !data.length) {
      return 'Empty';
    }

    return `${data.length}`;
  }
}
