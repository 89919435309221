import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { TagCacheService } from '../../services';

import { LibraryTagData } from '../../classes';
import { Tag } from '../../models';

@Component({
  selector: 'aa-tag-list',
  templateUrl: './tag-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagListComponent {

  private _tagIds: Guid[];
  private _libraryTags: LibraryTagData;

  @Input() set tagIds(value: Guid[]) {
    this._tagIds = value;
    this.init();
  }

  public tags: Tag[] = [];

  constructor(private readonly tagCache: TagCacheService) {
    tagCache.data$.pipe(take(1)).subscribe(data => {
      this._libraryTags = data;
      this.init();
    });
  }

  init() {

    if (!this._libraryTags) {
      this.tags = [];
      return;
    }



    this.tags = this._libraryTags.findTags(this._tagIds);
  }
}
