import { FormGroup, Validators } from '@angular/forms';
import { IFactory, Serializer } from '@aa/common';

import { IFormSerializer, FormConfigBuilder } from '../classes';
import { Library } from '../models';


const fb = new FormConfigBuilder<Library>([
  { key: 'name', validators: [Validators.required] },
  { key: 'description', validators: [Validators.required] },
  { key: 'logoImage' },
  { key: 'serviceName' },
]);

export class LibrarySerializer extends Serializer<Library> implements IFormSerializer<Library> {

  public getType(): IFactory<Library> {
    return Library;
  }

  public getName(): string {
    return 'Library';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
