// common
import { IQuestion, IQuestionResponse } from '@aa/common';

// local
import { LibraryItem } from './library-item.model';

export class Question extends LibraryItem implements IQuestion {

  text: string;

  name: string;

  slug: string;

  treeId: string;

  tagId: Guid;

  variableId?: Guid;

  relatedQuestionId?: Guid;

  responses: IQuestionResponse[];

  entryPoint?: boolean;

  info: string;
}
