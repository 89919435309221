import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';

import { AppLibrary, Question, } from '../models';
import { QuestionSerializer } from '../serializers';

import { AbstractApiService } from './abstract-api.service';
import { LibraryResolverService } from './library-resolver.service';
import { AbstractTaggedItemCache } from './abstract-tagged-item-cache.service';
import { TagCacheService } from './tag-cache.service';


@Injectable({providedIn: 'root'})
export class QuestionApiService extends AbstractApiService<Question> {

  constructor (injector: Injector, private readonly libraryResolver: LibraryResolverService) {
    super(injector, 'questions', new QuestionSerializer());
  }

  getLibraryQuestionsByLibraryId(libraryId: Guid): Observable<Question[]> {

    const library = this.libraryResolver.getLibrary(libraryId);

    if (!library) {
      console.warn('Failed to resolve library', libraryId);
      return of([]);
    }

    return this.getLibraryQuestions(library);
  }

  getLibraryQuestions(library: AppLibrary): Observable<Question[]> {
    const args = AbstractApiService.getLibraryArgs(library);
    return super.getList(args);
  }
}

/**
 * Stateful service for caching questions associated with current library
 */
@Injectable({providedIn: 'root'})
export class QuestionCacheService extends AbstractTaggedItemCache<Question> {

  constructor(tagCache: TagCacheService, api: QuestionApiService) {
    super(tagCache, api);
  }
}
