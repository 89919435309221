import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable,  of, throwError, EMPTY as empty } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// shared
import { AppChannel, ChannelAccess } from '../../models';
import { AppMetaDataService } from '../../services';
import { ChannelSelectDialogComponent, IChannelSelectDialogData } from './channel-select-dialog.component';


@Injectable({providedIn: 'root'})
export class ChannelSelectService {

  writableChannels: AppChannel[] = [];

  constructor(private dialog: MatDialog, metaService: AppMetaDataService) {
    metaService.metaData$.subscribe(data => {

       this.writableChannels = data ?
         data.filterChannelsByAccess(ChannelAccess.Create, false) : [];

    });
  }

  get writableChannelCount(): number {
    return this.writableChannels.length;
  }

  selectWritableChannel(prompt: string): Observable<AppChannel> {
    return this.selectChannel(this.writableChannels, prompt);
  }

  selectChannel(channels: AppChannel[], prompt: string): Observable<AppChannel> {
    if (!channels || !channels.length) {
      return throwError('No valid channels available');
    }

    // if single channel then return
    if (channels.length === 1) {
      return of(channels[0]);
    }

    // otherwise open up select dialog
    const data: IChannelSelectDialogData = {
      channels: channels,
      prompt: prompt
    };

    const ref = this.dialog.open(ChannelSelectDialogComponent, {data});

    return ref.afterClosed().pipe(
      mergeMap(res => res ? of(res) : empty)
    );
  }
}
