import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

// https://github.com/alphagov/govuk-frontend/blob/master/src/components/details/details.js

// Create a flag to know if the browser supports navtive details
const NATIVE_DETAILS = typeof document.createElement('details').open === 'boolean';

interface IEventInfo {
  name: string;
  handler: (e: Event) => void;
}

@Directive({
  selector: '[aaDetails]'
})
export class DetailsDirective implements AfterViewInit, OnDestroy {

  @Output()
  public readonly detailsClick = new EventEmitter();

  private open: boolean = false;

  private details: HTMLDetailsElement;
  private summary: Element;
  private content: Element;

  private polyfillEnabled: boolean;

  private readonly summaryEvents: IEventInfo[] = [];

  constructor(private readonly elRef: ElementRef) {
    this.details = this.elRef.nativeElement;
  }

  ngOnDestroy() {

    this.destroySummaryEvents();

    this.details = null;
    this.summary = null;
    this.content = null;
  }

  ngAfterViewInit() {
    if (NATIVE_DETAILS) {
      return;
    }

    this.summary = this.details.getElementsByTagName('summary').item(0);
    this.content = this.details.getElementsByTagName('div').item(0);


    this.polyfillEnabled = this.summary != null && this.content != null;

    if (!this.polyfillEnabled) {
      console.warn('Invalid details element');
      return;
    }

    this.summary.setAttribute('tabIndex', '0');

    this.bindSummaryEvents();

    this.syncAttributes();
  }

  private destroySummaryEvents() {
    if (!this.summary) {
      return;
    }

    this.summaryEvents.forEach(o => this.summary.removeEventListener(o.name, o.handler));
    this.summaryEvents.length = 0;
  }

  private bindSummaryEvents() {
    if (!this.summary) {
      return;
    }

    const bindEvent = (name: string, handler: (e: Event) => void) => {
      this.summary.addEventListener(name, handler);
      this.summaryEvents.push({name, handler});
    };

    bindEvent('click', (event: Event) => {

      this.detailsClick.emit();
      this.open = !this.open;
      this.syncAttributes();
    });

    // Prevent keyup to prevent clicking twice in Firefox when using space key
    bindEvent('keyup', (event: KeyboardEvent) => {
      const target = <Element>event.target;
      if (event.key === 'Spacebar' || event.key === ' ') {
        if (target.nodeName.toLowerCase() === 'summary') {
          event.preventDefault();
        }
      }
    });

    bindEvent('keypress', (event: KeyboardEvent) => {
      const target = <any>event.target;

      // When the key gets pressed - check if it is enter or space
      if (event.key === 'Enter' || event.key === 'Spacebar' || event.key === ' ') {
        if (target.nodeName.toLowerCase() === 'summary') {

          // Prevent space from scrolling the page
          // and enter from submitting a form
          event.preventDefault();

          // Click to let the click event do all the necessary action
          if (target.click) {
            target.click();
          }
        }
      }
    });
  }

  private syncAttributes() {
    if (!this.polyfillEnabled) {
      return;
    }

    if (this.open) {
      this.details.setAttribute('open', '');
      this.summary.setAttribute('aria-expanded', 'true');
      this.content.setAttribute('aria-hidden', 'false');

      // console.log('clickDetails:open');
    } else {
      this.details.removeAttribute('open');
      this.summary.setAttribute('aria-expanded', 'false');
      this.summary.setAttribute('aria-hidden', 'true');

      // console.log('clickDetails:close');
    }
  }
}
