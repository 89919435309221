// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { EnumHelper } from '@aa/common';

// shared
import { AppChannel, ChannelAccess } from '../models';

/*
 * Formats access for an AppChannel model
 * Usage:
 *   item | channelAccess
 */
@Pipe({name: 'channelAccess'})
export class ChannelAccessPipe implements PipeTransform {

  // define list of flags in the order we wish them to appear
  static flagList = [
    ChannelAccess.Create,
    ChannelAccess.Read,
    ChannelAccess.Update,
    ChannelAccess.Delete,
    ChannelAccess.Share];

  static formatAccessFilter(filter: ChannelAccess): string {

    const flagStrings: string[] = [];

    // extract all known flags from the filter
    for (const flag of ChannelAccessPipe.flagList) {
        if (EnumHelper.hasFlag(filter, flag)) {
          flagStrings.push(ChannelAccessPipe.formatAccessFlag(flag));
        }
    }

    const count = flagStrings.length;

    if (count === 0) {
      return 'None';
    }

    return flagStrings.join(', ');
  }

  /**
   * Format a single channel access flag
   * @param {ChannelAccess} flag
   * @returns {any}
   */
  static formatAccessFlag(flag: ChannelAccess) {
    switch (flag) {
      case ChannelAccess.None:   return 'None';
      case ChannelAccess.Create: return 'Create';
      case ChannelAccess.Read:   return 'Read';
      case ChannelAccess.Update: return 'Update';
      case ChannelAccess.Delete: return 'Delete';
      case ChannelAccess.Share:  return 'Share';
      default: return '';
    }
  }

  transform(channel: AppChannel): string {
    if (!channel) {
      return 'None';
    }

    return ChannelAccessPipe.formatAccessFilter(channel.access);
  }
}
