<!-- <div *ngIf="value >= 0"> -->
<div *ngIf="active" class="content">
  <mat-spinner class="spinner">
  </mat-spinner>
  <div class="caption">{{message}}</div>
</div>

<div *ngIf="error" class="caption">
  {{error}}
</div>
