import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, exhaustMap, map, switchMap, tap, throttleTime } from 'rxjs/operators';

import { AppStorageService, AuthService, ISelfServMeta, IUser, SelfServDataService, SelfServMetaStatus } from '@aa/common';
import { Command, Icon } from '@aa/app/ui';
import { AppDataUpdateOptions } from '@aa/app/shared';

const updateOptions: AppDataUpdateOptions = {
  throttleTime: 1000,
  updateInterval: 5 * 60 * 1000, // 5 mins
};

const CACHE_KEY = 'currentSelfServDomain';

const DEFAULT_META: ISelfServMeta = {
  metaStatus: SelfServMetaStatus.Error,
  domain: 'Undefined'
} as any;

@Injectable({providedIn: 'root'})
export class SelfServMetaListCache implements OnDestroy {

  private readonly domainSource = new BehaviorSubject<Guid>(null);
  private readonly updateEventSource = new BehaviorSubject<string>('initial');
  private readonly metaSource = new BehaviorSubject<ISelfServMeta[]>(null);

  public readonly metaList$: Observable<ISelfServMeta[]> = this.metaSource.asObservable();
  public readonly currentMeta$: Observable<ISelfServMeta>;

  public readonly refreshCommand: Command = new Command({
    name: 'Refresh',
    description: 'Refresh SelfSelf data',
    icon: Icon.Refresh});

  constructor(private readonly dataService: SelfServDataService, auth: AuthService, appStorage: AppStorageService) {
    auth.loggedIn$.pipe(
      tap(user => user && this.setCurrentDomain(appStorage.getString(CACHE_KEY))), // push saved domain
      switchMap(user => this.buildMetaStream(user)))
      .subscribe(this.metaSource);

    this.currentMeta$ = this.buildCurrentMetaStream(this.metaSource, this.domainSource);

    // stash current library in storage
    this.currentMeta$.subscribe(meta => {
      if (meta) {
        appStorage.setString(CACHE_KEY, meta.domain);
      }
    });

    this.refreshCommand.subscribe(() => this.refresh());
  }

  public ngOnDestroy(): void {
    this.domainSource.complete();
    this.updateEventSource.complete();
    this.metaSource.complete();
    this.refreshCommand.destroy();
  }

  public setCurrentDomain(domain: string) {
    this.domainSource.next(domain);
  }

  public refresh() {
    this.updateEventSource.next('refresh');
  }

  // create stream for selecting active meta based on treeId
  private buildCurrentMetaStream(meta$: Observable<ISelfServMeta[]>, domain$: Observable<Guid>): Observable<ISelfServMeta> {

    return combineLatest([meta$, domain$.pipe(distinctUntilChanged())])
      .pipe(map(([metaList, domain]) => {
          if (!metaList) {
            return null;
          }

          if (!metaList.length) {
            console.warn('selfserv meta list is empty');
            return DEFAULT_META;
          }

          const meta = domain && metaList.find(o => o.domain === domain);
          return meta || metaList[0];
        })
      );
  }

  private buildMetaStream(user: IUser): Observable<ISelfServMeta[]> {
    if (!user) {
      return of(null);
    }


    const metaData$ = this.dataService.getMetaListByTeamId(user.teamId)
      .pipe(
        catchError(error => {
          console.log('Failed to get self serv meta data', error);
          return of([DEFAULT_META]);
        }));

    return this.updateEventSource.pipe(
      throttleTime(updateOptions.throttleTime),
      switchMap(o => timer(0, updateOptions.updateInterval)),
      exhaustMap(() => metaData$) // exhaust map discards incoming events until inner observable completes
    );
  }
}
