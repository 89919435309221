// angular
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

// rxjs
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

// local
import { UserNameService } from './user-api.service';
import { DateFormatter } from '../classes';

@Injectable({providedIn: 'root'})
export class DateStampService {

  private readonly dateFormatter: DateFormatter = new DateFormatter();

  constructor(private readonly userNameService: UserNameService) {
  }

  formatAsync(date: Date, userId: Guid = null): Observable<string> {

    const dateStr = this.dateFormatter.formatItem(date);

    if (!dateStr) {
      return of('-');
    }

    if (!userId) {
      return of(dateStr);
    }

    return this.userNameService.getUserName(userId)
      .pipe(
        startWith(dateStr),
        // catchError(() => null),
        map(name => {

          if (!name) {
            return dateStr;
          }

          return dateStr + ' by ' + name;
        })
      );
  }
}
