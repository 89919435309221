import { Injectable } from '@angular/core';
import {
  Router, ActivatedRouteSnapshot, CanActivate, CanActivateChild,
  RouterStateSnapshot, UrlTree
} from '@angular/router';

import { AuthService } from '@aa/common';
import { IPageRouteData } from '@aa/app/shared/models/page-routes.interface';

@Injectable({providedIn: 'root'})
export class PageGuardService implements CanActivate, CanActivateChild {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    const data = <IPageRouteData>route.data;

    // permission error
    if (data.roles && !this.auth.hasRequiredRoles(data.roles)) {

      let redirectUrl = '/pages/error';
      if (data.errorPage) {
        redirectUrl += '/' + data.errorPage;
      }

      return this.router.parseUrl(redirectUrl);
    }

    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
