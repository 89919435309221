import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Command, Icon } from '@aa/app/ui';

import { IFeedbackDialogData, Snippet } from '../../models';

import { FeedbackDialogComponent } from './feedback-dialog.component';

@Injectable({providedIn: 'root'})
export class FeedbackDialogService {

  constructor(private readonly dialog: MatDialog) {
  }

  public static createSnippetFeedbackCommand() {
    return new Command({
      name: 'Feedback',
      description: `Provide feedback on global snippets`,
      icon: Icon.Feedback,
      enabled: false
    });
  }

  public open(data: IFeedbackDialogData = {}, width: string = '550px'): MatDialogRef<FeedbackDialogComponent> {

    return this.dialog.open(FeedbackDialogComponent, {data, disableClose: true, width});
  }

  public openSnippet(snippet: Snippet): MatDialogRef<FeedbackDialogComponent> {
    return this.open({snippet: snippet}, '1100px');
  }

}
