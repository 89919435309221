// rxjs
import { Observable, ReplaySubject, of, Subject } from 'rxjs';
import { map, share } from 'rxjs/operators';

// common
import { ApiResponse, Item, SubscriberComponent } from '@aa/common';

// local
import { IDataService, IDataCollection, IDataCollectionArgs } from '../models';
import { OnDestroy } from '@angular/core';

export interface IDataCollectionTransform<T> {
  apply(data: T[]): T[];
}

export class DataCollection<T extends Item> extends SubscriberComponent implements IDataCollection<T> {

  private static viewCount: number = 0;

  public readonly data$: Observable<T[]>;
  public readonly name: string;
  private readonly pendingItems = new Map<Guid, Observable<T>>();
  private readonly dataSource = new ReplaySubject<T[]>(1);
  private data: T[] = [];
  private _loading: boolean = false;

  constructor(public readonly parent: IDataService<T>,
              private readonly args: IDataCollectionArgs = {},
              private readonly transform: IDataCollectionTransform<T> = null) {

    super();

    DataCollection.viewCount++;

    this.name = `${parent.getName()}_${DataCollection.viewCount}`;

    this.data$ = this.dataSource.asObservable();

    this.subscriptions.push(parent.update$.subscribe(item => this.handleUpdate(item)));
    this.subscriptions.push(parent.delete$.subscribe(id => this.handleDelete(id)));
  }

  public destroy(): void {
    super.ngOnDestroy();
    this.dataSource.complete();
  }

  public get loading() {
    return this._loading;
  }

  public get hasData() {
    return this.data && this.data.length > 0;
  }

  public getData(): T[] {
    return this.data;
  }


  public saveItem(item: T): Observable<T> {
    return this.parent.saveItem(item);
  }

  private deletePendingItem(id: Guid) {
    this.pendingItems.delete(id);
  }

  public getItem(id: Guid): Observable<T> {
    const item = this.find(id);
    if (item) {
      return of(item);
    }

    const pendingItem = this.pendingItems.get(id);
    if (pendingItem) {
      return pendingItem;
    }

    const subject = new Subject<T>();
    this.parent.getItem(id).subscribe(subject);

    subject.subscribe(
      res => {
        this.deletePendingItem(id);
        this.addItem(res);
      },
      err => {
        this.deletePendingItem(id);
      });

    this.pendingItems.set(id, subject);

    // noinspection TypeScriptValidateTypes
    return subject;
  }

  /**
   * Refresh current data (chainable)
   */
  public refresh(): DataCollection<T> {

    if (this._loading) {
      // TODO: use timeout to schedule a another refresh
      return this;
    }

    this._loading = true;

    // noinspection TypeScriptValidateTypes
    this.parent.getList(this.args).pipe(
      map(items => {
        if (!items) {

          return [];
        }

        if (this.transform) {
          return this.transform.apply(items);
        }

        return items;
      })
    ).subscribe(
      data => this.setData(data),
      err => this.handleError(err),
      () => this.complete()
    );

    return this;
  }

  /**
   * Find item in current cached data set
   * @param id
   * @returns {any}
   */
  public find(id: Guid): T {
    if (this.data == null) {
      return null;
    }

    return this.data.find(o => o.id === id);
  }

  private complete() {
    this._loading = false;
  }

  public setData(data: Array<T>) {
    this.data = data;
    this.notify();
  }

  private addItem(item: T) {
    this.data.push(item);
    this.notify();
  }

  private handleError(error: ApiResponse) {
    this.dataSource.error(error);
  }

  private notify() {
    // noinspection TypeScriptValidateTypes
    this.dataSource.next(this.data);
  }

  private handleUpdate(item: T) {
    if (!item) {
      return;
    }

    const index = this.data.findIndex(o => o.id === item.id);

    if (index >= 0) {
      this.data[index] = item;
    } else {
      this.data.push(item);
    }

    if (this.transform) {
      this.data = this.transform.apply(this.data);
    }

    this.notify();
  }

  private handleDelete(id: Guid) {
    const index = this.data.findIndex(o => o.id === id);

    if (index > -1) {
      const item = this.data[index];
      this.data.splice(index, 1);
      this.notify();
    }
  }
}
