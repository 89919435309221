import { BehaviorSubject, Observable, of } from 'rxjs';

// rxjs
import { catchError, map, switchMap } from 'rxjs/operators';

// common
import { ITaggedItem, TaggedItemLibrary } from '@aa/common';

// forms
import { ISelectOptionGroup, SelectOptionMapper } from '@aa/app/forms';

import { AbstractApiService } from './abstract-api.service';
import { TagCacheService } from './tag-cache.service';

interface INamedTaggedItem extends ITaggedItem {
  name: string;
}

export class TaggedItemUtil {

  public static mapOptionGroups<T extends INamedTaggedItem>(library: TaggedItemLibrary<T>): ISelectOptionGroup[] {
    if (!library) {
      return [];
    }

    const groups: ISelectOptionGroup[] = [];
    library.groups.forEach(group => {
      groups.push({
        label: group.name,
        options: SelectOptionMapper.mapName(group.items)
      });
    });

    return groups;
  }
}


export abstract class AbstractTaggedItemCache<T extends INamedTaggedItem> {

  private readonly dataSource = new BehaviorSubject<TaggedItemLibrary<T>>(null);
  public readonly data$: Observable<TaggedItemLibrary<T>> = this.dataSource.asObservable();

  public readonly selectOptionGroups$: Observable<ISelectOptionGroup[]>;

  protected constructor(tagCache: TagCacheService, apiService: AbstractApiService<T>) {
    tagCache.data$.pipe(
      switchMap(tagData => {
        if (!tagData) {
          return of(null);
        }

        return apiService.getLibraryItems(tagData.library).pipe(map(
          items => new TaggedItemLibrary<T>(this.filterItems(items), tagData, o => o.name)
        ));
      }),
      catchError( err => {
        console.error('TaggedItemCacheService : Failed to get library items', err);
        return of(null);
      })
    ).subscribe(this.dataSource);

    // select option groups
    this.selectOptionGroups$ = this.data$.pipe(map(library => TaggedItemUtil.mapOptionGroups(library)));
  }

  protected filterItems(items: T[]): T[] {
    return items;
  }
}
