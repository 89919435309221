import { FormGroup, ValidatorFn, Validators } from '@angular/forms';

// common
import { IFactory, Serializer, VariableType } from '@aa/common';

// local
import { FormConfigBuilder, CustomValidators, IFormSerializer } from '../classes';
import { Variable } from '../models';

const fb = new FormConfigBuilder<Variable>([
  { key: 'varType', defaultValue: VariableType.Text },
  { key: 'key', defaultValue: '', validators: [Validators.required, Validators.minLength(2)] },
  { key: 'name', validators: [Validators.required]  },
  { key: 'description', validators: [Validators.required, Validators.minLength(2)] },
  { key: 'value' },
  { key: 'optional', defaultValue: false },
  { key: 'linkText' },
  { key: 'tagId' },
  { key: 'masterId' },
  { key: 'libraryId', validators: [Validators.required] },
  { key: 'parentId' },
  { key: 'teamId' }
]);

export class VariableSerializer extends Serializer<Variable> implements IFormSerializer<Variable> {

  public static syncValueValidator(varType: VariableType, required: boolean, form: FormGroup) {

    const valueControl = form.get('value');
    if (!valueControl) {
      return;
    }

    const validators: ValidatorFn[] = [];

    if (required) {
      validators.push(Validators.required);
    }

    switch (varType) {
      case VariableType.Url:
        validators.push(CustomValidators.https);
        break;

      default:
        break;
    }

    valueControl.setValidators(validators);
  }

  public getType(): IFactory<Variable> {
    return Variable;
  }

  public getName(): string {
    return 'Variable';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
