import { Inject, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// core
import { EnumHelper } from '@aa/common';

// local
import { AppChannel, ChannelItem, ChannelAccess, ShareRef } from '../../models';
import { AbstractChannelItemApiService } from '../../services';


export interface IChannelShareDialogData {
  prompt: string;
  item: ChannelItem;
  channels: AppChannel[];
  dataService: AbstractChannelItemApiService<any>;
}

interface IShareInfo {
  channel: AppChannel;
  busy: false;
  ref: ShareRef;
  outOfSync: boolean;
  canShare: boolean;
}

@Component({
  selector: 'aa-channel-share-dialog',
  templateUrl: './channel-share-dialog.component.html',
  styleUrls: ['./channel-share-dialog.component.scss']
})
export class ChannelShareDialogComponent {

  private readonly dataService: AbstractChannelItemApiService<any>;
  public readonly prompt: string;
  public readonly channels: AppChannel[];
  public readonly item: ChannelItem;

  public readonly shareInfo: IShareInfo[] = [];

  public dirty: boolean = false;

  constructor(private dialogRef: MatDialogRef<ChannelShareDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: IChannelShareDialogData) {

    this.item = data.item;

    this.dataService = data.dataService;
    this.channels = data.channels || [];
    this.prompt = data.prompt || 'Please select a channel';

    this.rebuildShareInfo();
  }

  private rebuildShareInfo() {
    this.shareInfo.length = 0;
    for (const channel of this.channels) {
      this.shareInfo.push(this.getShareInfo(channel));
    }
  }

  private getShareInfo(channel: AppChannel): IShareInfo {

    const ref = this.findRef(channel.id);

    return {
      channel: channel,
      busy: false,
      ref: ref,
      outOfSync: ref && this.item.lastEdited > ref.shared,
      canShare: EnumHelper.hasFlag(channel.access, ChannelAccess.Share)
    };
  }

  private findRef(channelId: Guid): ShareRef {
    if (!this.item.refs) {
      return null;
    }

    return this.item.refs.find(o => o.channelId === channelId);
  }

  share(info: IShareInfo) {

    this.dataService.createSharedItem(this.item, info.channel)
      .then(ref => {
          ChannelItem.addRef(this.item, ref);
          this.rebuildShareInfo();
        },
        err => {
          info.busy = false;
        });
  }

  unshare(info: IShareInfo) {
    if (!info.ref) {
      return;
    }
    this.dataService.deleteSharedItem(this.item, info.channel)
      .then(() => {
        ChannelItem.removeRef(this.item, info.ref.channelId);
        this.rebuildShareInfo();
      }, () => {});
  }

  close() {

    const updatedItem = this.dirty ? this.item : null;
    this.dialogRef.close(updatedItem);
  }
}
