import { CommandComponent } from './command';

import { SnippetPreviewComponent } from './snippet-preview';
import { ResizableComponent } from './resizable';
import { TeamLogoComponent, ChannelLogoComponent } from './logos';
import { DateStampComponent } from './date-stamp.component';
import { MainNavComponent } from './nav';
import { AboutDialogComponent } from './about';
import { NeatlinesIconComponent } from './neatlines-icon';
import { SpinnerComponent } from './spinner.component';
import { CircleComponent } from './circle.component';
import { ActionMenuComponent } from './action_menu.component';

import { DropdownComponent, DropdownCmdComponent, DropdownCheckboxComponent } from './dropdown';

import { ProgressReportComponent } from './progress-report';

import { LibrarySelectMenuComponent } from './library-select';
import { DomainSelectMenuComponent } from './domain-select';
import { TagSelectDialogComponent } from './tag-select';
import { TagListComponent } from './tag-list';

import { UserEditorDialogComponent } from './user_editor';

import { TrialExpiryDialogComponent } from './trial-expiry-dialog';
import { FeedbackDialogComponent, FeedbackDialogService } from './feedback-dialog';

import { ColorInputComponent } from './color-input';

import {
  ProfileEditorDialogComponent, ProfileInfoComponent,
  PasswordChangeDialogComponent, RoleListComponent
} from './profile';


import {
  DetailsDialogComponent
} from './details-dialog';


import {
  SettingsDialogComponent
} from './settings-dialog';

import {
  ChannelSelectDialogComponent
} from './channel-select';

import {
  ChannelShareDialogComponent,
} from './channel-share';

import { ChannelItemInfoComponent } from './channel-item-info';
import { ChannelInfoDialogComponent } from './channel-info';

import { PurgeSelfservButtonComponent } from './purge-selfserv-button';

import { TABLE_COMPONENTS } from './table';


export * from './snippet-preview';
export * from './nav';
export * from './command';
export * from './resizable';
export * from './dropdown';
export * from './user_editor';

export * from './details-dialog';
export * from './settings-dialog';
export * from './channel-select';
export * from './abstract-users.component';

export { FeedbackDialogService };

// abstract components are export only
export * from './item-editor.component';
export * from './channel-item-editor.component';
export * from './tag-select';
export * from './trial-expiry-dialog';

export const COMPONENTS = [
  ...TABLE_COMPONENTS,

  FeedbackDialogComponent,
  UserEditorDialogComponent,
  AboutDialogComponent,
  ProfileEditorDialogComponent,
  PasswordChangeDialogComponent,

  TagSelectDialogComponent,

  SettingsDialogComponent,
  DetailsDialogComponent,
  ChannelSelectDialogComponent, ChannelShareDialogComponent, ChannelInfoDialogComponent,
  TrialExpiryDialogComponent,

  ChannelItemInfoComponent,
  ProfileInfoComponent,
  MainNavComponent,
  DateStampComponent,
  CommandComponent,
  ResizableComponent,
  TeamLogoComponent,
  ChannelLogoComponent,
  NeatlinesIconComponent,
  SpinnerComponent,
  CircleComponent,
  DropdownComponent,
  DropdownCmdComponent,
  DropdownCheckboxComponent,
  ActionMenuComponent,
  RoleListComponent,
  LibrarySelectMenuComponent,
  DomainSelectMenuComponent,
  ProgressReportComponent,
  SnippetPreviewComponent,
  TagListComponent,
  ColorInputComponent,
  PurgeSelfservButtonComponent
];

