// external
import {Component, Inject} from '@angular/core';
import {first} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { TagType } from '@aa/common';

// local
import { TagGroup, TagGroupMap} from '../../classes';
import { Snippet, Tag} from '../../models';
import { TagApiService, TagCacheService } from '../../services';

export interface ITagSelectDialogData {
  snippet: Snippet;
}


@Component({
  selector: 'aa-tag-select-dialog',
  templateUrl: './tag-select-dialog.component.html',
  styleUrls: ['./tag-select-dialog.component.scss']
})
export class TagSelectDialogComponent {

  public static defaultWidth = '800px';

  private readonly snippet: Snippet = null;
  private tagsBackup: string[];

  public tagTypeGroups: TagGroup[];

  private tags: Tag[] = [];

  constructor(private tagCache: TagCacheService,
              private dialogRef: MatDialogRef<TagSelectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: ITagSelectDialogData) {

    this.snippet = data.snippet;

    if (!this.snippet) {
      throw Error('TagSelectDialogComponent: Snippet is undefined');
    }

    if (!this.snippet.tags) {
      this.snippet.tags = [];
    }

    this.tagsBackup = Object.assign([], this.snippet.tags);

    // subscribe to tags
    this.tagCache.data$.pipe(first()).subscribe(libraryTags => {
      if (!libraryTags) {
        this.close();
        return;
      }

      this.tags = libraryTags.visibleTags;
      this.rebuildTagGroups();
    });
  }

  public cancel() {
    this.reset();
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }

  public isActiveTag(tag: Tag): boolean {
    return this.snippet.tags.indexOf(tag.id) !== -1;
  }

  private getTagType(id: string): TagType {
    for (const tag of this.tags) {
      if (tag.id === id) {
        return tag.tagType;
      }
    }

    return TagType.None;
  }

  public selectTag(tag: Tag) {
    const selection = this.snippet.tags;
    const id = tag.id;

    if (this.isSelected(id)) {
      selection.splice(selection.indexOf(id), 1);
    } else {
      selection.push(id);
    }

    this.rebuildTagGroups();
  }

  private isSelected(id: Guid) {
    return this.snippet.tags.includes(id);
  }

  public reset() {
    this.snippet.tags.length = 0;

    this.tagsBackup.forEach(id => {
      this.snippet.tags.push(id);
    });
  }

  rebuildTagGroups() {
    this.tagTypeGroups = [];

    // init groups
    const groupMap = new TagGroupMap(TagApiService.snippetTagTypes);

    // populate groups
    for (const tag of this.tags) {

      const group = groupMap.get(tag.tagType);

      // ignore tags with no group unless they are selected
      // this allows invalid tags to be unselected
      if (group.type === TagType.None && !this.isSelected(tag.id)) {
        continue;
      }

      group.push(tag);
    }

    // sort and save
    for (const group of groupMap.list) {

      // ignore empty groups
      if (group.items.length === 0) {
        continue;
      }

      group.sort();
      this.tagTypeGroups.push(group);
    }
  }
}
