import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { AuthService } from '@aa/common';


import { Command, Icon } from '@aa/app/ui';

import { ChannelItem, ITableColumn } from '../../models';

import { DetailsDialogComponent , IDetailsDialogData, IDetailsDialogDataItem} from './details-dialog.component';

import { ShareStatusPipe } from '../../directives';
import { ChannelResolverService, DateStampService, TagCacheService } from '../../services';
import { LibraryTagData } from '../../classes';

@Injectable({providedIn: 'root'})
export class DetailsDialogService {

  private tagData: LibraryTagData = null;

  constructor(private dialog: MatDialog,
              private readonly channelResolver: ChannelResolverService,
              private readonly dateStamp: DateStampService,
              private readonly auth: AuthService,
              tagCache:  TagCacheService) {

    // tags
    tagCache.data$.subscribe((data: LibraryTagData) => {
      this.tagData = data;
    });

  }

  public get canViewItemDetails(): boolean {
    return this.auth.canLocalise;
  }

  public static createCommand() {
    return new Command({
      name: 'Details',
      description: `Show additional details`,
      icon: Icon.List
    });
  }

  public static getItemValue<T>(item: T, col: ITableColumn<T>): string {

    if (!item) {
      return '-';
    }

    const rawValue = item[col.key];

    try {
      const value = col.formatter ? col.formatter.format(rawValue) : rawValue.toString();

      if (value && value.length > 0) {
        return value;
      }

      return '-';
    } catch (err) {
      console.log('Formatter error', col.key, rawValue, err, item);
      return `Error (${rawValue})`;
    }
  }

  public openFromColumnDef<T>(item: T, cols: ITableColumn<T>[], title?: string): MatDialogRef<DetailsDialogComponent> {

    const items: IDetailsDialogDataItem[] = cols.map(col => {
      return {
        label: col.title,
        value: DetailsDialogService.getItemValue(item, col)
      };
    });

    return this.open({items, title});
  }

  public open<T>(data: IDetailsDialogData): MatDialogRef<DetailsDialogComponent> {
    return this.dialog.open(DetailsDialogComponent, {data});
  }

  public openChannelItem(name: string, item: ChannelItem): MatDialogRef<DetailsDialogComponent> {


    const dataItems: IDetailsDialogDataItem[] = [
      { label: 'Id', value: item.id },
      { label: 'Channel', value: this.channelResolver.getChannelDescription(item.channelId) },
      { label: 'Description', value: item.description },
      { label: 'Keywords', value: item.keywords },
      { label: 'Created',    asyncValue: this.dateStamp.formatAsync(item.created,    item.createdUserId)},
      { label: 'LastEdited', asyncValue: this.dateStamp.formatAsync(item.lastEdited, item.lastEditedUserId)},
    ];

    if (item.refs && item.refs.length) {
      dataItems.push({
        label: 'Share Status',
        value: ShareStatusPipe.formatShareStatusFull(item)
      });
    }


    if (item.parentChannelId) {
      dataItems.push({
        label: 'Parent Channel',
        value: this.channelResolver.getChannelDescription(item.parentChannelId)
      });
    }

    // tag keywords
    dataItems.push({label: 'TagKeywords', value: this.getTagKeywords(item) });

    return this.open({items: dataItems, title: `${name} Details`});
  }

  private getTagKeywords(item: ChannelItem): string {

    const result = this.tagData && this.tagData.compileKeywords(item.tags);
    if (!result) {
      return 'None';
    }

    return result.join(', ');
  }
}
