<mat-form-field floatLabel="auto">

  <input matInput [matDatepicker]="picker" autocomplete="off" [placeholder]="label" [formControl]="control">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>

  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  <mat-error *ngIf="hasError">{{errors[0]}}</mat-error>

</mat-form-field>

