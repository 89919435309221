// external
import { Component, Input } from '@angular/core';

// core
import { Icon } from '@aa/app/ui';

@Component({
  template: `
  <div mat-menu-item
      [matTooltip]="tooltip"
       matTooltipPosition="right">
       <i [aaIcon]="icon" aria-hidden="true"></i> {{label}}
  </div>
  `,
  selector: 'aa-dropdown-checkbox'
})
export class DropdownCheckboxComponent {
  @Input() checked: boolean;
  @Input() label: string;
  @Input() tooltip: string;

 constructor() {
 }

  public get icon(): Icon {
    return this.checked ? Icon.Checked : Icon.Unchecked;
  }

}
