import { ISelectOption } from '../models';

export class SelectOptionMapper {

  public static mapName<T extends {id: Guid, name: string}>(items: T[]): ISelectOption[] {
    if (!items) {
      return [];
    }
    return items.map(o => {
      return {
        value: o.id,
        label: o.name
      };
    });
  }

  public static mapId<T extends { id: Guid }>(
    items: T[],
    formatter: (item: T) => string,
    readonly?: (item: T) => boolean
  ): ISelectOption[] {

    if (!items) {
      return [];
    }

    return items.map(o => {
      return <ISelectOption>{
        value: o.id,
        label: formatter(o),
        readonly: readonly && readonly(o)
      };
    });
  }

  /**
   * Use this to map a collection of items with any formatter function
   * e.g. SelectOptionMapper.mapValue(ChannelTypeFormatter.getChannelTypes(), ChannelTypeFormatter.formatName);
   * @param items
   * @param formatter
   */
  public static mapValue<T>(items: T[], formatter: (item: T) => string ): ISelectOption[] {
    // need to define mapper as separate variable to work around this issue
    // https://github.com/ng-packagr/ng-packagr/issues/696
    const mapper = o => {
      return {
        value: o,
        label: formatter(o)
      };
    };
    return items.map(mapper);
  }
}
