import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AbstractInputComponent } from '@aa/app/forms';
import { ColorContrast } from '../../classes';

@Component({
  selector: 'aa-color-input',
  templateUrl: './color-input.component.html',
  host: {
    'class': 'form-control'
  }
})
export class ColorInputComponent extends AbstractInputComponent {

  @Input() label: string;
  @Input() hint: string;
  @Input() control: AbstractControl;

  public contrastMessage: string = 'Empty';
  public contrastWarning: boolean = false;

  @Input() contrastRatio: number = 0;

  protected onValueChanged(value: any) {
    this.syncContrastInfo();
  }


  private syncContrastInfo() {
    const color = this.control.value;

    const info = ColorContrast.getContrastInfo(color, this.contrastRatio);

    this.contrastMessage = info.message;
    this.contrastWarning = info.warning;
  }
}
