import { Injector } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
// common
import { AuthService, ISerializer, Item, Role, GuidTools } from '@aa/common';
// core
import { ConfirmService, ToastService } from '@aa/app/ui';
// local
import {
  AdviceSet,
  AppLibrary,
  IDataCollectionArgs,
  IDataService,
  IDeleteArgs,
  IPermissions,
  ISaveArgs,
  ITokenData
} from '../models';

import {DataCollection, IFormSerializer} from '../classes';
import { ApiClient, IApiRequestArgs } from './api-client.service';

export const RESOURCE_TOKEN_HEADER = 'X-RESOURCE-TOKEN';

const apiBaseUrl = '/api';

/**
 * Base class for data access services
 */
export abstract class AbstractApiService<T extends Item> implements IDataService<T> {

  public readonly update$: Observable<T>;
  public readonly delete$: Observable<Guid>;

  private readonly updateSource = new Subject<T>();
  private readonly deleteSource = new Subject<Guid>();

  protected readonly auth: AuthService;
  protected readonly apiClient: ApiClient;
  protected readonly toast: ToastService;
  protected readonly confirmService: ConfirmService;

  public permissions: IPermissions = {
    read: [Role.SiteAdmin],
    index: [Role.SiteAdmin],
    create: [Role.SiteAdmin],
    edit: [Role.SiteAdmin],
    delete: [Role.SiteAdmin],
  };

  public static getTokenHeaders(...tokenDataArray: ITokenData[]): HttpHeaders {

    const tokens = tokenDataArray.map(o => o.token);

    return new HttpHeaders()
      .set(RESOURCE_TOKEN_HEADER, tokens);
  }

  public static getLibraryArgs(library: AppLibrary): IDataCollectionArgs {
    const params = new HttpParams()
      .set('libraryId', GuidTools.stripDashes(library.id));

    const headers = new HttpHeaders()
      .set(RESOURCE_TOKEN_HEADER, library.token);

    return { params, headers };
  }

  /**
   * Genraate url relative to API root
   * @param path e.g. users/1/snippets
   */
  public generateApiUrl(path: string) {

    let url = apiBaseUrl;

    if (path[0] !== '/') {
      url += '/';
    }

    return url + path;
  }

  protected constructor(injector: Injector,
                        public readonly collection: string,
                        public readonly serializer: IFormSerializer<T>) {

    this.auth = injector.get<AuthService>(AuthService);
    this.apiClient = injector.get<ApiClient>(ApiClient);
    this.toast = injector.get<ToastService>(ToastService);
    this.confirmService = injector.get<ConfirmService>(ConfirmService);

    this.update$ = this.updateSource.asObservable();
    this.delete$ = this.deleteSource.asObservable();
  }

  public getSerializer(): IFormSerializer<T> {
    return this.serializer;
  }

  public getLibraryView(library: AppLibrary): DataCollection<T> {
    const args = AbstractApiService.getLibraryArgs(library);
    return new DataCollection(this, args);
  }

  public getLibraryItems(library: AppLibrary): Observable<T[]> {
    const args = AbstractApiService.getLibraryArgs(library);
    return this.getList(args);
  }

  public getName(): string {
    return this.collection;
  }

  public canIndex(): boolean {
    return this.checkPermission(this.permissions.index);
  }

  public canEdit(item: T): boolean {
    return this.checkPermission(this.permissions.edit);
  }

  public canCreate(): boolean {
    return this.checkPermission(this.permissions.create);
  }

  public canDelete(item: T): boolean {
    if (!item || !item.id) {
      return false;
    }

    return this.checkPermission(this.permissions.delete);
  }

  protected checkPermission(roles: Role[]) {

    for (const role of roles) {
      if (this.auth.hasRequiredRole(role)) {
        return true;
      }
    }

    return false;
  }

  public buildCollectionUrl(): string {
    return this.generateApiUrl(this.collection);
  }

  public buildItemUrl(id: Guid): string {
    return `${this.buildCollectionUrl()}/${id}`;
  }

  /*
  Get the message to be displayed when a delet confrimation is displayed
  Can be overriden by derived class
   */
  protected getConfirmDeleteMessage(item: Item, args: IDeleteArgs): string {

    if (args.force) {
      return 'Are you sure you want to force delete this item?';
    }

    return 'Are you sure you want to delete this item?';
  }

  /**
   * Get headers that should sent with CRUD operation
   * @param {T} item
   * @returns {HttpHeaders}
   */
  protected getItemHeaders(item: T): HttpHeaders {
    return null;
  }

  protected onSave(item: T, args: ISaveArgs) {
    if (args.invalidate) {
      this.invalidateItem(item);
    }
  }

  protected onDelete(item: T) {
    this.notifyDelete(item.id);
  }

  public notifyDelete(id: Guid) {
    if (!id) {
      return;
    }
    this.deleteSource.next(id);
  }

  public deleteItemById(id: Guid, args: IDeleteArgs = null): Observable<boolean> {
    return this.getItem(id).pipe(
        mergeMap(item => this.deleteItem(item, args)
      )
    );
  }

  public deleteItem(item: T, args: IDeleteArgs = null): Observable<boolean> {

    args = args || {
      confirm: true,
      toast: true
    };

    const subject = new Subject<boolean>();

    if (args.confirm) {
      const message = args.confirmMessage || this.getConfirmDeleteMessage(item, args);
      this.confirmService.show({title: 'Confirm Delete', content: message, key: args.confirmKey})
        .subscribe(result => {
          if (result) {
            this.deleteInternal(item, args, subject);
          } else {
            subject.next(false);
            subject.complete();
          }
        });
    } else {
      this.deleteInternal(item, args, subject);
    }

    return subject.asObservable();
  }

  private deleteInternal(item: T, args: IDeleteArgs, subject: Subject<boolean>) {
    const url = this.buildItemUrl(item.id);
    const headers = this.getItemHeaders(item);

    let params: HttpParams = null;
    if (args.force) {
      params = new HttpParams().append('force', 'true');
    }

    // add subscription
    this.apiClient.delete(url, headers, params).subscribe(
      result => {
        this.onDelete(item);
        subject.next(true);
        subject.complete();

        if (args.toast) {
          this.toast.success('Done', 'Item deleted successfully');
        }
      },
      err => {
        console.log(err);
        subject.error(err);
        subject.complete();

        if (args.toast) {
          this.toast.error('Error', err);
        }
      }
    );
  }

  public cloneItem(item: T, processor?: ((T) => void)): Observable<T> {
    const copy = this.serializer.clone(item);
    copy.id = null;

    if (processor) {
      processor(copy);
    }

    return this.saveItem(copy).pipe(tap(newItem => {
      this.toast.success('Done', 'Item cloned successfully');
    }, error => {
      this.toast.error('Failed', 'Failed to clone item');
    }));
  }

  public saveItem(item: T, args?: ISaveArgs): Observable<T> {

    args = args || { invalidate: true };

    let url: string = null;
    let method: string = null;

    if (item.id) {
      url = this.buildItemUrl(item.id);
      method = 'PUT';
    } else {
      url = this.buildCollectionUrl();
      method = 'POST';
    }

    const requestArgs = {
      body: item,
      factory: this.serializer,
      headers: this.getItemHeaders(item)
    };

    return this.apiClient.request(method, url, requestArgs).pipe(
      map(res => res.getData<T>()),
      tap(updatedItem => this.onSave(updatedItem, args))
    );
  }

  /**
   * Use this when we want to notify view of new data
   * @param item
   * @returns {boolean}
   */
  protected invalidateItem(item: T): boolean {
    if (!item || !item.id) {
      return false;
    }

    this.updateSource.next(item);
    return true;
  }

  public getView(args: IDataCollectionArgs = null): DataCollection<T> {
    return new DataCollection<T>(this, args);
  }

  public refreshItem(item: T): Observable<T> {

    if (!item.id) {
      return throwError('Invalid item id');
    }

    const headers = this.getItemHeaders(item);
    return this.getItem(item.id, {headers});
  }

  public getItem(id: Guid, options?: { params?: HttpParams, headers?: HttpHeaders }): Observable<T> {

    options = options || {};

    const args = {
      factory: this.serializer,
      params: options.params,
      headers: options.headers
    };

    const url: string = this.buildItemUrl(id);

    const type = this.serializer;
    return this.apiClient.get<T>(url, args).pipe(
      map(res => res.getData<T>()),
      tap(
        item => this.updateSource.next(item),
        err => {}
      )
    );
  }

  public getList(args: IDataCollectionArgs = null): Observable<T[]> {

    args = args || {};

    const url: string = args.customUrl || this.buildCollectionUrl();

    const apiArgs: IApiRequestArgs<T> = {
      serializer: this.serializer,
      params: args.params,
      headers: args.headers
    };

    return this.apiClient.get(url, apiArgs).pipe(
      map(res => res.getData<T[]>())
    );
  }

}
