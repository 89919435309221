import { Directive, OnDestroy } from '@angular/core';

// rxjs
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// common
import { AppStorageService, AuthService, ISerializer } from '@aa/common';

@Directive() // abstract service
export abstract class AbstractSettingsService<T> implements OnDestroy {
  private readonly updatedSource = new Subject<keyof(T)>();
  private readonly authSubscription: Subscription;

  protected _data: T;
  public readonly updated$: Observable<keyof(T)>;

  protected constructor(
    protected readonly auth: AuthService,
    private readonly appStorage: AppStorageService,
    private readonly serializer: ISerializer<T>,
    private readonly storageKey: string) {

    this.updated$ = this.updatedSource.asObservable();
    this._data = this.loadSettings();
    this.fixUp();

    this.authSubscription = auth.loggedIn$.pipe(
      filter(user => user != null)
    ).subscribe(() => {
        this.fixUp();
    });
  }

  /**
   * To be used when services are created manually and not as singleton instances
   */
  public ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  /**
   * Check for invalid settings and fix them
   * This is called whenever user is updated and on load
   */
  private fixUp(): void {
    this._data = this.serializer.fixUp(this._data);
  }

  public saveSettings() {
    const json = this.serializer.serialize(this._data);
    this.appStorage.setString(this.storageKey, json);
  }

  protected loadSettings(): T {
    const data = this.appStorage.getString(this.storageKey);

    if (data) {
      try {
        return this.serializer.parse(data);
      } catch (err) {
        console.log('Failed to parse saved settings:', err);
      }
    }


    return this.serializer.create();
  }

  protected notifyUpdate(prop: keyof(T)) {
    this.updatedSource.next(prop);
    this.saveSettings();
  }
}
