<div cdkDrag>
  <div class="tools drag-hide" [ngClass]="{visible: showToolbar}">
    <div class="icons">
      <div class="drag-handle" cdkDragHandle><i class="fa fa-arrows-v"></i></div>
      <div class="delete" (click)="notifyDelete()"><i class="fa fa-remove"></i></div>
    </div>
  </div>

  <div class="data">
    <aa-select class="select"
      [filterEnabled]="itemOptions?.length > 10"
      multiple="false"
      [control]="this.idControl"
      [options]="itemOptions">
    </aa-select>
  </div>
</div>
