import { Theme } from '@aa/common';

import { AbstractFormatter } from './abstract_formatter.class';
import { ColorFormatter } from './color.formatter';
import { ContrastLimits } from './color-contrast.class';

export class ThemeFormatter extends AbstractFormatter<Theme> {

  constructor() {
    super(true /*isHtml */);
  }

  protected formatItemInternal(value: Theme): string {
    if (!value) {
      value = new Theme();
    }

    return ColorFormatter.formatColorIcon(value.primary, 'Primary', ContrastLimits.Primary) +
      ColorFormatter.formatColorIcon(value.secondary, 'Secondary', ContrastLimits.Secondary) +
      ColorFormatter.formatColorIcon(value.header, 'Header', ContrastLimits.Header) +
      ColorFormatter.formatColorIcon(value.hover, 'Hover', ContrastLimits.Hover);
  }
}
