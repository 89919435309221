import {
  IAdviceSet,
  IQuestion,
  ISelfServData, ISelfServMeta, ISelfServVariable,
  ISnippet,
} from './self-serv-data.interface';

import { Item } from './item.model';
import { Guid } from './guid.type';
import { ITag, ITagMap } from './tag.interface';
import { Paragraph } from './paragraph.model';

export class SelfServData implements ISelfServData, ITagMap {

  readonly error: string;
  readonly startText: string;
  readonly contactText: string;
  readonly lastEdited?: Date;

  readonly adviceSets: IAdviceSet[] = [];
  readonly questions: IQuestion[] = [];
  readonly snippets: ISnippet[] = [];
  readonly tags: ITag[] = [];
  readonly variables: ISelfServVariable[] = [];

  private readonly snippetMap: Map<Guid, ISnippet>;
  private readonly questionMap: Map<Guid, IQuestion>;
  private readonly adviceSetMap: Map<Guid, IAdviceSet>;
  private readonly tagMap: Map<Guid, ITag>;
  private readonly variableMap: Map<Guid, ISelfServVariable>;

  constructor(public readonly meta: ISelfServMeta, source: ISelfServData) {

    Object.assign(this, source);

    this.snippetMap = Item.createIdMap(this.snippets);
    this.questionMap = Item.createIdMap(this.questions);
    this.adviceSetMap = Item.createIdMap(this.adviceSets);
    this.tagMap = Item.createIdMap(this.tags);
    this.variableMap = Item.createIdMap(this.variables);
  }

  public getSnippets(snippetIds: Guid[]): ISnippet[] {
    return Item.resolveItems(this.snippetMap, snippetIds);
  }

  public getParagraphs(snippetIds: Guid[]): Paragraph[] {
    return this.getSnippets(snippetIds).map<Paragraph>(o => ({
        data: o.data,
        heading: o.description
      })
    );
  }

  public getSnippet(id: Guid): ISnippet {
    return this.snippetMap.get(id);
  }

  public getQuestion(id: Guid): IQuestion {
    return this.questionMap.get(id);
  }

  public findTag(id: Guid): ITag {
    return this.tagMap.get(id);
  }

  public getAdviceSet(id: Guid): IAdviceSet {
    return this.adviceSetMap.get(id);
  }

  public getVariable(id: Guid): ISelfServVariable {
    return this.variableMap.get(id);
  }
}
