// external
import { Pipe, PipeTransform } from '@angular/core';

// common
import { EnumHelper } from '@aa/common';

// shared
import { ChannelType, AppChannel } from '../models';
import { ChannelTypeFormatter } from '../classes';

/*
 * Formats refAccepts filter for AppChannel models
 * Usage:
 *   item | channelAccepts
 */
@Pipe({name: 'channelAccepts'})
export class ChannelAcceptsPipe implements PipeTransform {

  // define list of flags in the order we wish them to appear
  static flagList: ChannelType[] = [
    ChannelType.Private,
    ChannelType.Team,
    ChannelType.Review,
    ChannelType.Group,
    ChannelType.Global
  ];

  static formatAcceptsFilter(filter: ChannelType): string {

    const flagStrings: string[] = [];

    // extract all known flags from the filter
    for (const flag of ChannelAcceptsPipe.flagList) {
        if (EnumHelper.hasFlag(filter, flag)) {
          flagStrings.push(ChannelTypeFormatter.formatName(flag));
        }
    }

    const count = flagStrings.length;

    if (count === 0) {
      return 'None';
    }

    return flagStrings.join(', ');
  }

  transform(channel: AppChannel): string {
    return ChannelAcceptsPipe.formatAcceptsFilter(channel ? channel.shareType : ChannelType.None);
  }
}
