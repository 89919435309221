import { ItemIdPipe } from './item_id.pipe';
import { ChannelTypePipe } from './channel_type.pipe';
import { TeamNamePipe } from './team_name.pipe';

import { ShareStatusPipe } from './share_status.pipe';
import { ChannelNamePipe } from './channel_name.pipe';
import { ChannelAccessPipe } from './channel_access.pipe';
import { ChannelAcceptsPipe } from './channel_accepts.pipe';
import { ChannelColorPipe } from './channel_color.pipe';
import { TagClassPipe } from './tag_class.pipe';
import { LibraryIdPipe } from './library-id.pipe';
import { TeamPlanTypePipe } from './team-plan-type.pipe';
import { FeedbackTypeDescriptionPipe } from './feedback-description.pipe';
import { LocalisationFlagPipe } from './localisation-flag.pipe';
import { LocalisationModePipe } from './localisation-mode.pipe';
import { TreeStatusPipe } from './tree-status.pipe';
import { VariableTypePipe } from './variable-type.pipe';

export * from './share_status.pipe';
export * from './channel_name.pipe';

export const SHARED_DIRECTIVES = [
  ChannelTypePipe,
  ChannelNamePipe,
  ChannelAccessPipe,
  ChannelAcceptsPipe,
  ChannelColorPipe,
  ItemIdPipe,
  TeamNamePipe,
  ShareStatusPipe,
  TagClassPipe,
  LibraryIdPipe,
  TeamPlanTypePipe,
  FeedbackTypeDescriptionPipe,
  LocalisationFlagPipe,
  LocalisationModePipe,
  TreeStatusPipe,
  VariableTypePipe

];
