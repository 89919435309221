import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { IAppConfig, Guid, IEnvironment} from '../models';
import { BehaviorSubject } from 'rxjs';

export interface IAnalyticsContext {
  teamName?: string;
  domain?: string;
}

@Injectable({providedIn: 'root'})
export class AppConfig implements IAppConfig {

  // is this a production build
  public production: boolean;

  // AppName
  public appName: string = null;

  // Version
  public version: string = null;

  // Hotjar Id
  public hotjarId?: number = null;

  // Google Analytics Key
  public gaKey?: string = null;

  // Application insights key
  public aiKey?: Guid = null;

  // Active directory app id
  public adKey?: Guid = null;

  // CdnOrigin
  public cdnOrigin: string = '';

  // TeamName and SelfServDomain (for analytics)
  // Observable provided in addition to property so that analytics service can subscribe
  private readonly _analyticsContextSource = new BehaviorSubject<IAnalyticsContext>(null);

  public readonly analyticsContext$ = this._analyticsContextSource.asObservable();

  public setAnalyticsContext(value: IAnalyticsContext) {
    this._analyticsContextSource.next(value);
  }

  public init(appName: string, env: IEnvironment): void {
    this.appName = appName;
    this.version = env.version;
    this.production = env.production;

    // get global appConfig object
    let appConfig: IAppConfig = window['appConfig'];

    if (!appConfig) {
      // display warning in production
      if (this.production) {
        console.error('window.appConfig is not defined');
      }
      appConfig = {};
    }

    this.gaKey = appConfig.gaKey || env.gaKey;
    this.hotjarId = appConfig.hotjarId || env.hotjarId;

    this.adKey = appConfig.adKey || env.adKey;
    this.aiKey = appConfig.aiKey;
    this.cdnOrigin = appConfig.cdnOrigin || '';
  }
}
