<aa-dialog (keyup.escape)="close()">

  <aa-dialog-title>{{title}}</aa-dialog-title>

  <aa-dialog-content>

    <aa-form-info *ngFor="let item of items" [label]="item.label">
      <div *ngIf="item.asyncValue; else sync">
        {{item.asyncValue | async}}
      </div>
      <ng-template #sync>
        {{item.value || '-'}}
      </ng-template>
    </aa-form-info>

  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button (click)="close()">
      OK
    </button>
  </aa-dialog-actions>
</aa-dialog>
