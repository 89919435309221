import { Component, Input } from '@angular/core';

import { Command, Icon } from '@aa/app/ui';

@Component({
  selector: 'aa-action-menu',
  template: `
    <button tabindex="-1" mat-icon-button [matMenuTriggerFor]="actionMenu"><i [aaIcon]="icon"></i></button>
    <aa-dropdown #actionMenu x-position="before" class='action-menu'>

    <div *ngFor="let action of actions">
      <aa-dropdown-cmd [cmd]="action"></aa-dropdown-cmd>
    </div>
  </aa-dropdown>`
})
export class ActionMenuComponent {

  public readonly icon: Icon = Icon.Action;

  @Input() public actions: Command[] = [];
}

