import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IConfirmDialogData } from './confirm-dialog-data.interface';

@Component({
  selector: 'aa-info-dialog',
  templateUrl: './info-dialog.component.html',
})
export class InfoDialogComponent {
  public title: string;
  public content: string;

  constructor(private dialogRef: MatDialogRef<InfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: IConfirmDialogData) {
    this.title = data.title;
    this.content = data.content;
  }

  public hide(result: boolean) {
    this.dialogRef.close(result);
  }
}
