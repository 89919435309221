import { NgModule, Optional } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AdviceAidCommonModule } from '@aa/common';
import { UiModule } from '@aa/app/ui';
import { CustomFormsModule } from '@aa/app/forms';

import { SHARED_DIRECTIVES } from './directives';
import { COMPONENTS } from './components';


@NgModule({
  imports:      [ AdviceAidCommonModule, UiModule, CustomFormsModule, RouterModule ],
  declarations: [ COMPONENTS, SHARED_DIRECTIVES],
  exports:      [
    UiModule, CustomFormsModule, RouterModule, AdviceAidCommonModule,
    ...SHARED_DIRECTIVES,
    ...COMPONENTS
  ]
})
export class SharedModule {

  // ensure trial expiry dialog is instantiated by injecting into constructor
  constructor() {
  }
}
