<nav class="noselect noprint">
  <ul class="no-shrink">
    <li>
      <a class='home-menu' #homeMenuTigger="matMenuTrigger" [matMenuTriggerFor]="homeMenu">
        <aa-neatlines-icon></aa-neatlines-icon>
      </a>

      <aa-dropdown #homeMenu>
        <a *ngFor="let link of visibleLinks" mat-menu-item [routerLink]="link.path" routerLinkActive="active-link">
          {{link.name}}
        </a>
        <div class="dropdown-divider"></div>
        <a mat-menu-item [href]="s21url" target="_blank" matTooltip="Open the Section 21 check tool">
          <i [aaIcon]="linkIcon" aria-hidden="true"></i>
          <span>s21check</span>
        </a>
      </aa-dropdown>
    </li>

    <li class="logo" >
      <a (click)="homeMenuTigger.openMenu()">
        <img [src]="'adviceaid_logo.png' | imageSrc">
      </a>
    </li>

    <li class="breadcrumb">
      {{name}}
    </li>
  </ul>


  <!-- Page specific nav bar stuff goes here it should be a single <ul>-->
  <div class="content">
    <ng-content></ng-content>
  </div>


  <!-- Login appears on all pages -->
  <ul class="right no-shrink">
    <li *ngIf="isFreeTrial" class="text trial">
      <div class="trial-heading">Free Trial</div>
      <div class="trial-expiry">{{trialExpiryMessage}}</div>
    </li>

    <li>

      <a [matMenuTriggerFor]="userMenu"
         matTooltip="User Profile">
        <div class="user-name">{{auth.user?.firstName}}</div>
        <i class="large-icon fa fa-user"></i>
      </a>

      <aa-dropdown #userMenu>
        <aa-profile-info></aa-profile-info>
        <div class="dropdown-divider"></div>
        <a mat-menu-item class="dropdown-item"  (click)="showEditProfileDialog()">
          User Profile
        </a>
        <div class="dropdown-divider"></div>
        <a mat-menu-item class="dropdown-item" (click)="logout()">
          Logout
        </a>

        <div class="dropdown-divider"></div>
        <a mat-menu-item (click)="showAboutDialog()">
          About
        </a>
      </aa-dropdown>
    </li>


    <!--
    <li class="nav-icon" *ngIf="isWorkspaceUser">
      <a (click)="settingsDialog.open()"
         matTooltip="Settings">
        <i class="fa fa-gear"></i>
      </a>
    </li>
    -->

    <li class="nav-icon">
      <a (click)="feedbackDialog.open()"
         matTooltip="Feedback">
        <i class="large-icon fa fa-comment"></i>
      </a>
    </li>

    <li class="nav-icon">
      <a (click)="grooveService.toggle()"
         matTooltip="Help">
        <i class="large-icon fa fa-question" *ngIf="!grooveService.loading; else showSpinner"></i>
        <ng-template #showSpinner>
          <aa-spinner></aa-spinner>
        </ng-template>

      </a>
    </li>

  </ul>

</nav>

