import { Validators } from '@angular/forms';
import { Role, Serializer } from '@aa/common';
import { IFormSerializer, FormConfigBuilder } from '../classes';
import { Channel, ChannelType } from '../models';


const fb = new FormConfigBuilder<Channel>([
  { key: 'name', validators: [Validators.required] },
  { key: 'teamId', validators: [Validators.required] },
  { key: 'channelType', defaultValue: ChannelType.Group },
  { key: 'logoImage' },
  { key: 'shareRole', defaultValue: Role.None },
  { key: 'shareType', defaultValue: ChannelType.None }
]);

export class ChannelSerializer extends Serializer<Channel> implements IFormSerializer<Channel> {

  public getType() {
    return Channel;
  }

  public getName() {
    return 'Channel';
  }

  public buildForm() {
    return fb.build();
  }
}
