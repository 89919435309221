<aa-form-dialog [content]="this">

    <aa-form-info label="Team">
      <aa-team-logo [team]="team"></aa-team-logo>
    </aa-form-info>

    <aa-form-info label="Plan">
      {{team?.planType | teamPlanType}}
    </aa-form-info>

    <aa-form-info label="Email">
      {{user.email}}
    </aa-form-info>

    <aa-form-info label="Roles">
      <aa-role-list [roles]="user.roles"></aa-role-list>
    </aa-form-info>

    <aa-form-info label="Can Localise?">
      {{auth.canLocalise ? 'Yes' : 'No'}}
    </aa-form-info>
    <hr>

    <aa-form-info label="Password">
      <button mat-raised-button (click)="changePassword()">Change Password</button>
    </aa-form-info>

    <hr>

    <div *ngIf="!item">Loading...</div>

    <div *ngIf="item">
      <aa-text-input label="First Name" [control]="control('firstName')"></aa-text-input>

      <aa-text-input label="Last Name" [control]="control('lastName')"></aa-text-input>

      <aa-text-input label="Job Title" [control]="control('jobTitle')"></aa-text-input>

      <aa-text-input label="Phone" [control]="control('phoneNumber')"></aa-text-input>

    </div>

</aa-form-dialog>



