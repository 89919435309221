
export enum SortMode {
  None = 0,

  Alphabetical = 1,
  AlphabeticalReverse = 2,

  DateNewest = 11,
  DateOldest = 12
}
