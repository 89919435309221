import { Injectable, Injector } from '@angular/core';

import { Group } from '../models';
import { AbstractApiService } from './abstract-api.service';
import { GroupSerializer } from '../serializers';

@Injectable({providedIn: 'root'})
export class GroupApiService extends AbstractApiService<Group> {

  constructor (injector: Injector) {
    super(injector, 'groups', new GroupSerializer());
  }
}
