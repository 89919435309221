
// external
import { Subscription ,  Subject } from 'rxjs';

// local
import { Hotkey } from './hotkey.model';
import { Icon } from './icon.enum';
import { ICommand } from './command.interface';

export class CommandArgs {
  constructor(public data?: any) {
  }
}

export class Command implements ICommand {
  public readonly hotKey: Hotkey;

  private readonly source = new Subject<CommandArgs>();
  private readonly source$ = this.source.asObservable();

  public readonly icon: Icon = null;
  public name: string;
  public readonly description: string;
  public readonly shortcut: string = null;
  public tooltip: string;

  public readonly tooltipDisabled: string;

  public enabled: boolean = true;


  constructor(args: ICommand) {
    this.name = args.name;
    this.description = args.description;
    this.icon = args.icon;
    this.tooltip = args.description;
    this.tooltipDisabled = `${args.name} disabled`;

    // only assign if explicitly defined as false
    if (args.enabled === false) {
      this.enabled = false;
    }


    if (!args.shortcut) {
      return;
    }
    // hotkey binding from shortcut
    this.shortcut = args.shortcut;
    this.tooltip += ` (${this.shortcut})`;

    this.hotKey = new Hotkey(args.shortcut, (event: KeyboardEvent) => {
      this.execute();
      return false; // Prevent bubbling
    });
  }

  /**
   * Execute the command
   * @param data Optional parameters to be used by the subscriber,
   * e.g. template type for a new document
   */
  public execute(data: any = null) {
    if (!this.enabled) {
      console.log('Command disabled:', this.name);
      return;
    }
    const args = new CommandArgs(data);

    this.source.next(new CommandArgs(data));
  }

  public subscribe(callback: (value: CommandArgs) => void): Subscription {
    return this.source$.subscribe(callback);
  }

  /**
   * complete the command source to kill all subscribers
   */
  public destroy() {
    this.source.complete();
  }
}
