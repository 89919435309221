import { ISortable } from '../models';
import sortBy from 'lodash-es/sortBy';

export class TagUtil {

    // TODO: move this somewhere more generic as it is not tag specific
    public static sort<T extends ISortable>(tags: T[]): T[] {
        // define sort up front https://github.com/ng-packagr/ng-packagr/issues/696
        const sort = (tag: T) => {

            if (!tag.order) {
                return tag.name;
            }

            // pad the order param to ensure correct sorting
            const order: string = ('0000' + tag.order).substr(-4);

            return order + tag.name;
        };

        return sortBy(tags, sort);
    }

    public static normalizeKeyword(data: string): string {
        if (!data) {
            return '';
        }

        return data.toLowerCase().trim();
    }

    public static normalizeKeywordList(csvData: string): string[] {
        if (!csvData || !csvData.length) {
            return [];
        }

        const result = [];

        const minLength = 3; // 3 chars

        // convert to lower case and strip out brackets and apostrophes
        const normalizedData = csvData.toLowerCase().replace(/[()']/g, '');

        // split csv
        for (let keyword of normalizedData.split(',')) {
            keyword = keyword.trim();
            if (keyword.length < minLength) {
                continue;
            }

            result.push(keyword);
        }

        return result;
    }

}
