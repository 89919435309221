import { LogoutComponent} from './logout.component';
import { OAuthRedirectComponent } from './oath-redirect.component';
import { BootstrapErrorComponent } from './bootstrap-error.component';
import { EmptyComponent } from './empty.component';


export * from './logout.component';
export * from './oath-redirect.component';
export * from './bootstrap-error.component';
export * from './empty.component';

export const COMPONENTS = [
  LogoutComponent,
  OAuthRedirectComponent,
  BootstrapErrorComponent,
  EmptyComponent
];
