// external
import { Observable, throwError } from 'rxjs';
import { saveAs } from 'file-saver';

// angular
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

// local
import { ApiResponse, IApiResponse, ISerializer } from '../models';


export class HttpUtil {

  public static parseError(res: any): ApiResponse {

    const status = res.status;

    if (!status) {
      return ApiResponse.Error(0, 'Offline');
    }

    // HttpErrorResponse
    if (res instanceof HttpErrorResponse) {
      const error: ApiResponse = res.error;
      if (error && error.message) {
        return ApiResponse.Error(status, error.message, error.errors);
      }
    }

    // best effort
    const defaultMessage = res.statusText || res.status || res.toString();
    return ApiResponse.Error(status, defaultMessage);
  }

  public static handleError(error: any): Observable<ApiResponse> {
    return throwError(HttpUtil.parseError(error));
  }

  public static getFileName(headers: HttpHeaders): string {
    const contentDisposition = headers.get('content-disposition');

    if (!contentDisposition) {
      return null;
    }

    const match = contentDisposition.match(/filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/);

    if (!match || match.length < 2) {
      return null;
    }

    return match[1];
  }

  private static parseData<T>(json: IApiResponse, serializer: ISerializer<T>): any {

    if (!json) {
      throw new Error(('Invalid data'));
    }

    const data = json.data;

    if (!data) {
      return null;
    }

    if (serializer) {

      if (data instanceof Array) {
        const result: T[] = [];
        for (const item of data) {
          result.push(serializer.parse(item));
        }
        return result;
      }

      return serializer.parse(data);
    }

    return data;
  }

  public static parseBlobResponse<T>(res: HttpResponse<Blob>): ApiResponse {
    if (!res) {
      throw new Error(('Invalid response'));
    }

    const status = res.status;

    if (status < 200 || status >= 400) {
      throw(res);
    }

    try {
      // check if response was a file
      const fileName = HttpUtil.getFileName(res.headers);

      const blob: Blob = res.body;
      console.log('File download ok', fileName);
      saveAs(blob, fileName);
      return ApiResponse.File(status, fileName, blob);

    } catch (err) {
      console.log('http error', err);
      return ApiResponse.Error(0, err.toString());
    }
  }

  public static parseResponse<T>(res: HttpResponse<IApiResponse>, serializer?: ISerializer<T>): ApiResponse {
    if (!res) {
      throw new Error(('Invalid response'));
    }

    const status = res.status;

    if (status < 200 || status >= 400) {
      throw(res);
    }

    // read data
    const jsonResponse: IApiResponse = res.body;

    // empty body
    if (!jsonResponse) {
      return ApiResponse.Success(res.status, res.statusText, null);
    }

    try {
      const data = HttpUtil.parseData<T>(jsonResponse, serializer);
      return ApiResponse.Success(res.status, jsonResponse.message, data);
    } catch (err) {
      console.log('http error', err, jsonResponse);
      return ApiResponse.Error(0, err.toString());
    }
  }
}
