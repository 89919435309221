// external
import { FormGroup, Validators } from '@angular/forms';

// common
import { IFactory, Serializer } from '@aa/common';

// local
import { IFormSerializer, CustomValidators, FormConfigBuilder } from '../classes';
import { Snippet } from '../models';

const fb = new FormConfigBuilder<Snippet>([
  { key: 'description', validators: [Validators.required] },
  { key: 'tags' },
  { key: 'source' },
  { key: 'localisationHint' },
  { key: 'keywords', validators: [CustomValidators.keywordList] },
  { key: 'localisation' }
]);

export class SnippetSerializer extends Serializer<Snippet> implements IFormSerializer<Snippet> {

  public static instance = new SnippetSerializer();

  public getType(): IFactory<Snippet> {
    return Snippet;
  }

  public getName(): string {
    return 'Snippet';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
