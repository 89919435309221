
// common
import { TreeStatus } from '@aa/common';

// local
import { AbstractFormatter } from './abstract_formatter.class';

export class TreeStatusFormatter extends AbstractFormatter<TreeStatus> {

  public static readonly nameMap = new Map<TreeStatus, string>([
    [TreeStatus.Live, 'Live'],
    [TreeStatus.Beta, 'Beta'],
    [TreeStatus.Demo, 'Demo'],
    [TreeStatus.Disabled, 'Disabled']
  ]);

  static getTreeStatusTypes(): TreeStatus[] {
    return [
      TreeStatus.Live, TreeStatus.Beta, TreeStatus.Demo, TreeStatus.Disabled
    ];
  }

  public static formatName(value: TreeStatus): string {
    if (!value) {
      value = TreeStatus.Live;
    }

    const name = TreeStatusFormatter.nameMap.get(value);
    return name || `Unknown ${value}`;
  }

  protected formatItemInternal(value: TreeStatus): string {
    return TreeStatusFormatter.formatName(value);
  }
}
