import { AbstractFormatter } from './abstract_formatter.class';
import { FeedbackType } from '../models';

export class FeedbackTypeFormatter extends AbstractFormatter<FeedbackType> {

  public static default: FeedbackTypeFormatter = new FeedbackTypeFormatter();

  private static readonly nameMap = new Map<FeedbackType, string>([
    [FeedbackType.None, 'None'],
    [FeedbackType.AppFeatureIdea, 'Feature Idea'],
    [FeedbackType.AppBugReport, 'Bug Report'],
    [FeedbackType.AppHelp, 'Help'],
    [FeedbackType.AppAccountSetup, 'Account Setup'],

    [FeedbackType.EmptySearch, 'Empty Search'],

    [FeedbackType.SnippetOutOfDate, 'Out of date'],
    [FeedbackType.SnippetBadAdvice, 'Bad Advice'],
    [FeedbackType.SnippetInappropriate, 'Inappropriate'],
    [FeedbackType.SnippetTypo, 'Spelling and Grammar'],

    [FeedbackType.Other, 'Other']
  ]);

  private static readonly descriptionMap = new Map<FeedbackType, string>([
    [FeedbackType.AppFeatureIdea, 'Make a suggestion for a new AdviceAid feature'],
    [FeedbackType.AppBugReport, 'Let us know if something is not working as expected'],
    [FeedbackType.AppHelp, 'If you need assistance with using the app'],
    [FeedbackType.AppAccountSetup, 'Help and support configuring your AdviceAid account'],

    [FeedbackType.EmptySearch, 'My search keywords did not return any results'],

    [FeedbackType.SnippetOutOfDate, 'Snippet content is out of date and requires review'],
    [FeedbackType.SnippetBadAdvice, 'Snippet content is misleading or incorrect'],
    [FeedbackType.SnippetInappropriate, 'Snippet content is offensive or inappropriate'],
    [FeedbackType.SnippetTypo, 'Snippet contains a spelling or grammar error'],

    [FeedbackType.Other, 'Please provided details below']
  ]);

  public static formatName(value: FeedbackType): string {
    if (!value) {
      return '-';
    }

    return FeedbackTypeFormatter.nameMap.get(value) || `Feedback ${value}`;
  }

  public static formatDescription(value: FeedbackType): string {
    if (!value) {
      return 'What kind of feedback would you like to provide?';
    }

    const name = FeedbackTypeFormatter.formatName(value);

    const description = FeedbackTypeFormatter.descriptionMap.get(value);

    if (!description) {
      return name;
    }

    return `${name} : ${description}`;
  }

  protected formatItemInternal(value: FeedbackType): string {
    return FeedbackTypeFormatter.formatName(value);
  }
}
