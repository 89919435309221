// local
import { ChannelType } from './channel_type.enum';
import { SortMode } from './sort_mode.enum';
import { LocalisationFlag } from '@aa/app/shared';

export class FilterSettings {

  // TODO: move this to a view settings class
  localisationPlaceholders: boolean = false;

  sortMode: SortMode = SortMode.Alphabetical;

  tagIds: Guid[] = null;

  channelTypeFilter: ChannelType = ChannelType.All;

  maxResults: number = 20;

  disabledChannels: object = {};

  disabledGroups: object = {};

  collapsedGroups: object = {};

  channelSidebar: boolean = true;

  searchTextMap: object = {};

  currentFilter: string = null;

  localisationFilter: LocalisationFlag;
}
