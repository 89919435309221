<style>
  [hidden] {
    display: none;
  }

  .filter-input {
    padding-top: 8px;
    height: 60px;
  }

</style>
<mat-form-field>
  <mat-select [placeholder]="label" [formControl]="control" [multiple]="multiple">
    <div class="mat-option filter-input" *ngIf="filterEnabled">
      <mat-form-field>
        <input matInput autocomplete="off" placeholder="Filter" (keyup)="updateFilter($event.target.value)" (click)="$event.stopPropagation()">
      </mat-form-field>
    </div>

    <mat-option *ngIf="allowClear">None</mat-option>
    <ng-container *ngIf="groups; else flat" >
      <mat-optgroup *ngFor="let group of groups" [label]="group.label">
        <mat-option *ngFor="let option of group.options"
                    [value]="option.value"
                    [disabled]="option.readonly"
                    [hidden]="option.hidden"
        >{{option.label}}</mat-option>
      </mat-optgroup>

    </ng-container>
    <ng-template #flat>
      <mat-option *ngFor="let option of options"
                  [value]="option.value"
                  [disabled]="option.readonly"
                  [hidden]="option.hidden"
      >{{option.label}}</mat-option>
    </ng-template>

  </mat-select>
  <mat-hint *ngIf="hint && !control.value">{{hint}}</mat-hint>
</mat-form-field>
