
import { LibraryItem } from './library-item.model';
import { ITaggedItem, IVariable, VariableType,  Organisation } from '@aa/common';

export interface IVariableLocalisation {
  parentId: Guid;
  teamId: Guid;
  value?: string;
  organisations?: Organisation[];
  skipped: boolean;
}

export const VariableTypeCollection = [
  VariableType.Text,
  VariableType.Url,
  VariableType.Boolean,
  VariableType.Organisation
];


export enum VariableScope {
  None = 0,
  Library = 1,
  Team = 2,
  Localisation = 4
}

export class Variable extends LibraryItem implements ITaggedItem, IVariable {

  // e.g. HOUSING_TEAM_NAME (can be edited but should be discouraged, as it will break all dependent text and localisations)
  key: string;

  name: string;

  description: string;

  scope: VariableScope;

  varType: VariableType;

  optional?: boolean;

  // id of parent variable
  parentId?: Guid;

  tagId?: Guid;

  teamId?: Guid;

  value?: string;

  // alt text for url variables
  linkText?: string;

  organisations?: Organisation[];

  // id of master variable that toggles this var on/off
  masterId?: Guid;

  // localisation flag marking variable as not applicable
  skipped?: boolean;

  // UI only property for keeping track of value before localisation
  defaultValue?: string;
}
