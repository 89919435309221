<div class="logo-container">

  <i *ngIf="icon" [ngClass]="{watermark:watermark}" [title]="name" [aaIcon]="icon"></i>

  <ng-container *ngIf="logoImage" class="logo-image">
    <img *ngIf="watermark; else notWatermark"
         [src]="activeImage"
         (load)="onImageLoaded()"
         (error)="onImageError()"
         [ngClass]="{watermark: useWatermarkCss, hidden: hidden}"
         [title]="name"/>

    <ng-template #notWatermark>
      <img [src]="logoImage" [title]="name"/>
    </ng-template>
  </ng-container>

</div>
