import { Validators } from '@angular/forms';
import { IFactory, Serializer } from '@aa/common';

import { IFormSerializer, FormConfigBuilder } from '../classes';
import { Group } from '../models';

const fb = new FormConfigBuilder<Group>([
  { key: 'name', validators: [Validators.required] },
  { key: 'libraries' },
  { key: 'subscriptions' },
  { key: 'logoImage' },
  { key: 'syncTeamChannels' }
]);

export class GroupSerializer extends Serializer<Group> implements IFormSerializer<Group>  {

  public getType(): IFactory<Group> {
    return Group;
  }

  public getName() {
    return 'Group';
  }

  public buildForm() {
    return fb.build();
  }
}
