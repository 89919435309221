<aa-dialog (keyup.escape)="cancel()">
  <aa-dialog-title>Edit Topics</aa-dialog-title>
  <aa-dialog-subtitle>Please select at least one topic</aa-dialog-subtitle>

  <aa-dialog-content>
    <div class="tags">
      <div *ngFor="let group of tagTypeGroups" class="tag-select-group">

        <!-- Only show tag name when there is more than one type -->
        <div class="tag-group-name" *ngIf="tagTypeGroups.length > 1">{{group.name}}</div>

        <div *ngFor="let tag of group.items"
             (click)="selectTag(tag)"
             [matTooltip]="tag.description"
             class="selectable tag {{tag | tagClass}}"
             [class.active]="isActiveTag(tag)">
          {{tag.name}}
        </div>
      </div>
    </div>
  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button class="pull-left" (click)="reset()">
      Reset
    </button>

    <button mat-raised-button color="primary" (click)="close()">
      Done
    </button>
  </aa-dialog-actions>
</aa-dialog>
