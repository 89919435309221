import { Component, OnDestroy } from '@angular/core';

// core
import { ISelfServMeta, SubscriberComponent } from '@aa/common';

// ui
import { Command, Icon } from '@aa/app/ui';

// shared
import { SelfServMetaListCache } from '../../services';
import { TextUtil } from '../../classes';

interface DomainMenuItem {
  domain: string;
  url: string;
  label: string;
  description: string;
}

@Component({
  selector: 'aa-domain-select-menu',
  templateUrl: 'domain-select-menu.component.html',
  styleUrls: ['domain-select-menu.component.css']
})
export class DomainSelectMenuComponent extends SubscriberComponent implements OnDestroy {

  public readonly linkIcon: Icon = Icon.External;

  public itemList: DomainMenuItem[];
  public currentItem: DomainMenuItem;

  public readonly refreshCmd: Command;

  public readonly viewSelfServCmd: Command = new Command({
    name: 'Open SelfServ',
    description: 'Navigate to your public SelfServ web app',
  });

  constructor(private readonly metaService: SelfServMetaListCache) {

    super();

    this.refreshCmd = metaService.refreshCommand;

    function mapItem(item: ISelfServMeta): DomainMenuItem {
      return {
        domain: item.domain,
        url: item.url,
        label: TextUtil.getLinkLabel(item.url) || 'Invalid Data',
        description: item.treeName
      };
    }

    this.subscribe(metaService.metaList$, metaList => {
      if (!metaList) {
        this.itemList = null;
        return;
      }

      this.itemList = metaList.map(mapItem);
    });

    this.subscribe(metaService.currentMeta$, meta => {
      if (meta) {
        this.currentItem = mapItem(meta);
      } else {
        this.currentItem = null;
      }
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.viewSelfServCmd.destroy();
  }

  public setDomain(domain: string) {
    this.metaService.setCurrentDomain(domain);
  }
}
