import { Component, Input, OnInit } from '@angular/core';

import { Icon } from '@aa/app/ui';

import { ChannelResolverService } from '../../services';
import { ChannelType } from '../../models';

@Component({
  selector: 'aa-channel-logo',
  templateUrl: './channel-logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class ChannelLogoComponent implements OnInit {

  private _channelId: string = null;

  public hidden: boolean = false;
  public name: string;

  public activeImage: string;
  public logoImage: string;
  public watermarkImage: string;

  public icon: Icon = null;
  public useWatermarkCss: boolean = false;

  // @Input() caption: boolean = false;
  @Input() watermark: boolean = false;

  constructor(private channelResolver: ChannelResolverService) {
  }

  @Input('channelId')
  set channelId(id: Guid) {
    if (id === this._channelId) {
      return;
    }

    this._channelId = id;

    const channel = this.channelResolver.getChannel(id);

    this.name = this.logoImage = this.icon = null;

    if (!channel) {
      return;
    }

    this.name = channel.name;

    this.activeImage = this.logoImage = channel.logoImage;
    this.watermarkImage = channel.watermarkImage;

    switch (channel.channelType) {
      case ChannelType.Private:
        this.icon = Icon.Private;
        break;

      case ChannelType.Team:
        this.icon = Icon.Team;
        this.name += ' (Team)';
        break;

      case ChannelType.Global:
        this.activeImage = this.logoImage = channel.logoImage;
        this.watermarkImage = channel.watermarkImage;
    }
  }

  ngOnInit() {
    this.useWatermarkCss = this.watermark;

    // manually set watermark css if watermarkImage is not defined
    if (this.watermark && this.watermarkImage) {
      this.activeImage = this.watermarkImage;
      this.useWatermarkCss = false;
    }
  }

  public onImageLoaded() {
    this.hidden = false;
  }

  public onImageError() {
    this.hidden = true;

    // swap watermark image on error
    if (this.activeImage === this.watermarkImage && this.watermarkImage !== this.logoImage) {
      this.activeImage = this.logoImage;
      this.useWatermarkCss = true;
      this.channelResolver.clearWatermarkImage(this._channelId);
    }
  }
}
