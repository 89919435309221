import { Command, Icon } from '@aa/app/ui';


function cmd(name: string, description: string, icon: Icon): Command {
  return new Command({
    name: name,
    description: description,
    icon: icon
  });
}

/**
 * List of inserts available inside workspace
 * This is defined outside the workspace module so that the admin
 * module has access to the command keys for mode configuration
 */
export class DocumentInsertCommands {

  readonly actionTable = new Command({name: 'Action Table', description: 'Insert new action table', icon: Icon.Table});
  readonly phpIntro    = new Command({name: 'Plan Intro',    description: 'Insert Personal Housing Plan introduction', icon: Icon.Intro});
  readonly adviceIntro = new Command({name: 'Advice Intro', description: 'Insert Advice introduction', icon: Icon.Intro});

  readonly basicSignature  = new Command({name: 'Basic Signature', description: 'Insert basic signature', icon: Icon.Signature});
  readonly formalSignature = new Command({name: 'Formal Signature', description: 'Insert formal signature', icon: Icon.Signature});
  readonly emptyPara       = new Command({name: 'Empty Paragraph', description: 'Insert empty paragraph at end of document', icon: Icon.File});


  public getCommmandMap(): Map<string, Command> {
    const map = new Map<string, Command>();

    Object.keys(this).forEach(key => {
      map.set(key, <Command>this[key]);
    });

    return map;
  }

  public getCommandGroups(): Command[][] {
    return [
      [
        this.actionTable,
        this.phpIntro,
        this.adviceIntro
      ],
      [
        this.basicSignature,
        this.formalSignature,
        this.emptyPara
      ]
    ];
  }
}
