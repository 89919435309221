 <mat-form-field>
    <textarea [formControl]="control"
              [maxlength]="maxLength"
              matInput
              [placeholder]="label"
              cdkTextareaAutosize
              [cdkAutosizeMinRows]="minRows"
              [cdkAutosizeMaxRows]="maxRows"
              [ngClass]="{monospace: monospace}">
    </textarea>

   <mat-hint *ngIf="hint; else defaultHint">{{hint}}</mat-hint>


   <ng-template #defaultHint>
     <!-- <mat-hint >{{charsRemaining}} chars remaining</mat-hint> -->
   </ng-template>
   <mat-error *ngIf="hasError">{{errors[0]}}</mat-error>
 </mat-form-field>
