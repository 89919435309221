<aa-dialog (keyup.escape)="cancel()" [loading]="loading">

  <aa-dialog-title>
    {{snippet ? "Snippet Feedback" : "App Feedback"}}
    <div class="description">{{feedback.feedbackType | feedbackTypeDescription}}</div>
  </aa-dialog-title>

  <aa-dialog-content class="left">

    <aa-select label="Feedback Type"
               [options]="options"
               [control]="control('feedbackType')">
    </aa-select>

    <aa-textarea-input
      [control]="control('info')"
      minRows="2"
      label="Additional Info">
    </aa-textarea-input>

    <aa-form-error prefix="Error" [error]="error"></aa-form-error>

  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button color='primary' (click)="submit()" [disabled]="loading || !feedback.feedbackType">
      Send
    </button>

    <button mat-raised-button (click)="cancel()">
      Cancel
    </button>
  </aa-dialog-actions>

  <aa-dialog-right *ngIf="snippet">
    <aa-snippet-preview class="snippet" [snippet]="snippet"></aa-snippet-preview>
  </aa-dialog-right>

</aa-dialog>
