export enum ShareStatus {
  None = 0,

  // these must match their respective channel types
  Global = 4,
  Group  = 8,
  Review = 16,

  OutOfDate = 32
}
