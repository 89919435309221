import { FormRowComponent } from './form-row.component';
import { FormInfoComponent } from './form-info.component';
import { TextInputComponent } from './text-input.component';
import { NumericInputComponent } from './numeric-input.component';

import { TextAreaInputComponent } from './textarea-input.component';
import { DateInputComponent } from './date-input.component';
import { FormErrorComponent } from './form-error.component';

import { FormCheckboxComponent } from './form-checkbox.component';

import { SelectComponent } from './select.component';
import { PasswordInputComponent } from './password-input.component';
import { FormDialogComponent } from './form-dialog.component';

import { ListEditorComponent, ListItemComponent, IdListEditorComponent, IdListItemComponent } from './list-editor';
import { HtmlInputComponent } from './html-input';

export * from './abstract-input.component';

export const COMPONENTS = [
  HtmlInputComponent,
  FormErrorComponent,
  FormRowComponent,
  FormCheckboxComponent,
  TextInputComponent,
  NumericInputComponent,
  TextAreaInputComponent,
  DateInputComponent,
  PasswordInputComponent,
  SelectComponent,
  FormInfoComponent,
  FormDialogComponent,

  ListEditorComponent, ListItemComponent,
  IdListEditorComponent, IdListItemComponent
];


