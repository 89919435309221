// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { EnumHelper } from '@aa/common';

// local
import { ChannelItem, ShareStatus } from '../models';

/*
 * Formats shareStatus for an IChannelItem
 * Usage:
 *   item | shareStatus
 */
@Pipe({name: 'shareStatus'})
export class ShareStatusPipe implements PipeTransform {

  // define list of flags in the order we wish them to appear
  static flagList: ShareStatus[] = [
    ShareStatus.Review,
    ShareStatus.Group,
    ShareStatus.Global
  ];

  private static formatShareStatusFlag(status: ShareStatus): string {
    switch (status) {
      case ShareStatus.Review:    return 'Under Review';
      case ShareStatus.Group:     return 'Group';
      case ShareStatus.Global:    return 'Global';
      case ShareStatus.OutOfDate: return 'Out of date';

      default: return 'Unknown';
    }
  }

  private static formatShareStatusArray(array: ShareStatus[]) {
    switch (array.length) {
      case 0:  return 'Unknown';
      case 1:  return ShareStatusPipe.formatShareStatusFlag(array[0]);
      default: return 'Multiple';
    }
  }

  private static getShareStatusArray(status: ShareStatus) {
    // https://github.com/ng-packagr/ng-packagr/issues/696
    const callback = flag => EnumHelper.hasFlag(status, flag);
    return ShareStatusPipe.flagList.filter(callback);
  }

  /**
   * Format share status as single word with and asterisk to indicate sync status
   * Examples:
   * Group             => Group
   * Group | OutOfSync => Group*
   * Group | Global    => Multiple
   * @param {ChannelItem} item
   * @returns {string}
   */
  static formatShareStatusCompact(item: ChannelItem): string {

    const status = ChannelItem.getShareStatus(item);

    if (status === ShareStatus.None) {
      return 'None';
    }

    const matchedFlags = ShareStatusPipe.getShareStatusArray(status);
    let result = ShareStatusPipe.formatShareStatusArray(matchedFlags);

    if (EnumHelper.hasFlag(status, ShareStatus.OutOfDate)) {
      result += '*';
    }

    return result;
  }

  /**
   * Format share status as text description
   * Examples:
   * Group             => Group
   * Group | OutOfSync => Group  (Out of Sync)
   * Group | Global    => Group and Global
   * @param {ChannelItem} item
   * @returns {string}
   */
  static formatShareStatusFull(item: ChannelItem): string {

    const status = ChannelItem.getShareStatus(item);

    if (status === ShareStatus.None) {
      return 'Not shared';
    }

    const matchedFlags = ShareStatusPipe.getShareStatusArray(status);
    const count = matchedFlags.length;
    let result = count === 0 ? 'Unknown' : '';

    for (let i = 0; i < count; i++) {

      result += ShareStatusPipe.formatShareStatusFlag(matchedFlags[i]);

      if (i === 0) {
        continue; // no delimiter
      }

      if (i === count - 1) {
        result += ' and '; // final delimiter
      } else {
        result += ', '; // intermediate delimiter
      }
    }

    if (EnumHelper.hasFlag(status, ShareStatus.OutOfDate)) {
      result += ' (Out of date)';
    }

    return result;
  }


  transform(item: ChannelItem, format: 'compact'|'full' = 'compact'): string {

    if (format === 'full') {
      return ShareStatusPipe.formatShareStatusFull(item);
    }

    return ShareStatusPipe.formatShareStatusCompact(item);
  }
}
