
import { Snippet, SnippetType } from '../models';
import { IUser } from '@aa/common';
import { DatePipe } from '@angular/common';

export class ContentGenerator {

  public static generateEmptySnippet(): Snippet {
    const snippet = new Snippet();

    snippet.data = 'Insert content here...';

    return snippet;
  }

  /**
   * Generate action table as per issue #75
   * 2 x Housing Adviser, 3 x You
   * @returns {Snippet}
   */
  public static generateActionTable(): Snippet {

    const length = 5;

    const snippet = new Snippet();

    // init html data
    // note that these styles should match the defaults used in CkTextEditor.class
    snippet.data = `
    <table border="1" cellpadding="0" cellspacing="0">
      <thead>
        <tr>
          <th></th>
          <th>Action</th>
          <th>By When</th>
          <th>By Whom</th>
        </tr>
      </thead>
      <tbody>`;

    for (let i = 0; i < length; i++) {
      const rowId = i + 1;
      snippet.data +=
        `<tr>
          <td>${rowId}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>`;
      }

      snippet.data +=
        `</tbody></table>`;

    return snippet;
  }

  public static generateBasicSignatureSnippet(user: IUser): Snippet {
    const snippet = new Snippet();
    snippet.snippetType = SnippetType.Signature;

    // init html data
    snippet.data = `
      <p>Yours Sincerely,</p>
      <p>
        ${user.firstName} ${user.lastName}<br>
        ${user.jobTitle}</span>
       </p>
    `;

    return snippet;
  }

  public static generateFormalSignatureSnippet(user: IUser): Snippet {
    const snippet = new Snippet();
    snippet.snippetType = SnippetType.Signature;

    // init html data
    snippet.data = `
      <p>Signed:</p>
      <table class="document-signature">
        <tr>
          <td class="underline"></td>
          <td class="underline"></td>
        </tr>
        <tr>
          <td>${user.firstName} ${user.lastName}</td>
          <td class="underline"></td>
        </tr>
        <tr>
          <td>${user.jobTitle}</td>
          <td>Client(s)</td>
        </tr>
      </table>
    `;

    return snippet;
  }

  public static generateIntroSnippet(introText: string): Snippet {

    const snippet = new Snippet();
    snippet.snippetType = SnippetType.Intro;

    // format date
    const dateStr = new DatePipe('en-gb').transform(new Date(), 'mediumDate');

    // init html data
    snippet.data = `
      <p>${dateStr}</p>

      <p>${introText}</p>
    `;

    return snippet;
  }

  public static generatePhpIntroSnippet(): Snippet {

    // tslint:disable-next-line:max-line-length
    const introText = `Thank you for your recent contact regarding your housing situation. Please find below an action plan, an assessment of your housing situation, your issue, your options and tailored advice to help you to resolve your issue.`;

    return ContentGenerator.generateIntroSnippet(introText);
  }

  public static generateAdviceIntroSnippet(): Snippet {
    const introText = `Thank you for your recent contact regarding your housing situation. Please find some advice relating to your situation below.`;

    return ContentGenerator.generateIntroSnippet(introText);
  }
}
