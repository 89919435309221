import { Injectable, Injector } from '@angular/core';

import { Tree } from '../models';
import { TreeSerializer } from '../serializers';

import { AbstractApiService } from './abstract-api.service';

@Injectable({providedIn: 'root'})
export class TreeApiService extends AbstractApiService<Tree> {
  constructor (injector: Injector) {
    super(injector, 'trees', new TreeSerializer());
  }
}
