export enum NotificationType {
  Success,
  Error,
  Info,
  Alert
}

export interface INotification {
  type: NotificationType;
  title: string;
  message: string;
}
