import sortBy from 'lodash-es/sortBy';

import { EnumHelper } from '@aa/common';

import {
  AppChannel,
  AppChannelData,
  AppChannelGroup,
  AppLibrary,
  ChannelAccess,
  ChannelType
} from '../models';

export class AppChannelGroupData {

  readonly channels: AppChannel[] = [];

  constructor(readonly group: AppChannelGroup) {
  }

  get id(): Guid {
    return this.group.id;
  }

  get name(): string {
    return this.group.name;
  }
}


export class AppMetaData {

  private static readonly defaultGroup: AppChannelGroup = {
    id: 'defaultGroup',
    name: 'Default',
    channelType: ChannelType.None
  };

  public readonly channelList: AppChannel[];

  public get groups(): AppChannelGroup[] {
    return this.channelData.groups;
  }

  static checkSharedAccess(channel: AppChannel, parentChannelType: ChannelType) {
    return EnumHelper.hasFlag(channel.access, ChannelAccess.Share) &&
           EnumHelper.hasFlag(channel.shareType, parentChannelType);
  }

  constructor(readonly libraryList: AppLibrary[], readonly channelData: AppChannelData) {

    // validate library list
    if (!libraryList || !libraryList.length)  {
      throw Error('Invalid library list');
    }

    // validate channel list
    this.channelList = channelData.channels;
    if (!this.channelList || !this.channelList.length)  {
      throw Error('Invalid channel list');
    }

    // groups
    const groups = channelData.groups;
    if (!groups || !groups.length)  {
      throw Error('Invalid channel group list');
    }
  }

  private getGroupInfo(id: Guid) {
    return this.channelData.groups.find(o => o.id === id) || AppMetaData.defaultGroup;
  }

  /**
   * Loop through channel data and build groups based on channel type
   */
  public buildGroupDataList(requiredAccess: ChannelAccess = ChannelAccess.None): AppChannelGroupData[] {

    const groupDataList: AppChannelGroupData[] = [];

    for (const channel of this.filterChannelsByAccess(requiredAccess, false)) {

      const group = this.getGroupInfo(channel.groupId);

      let groupData = groupDataList.find(o => o.group.id === group.id);
      if (!groupData) {
        groupData = new AppChannelGroupData(group);
        groupDataList.push(groupData);
      }

      groupData.channels.push(channel);
    }

    return sortBy(groupDataList, o => o.group.channelType);
  }

  getLibrary(libraryId: Guid): AppLibrary {
    return this.libraryList.find(o => o.id === libraryId);
  }

  getLibraryOrDefault(libraryId: Guid): AppLibrary {
    return this.getLibrary(libraryId) || this.getDefaultLibrary();
  }

  getDefaultLibrary(): AppLibrary {
    return this.libraryList[0];
  }

  getLibraryOrThrow(libraryId: Guid): AppLibrary {
    const library = this.getLibrary(libraryId);
    if (!library) {
      throw Error('Failed to find library:' + libraryId);
    }
    return library;
  }


  getChannel(channelId: Guid): AppChannel {
    return this.channelList.find(o => o.id === channelId);
  }

  getChannelOrThrow(channelId: Guid): AppChannel {
    const channel = this.getChannel(channelId);
    if (!channel) {
      throw Error('Failed to find channel:' + channelId);
    }
    return channel;
  }

  getChannelName(channelId: Guid): string {
    const channel = this.getChannel(channelId);

    if (!channel) {
      return null;
    }

    return channel.name;
  }

  getPrivateChannel() {
    const channel = this.channelList.find(o => o.channelType === ChannelType.Private);

    if (!channel) {
      throw Error('Failed to resolve private channel');
    }

    return channel;
  }

  getChannelByType(channelType: ChannelType) {
    return this.channelList.find(o => o.channelType === channelType);
  }

  /**
   * Returns all channels which match the channelType filter
   * @param {ChannelType} filter
   * @returns {AppChannel[]}
   */
  filterChannelsByType(filter: ChannelType): AppChannel[] {
    if (filter === ChannelType.All) {
      return this.channelList;
    }

    // get all channels that match the filter
    return this.channelList.filter(o => EnumHelper.hasFlag(filter, o.channelType));
  }

  /**
   * Returns all channels that have the given access flags
   * @param {ChannelAccess} requiredAccess
   * @param {boolean} includeLocalisation (default = false)
   * @returns {AppChannel[]}
   */
  filterChannelsByAccess(requiredAccess: ChannelAccess, includeLocalisation: boolean = false): AppChannel[] {

    let list = this.channelList;
    if (!includeLocalisation) {
      list = list.filter(o => o.channelType !== ChannelType.Localisation);
    }

    if (requiredAccess === ChannelAccess.None) {
      return list;
    }

    /* tslint:disable-next-line:no-bitwise */
    return list.filter(o => EnumHelper.hasFlag(o.access, requiredAccess)); // match all filter bits
  }

  /**
   * Returns all channels that user can read from
   * @returns {AppChannel[]}
   */
  getReadChannels(): AppChannel[] {

    return this.channelList.filter(o => EnumHelper.hasFlag(o.access, ChannelAccess.Read));
  }

  /**
   * Returns all channels that have the given access flags
   * @param {ChannelAccess} requiredAccess
   * @returns {AppChannel[]}
   */
  getSharedChannels(parentChannelType: ChannelType): AppChannel[] {

    if (parentChannelType === ChannelType.None) {
      return [];
    }

    // find channels which will accept the given parent channelType
    return this.channelList.filter(o => AppMetaData.checkSharedAccess(o, parentChannelType));
  }

  hasSharedChannels(parentChannelType: ChannelType): boolean {

    if (parentChannelType === ChannelType.None) {
      return false;
    }

    // find channels which will accept the given parent channelType
    return this.channelList.some(o => AppMetaData.checkSharedAccess(o, parentChannelType));
  }

}
