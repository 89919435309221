// external
import { Subscription ,  Observable } from 'rxjs';
import { OnDestroy, EventEmitter, Directive } from '@angular/core';

const noop = () => {};

/**
 * Base class for managing observable subscriptions
 * See: http://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
 */
@Directive()
export abstract class SubscriberComponent implements OnDestroy {
  protected subscriptions: Subscription[] = [];

  protected subscribe<T>(source: Observable<T> | EventEmitter<T>,
                         action: (value: T) => void,
                         error: (value: T) => void = null): Subscription {
    let sub: Subscription = null;

    if (source instanceof EventEmitter) {
      sub = (<EventEmitter<T>>source).subscribe(action);
    } else {

      error = error || noop;

      sub = (<Observable<T>>source).subscribe(action, error);
    }

    this.subscriptions.push(sub);

    return sub;
  }

  /* REmoved to avoid dependency on command
  protected subscribeCmd(cmd: Command , action: (value: CommandArgs) => void) {
    this.subscriptions.push(cmd.subscribe(action));
  }
  */

  public addSubscription(sub: Subscription) {
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }

    this.subscriptions = [];
  }
}


