import { Channel } from '../models';
import { AbstractFormatter } from './abstract_formatter.class';
import { ChannelTypeFormatter } from './channel-type.formatter';

export class ChannelIdFormatter extends AbstractFormatter<Guid> {

  public static formatChannelName(channel: Channel): string {
    if (!channel) {
      return 'None';
    }
    const type = ChannelTypeFormatter.formatName(channel.channelType);
    return `${channel.name} (${type})`;
  }

  constructor(public channels: Channel[]) {
    super();

    if (!channels) {
      throw new Error('Invalid channel list passed to team formatter');
    }
  }

  protected formatItemInternal(value: Guid): string {

    if (!value) {
      return 'None';
    }

    const channel = this.channels.find(o => o.id === value);
    if (!channel) {
      return `Channel (${value})`;
    }

    return ChannelIdFormatter.formatChannelName(channel);
  }
}
