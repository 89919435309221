import { Component, Input } from '@angular/core';


@Component({
  selector: 'aa-form-error',
  templateUrl: './form-error.component.html',
})
export class FormErrorComponent {
  @Input() prefix: string = 'Error';
  @Input() error: string = null;
  @Input() details: string[] = null;
}
