// core
import { Role, Item } from '@aa/common';

// local
import { ChannelType } from './channel_type.enum';
import { ChannelAccess } from './channel_access.enum';

import { ITokenData } from './token-data.interface';

export class Channel extends Item {

  name: string;

  userId: Guid = null;

  teamId: Guid = null;

  channelType: ChannelType;

  logoImage: string;

  shareRole: Role;

  shareType: ChannelType;

  static isSubscribable(channel: Channel): boolean {
    if (!channel) {
      return false;
    }

    switch (channel.channelType) {
      case ChannelType.Group:
      case ChannelType.Global:
      case ChannelType.Team:
        return true;

      default:
        return false;
    }
  }

  static isSharedChannelType(channelType: ChannelType): boolean {
    switch (channelType) {
      case ChannelType.Group:
      case ChannelType.Global:
      case ChannelType.Review:
        return true;

      default:
        return false;
    }
  }
}

export class AppChannel extends Channel implements ITokenData {

  watermarkImage: string;
  access: ChannelAccess;
  token: string;
  groupId: Guid;
}

export class AppChannelGroup {
  id: Guid;
  channelType: ChannelType;
  name: string;
}

export class AppChannelData {
  channels: AppChannel[];
  groups: AppChannelGroup[];
}

export interface IChannelShareRequest {
  notes?: string;
}
