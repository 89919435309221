// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { LocalisationFlag } from '../models';
import { LocalisationFlagFormatter } from '../classes';

/*
 * Formats localisationFlag values
 * Usage:
 *   item.localisation | localisationFlag
 */
@Pipe({name: 'localisationFlag'})
export class LocalisationFlagPipe implements PipeTransform {

  transform(value: LocalisationFlag): string {
    return LocalisationFlagFormatter.formatName(value);
  }
}
