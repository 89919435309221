import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Idle, DocumentInterruptSource, StorageInterruptSource } from '@ng-idle/core';

// @aa/common
import { GuidTools, AuthService, AppConfig } from '@aa/common';


// local
import { UserActivity, UserActivitySerializer } from '../models';
import { KeepaliveService } from './keep-alive.service';

@Injectable({providedIn: 'root'})
export class UserActivityService {

  static readonly interruptSources: any[] = [
  // new DocumentInterruptSource('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll'),
  new DocumentInterruptSource('keydown DOMMouseScroll mousewheel mousedown scroll'), // mousemove removed to improve drag-drop performance
  new StorageInterruptSource()];

  public static readonly idleInterval = 60 * 5; // 5 minutes
  public static readonly  pingInterval = 30; // 30 seconds

  private static readonly userActivitySerializer = new UserActivitySerializer();

  public appVersion: string;

  private static loadSession(key: string): UserActivity {
    if (!key) {
      throw new Error('Invalid storage key');
    }

    const savedActivityData = sessionStorage && sessionStorage.getItem(key);

    if (!savedActivityData) {
      return null;
    }

    try {
      return UserActivityService.userActivitySerializer.parse(savedActivityData);
    } catch (err) {
      console.warn('Failed to deserialize saved session');
      return null;
    }
  }

  private static saveSession(key: string, session: UserActivity) {
    if (!sessionStorage) {
      return;
    }

    // save it
    const json = UserActivityService.userActivitySerializer.serialize(session);
    sessionStorage.setItem(key, json);
  }

  constructor(
    appConfig: AppConfig,
    private ngZone: NgZone,
    private http: HttpClient,
    private auth: AuthService,
    private idle: Idle,
    private keepalive: KeepaliveService) {

    this.appVersion = appConfig.version;

    ngZone.runOutsideAngular(() => {
      // sets an idle timeout of 5 seconds, for testing purposes.
      this.idle.setIdle(UserActivityService.idleInterval);

      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(UserActivityService.interruptSources);
    });

    this.idle.onIdleEnd.subscribe(() => console.log('User activity resumed'));

    this.idle.onIdleStart.subscribe(() => {
      console.log('User is idle');
    });

    // setup keepalive
    this.keepalive.interval(UserActivityService.pingInterval);
    this.keepalive.onPing.subscribe(() => {

      ngZone.run(() => {
        this.trackActivity();
      });
    });

    // check idle
    this.checkIdle();
    this.auth.user$.subscribe(() => {
      this.checkIdle();
    });


    // logout on idle
    this.idle.onTimeout.subscribe(() => {
      auth.logout();
    });

    // refresh token periodically when user is active
    this.keepalive.onPing.subscribe(() => {
      auth.checkToken();
    });
  }

  private checkIdle() {

    const user = this.auth.user;
    const running = this.idle.isRunning();

    if (!user && running) {
      this.idle.stop();
      return;
    }

    if (user && !running) {
      const timeout = Math.max(user.timeout - this.idle.getIdle(), 1); // timeout trigger time

      this.ngZone.runOutsideAngular(() => {
        this.idle.setTimeout(timeout);
        this.idle.watch();
      });

      this.updateSession();
      return;
    }
  }

  private getStorageKey(): string {

    const user = this.auth.user;
    if (!user) {
      return null;
    }

    return `activity_${user.id}`;
  }

  private updateSession(): UserActivity {

    // save it to session storage
    const key = this.getStorageKey();

    if (!key) {
      return null;
    }

    const session = this.findOrCreateSession(key);

    // update end
    session.end = new Date();

    // save it
    UserActivityService.saveSession(key, session);

    return session;
  }

  private trackActivity(): void {

    const session = this.updateSession();

    if (!session) {
      return;
    }

    this.http.post('/api/track/activity', session)
      .subscribe(
      res => {},
      (err: HttpErrorResponse) => {
        // console.warn('track activity failed:', err);
        if (err.status === 401) {
          this.auth.logout();
        }
      }
    );
  }

  private checkSession(session: UserActivity): boolean {

    if (!session || !session.end) {
      return false;
    }

    // check version
    if (session.version !== this.appVersion) {
      return false;
    }

    // check elapsed time
    const now = new Date();
    const end = new Date(session.end);
    const elapsed = (now.getTime() - end.getTime()) / 1000;
    if (elapsed > UserActivityService.idleInterval) {
      return false;
    }

    return true;
  }

  private findOrCreateSession(key: string): UserActivity {

    const now = new Date();

    // try load saved session
    const savedSession = UserActivityService.loadSession(key);

    // check if is valid
    if (this.checkSession(savedSession)) {
      return savedSession;
    }

    // otherwise create new
    const session = new UserActivity();
    session.id = GuidTools.newGuid();
    session.start = session.end = now;
    session.version = this.appVersion;

    return session;
  }

}
