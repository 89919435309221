// common
import { ISnippet, Item } from '@aa/common';

// local
import { Paragraph } from '../../../../common/lib/models/paragraph.model';
import { ChannelItem } from './library-item.model';
import { SnippetType } from './snippet_type.enum';

export enum LocalisationFlag {
  None = 0,
  Optional = 1,
  Recommended = 2,
  Required = 4,
  Done = 8
}

export enum LocalisationMode {
  Append = 0,
  Replace = 1
}

export interface ISnippetCreateArgs {
  data: string;
}

export interface ISnippetMessage {
  text: string;
  tooltip: string;
}

export interface ISnippetSet {
  id: Guid;
  name: string;
  description: string;
  snippets: ISnippet[];

  slug?: string;
}

export interface ISnippetLocalisation extends Item {

  parentId?: Guid;
  channelId: Guid;
  data: string;

  localisationMode?: LocalisationMode;
}

export class Snippet extends ChannelItem implements Paragraph, ISnippet, ISnippetLocalisation  {

  data: string;

  snippetType: SnippetType = SnippetType.Content;

  useCount?: number = 0;

  source?: string = null;
  localisation: LocalisationFlag;
  localisationHint?: string;
  localisationMode?: LocalisationMode;

  public static getModeLabel(mode: LocalisationMode) {
    switch (mode) {
      case LocalisationMode.Replace: return 'Replace';
      default:
        return 'Append';
    }
  }

  constructor(source?: Snippet) {
    super();
    if (source) {
      Object.assign(this, source);
    }
  }
}
