<aa-dialog (keyup.escape)="close()">

  <aa-dialog-title>Advice Aid</aa-dialog-title>

  <aa-dialog-content>

    <p class='version-info'>Version: {{version}}</p>

    <button mat-button *ngIf="channelInfo.canView" (click)="channelInfo.showDialog()">
      Channel Info
    </button>

   <aa-copyright></aa-copyright>
  </aa-dialog-content>

  <aa-dialog-actions>
    <button tabindex="0"  mat-raised-button (click)="close()">
      OK
    </button>
  </aa-dialog-actions>
</aa-dialog>


