import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AbstractInputComponent } from './abstract-input.component';

@Component({
  selector: 'aa-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss'],
  host: {
    'class': 'form-control'
  }
})
export class FormCheckboxComponent extends AbstractInputComponent {

  @Input() color: 'accent'|'primary'|'warn' = 'accent';
  @Input() label: string;
  @Input() control: AbstractControl;
  @Input() hint: string;
}
