import { Role } from '@aa/common';

import { PasswordData } from './password.model';
import { Item } from '@aa/common';
import { UserProfile } from './user-profile.model';

export class User extends Item implements UserProfile {

  teamId: Guid;

  firstName: string = null;

  lastName: string = null;

  jobTitle: string = null;

  email: string = null;

  phoneNumber: string = null;

  roles: Role[] = [];

  password: PasswordData = null;

  activated: boolean = false;

  created: DateString = null;

  lastLogin: DateString = null;

  expiry: DateString = null;

  token: string;

  activationEmail?: boolean;
}
