import { Component } from '@angular/core';


/**
 * Display copyright message
 */
@Component({
  selector: 'aa-copyright',
  templateUrl: './copyright.component.html'
})
export class CopyrightComponent {
  public readonly year: number = new Date().getFullYear();
}
