import { Inject, Injectable } from '@angular/core';
import * as msal from '@azure/msal-browser';

// common
import { AppConfig, ITokenLoginArgs, AuthService } from '@aa/common';

// local
import { DOCUMENT, LocationStrategy } from '@angular/common';

/**
 * Microsoft auth service integration based on
 * https://github.com/benbaran/msal-angular
 */
@Injectable({providedIn: 'root'})
export class MicrosoftAuthService {

  readonly graphScopes = ['openid', 'profile', 'email'];

  public error: string;
  private app: msal.PublicClientApplication;

  public enabled: boolean = false;

  constructor(private readonly auth: AuthService, config: AppConfig,
              locationStrategy: LocationStrategy,
              @Inject(DOCUMENT) document: any) {

    const clientId = config.adKey;

    if (!clientId) {
      console.log('Microsoft login service : invalid key');
      return;
    }

    // generate redirect uri
    const redirectUri = document.location.origin + locationStrategy.getBaseHref() + 'oauth';

    const msalConfig: msal.Configuration = {
      auth: {
        clientId: clientId,
        redirectUri: redirectUri
      }
    };

    // console.log('msalConfig', msalConfig);

    try {
      this.app = new msal.PublicClientApplication(msalConfig);
      this.enabled = true;

    } catch (e) {
      console.warn('Failed to initialize MSAL', e);
    }

    auth.loggedIn$.subscribe(user => {
      if (!user) {
        this.logout();
      }
    });
  }

  public async login(): Promise<boolean> {

    if (!this.enabled) {
      return Promise.reject('Microsoft login is disabled');
    }

    const request: msal.PopupRequest = {
      scopes: this.graphScopes,
      prompt: 'select_account'
    };

    return this.app.initialize()
      .then(() => this.app.loginPopup(request))
      .then((response: msal.AuthenticationResult) => {

        const args: ITokenLoginArgs = {token: response.idToken};
        return this.auth.loginHelper('/api/auth/login/microsoft', args);
      }).catch(err => {
        throw this.processError(err);
      });
  }

  public logout(): Promise<void> {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#skipping-the-server-sign-out
    return this.app.initialize()
    .then(() => this.app.logoutRedirect({
      onRedirectNavigate: (url) => {
        // Return false if you would like to stop navigation after local logout
        return false;
      }
    }));
  }

  private processError(err: any): string {

    console.warn('MSAL error', err);

    if (err instanceof msal.AuthError) {
      const authError = <msal.AuthError>err;

      switch (authError.errorCode) {
        case 'user_cancelled':
          return 'Cancelled';

        default:
          return authError.errorMessage;
      }
    }

    return err;
  }
}
