import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ISelectOption, ISelectOptionGroup } from '../models';


@Component({
  selector: 'aa-select',
  templateUrl: './select.component.html',
  host: {
    'class': 'form-control'
  }
})
export class SelectComponent implements OnInit, OnDestroy {

  private _groups: ISelectOptionGroup[] = null;
  private _options: ISelectOption[] = [];
  private _normalizedLabels: string[] = [];
  private _filterStr: string = null;

  @Input() multiple: boolean = false;
  @Input() filterEnabled: boolean = false;
  @Input() label: string;
  @Input() hint: string;
  @Input() control: FormControl;
  @Input() allowClear: boolean = false;

  private subscription: Subscription;
  private _initialised: boolean = false;

  get groups(): ISelectOptionGroup[] {
    return this._groups;
  }

  @Input() set groups(groups: ISelectOptionGroup[]) {
    this._groups = groups;

    if (groups) {
      this.options = [].concat.apply([], groups);
    }
  }

  get options(): ISelectOption[] {
    return this._options;
  }

  @Input() set options(options: ISelectOption[]) {
    this._options = options || [];
    this.rebuildLabels();
    this.rebuildFilter();
  }

  ngOnInit() {
    this._initialised = true;
    this.subscription = this.control.valueChanges.subscribe(value => {
      this.rebuildFilter();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription = null;
  }

  public updateFilter(filter: string) {
    this._filterStr = filter;
    this.rebuildFilter();
  }

  private rebuildLabels() {
    this._normalizedLabels = this._options.map(o => o.label ? o.label.toLowerCase() : '');
  }

  private isSelected(value: any): boolean {
    const currentValue = this.control.value;

    if (!currentValue) {
      return false;
    }

    if (currentValue.length) {
      return currentValue.indexOf(value) !== -1;
    }

    return currentValue === value;
  }

  private rebuildFilter() {
    if(!this._initialised) {
      return;
    }

    // hide all readonly options thar are readonly and not currently selected
    for (const option of this._options) {
      option.hidden = option.readonly && !this.isSelected(option.value);
    }

    if (!this._filterStr || this._filterStr.length === 0) {
      return;
    }

    for (let i = 0 ; i < this._normalizedLabels.length; i++) {
      const option = this._options[i];

      if (!option.hidden) {
        option.hidden = this._normalizedLabels[i].indexOf(this._filterStr) === -1;
      }
    }
  }
}
