import { FormGroup, Validators } from '@angular/forms';
import { IFactory, Serializer } from '@aa/common';

// local
import { IFormSerializer, FormConfigBuilder } from '../classes';
import { Question  } from '../models';

const fb = new FormConfigBuilder<Question>([
  { key: 'treeId', validators: [Validators.required] },
  { key: 'text', validators: [Validators.required, Validators.minLength(3)] },
  { key: 'name' },
  { key: 'slug' },
  { key: 'info' },
  { key: 'tagId' },
  { key: 'variableId' },
  { key: 'relatedQuestionId'},
  { key: 'entryPoint', defaultValue: false },
  { key: 'responses', defaultValue: [] }
]);

export class QuestionSerializer extends Serializer<Question> implements IFormSerializer<Question> {

  public getType(): IFactory<Question> {
    return Question;
  }

  public getName(): string {
    return 'Question';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
