import { Organisation } from './organisation';

export enum VariableType {
  None = 0,
  Text = 1,
  Url = 2,
  Boolean = 4,
  Organisation = 5
}

export interface IVariable {
  id: string;
  key: string;
  name: string;
  varType: VariableType;
  value?: string;
  linkText?: string;
  organisations?: Organisation[];
  cssClass?: string;
  skipped?: boolean;
}
