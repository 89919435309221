// external
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// shared
import { TeamApiService } from '../services/team-api.service';

/*
 * Formats a team name asynchronously
 * Usage:
 *   item.teamId | teamName | async
 */
@Pipe({name: 'teamName'})
export class TeamNamePipe implements PipeTransform {

  constructor(private teamService: TeamApiService) {
  }
  transform(id: string): Observable<string> {
    return this.teamService.getCachedItem(id).pipe(
      map(res => res.name)
    );
  }
}
