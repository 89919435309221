// angular
import { Component, forwardRef, Input} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

// local
import { ISelectOption } from '../../models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface ListItem {
  id: Guid;
}

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  /*tslint:disable-next-line:no-use-before-declare*/
  useExisting: forwardRef(() => IdListEditorComponent),
  multi: true
};

@Component({
  selector: 'aa-id-list-editor',
  templateUrl: './id-list-editor.component.html',
  styleUrls: ['./list-editor.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR],
})
export class IdListEditorComponent implements ControlValueAccessor {

  public list: ListItem[] = [];

  @Input() label: string = 'List';
  @Input() itemOptions: ISelectOption[];

  public static validateString(item: string): boolean {
    // string is not empty and not just whitespace
    return (item && /\S/.test(item));
  }

  private onChange = (_: any) => {};

  private onTouched = () => {};

  public onDrop(event: CdkDragDrop<Guid[]>) {
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
    this.notifyUpdate();
  }

  public updateItem(idx: number, snippetId: Guid) {
    this.list[idx] = {id: snippetId};
    this.notifyUpdate();
  }

  public deleteItem(idx: number) {
    this.list.splice(idx, 1);
    this.notifyUpdate();
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    this.setList(value);
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private notifyUpdate() {
    this.onChange(this.list.map(o => o.id)); // clone the array
    this.onTouched();
  }

  public addNewItem() {
    this.list.push({id: null});
  }

  private setList(list: Guid[]) {
    this.list.length = 0;

    if (!list) {
      return;
    }

    for (const id of list) {
      this.list.push({id});
    }
  }
}
