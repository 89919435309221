import {
  Component, Input, Output, HostListener, EventEmitter, ViewChild, ElementRef,
  OnInit, AfterViewChecked
} from '@angular/core';

// local
import { IListItem } from '../../models';

@Component({
  selector: 'aa-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit, AfterViewChecked {

  @ViewChild('input') inputRef: ElementRef;

  @Input() item: IListItem;

  @Output() changed = new EventEmitter<IListItem>();
  @Output() deleted = new EventEmitter<IListItem>();

  public initialData: string;
  private initialized: boolean = false;
  private mouseOver: boolean = false;

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.initialized) {
      return;
    }
    this.mouseOver = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mouseOver = false;
  }


  ngOnInit() {
    // cache the initial data to allow updates without triggering the view to refresh
    this.initialData = this.item.data;
  }

  ngAfterViewChecked() {
    // mark component as initialized after the final lifecycle hook
    // otherwise we get funny behaviour with the mouse events where mouseleave is not triggered as expected
    // https: // angular.io/docs/ts/latest/guide/lifecycle-hooks.html
    this.initialized = true;
  }

  focus() {
    this.inputRef.nativeElement.focus();
  }

  public notify() {
    this.changed.emit(this.item);
  }

  public get showToolbar(): boolean {
    return this.mouseOver;
  }

  public delete() {
    this.deleted.emit(this.item);
  }
}
