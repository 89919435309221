// external
import { Pipe, PipeTransform } from '@angular/core';

// shared
import { FeedbackType } from '../models';
import { FeedbackTypeFormatter } from '../classes';

/*
 * Formats description for a FeedbackType value
 * Usage:
 *   feedback.feedbackType | feedbackTypeDescription
 */
@Pipe({name: 'feedbackTypeDescription'})
export class FeedbackTypeDescriptionPipe implements PipeTransform {

  transform(feedbackType: FeedbackType): string {

    return FeedbackTypeFormatter.formatDescription(feedbackType || FeedbackType.None);
  }
}
