import { Component, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { SubscriberComponent } from '../classes';
import { ISelfServMeta } from '../models';

/**
 * Display copyright message
 */
@Component({
  selector: 'aa-advice-footer',
  templateUrl: './advice-footer.component.html'
})
export class AdviceFooterComponent extends SubscriberComponent implements OnInit {
  // sync date at startup and then every minute in case people keep the page open

  public today: number;

  @Input() meta: ISelfServMeta;

  public telephone: string;
  public url: string;

  constructor() {
    super();
    super.subscribe(timer(0, 6000), () => this.syncDate());
  }

  private syncDate() {
    this.today = Date.now();
  }

  public ngOnInit(): void {
    if (!this.meta) {
      return;
    }

    this.url = this.meta.url;

    if (this.meta.contact) {
      this.telephone = this.meta.contact.telephone;
    }

  }
}
