// external
import { DatePipe } from '@angular/common';

// local
import { AbstractFormatter } from './abstract_formatter.class';

export class DateFormatter extends AbstractFormatter<Date> {

  private readonly pipe = new DatePipe('en-gb');

  private readonly dateFormat: string;

  constructor(showTime: boolean = false) {
    super();
    this.dateFormat = showTime ? 'd/M/yy HH:mm' : 'd/M/yy';
  }

  protected formatItemInternal(value: Date): string {

    if (!value) {
      return null;
    }

    return this.pipe.transform(value, this.dateFormat);
  }
}
