// angular
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// idle
import { Idle } from '@ng-idle/core';

// rxjs
import { Subscription,  interval } from 'rxjs';

// local
import { ToastService } from '@aa/app/ui';

@Injectable({providedIn: 'root'})
export class UpdateService {

  timerSubscription: Subscription;

  private lastChecked: number = 0;

  // amount of time that must have passed to trigger and update
  public reloadTriggerInterval: number = 60 * 1000; // 1 minute

  // time between server status checks
  public checkInterval: number = 60 * 1000; // 1 minute

  // the internal ticker
  public readonly tickInterval: number = 10 * 1000; // 10 seconds

  public readonly lastMod: number = 0;

  private updateNotificationSent: boolean = false;
  private reloadPending: boolean = false;

  constructor(private readonly http: HttpClient,
              private readonly ngZone: NgZone,
              private readonly toast: ToastService,
              private readonly idle: Idle) {

    ngZone.runOutsideAngular(() => {
      this.timerSubscription = interval(this.tickInterval).subscribe(() => this.autoCheck());
    });

    this.lastMod = Date.parse(document.lastModified);
    console.log('Last Modified UTC: %s', new Date(this.lastMod).toUTCString());

    // reset the notification flag when user activity resumes
    idle.onIdleEnd.subscribe(() => this.reset());
  }

  private reset() {
    this.updateNotificationSent = false;
    this.reloadPending = false;
  }

  public autoCheck() {

    if (this.reloadPending) {
      this.reset();
      document.location.reload();
      return;
    }

    const now = new Date().getTime();

    if (now - this.lastChecked < this.checkInterval) {
      return;
    }

    this.lastChecked = now;
    this.checkUpdate();
  }

  public checkUpdate() {
    this.http.head(document.location.pathname, {observe: 'response'}).subscribe(
      res => this.processResponse(res),
      err => {},
    );
  }

  private processResponse(res: HttpResponse<any>) {
    const lastModHeader = res.headers.get('Last-Modified');

    // even if the sever returned 304 the app will
    // res.status will equal  200 and is thus of no use

    if (!lastModHeader || this.lastMod === 0) {
     // this.sendMessage('Auto update', 'NO header found');
      return;
    }
    const newLastMod = Date.parse(lastModHeader);

    // console.log("New Modified Check:", newLastMod, this.lastMod);
    if ((newLastMod - this.lastMod) < this.reloadTriggerInterval) {
      return; // no update required
    }

    // auto refresh if user is idling
    if (this.idle.isIdling()) {
      this.reloadPending = true;
      this.sendMessage('Auto update', 'App will refresh in a few seconds');
      return;
    }

    // display toast message on first attempt only
    if (this.updateNotificationSent) {
      return;
    }

    this.sendMessage('App update available', 'Please save your work and refresh the browser when ready');
    this.updateNotificationSent = true;
  }

  private sendMessage(title: string, msg: string) {

    // need to renter ng zone for UI update
    this.ngZone.run(() => {
      this.toast.info(title, msg, 10);
      console.log(`${title}. ${msg}`);
    });
  }
}
