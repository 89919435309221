import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export interface IDetailsDialogData {

  items: IDetailsDialogDataItem[];
  title?: string;
}

export interface IDetailsDialogDataItem {

  label: string;

  value?: string;

  /**
   * Optional async value that will override the value if defined.
   * This is unwrapped using the async pipe when rendered
   */
  asyncValue?: Observable<string>;
}

@Component({
  selector: 'aa-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.css']
})
export class DetailsDialogComponent implements OnInit {

  public readonly items: IDetailsDialogDataItem[];
  public readonly title: string;

  constructor(private readonly dialogRef: MatDialogRef<DetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: IDetailsDialogData) {

    if (!data) {
      throw Error('Data undefined');
    }

    if (!data.items) {
      throw Error('Items undefined');
    }

    this.items = data.items;
    this.title = data.title || 'Details';
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
