import { FormGroup, Validators } from '@angular/forms';

// common
import { IFactory, Serializer } from '@aa/common';

import { IFormSerializer, FormConfigBuilder } from '../classes';
import { Team, TeamPlanType } from '../models';
import { ThemeSerializer } from './theme.serializer';

export const DEFAULT_LOGO: string = '/media/teams/default_logo.png';

const fb = new FormConfigBuilder<Team>([
  { key: 'name', validators: [Validators.required] },
  { key: 'fullName', validators: [Validators.required] },
  { key: 'serviceName' },

  { key: 'theme', control: new ThemeSerializer().buildForm() },

  { key: 'domain' },

  { key: 'libraries' },
  { key: 'subscriptions' },
  { key: 'logoImage', defaultValue: DEFAULT_LOGO },
  { key: 'headerImage', defaultValue: DEFAULT_LOGO },
  { key: 'roles' },
  { key: 'expiry' },

  { key: 'planType', defaultValue: TeamPlanType.StandardTrial },

  { key: 'groupIds', defaultValue: null },
  { key: 'notificationsEnabled', defaultValue: false}
]);

export class TeamSerializer extends Serializer<Team> implements IFormSerializer<Team> {

  public getType(): IFactory<Team> {
    return Team;
  }

  public getName(): string {
    return 'Team';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
