// angular
import { Injectable } from '@angular/core';

// idle
import { Idle } from '@ng-idle/core';

// rxjs
import {
  Observable,
  BehaviorSubject,
  forkJoin,
  of,
  combineLatest,
  EMPTY as empty,
  timer,
  throwError,
  concat,
  ReplaySubject
} from 'rxjs';
import {
  catchError, map, switchMap, distinctUntilChanged, exhaustMap, retryWhen, take,
  tap, throttleTime, delay, filter
} from 'rxjs/operators';

// common
import { AppStorageService, AuthService } from '@aa/common';

// core
import { Command, Icon, HotkeysService } from '@aa/app/ui';

// local
import { AppChannelData, AppDataUpdateOptions, AppLibrary } from '../models';
import { AppMetaData } from '../classes';
import { ApiClient } from './api-client.service';


const LIBRARY_ID_KEY = 'metaLibraryId';

@Injectable({providedIn: 'root'})
export class AppMetaDataService {

  static readonly defaultOptions: AppDataUpdateOptions = {
    throttleTime: 1000,
    updateInterval: 5 * 60 * 1000, // 5 mins
  };

  // private streams
  private readonly updateEventStream = new BehaviorSubject<string>('initial');
  private readonly metaDataSource = new BehaviorSubject<AppMetaData>(null);
  private readonly currentLibrarySource = new ReplaySubject<AppLibrary>(1);

  // public observation point
  public readonly metaData$: Observable<AppMetaData> = this.metaDataSource.asObservable();
  public readonly currentLibrary$: Observable<AppLibrary> = this.currentLibrarySource.asObservable();

  // control streams
  private readonly libraryIdStream = new BehaviorSubject<Guid>(null);

  public readonly refreshCommand: Command = new Command({
    name: 'Refresh',
    description: 'Refresh library and channel data',
    icon: Icon.Refresh,
    shortcut: 'alt+r'});

  constructor (private readonly apiClient: ApiClient,
               private readonly idle: Idle,
               private hotkeyService: HotkeysService,
               appStorage: AppStorageService,
               auth: AuthService) {

    const options = AppMetaDataService.defaultOptions;

    // bind to refresh command
    hotkeyService.add(this.refreshCommand.hotKey);
    this.refreshCommand.subscribe(() => {
      this.refresh();
    });

    // trigger refresh when user activity resumed
    this.idle.onIdleEnd.subscribe(() => this.refresh());

    // meta data stream
    // bind to logged in state
    auth.loggedIn$.pipe(
      tap(user => user && this.setLibrary(appStorage.getString(LIBRARY_ID_KEY))), // push saved libraryid
      switchMap(user => user ? this.buildMetaDataStream(options) : of(null))
    ).subscribe(this.metaDataSource);

    // wire up current library source
    this.buildCurrentLibraryStream(this.metaDataSource, this.libraryIdStream)
      .subscribe(this.currentLibrarySource);

    // stash current library in storage
    this.currentLibrary$.subscribe(library => {
      if (library) {
        appStorage.setString(LIBRARY_ID_KEY, library.id);
      }
    });

  }

  // create stream for selecting active library
  private buildCurrentLibraryStream(metaStream: Observable<AppMetaData>, libraryIdStream: Observable<Guid>): Observable<AppLibrary> {

    return combineLatest([metaStream, libraryIdStream.pipe(distinctUntilChanged())])
      .pipe(map(([meta, libraryId]) => {
        if (!meta) {
          return null;
        }

        return meta.getLibraryOrDefault(libraryId);
      })
    );
  }

  private buildMetaDataStream(options: AppDataUpdateOptions): Observable<AppMetaData> {

    // const retryStream = Observable.timer(0, options.retryInterval)
    const libraries$ = this.retry(this.getLibraries());
    const channels$ = this.retry(this.getChannelData());

    const metaDataStream = forkJoin([libraries$, channels$])
      .pipe(
        map(([libraries, channels]) => new AppMetaData(libraries, channels)),

        catchError(err => {
            console.warn('Meta data error', err);
            return empty;
        })
    );

    return this.updateEventStream.pipe(
        throttleTime(options.throttleTime),
        switchMap(o => timer(0, options.updateInterval)),
        exhaustMap(() => metaDataStream) // exhaust map discards incoming events until inner observable completes
      );
  }

  /**
   * retry stream a given number of times after a specified delay
   */
  private retry<T>(source: Observable<T>, count: number = 1, delayMillis: number = 1000): Observable<T>  {
    return source.pipe(
      retryWhen(errors => {
        return concat(errors.pipe(delay(delayMillis), take(count)), throwError(errors[0])
        );
      })
    );
  }


  reset() {
    this.metaDataSource.next(null);
    console.log('metadata reset trigger');
  }

  /**
   * refresh the app data now
   */
  refresh() {
    this.updateEventStream.next('refresh');
    console.log('metadata refresh trigger');
  }


  // get channel data for current user
  private getChannelData(): Observable<AppChannelData> {
    return this.apiClient.get('/api/app/channels').pipe(
      map(o => o.getData<AppChannelData>())
    );
  }

  // get library data for current user
  private getLibraries(): Observable<AppLibrary[]> {
    return this.apiClient.get('/api/app/libraries').pipe(
      map(o => o.getData<AppLibrary[]>())
    );
  }

  setLibrary(id: Guid) {
    if (id) {
      this.libraryIdStream.next(id);
    }
  }
}

