import { Item } from '@aa/common';

// local
import { ITokenData } from './token-data.interface';

export class TagLabels {
  category: string = 'Housing Status';
  section: string = 'Section';
  info: string = 'Drilldown';
}

export class Library extends Item {

  name: string;

  description: string;

  tagLabels: TagLabels = new TagLabels();

  logoImage: string;

  serviceName: string;
}

export class AppLibrary extends Library implements ITokenData {

  token: string;
}

