import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@aa/app/material';

import { COMPONENTS } from './components';

import { DIRECTIVES } from './directives';

@NgModule({
  imports:      [ CommonModule, MaterialModule, FormsModule ],
  declarations: [ ...COMPONENTS, ...DIRECTIVES ],
  exports:      [
    CommonModule, MaterialModule,
    ...COMPONENTS,
    ...DIRECTIVES
  ]
})
export class UiModule {
}
