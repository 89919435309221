import { LibraryItem } from './library-item.model';

import { ITag, TagType } from '@aa/common';

import { ISortable } from './sortable.interface';

export class Tag extends LibraryItem implements ISortable, ITag {

  tagType: TagType;

  name: string;

  description?: string;

  order?: number;

  useCount?: number;

  groupId?: Guid;

  tagId?: Guid;

  /**
   * CSV list of search keywords
   */
  keywords?: string;

  constructor(id: string = null) {
    super();
    this.id = id;
  }
}
