import { Variable } from '../models';
import { AbstractFormatter } from './abstract_formatter.class';


export class VariableFormatter extends AbstractFormatter<Guid> {

  constructor(private variables: Variable[]) {
    super();

    if (!variables) {
      throw new Error('Invalid variable list passed to VariableFormatter');
    }
  }

  protected formatItemInternal(value: Guid): string {

    if (!value) {
      return '-';
    }

    const variable = this.variables.find(o => o.id === value);
    if (!variable) {
      return `Variable (${value})`;
    }

    return variable.key;
  }
}
