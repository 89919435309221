
/**
 * AdviceAid team plan types
 */
export enum TeamPlanType {
  StandardTrial = 0,
  PlusTrial     = 1,
  StandardPlan  = 10,
  PlusPlan      = 11
}

export const TeamPlanTypeCollection = [
  TeamPlanType.StandardTrial,
  TeamPlanType.PlusTrial,
  TeamPlanType.StandardPlan,
  TeamPlanType.PlusPlan,
];
