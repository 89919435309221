<aa-dialog (keyup.escape)="cancel()">

  <aa-dialog-title>Select Channel</aa-dialog-title>

  <aa-dialog-subtitle>{{prompt}}</aa-dialog-subtitle>

  <aa-dialog-content>
    <div class="channel-info-list">
      <div *ngFor="let channel of channels" class="channel-info-container" (click)="selectChannel(channel)" [matTooltip]="getTooltip(channel)">

        <div class="channel-info" [style.border-left-color]="channel | channelColor">
          <div class="channel-type">{{channel | channelType}}</div>
          <div class="channel-name">
            {{channel.name}}
          </div>
        </div>
      </div>
    </div>
  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button (click)="cancel()">
      Cancel
    </button>
  </aa-dialog-actions>

<aa-dialog>


