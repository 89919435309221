
import { Guid } from './guid.type';

export abstract class Item {

  id: Guid;
  ver?: number;

  constructor(source?: Item) {
    if (!source) {
      return;
    }

    this.id = source.id;
  }

  public static createIdMap<T extends {id: Guid}>(items: T[]): Map<Guid, T> {
    const result = new Map<Guid, T>();

    for (const item of items) {
      result.set(item.id, item);
    }

    return result;
  }

  public static resolveItems<T extends Item>(map: Map<Guid, T>, ids: Guid[]) {
    if (!ids) {
      return [];
    }

    const result = [];

    for (const id of ids) {
      const item = map.get(id);

      if (item) {
        result.push(item);
      }
    }

    return result;
  }
}
