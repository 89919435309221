import { Component, Input, OnInit } from '@angular/core';

import { UserNameService } from '../services';

@Component({
  selector: 'aa-date-stamp',
  template: `{{date | date}} <span *ngIf="userName"> by {{userName}}</span>`
})
export class DateStampComponent implements OnInit {
  @Input() date: Date = null;
  @Input() userId: Guid = null;

  private _userName: string = null;
  public get userName(): string {
    return this._userName;
  }

  constructor(private userNameService: UserNameService) {
  }

  ngOnInit() {
    if (!this.userId) {
      return;
    }

    this.userNameService.getUserName(this.userId)
      .subscribe(
        (name: string) => this._userName = name,
        err => {}
      );
  }
}
