import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

// core
import { AuthService, IUser } from '@aa/common';

// forms
import { AbstractForm } from '@aa/app/forms';

// local
import { UserApiService } from '../../services';
import { PasswordData } from '../../models';
import { PasswordDataSerializer } from '../../serializers';

@Component({
  selector: 'aa-change-password-dialog',
  templateUrl: './password_change.dialog.html',
})

export class PasswordChangeDialogComponent extends AbstractForm {

  public user: IUser;

  constructor(private userService: UserApiService,
              private dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
              auth: AuthService) {
    super(new PasswordDataSerializer().buildPasswordChangeForm());

    this.user = auth.user;
  }

  submit() {
    if (!this.checkForm()) {
      return;
    }

    const data = <PasswordData>this.form.value;
    this.userService.changePassword(this.user.id, data).then(
      res => { this.close(); this.endAsync(); },
      err => { this.setError(err); this.endAsync(); }
    );
  }

  cancel() {
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
