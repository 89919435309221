import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AuthService, Role } from '@aa/common';

@Injectable({providedIn: 'root'})
export class ComposeRedirectService implements CanActivate {

  constructor(private router: Router, private auth: AuthService) {
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // https://juristr.com/blog/2018/11/better-route-guard-redirects/
    return this.router.parseUrl(this.getUrl());
  }

  getUrl() {
    if (this.auth.hasRequiredRole(Role.Compose)) {
      return '/pages/compose';
    }

    return '/pages/dashboard';
  }
}
