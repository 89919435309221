import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

// common
import { AppConfig } from '@aa/common';

// local
import './about.component.css';
import { ChannelInfoDialogService } from '../channel-info/channel-info.service';


@Component({
  selector: 'aa-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutDialogComponent {

  public readonly version: string;

  constructor(appConfig: AppConfig,
              private dialogRef: MatDialogRef<AboutDialogComponent>,
              public readonly channelInfo: ChannelInfoDialogService) {
    this.version = 'v' + appConfig.version;
  }

  close() {
    this.dialogRef.close();
  }
}
