import { Component } from '@angular/core';

@Component({
  selector: 'aa-form-row',
  template: `<ng-content></ng-content>`,
  host: {
    'class': 'row'
  }
})
export class FormRowComponent {
}
