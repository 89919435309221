import { FormGroup, Validators } from '@angular/forms';

// common
import { IFactory, Serializer, TreeType } from '@aa/common';

// local
import { IFormSerializer, FormConfigBuilder } from '../classes';
import { Tree } from '../models';

const fb = new FormConfigBuilder<Tree>([
  { key: 'name', defaultValue: '', validators: [Validators.required, Validators.minLength(3)]  },
  { key: 'startText', validators: [Validators.required] },
  { key: 'contactText' },
  { key: 'disclaimer'},
  { key: 'default', defaultValue: false },
  { key: 'type', defaultValue: TreeType.Public }
]);

export class TreeSerializer extends Serializer<Tree> implements IFormSerializer<Tree> {

  public getType(): IFactory<Tree> {
    return Tree;
  }

  public getName(): string {
    return 'Tree';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
