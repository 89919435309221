// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { TeamPlanType } from '../models';
import { TeamPlanTypeFormatter } from '../classes';

/*
 * Formats teamPlanType
 * Usage:
 *   team.planType | teamPlanType
 */
@Pipe({name: 'teamPlanType'})
export class TeamPlanTypePipe implements PipeTransform {

  transform(planType: TeamPlanType): string {
    return TeamPlanTypeFormatter.formatName(planType);
  }
}
