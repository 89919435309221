import { NgModule, ModuleWithProviders, APP_INITIALIZER, APP_BOOTSTRAP_LISTENER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// idle
import { NgIdleModule, KeepaliveSvc } from '@ng-idle/core';

import { MaterialModule } from '@aa/app/material';

import { COMPONENTS } from './components';

import { environment } from '../../environments/environment';

// common
import {
  AdviceAidCommonModule,
  AuthService,
  AppStorageService,
  AnalyticsService,
  AppConfig
} from '@aa/common';


import {
  // misc service
  KeepaliveService,
  JwtInterceptorService,

  // startup services
  UpdateService,
  UserActivityService,
  NavigationService
} from './services';

import { GrooveWidgetService } from '@aa/app/shared';
import { ToastService } from '@aa/app/ui';

// list of services which must be instantiated after bootstrap
const STARTUP_SERVICES = [
  UpdateService,
  NavigationService,
  UserActivityService,
  GrooveWidgetService,
  AnalyticsService
];

// wire up app storage notifications
export function appStorageNotifications(appStorage: AppStorageService, toast: ToastService): Function {
  return () => {
    appStorage.notification$.subscribe((data) => {
      toast.notify(data, 10);
    });
  };
}

export function appInitializer(appConfig: AppConfig): Function {
  return () => appConfig.init('app', environment);
}

// initialize the auth service
export function authBootstrap(auth: AuthService): Function {
  return () => auth.init();
}

// null bootstrap function which is used force DI to generate singleton services
// we don't need to use the args
export function serviceBootstrap(): Function {
  return () => null;
}

@NgModule({
  imports:      [
    CommonModule, RouterModule, HttpClientModule, MaterialModule, AdviceAidCommonModule,
    NgIdleModule],
  declarations: [ ...COMPONENTS ]
})
export class CoreModule {

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: KeepaliveSvc, useExisting: KeepaliveService },
        { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AppConfig], multi: true },

        { provide: APP_BOOTSTRAP_LISTENER, useFactory: appStorageNotifications, deps: [ AppStorageService, ToastService ], multi: true },
        { provide: APP_BOOTSTRAP_LISTENER, useFactory: authBootstrap, deps: [AuthService], multi: true },
        { provide: APP_BOOTSTRAP_LISTENER, useFactory: serviceBootstrap, deps: STARTUP_SERVICES, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
      ]
    };
  }
}
