export interface ExtendedKeyboardEvent extends KeyboardEvent {
  returnValue: boolean; // IE returnValue
}

type IHotkeyCallback = (event: KeyboardEvent) => ExtendedKeyboardEvent|boolean;

export class Hotkey {
  public readonly combo: string;

  /**
   * Creates a new Hotkey for EventManager binding
   *
   * @param {string}   combo       Key binding e.g. control+shift+z or control.shift.z
   * @param {Function} callback    method to call when key is pressed
   */
  constructor(combo: string,
              public readonly callback: IHotkeyCallback) {

    this.combo = combo.replace(/\+/g, '.');
  }
}
