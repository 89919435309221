import { TreeStatus, ISelfServContactInfo } from '@aa/common';
import { LibraryItem } from './library-item.model';
import { HeaderAlignment } from './header-alignment.enum';

export class SelfServConfig extends LibraryItem implements ISelfServContactInfo {

  teamId: Guid;

  domain: string;

  externalDomain?: string;

  status: TreeStatus;

  logoImage?: string;

  treeId?: Guid;

  teamName?: string;

  fullCouncilName?: string;

  disclaimer?: string;
  contactText?: string;
  startText?: string;

  // link back to council website
  homeLink?: string;

  // link back to council housing page
  housingLink?: string;

  // feedback
  feedbackLink?: string;

  // privacy
  privacyLink?: string;

  // contact
  contactLink?: string;

  // Google Analytics Key
  gaKey?: string;

  // Hotjar Site Id
  hotjarId?: string;

  // Contact
  selfReferralUrl?: string;
  telephone?: string;
  email?: string;
  information?: string;
  emergencyContact?: string;
  translationUrl?: string;
  address?: string;

  // Custom css
  headerAlignment?: HeaderAlignment;
  customCss?: string;

  // Accessiblity
  accessibleLinkText?: boolean;
}
