import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

export class PaginatedDataSet<T> {

  private readonly _pagedDataSource = new BehaviorSubject<T[]>([]);
  public readonly pagedData$ = this._pagedDataSource.pipe(debounceTime(5));

  public readonly updated$ = new EventEmitter();

  private _pagedData: T[] = [];
  private _data: T[] = [];
  private _pageSize: number = 20;
  private _pageIndex: number = 0;

  constructor(public readonly id: string = null) {

  }

  get pageSize(): number {
    return this._pageSize;
  }

  get pageIndex(): number {
    return this._pageIndex;
  }

  set pageIndex(value: number) {
    this._pageIndex = value;
    this.rebuildPagedResults();
  }

  get length(): number {
    return this._data.length;
  }

  get pagedData(): T[] {
    return this._pagedData;
  }

  private clear() {
    this._pageSize = this._pageIndex = 0;
    this._data = this._pagedData = [];
    this.notifyChange();
  }

  private notifyChange() {
    this._pagedDataSource.next(this._pagedData);
  }

  increasePageSize(increment: number) {
    this.setPage(this._pageIndex, this._pageSize + increment);
  }

  setPage(index: number, size: number) {
    this._pageIndex = index;
    this._pageSize = size;
    this.rebuildPagedResults();
  }

  setData(data: T[]) {
    this._data = data;
    this.updated$.emit();
    this.rebuildPagedResults();
  }

  getData(): T[] {
    return this._data;
  }

  rebuildPagedResults() {

    const length = this._data.length;

    // ensure pageIndex is valid
    const maxPageIndex = Math.floor(length / this._pageSize);
    if (this._pageIndex > maxPageIndex) {
      this._pageIndex = maxPageIndex;
    }

    // Grab the page's slice of the filtered sorted data.
    const maxStartIndex = this._pageSize;

    const start = this._pageSize * this._pageIndex;
    const end = Math.min(start + this._pageSize, this._data.length);

    this._pagedData = this._data.slice(start, end);

    this.notifyChange();
  }
}
