// angular
import { Injectable, Injector } from '@angular/core';
// rxjs
import { map, tap } from 'rxjs/operators';
// core
import { Role } from '@aa/common';
// local
import { ISaveArgs, ISnippetLocalisation, ISnippetMessage, LocalisationMode, Snippet } from '../models';
import { SnippetSerializer } from '../serializers';
import { AbstractChannelItemApiService } from './abstract-channel-item-data.service';
import { AbstractApiService } from './abstract-api.service';

export enum SnippetUseType {
  General = 0,
  Copy = 1
}

@Injectable({providedIn: 'root'})
export class SnippetApiService extends AbstractChannelItemApiService<Snippet> {

  constructor (injector: Injector) {
    super(injector, 'snippets', new SnippetSerializer());

    const p = this.permissions;

    p.create.push(Role.Advisor);
    p.edit.push(Role.SiteAdmin);
    p.delete.push(Role.SiteAdmin);
  }

  getMessage(snippet: Snippet): ISnippetMessage {
    return this.generateMessage(snippet);
  }

  private generateMessage(item: Snippet): ISnippetMessage {

    const millisecondsPerDay = 86400000;

    const now = new Date().getTime();

    // check creation time
    const timeSinceCreation = now - new Date(item.created).getTime();
    if (timeSinceCreation < millisecondsPerDay) {
      return { text: 'New', tooltip: 'This snippet is new' };
    }

    // check last edited
    const timeSinceLastEdited = now - new Date(item.lastEdited).getTime();

    if (timeSinceLastEdited < millisecondsPerDay) {
      return { text: 'Updated', tooltip: 'This snippet has been recently updated' };
    }

    if (item.localisationMode === LocalisationMode.Replace) {
      return { text: 'Replaced', tooltip: 'The snippet text has been replaced with custom local content' };
    }

    return null;
  }

  public getType() {
    return Snippet;
  }

  /**
   * Track snippet use
   * @param {Guid} snippetId
   * @param {SnippetUseType} type Optional type param e.g. Copy
   * @returns {Promise<boolean>}
   */
  public trackSnippetUse(snippetId: Guid, type: SnippetUseType = SnippetUseType.General): Promise<boolean> {

    if (!snippetId) {
      console.warn('trackSnippetUse : invalid id');
      return;
    }

    const url = this.buildItemUrl(snippetId) + '/track?type=' + type;

    this.apiClient.post(url)
      .pipe(map(() => true))
      .toPromise();
  }

  public saveSnippetLocalisation(item: ISnippetLocalisation, args: ISaveArgs) {
    if (!this.appMeta) {
      throw Error('App meta is not defined');
    }

    const channel = this.appMeta.getChannelOrThrow(item.channelId);

    const requestArgs = {
      body: item,
      headers: AbstractApiService.getTokenHeaders(channel)
    };

    const url = this.buildCollectionUrl() + '/localisation';

    return this.apiClient.post(url, requestArgs).pipe(
      tap(updatedItem => {
        this.invalidateChannel(item.channelId);
        this.toast.info('Success', 'Local content updated');
      })
    );
  }
}



