/**
 * Created by Tom Makin on 23/10/2016.
 */

export enum Role {
  None       = 0,
  Advisor    = 1,
  TeamAuthor = 2,

  TeamAdmin  = 11,
  Publisher  = 12,

  // app feature roles

  Tester     = 21,
  FreeTrial  = 22,
  Compose  = 23,
  Workspace  = 24,

  DemoTeam   = 90,
  SiteAdmin  = 91,
  Master     = 99
}
