import { EventEmitter } from '@angular/core';

export interface ITextEditorSettings {
  debounce?: number;
  showToolbar?: boolean;
  createSnippetEnabled?: boolean;
  splitParaEnabled?: boolean;
  toolbarContainer?: HTMLElement;
  tablesEnabled?: boolean;
}

export class TextEditorSettings implements ITextEditorSettings {
  public readonly debounce: number = 300;
  public readonly showToolbar: boolean = true;
  public readonly tablesEnabled: boolean = true;

  public readonly splitParaEnabled: boolean = false;
  public readonly createSnippetEnabled: boolean = false;
  public readonly toolbarContainer: HTMLElement = null;

  constructor(source?: ITextEditorSettings) {
    if (source) {
      Object.assign(this, source);
    }
  }
}

export abstract class TextEditor {

  public initialized: boolean = false;

  public readonly onSelect = new EventEmitter<string>();
  public readonly onChange = new EventEmitter<TextEditor>();
  public readonly onInit  = new EventEmitter<TextEditor>();
  public readonly onCreateSnippet = new EventEmitter<string>();
  public readonly onPaste = new EventEmitter<string>();
  public readonly onInsertParagraph = new EventEmitter<string>();

  private debounceTimeout: any = null;

  protected constructor(public settings: TextEditorSettings) {
  }

  public destroy(): void {
    this.onSelect.complete();
    this.onChange.complete();
    this.onInit.complete();
    this.onCreateSnippet.complete();
    this.onPaste.complete();
    this.onInsertParagraph.complete();
  }

  protected notifyInit() {
    this.onInit.emit(this);
  }

  public notifySelect() {
    this.onSelect.emit(this.getSelection());
  }

  protected notifyPaste(data: string) {
    this.onPaste.emit(data);
  }


  protected notifyChange() {
    if (!this.settings.debounce) {
      this.notifyChangeDebounced();
      return;
    }

    // Debounce update
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    this.debounceTimeout = setTimeout(() => {
      this.notifyChangeDebounced();
      this.debounceTimeout = null;
    }, this.settings.debounce);
  }

  /**
   * Value update process
   */
  private notifyChangeDebounced() {
    // this.zone.run(() => {
      this.onChange.emit(this);
    // });
  }

  protected insertParagraph(data: string) {
    this.onInsertParagraph.emit(data);
  }

  protected createSnippet() {
    const data = this.getSelection();

    this.onCreateSnippet.emit(data);
  }

  public abstract init(el: HTMLElement);

  /**
   * wrap selected text with the supplied html element
   * Not currently used as replace selection is preferred
   */
  public abstract wrapSelection(html: string): boolean;

  /**
   * Replace selected text with given html
   * @param html
   */
  public abstract replaceSelection(html: string): boolean;

  public abstract unwrap(selector: string): boolean;

  public abstract getSelection(): string;

  public abstract getData(): string;

  public abstract setData(data: string): void;

}
