import { AbstractFormatter } from './abstract_formatter.class';
import { ColorContrast } from './color-contrast.class';

export class ColorFormatter extends AbstractFormatter<string> {

  constructor() {
    super(true /*isHtml */);
  }

  public static formatColorIcon(value?: string, title: string = null, contrastLimit: number = 0): string {
    const info = ColorContrast.getContrastInfo(value, contrastLimit);

    if (title) {
      title = `${title} : ${info.message}`;
    } else {
      title = info.message;
    }

    if (!value) {
      value = 'white';
    }

    const cls = info.warning ? 'warning' : '';

    return `<span class="color-icon ${cls}" style="background-color:${value}" title="${title}"></span>`;
  }

  protected formatItemInternal(value: string): string {
    return ColorFormatter.formatColorIcon(value);
  }
}

