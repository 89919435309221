// common
import { AppStorageService, AuthService, IFactory, Serializer } from '@aa/common';

// local
import { ITableColumn } from '../../models';
import { AbstractSettingsService } from '../../classes';

class TableSettings {
  filter: string;
  columns: any;
}

export class TableSettingsSerializer extends Serializer<TableSettings> {

  public getType(): IFactory<TableSettings> {
    return TableSettings;
  }

  public getName() {
    return 'TableSettings';
  }

  public fixUp(data: TableSettings): TableSettings {
     if (!data.columns) {
       data.columns = {};
      }

     return data;
  }
}

export class TableSettingsService extends AbstractSettingsService<TableSettings> {

  private readonly initialState = {};

  constructor(auth: AuthService, storage: AppStorageService, name: string) {
    super(auth, storage, new TableSettingsSerializer(), 'TABLE_SETTINGS_' + name);

  }


  public resetColumns(columns: ITableColumn[]) {
    this._data.columns = {};

    // restore initial state
    for (const col of columns) {
      col.hidden = this.initialState[col.key];
    }

    this.notifyUpdate('columns');
  }

  public saveColumnState(col: ITableColumn) {
    this._data.columns[col.key] = col.hidden;
    this.notifyUpdate('columns');
  }

  public initColumnState(columns: ITableColumn[]) {

    const settings = this._data.columns;

    for (const col of columns) {
      this.initialState[col.key] = col.hidden;

      if (!settings.hasOwnProperty(col.key)) {
        continue;
      }

      col.hidden = !!settings[col.key];
    }
  }

  get filter() {
    return this._data.filter;
  }

  set filter(value: string) {
    this._data.filter = value;
    this.notifyUpdate('filter');
  }
}
