// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { Channel } from '../models';
import { ChannelTypeFormatter } from '../classes';

/*
 * Formats channelType for an IChannelItem
 * Usage:
 *   item | channelType
 */
@Pipe({name: 'channelType'})
export class ChannelTypePipe implements PipeTransform {

  transform(channel: Channel): string {
    if (!channel) {
      return '';
    }

    return ChannelTypeFormatter.formatName(channel.channelType);
  }
}
