import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { KeepaliveSvc } from '@ng-idle/core';

/**
 * Cut down version of @ng-idle keep alive service without http dependency
 */
@Injectable({providedIn: 'root'})
export class KeepaliveService extends KeepaliveSvc implements OnDestroy {

  private pingInterval: number = 10 * 60;
  private pingHandle: any;

  /*
   * An event emitted when the service is pinging.
   */
  public onPing: EventEmitter<any> = new EventEmitter;

  /*
   * Sets the interval (in seconds) at which the ping operation will occur when start() is called.
   * @param seconds - The ping interval in seconds.
   * @return The current interval value.
   */
  interval(seconds?: number): number {
    if (!isNaN(seconds) && seconds > 0) {
      this.pingInterval = seconds;
    } else if (!isNaN(seconds) && seconds <= 0) {
      throw new Error('Interval value must be greater than zero.');
    }

    return this.pingInterval;
  }

  /*
   * Immediately performs the ping operation. If a request has been set, an HTTP
   * request will be made and the response will be emitted via the
   * onPingResponse event.
   */
  ping(): void {
    this.onPing.emit(null);
  }

  /*
   * Starts pinging on an interval.
   */
  start(): void {
    this.stop();

    this.pingHandle = setInterval(() => {
      this.ping();
    }, this.pingInterval * 1000);
  }

  /*
   * Stops pinging on an interval.
   */
  stop(): void {
    if (this.pingHandle) {
      clearInterval(this.pingHandle);
      this.pingHandle = null;
    }
  }

  /*
   * Performs any cleanup tasks when Angular destroys the instance.
   */
  ngOnDestroy(): void {
    this.stop();
  }

  /*
   * Returns whether or not the service will ping automatically at the specified interval.
   * @return True if the service will ping at the specified interval; otherwise, false.
   */
  isRunning(): boolean {
    return !!this.pingHandle;
  }
}
