// external
import { Injector, Pipe, PipeTransform } from '@angular/core';

import { AppMetaDataService } from '../services';
import { first } from 'rxjs/operators';
import { AppMetaData } from '../classes/app-meta-data.class';
import { LibraryResolverService } from '@aa/app/shared/services/library-resolver.service';

/*
 * Formats a libraryId using the current app meta data
 * Usage:
 *   item.libraryId | libraryId
 */
@Pipe({name: 'libraryId'})
export class LibraryIdPipe implements PipeTransform {

  private appMeta: AppMetaData = null;

  constructor(private readonly libraryResolver: LibraryResolverService) {
  }

  transform(libraryId: Guid): string {
    if (!libraryId) {
      return 'None';
    }

    const library = this.libraryResolver.getLibrary(libraryId);

    if (!library) {
      return libraryId;
    }

    return library.name;
  }
}
