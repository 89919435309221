// external
import { Injector, OnInit, Directive } from '@angular/core';
import { of } from 'rxjs';

// common
import { Role, AuthService, EnumHelper} from '@aa/common';

// core
import { ToastService, Command, Icon } from '@aa/app/ui';

// local
import { ChannelItem, AppChannel, ChannelAccess, AppLibrary } from '../models';
import { AppMetaData } from '../classes';
import { AbstractChannelItemApiService, AppMetaDataService, LibraryResolverService } from '../services';

import { ItemEditorComponent } from './item-editor.component';
import { ChannelSelectService } from './channel-select';
import { ChannelShareService } from './channel-share/';
import { DetailsDialogService } from './details-dialog';

@Directive()
export abstract class ChannelItemEditorComponent<TItem extends ChannelItem> extends ItemEditorComponent<TItem> implements OnInit  {

  public channel: AppChannel;
  public parentChannel: AppChannel;

  protected libraryId: Guid;

  private _canShare = false;
  private meta: AppMetaData;

  // menu action
  readonly menuActions: Command[] = [];
  readonly shareCmd: Command;
  readonly deleteCmd: Command;
  readonly saveAsCmd: Command;
  readonly refreshCmd: Command;
  readonly detailsCmd: Command;

  // services
  protected readonly channelSelectService: ChannelSelectService;
  protected readonly channelShareService: ChannelShareService;
  protected readonly auth: AuthService;
  protected readonly toast: ToastService;
  protected readonly detailsDialogService: DetailsDialogService;

  protected readonly name: string;
  protected readonly nameLower: string;

  constructor(private channelDataService: AbstractChannelItemApiService<TItem>,
              injector: Injector) {
    super(channelDataService, injector);

    this.channelShareService = injector.get(ChannelShareService);
    this.channelSelectService = injector.get(ChannelSelectService);
    this.auth = injector.get(AuthService);
    this.toast = injector.get(ToastService);
    this.detailsDialogService = injector.get(DetailsDialogService);

    const metaData: AppMetaDataService = injector.get(AppMetaDataService);

    this.subscribe(metaData.metaData$, (data: AppMetaData) => {
      this.meta = data;
      this.resolveChannels();
    });

    this.subscribe(metaData.currentLibrary$, (library: AppLibrary) => {
      this.libraryId = library ? library.id : null;
    });

    this.subscribe(channelDataService.metaDataUpdated$, data => {
      this.syncPermissions();
    });

    this.name = channelDataService.serializer.getName();
    this.nameLower = this.name.toLowerCase();

    this.shareCmd = new Command({
        name: 'Share',
        description: `Publish this ${this.nameLower} to a shared channel`,
        icon: Icon.Share
      });

    this.deleteCmd = new Command({
        name: 'Delete',
        description: `Delete this ${this.nameLower}`,
        icon: Icon.Delete
      });


    this.saveAsCmd = new Command({
      name: 'Save as',
      description: `Save a copy of this ${this.nameLower}`,
      icon: Icon.Copy
    });

    this.refreshCmd = new Command({
      name: 'Refresh',
      description: `Reload ${this.nameLower} data from server`,
      icon: Icon.Refresh
    });

    this.detailsCmd = new Command({
      name: 'Details',
      description: `Show additional ${this.nameLower} details`,
      icon: Icon.List
    });
  }

  public ngOnInit() {
    super.ngOnInit();

    this.addMenuItem(this.shareCmd, () => this.share());
    this.addMenuItem(this.saveAsCmd, () => this.saveAs());

    this.addMenuItem(this.deleteCmd, () => this.deleteItem());
    this.addMenuItem(this.refreshCmd, () => this.refreshItem());

    // details command only visible to site admin
    if (this.detailsDialogService.canViewItemDetails) {
      this.addMenuItem(this.detailsCmd, () => this.showDetailsDialog());
    }
  }

  protected addMenuItem(cmd: Command, callback: () => void) {
    this.menuActions.push(cmd);
    this.subscriptions.push(cmd.subscribe(callback));
  }

  protected onSave(updatedSnippet: TItem) {

    super.onSave(updatedSnippet);

    this.syncMenu();
  }

  private syncMenu() {

    // publish
    this.shareCmd.enabled = this.canShare;

    // save as
    this.saveAsCmd.enabled =  this.auth.hasRequiredRole(Role.Publisher) && this.channelSelectService.writableChannelCount > 0;

    // delete
    this.deleteCmd.enabled = this.canDelete;

    // refresh
    this.refreshCmd.enabled = this.item.id != null;
  }

  private rebuildSharedChannels() {
    if (!this.item || !this.meta) {
      this._canShare = false;
    }

    this._canShare = this.meta.hasSharedChannels(this.item.channelType);
  }

  public get canShare(): boolean {
    return this._canShare;
  }

  private resolveChannels() {
    if (!this.item || !this.meta) {
      this.channel = this.parentChannel = null;
      return;
    }

    // resolve channel
    this.channel = this.meta.getChannel(this.item.channelId);
    this.parentChannel = this.meta.getChannel(this.item.parentChannelId);
  }

  beforeShow(): boolean {

    if (!this.meta) {
      return false;
    }

    // resolve channels
    this.resolveChannels();

    // rebuild shared channels
    this.rebuildSharedChannels();

    this.syncMenu();

    return true;
  }

  public showDetailsDialog() {
    this.detailsDialogService.openChannelItem(this.name, this.item);
  }

  private saveAs() {

    this.channelSelectService
      .selectWritableChannel(`Please select a target channel to save copy of this ${this.nameLower}`)
      .subscribe(
        channel => {
          this.copyToChannel(channel);
        },
        err => {
          this.toast.alert('Cancelled', err);
        });
  }

  private copyToChannel(channel: AppChannel) {

    const clone = super.getSerializer().clone(this.item);

    clone.id = null;
    clone.channelId = channel.id;
    clone.channelType = channel.channelType;
    clone.libraryId = this.libraryId;

    // delete all ref info
    clone.refs = null;
    clone.parentChannelId = null;
    clone.parentId = null;

    this.init(clone);
    this.setDirtyFlag();
  }


  public saveWithShareConfirmation(close: boolean = false) {

    const channelUpdateList = this.getSharedChannelUpdateList();

    const updateSharedConfirmation$ = channelUpdateList.length > 0 ?
      this.confirmService.show({
        title: 'Shared Versions',
        content: `Do you want to update the shared version of this ${this.nameLower} as well?`
      }) : of(false);

    updateSharedConfirmation$.subscribe(updateSharedConfirmation => {

      // disable invalidate if share update was requested
      const invalidate = !updateSharedConfirmation;

      this.saveItem({close, invalidate}).then(updatedItem => {

        if (!updateSharedConfirmation) {
          return;
        }

        this.updateSharedVersions(channelUpdateList)
          .catch(err => [])
          .then(shareRefs => {
            this.channelDataService.invalidateChannel(updatedItem.channelId);
            this.refreshItem();
          });
      });
    });
  }

  /**
   * get list of channels with existign refs that user is allowed to update
   * @returns {AppChannel[]}
   */
  private getSharedChannelUpdateList(): AppChannel[] {
    if (!this.meta || !this.item) {
      return [];
    }

    const refs = this.item.refs;
    if (!refs || !refs.length) {
      return [];
    }

    const result: AppChannel[] = [];

    for (const ref of refs) {
      const channel = this.meta.getChannel(ref.channelId);
      if (EnumHelper.hasFlag(channel.access, ChannelAccess.Share)) {
        result.push(channel);
      }
    }

    return result;
  }

  private updateSharedVersions(targetChannels: AppChannel[]): Promise<boolean> {

    console.log('updating shared versions', targetChannels);

    const promises = targetChannels.map(channel => {
      return this.channelDataService
        .createSharedItem(this.item, channel, { toast: true, invalidate: false })
        .then(shareRef => {
          // only update the shared channel NOT the parent
          this.channelDataService.invalidateChannel(shareRef.channelId);
        });
    });

    return Promise.all(promises)
      .then(() =>  true, err => false);
  }

  private share() {
    if (!this.item) {
      return;
    }

    const promise: Promise<TItem> = this.form.dirty ?
      super.saveItem({close: false, invalidate: true}) :
      Promise.resolve(this.item);

    promise.then(
      item => {
        this.channelShareService.share(item, this.channelDataService).subscribe(updatedItem => {

          if (updatedItem) {
            this.init(updatedItem);
          }
        });
      }, err => console.warn('save failed', err)
    );
  }
}
