// external
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, Route } from '@angular/router';

// common
import { Role, SubscriberComponent, AuthService } from '@aa/common';


// local
import { AboutDialogComponent } from '../about';
import { ProfileEditorDialogComponent } from '../profile';
import { SettingsDialogService } from '../settings-dialog';
import { FeedbackDialogService } from '../feedback-dialog';

import { IPageRouteData } from '../../models';
import { GrooveWidgetService } from '../../services';
import { Icon } from '@aa/app/ui';

interface IRouteLink {
  name: string;
  path: string;
  roles: Role[];
  alwaysVisible: boolean;
}

// find pages route config
const root = 'pages';

@Component({
  selector: 'aa-main-nav',
  styleUrls: ['./main_nav.component.scss'],
  templateUrl: './main_nav.component.html',
})
export class MainNavComponent extends SubscriberComponent {

  public readonly linkIcon: Icon = Icon.External;
  public readonly s21url: string = 'https://www.s21check.com';

  public isWorkspaceUser: boolean = false;
  public isFreeTrial: boolean = false;
  public trialExpiryMessage: string = null;
  public readonly name: string;

  private readonly links: IRouteLink[] = [];
  public visibleLinks: IRouteLink[] = [];

  // view container ref required for ng2-bootstrap
  constructor(public readonly auth: AuthService,
              public readonly feedbackDialog: FeedbackDialogService,
              public readonly grooveService: GrooveWidgetService,
              private readonly dialog: MatDialog,
              route: ActivatedRoute) {

    super();

    const snapshot: ActivatedRouteSnapshot = route.snapshot;

    if (snapshot.params['editProfile']) {
      this.showEditProfileDialog();
    }

    // set name if available
    if (snapshot.data) {
      this.name = snapshot.data.name;
    }

    // init route config
    const routeConfig = this.findRouteConfig(snapshot, root);

    if (routeConfig) {
      for (const child of routeConfig.children) {
        const data = <IPageRouteData>child.data;
        if (!data || data.hidden) {
          continue;
        }

        const linkData: IRouteLink = {
          path: '/' + root + '/' + child.path,
          name: data.name,
          roles: data.roles,
          alwaysVisible: data.alwaysVisible
        };

        this.links.push(linkData);
      }
    } else {
      console.warn('Failed to resolve route config for ' + this.name);
    }

    // listen for user updates
    super.subscribe(auth.user$, () => {
      this.resync();
    });
    this.resync(); // call resync at end after all other initialization
  }

  resync() {

    this.isWorkspaceUser = this.auth.hasRequiredRole(Role.Workspace);

    this.updateVisibleLinks();
    this.updateFreeTrialInfo();
  }

  updateVisibleLinks() {

    this.visibleLinks = [];

    // loop through route config and extract links
    for (const link of this.links) {

      // ignore routes for which user does not have access
      if (!link.alwaysVisible && link.roles && !this.auth.hasRequiredRoles(link.roles)) {
        continue;
      }

      this.visibleLinks.push(link);
    }
  }

  updateFreeTrialInfo() {
    this.isFreeTrial = this.auth.hasRequiredRole(Role.FreeTrial);

    if (!this.isFreeTrial) {
      this.trialExpiryMessage = null;
      return;
    }

    this.trialExpiryMessage = this.formatExpiryMessage(this.auth.user.daysRemaining);
  }

  formatExpiryMessage(daysRemaining: number): string {
    if (!daysRemaining || daysRemaining < 0) {
      return 'Expired';
    }

    if (daysRemaining === 1) {
      return '1 day remaining';
    }

    return `${daysRemaining} days remaining`;
  }

  findRouteConfig(route: ActivatedRouteSnapshot, path: string): Route {
    while (route) {
      const config = route.routeConfig;
      if (!config) {
        break;
      }

      if (config.path === path) {
        return config;
      }

      route = route.parent;
    }

    return null;
  }

  logout() {
    this.auth.logout();
  }

  showAboutDialog() {
    this.dialog.open(AboutDialogComponent);
  }

  showEditProfileDialog() {
    this.dialog.open(ProfileEditorDialogComponent);
  }
}
