// external
import {  Component, Input, OnInit, HostListener  } from '@angular/core';

// core
import { Command } from '@aa/app/ui';

@Component({
  template: `
    <div [ngClass]="{'disabled': !cmd.enabled}"
        [matTooltip]="cmd.tooltip"
         matTooltipPosition="right">
      <i [aaIcon]="cmd.icon" aria-hidden="true"></i>
      <span class="name">{{cmd.name}}</span>
    </div>
  `,
  selector: 'aa-command',
  styleUrls: ['./command.component.css']
})
export class CommandComponent implements OnInit {
  @Input() cmd: Command;
  @Input() args: any = null;

  @HostListener('click') onClick() {
    this.cmd.execute(this.args);
  }

  ngOnInit() {
    if (this.cmd == null) {
      throw new Error('invalid [cmd] attribute: value is null');
    }

    if (!this.cmd.name) {
      throw new Error('invalid [cmd] attribute: no name defined');
    }
  }
}
