// external
import { Injector, OnInit, Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpHeaders } from '@angular/common/http';
import { FormControl } from '@angular/forms';

import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

// common
import { Item, StringKey } from '@aa/common';

// forms
import { AbstractForm, IFormDialogContent, IFormSubmitOptions } from '@aa/app/forms';

// core
import { ConfirmService, DialogEvent } from '@aa/app/ui';

// local
import { IDataService } from '../models';
import { IFormSerializer } from '@aa/app/shared';

@Directive()
export abstract class ItemEditorComponent<T extends Item> extends AbstractForm implements IFormDialogContent, OnInit {
  public item: T = null;
  public isNew: boolean = false;

  // injected services
  protected readonly confirmService: ConfirmService;
  private readonly dialogRef: MatDialogRef<any> = null;

  private readonly _canCreate: boolean = false;
  private _canEdit: boolean = false;
  private _canDelete: boolean = false;

  private readonly itemSource = new Subject<T>();
  public item$ = this.itemSource.asObservable();

  constructor(private readonly dataService: IDataService<T>,
              injector: Injector) {
    super(dataService.getSerializer().buildForm());

    this.confirmService = injector.get(ConfirmService);

    // dialog ref is optional
    this.dialogRef = injector.get(MatDialogRef, null);

    this._canCreate = dataService.canCreate();

    // init form
    this.subscribe(this.form.valueChanges, data => {
      if (this.item) {
        const oldItem = this.getSerializer().clone(this.item);
        this.setItem(data);
        this.onFormUpdated(oldItem, this.item);
      }
    });
  }

  protected getSerializer(): IFormSerializer<T> {
    return this.dataService.getSerializer();
  }

  ngOnInit() {
  }

  private setItem(data: T) {
    if (this.item) {
      Object.assign(this.item, data);
    } else {
      this.item = data;
    }

    this.itemSource.next(this.item);
  }

  public setControlEnabled(key: StringKey<T>, value: boolean) {
    const c = this.control(key);

    if (c.enabled === value) {
      return;
    }

    const opts = {onlySelf: true};
    if (value) {
      c.enable(opts);
    } else {
      c.disable(opts);
    }
  }

  public control(key: StringKey<T>): FormControl {
    return this.customControl(key);
  }

  public customControl(key: string): FormControl {
    if (!this.form) {
      throw new Error(`Failed to resolve control: ${key}. Form is not defined`);
    }

    const control = <FormControl>this.form.get(key);

    if (!control) {
      throw new Error(`Key not found in form definition: "${key}" (check the serializer)`);
    }

    return control;
  }

  public get canCreate() {
    return this._canCreate;
  }

  public get canEdit() {
    return this._canEdit;
  }

  public get canDelete() {
    return this._canDelete;
  }

  /**
   * Hook for derived classes to do stuff on save
   */
  protected onSave(updatedItem: T) {
    this.clearDirtyFlag();
    if (updatedItem) {
      this.item.id = updatedItem.id;
    }
  }

  /**
   * Hook for derived classes to do stuff on delete
   */
  protected onDelete() {
    this.item.id = null;
    this.setDirtyFlag();
  }

  protected beforeSave(item: T): boolean {
    return true;
  }

  /**
   * Hook for derived classes to do extra init before the dialog is shown
   */
  protected beforeShow(): boolean {
    return true;
  }

  public factory(): T {
    return this.getSerializer().create();
  }

  public initNew(): boolean {
    if (!this.canCreate) {
      const name = this.getSerializer().getName();
      console.error(`Current user not permitted to create ${name} items`);
      return;
    }

    const item = this.factory();
    return this.init(item);
  }

  public refreshItem() {
    if (!this.item.id) {
      return;
    }

    this.startAsync();
    this.dataService
      .refreshItem(this.item)
      .subscribe(updatedItem => {
          this.init(updatedItem);
        },
        err => {
          console.log('Failed to refresh item: ', err);
          this.close();
        },
        () => this.endAsync()
      );
  }

  public initAsync(id: Guid, headers?: HttpHeaders) {
    this.startAsync();
    this.dataService
      .getItem(id, {headers})
      .subscribe(item => {
          this.init(item);
        },
        err => {
          console.log('Failed to get item: ', err);
          this.close();
        },
        () => this.endAsync()
      );
  }

  protected syncPermissions() {

    const item = this.item;

    if (!item) {
      this._canEdit = this._canDelete = false;
      return;
    }

    this._canEdit = this.dataService.canEdit(item);
    let prefix = '';

    if (this.isNew) {
      this._canDelete = false;
      prefix = 'Create';

      // TODO: should we return here if user cannot edit?
    } else {
      this._canDelete = this.dataService.canDelete(item);
      prefix = 'Update';
    }

    if (!this._canEdit) {
      prefix = 'View';
    }

    const name = this.getSerializer().getName();
    super.setTitle(`${prefix} ${name}`);
  }

  public initClone(item: T): boolean {
    const clone = this.getSerializer().clone(item);
    return this.init(clone);
  }

  public init(item: T): boolean {

    // check if id is defined
    super.clearError();
    super.endAsync();

    this.isNew = !item.id;

    // ItemEditorComponent.patchValue(this.form, item);
    this.item = null; // clear old item before reset to avoid side effects
    this.form.reset();
    super.patchValue(item);



    // this.form.patchValue(item);

    // set item after the form has been setup
    this.setItem(item);

    // sync permission flags
    this.syncPermissions();

    // check if derived class wants to veto the item
    return this.beforeShow();
  }

  protected onFormUpdated(oldItem: T, newItem: T) {
  }

  public cancel(event: DialogEvent = null) {
    if (!this.dirty) {
      this.close();
      return;
    }

    if (event) {
      event.preventDefault();
    }

    this.confirmService.show({
      title: 'Discard changes?',
      content: 'Are you sure you want to discard unsaved changes?'
    })
      .subscribe(result => {
        if (result) {
          this.close();
        }
      });
  }

  protected close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }


  public submit(options?: IFormSubmitOptions): void {
    this.saveItem(options)
      .catch(err => console.warn('save failed', err));
  }

  protected saveItem(options?: IFormSubmitOptions): Promise<T> {

    options = options || {close: true, invalidate: true};

    const item = this.item;

    if (!item || !super.checkForm()) {
      return Promise.reject('Form is invalid');
    }

    if (!this.beforeSave(item)) {
      return Promise.reject('Save blocked');
    }

    this.startAsync();

    return this.dataService.saveItem(item, options).pipe(
      tap(
        result => {

          if (options.close) {
            this.close();
          } else {
            this.init(result);
          }

          this.onSave(result);
        },
        err => {
          this.setError(err);
          this.endAsync();
        },
        () => {
          this.endAsync();
          console.log('Save operation complete');
        }
      )
    ).toPromise();
  }

  public refreshItemWithConfirm() {
    if (!this.item.id) {
      return;
    }

    const confirmStream = this.dirty ?
      this.confirmService.show({
        title: 'Refresh Data',
        content: 'Are you sure you want to reload from the server and discard unsaved changes?'
      }) :
      of(true);

    confirmStream.subscribe(result => {
      if (result) {
        this.refreshItem();
      }
    });
  }

  public deleteItem() {
    if (!this.item) {
      return;
    }

    this.dataService.deleteItem(this.item, {confirm: true, toast: true})
      .subscribe(
        result => {
          if (result) {
            this.onDelete();
            this.close();
          }
        },
        err => this.setError(err),
        () => this.endAsync()
      );
  }
}
