import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { LibraryTagData } from '../classes';
import { AppMetaDataService } from './app-meta-data.service';
import { TagApiService } from './tag-api.service';

/**
 * Stateful service for caching tags associated with current library
 */
@Injectable({providedIn: 'root'})
export class TagCacheService {

  private readonly dataSource = new BehaviorSubject<LibraryTagData>(null);
  public readonly data$: Observable<LibraryTagData> = this.dataSource.asObservable();

  constructor(metaService: AppMetaDataService, private tagService: TagApiService) {
    metaService.currentLibrary$.pipe(
      switchMap(library => {
        if (!library) {
          return of(null);
        }

        return this.tagService.getLibraryTags(library).pipe(
          map(tags => new LibraryTagData(library, tags))
        );
      }),
      // tap(o => console.debug('TagCacheService refresh', o)),
      catchError(err => {
        console.error('TagCacheService : Failed to get libraryTags', err);
        return of(null);
      })
    ).subscribe(this.dataSource);
  }
}
