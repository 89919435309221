import { Injectable, OnDestroy } from '@angular/core';
import { AbstractChannelItemCache } from './abstract-channel-cache';

import {
  ItemFilter
} from '../classes';

import {
  AppLibrary,
  Snippet
} from '../models';

import { AppMetaDataService } from './app-meta-data.service';
import { TagCacheService } from './tag-cache.service';
import { SnippetApiService } from './snippet-api.service';

@Injectable({providedIn: 'root'})
export class SnippetCacheService extends AbstractChannelItemCache<Snippet> implements OnDestroy {

  constructor(metaService: AppMetaDataService, tagCache: TagCacheService, snippetService: SnippetApiService) {
    super(metaService, tagCache, snippetService);
  }

  public ngOnDestroy(): void {
    super.destroy();
  }

  protected getItemFilter(library: AppLibrary): ItemFilter<Snippet> {

    /*
    if (mode.snippetTags && mode.snippetTags.length) {
      return new SnippetItemFilter(mode.snippetTags);
    }
    */

    return null;
  }
}
