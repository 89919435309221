import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TextFieldModule} from '@angular/cdk/text-field';

import { UiModule } from '@aa/app/ui';

import { COMPONENTS } from './components';


/**
 * Custom Forms Module
 * ------------------
 * Contains reuseable components for consistent form layout and formatting
 *
 * Depends on UiModule for the icon directive
 */
@NgModule({
  imports:      [ UiModule, FormsModule, ReactiveFormsModule, TextFieldModule],
  declarations: [ COMPONENTS ],
  exports:      [
    FormsModule,
    ReactiveFormsModule,
    COMPONENTS]
})
export class CustomFormsModule {}
