// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { Channel } from '../models';
import { ChannelResolverService } from '../services';

/*
 * Returns a css colour spec for a channel
 * Usage:
 *   [style.color]="{{item | channelColor}}
 */
@Pipe({name: 'channelColor'})
export class ChannelColorPipe implements PipeTransform {

  constructor(private channelResolver: ChannelResolverService) {

  }

  transform(channel: Channel): string {
    if (!channel) {
      return '#fff';
    }

    return this.channelResolver.getColor(channel.id);
  }
}
