import { Serializer } from '@aa/common';

import { UserActivity } from './user_activity.model';

export class UserActivitySerializer extends Serializer<UserActivity> {

  public getType() {
    return UserActivity;
  }

  public getName() {
    return 'UserActivity';
  }
}
