import { Tag } from '../models';
import { AbstractFormatter } from './abstract_formatter.class';


export class TagFormatter extends AbstractFormatter<Guid> {

  constructor(private tags: Tag[]) {
    super();

    if (!tags) {
      throw new Error('Invalid tag list passed to tag formatter');
    }
  }

  protected formatItemInternal(value: Guid): string {

    if (!value) {
      return '-';
    }

    const tag = this.tags.find(o => o.id === value);
    if (!tag) {
      return `Tag (${value})`;
    }

    return tag.name;
  }
}
