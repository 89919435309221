import { Injectable } from '@angular/core';
import {
  Router, ActivatedRouteSnapshot, CanActivate, CanActivateChild,
  RouterStateSnapshot, UrlTree
} from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {

    // not logged in
    if (!this.auth.loggedIn) {
      return this.router.createUrlTree(['/login'], { queryParams: {'redirectUrl': route.url.toString()}});
    }

    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

}
