import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AbstractInputComponent } from './abstract-input.component';

@Component({
  selector: 'aa-numeric-input',
  templateUrl: './numeric-input.component.html',
  host: {
    'class': 'form-control'
  }
})
export class NumericInputComponent extends AbstractInputComponent {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() control: AbstractControl;

  onChange(value: string) {
    let numericValue = parseInt(value, 10);

    if (isNaN(numericValue)) {
      numericValue = 0;
    }

    if (this.control.value === numericValue) {
      return;
    }

    this.control.setValue(numericValue, { emitEvent: false, emitViewToModelChange: false });
  }
}
