import { Component, Inject } from '@angular/core';

import { INotification, NotificationType } from '@aa/common';

import { Icon } from '../../models';

import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface IToastRef {
  dismiss();
}

@Component({
  selector: 'aa-toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  readonly icon: Icon = Icon.Success;

  /** The message to be shown in the snack bar. */
  readonly message: string;

  /** The title. */
  readonly title: string;

  public static getTypeIcon(type: NotificationType): Icon {
    switch (type) {
      case NotificationType.Alert:
        return Icon.Alert;

      case NotificationType.Error:
        return Icon.Error;

      case NotificationType.Info:
        return Icon.Info;

      case NotificationType.Success:
      default:
        return Icon.Success;
    }
  }

  public static getTypeClass(type: NotificationType): string {
    switch (type) {
      case NotificationType.Alert:
        return 'alert';

      case NotificationType.Error:
        return 'error';

      case NotificationType.Info:
        return 'info';

      case NotificationType.Success:
      default:
        return 'success';
    }
  }

  constructor(private snackBarRef: MatSnackBarRef<ToastComponent>,
              @Inject(MAT_SNACK_BAR_DATA) private data: INotification) {
    this.message = data.message;
    this.title = data.title;
    this.icon = ToastComponent.getTypeIcon(data.type);
  }

  /** Dismisses the snack bar. */
  public dismiss(): void {
    this.snackBarRef.dismiss();
  }

}
