import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';


import { IFactory, ISerializer } from '../models';

export abstract class Serializer<T> implements ISerializer<T> {
  abstract getName(): string;

  abstract getType(): IFactory<T>;

  public create(): T {
    const type = this.getType();
    return new type();
  }

  public serialize(data: T): string {
    return JSON.stringify(data);
  }

  public parse(data: string | T): T {

    if (typeof data === 'string') {
      data = <T>JSON.parse(<string>data);
    }

    return <T>data;
  }

  public fixUp(data: T): T {
    return data;
  }

  public clone(data: T, exclude: Array<keyof T> = null): T {

    if (exclude) {
      data = <T>omit(data as any, exclude as Array<any>);
    }
    return cloneDeep(data);
  }
}
