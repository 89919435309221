import { AbstractFormatter } from './abstract_formatter.class';

export class NamedItemFormatter<T extends {id: Guid, name: string}> extends AbstractFormatter<Guid> {

  constructor(public data: T[] = [], public readonly defaultValue = 'None') {
    super();
  }

  /**
   * Update the team list for given formatter
   * @param teams
   */
  public setData(data: T[]) {
    this.data = data;
  }

  protected formatItemInternal(value: Guid): string {

    if (!value) {
      return this.defaultValue;
    }

    const item = this.data.find(o => o.id === value);
    if (!item) {
      return value;
    }

    return item.name;
  }
}
