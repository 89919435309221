import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService, Role } from '@aa/common';

import {
  LogoutComponent,
  OAuthRedirectComponent,
  BootstrapErrorComponent,
  ComposeRedirectService,
  EmptyComponent, PageGuardService
} from '@aa/app/core';

import { IPageRoute } from '@aa/app/shared';

// these roles must be re-exported to workaround enum bug
// https://github.com/angular/angular/issues/18170#issuecomment-323701683

/*
the order of these routes defines the order
they appear in top left nav
 */
const pageRoutes: IPageRoute[] = [
  {
    path: 'error',
    loadChildren: () => import('./pages/+error/error_page.module').then(m => m.ErrorPageModule),
    data: {name: 'Error', hidden: true }
  },
  /*
  {
    path: 'search',
    loadChildren: './pages/+search/search_page.module#SearchPageModule',
    data: {name: 'Search', roles: [Role.Advisor] }
  },
  {
    path: 'workspace',
    loadChildren: './pages/+workspace/workspace_page.module#WorkspacePageModule',
    data: {name: 'Workspace', roles: [Role.Advisor, Role.Workspace] }
  },
  */
  {
    path: 'compose',
    loadChildren: () => import('./pages/+compose/compose-page.module').then(m => m.ComposePageModule),
    data: {name: 'Compose' }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/+dashboard/dashboard_page.module').then(m => m.DashboardPageModule),
    data: {name: 'Dashboard'}
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/+admin/admin_page.module').then(m => m.AdminPageModule),
    data: {name: 'Admin', roles: [Role.SiteAdmin] }
  },
  { path: '', pathMatch: 'full', component: EmptyComponent, canActivate: [ComposeRedirectService]}
];

const routes: Routes = [
  // login
  {
    path: 'login',
    loadChildren: () => import('./pages/+login/login-page.module').then(m => m.LoginPageModule)
  },

  // logout
  {
    path: 'logout',
    component: LogoutComponent
  },

  // oauth redirect
  {
    path: 'oauth',
    component: OAuthRedirectComponent
  },

  // bootstrap error (note skipLocationChange:true should be used when invoking this route)
  {
    path: 'error',
    component: BootstrapErrorComponent
  },

  // pages
  {
    path: 'pages',
    canActivate: [AuthGuardService],
    canActivateChild: [PageGuardService],
    children: pageRoutes,
  },
  // default
  {
    path: '**',
    redirectTo: 'pages'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
