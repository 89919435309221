// external
import { EventEmitter, Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

// rxjs
import { EMPTY as empty, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

// clipboard
import * as clipboard from 'clipboard-polyfill';

// common
import { ISerializer} from '@aa/common';

// core
import { ToastService } from '@aa/app/ui';

// local
import { Snippet, IClipboardData } from '../models';
import { DocExportSerializer, SnippetSerializer } from '../serializers';
import { ApiClient, IApiRequestArgs } from './api-client.service';
import { DocExportRequest } from '@aa/app/shared/models/doc-export-request.model';


export class AsyncClipboardHelper<T> {

  public readonly updated = new EventEmitter();
  public data: IClipboardData = null;

  private renderSubscription: Subscription;

  constructor(private readonly clipboardService: ClipboardService,
              private readonly url: string,
              private readonly serializer: ISerializer<T>) {
  }

  public get hasData(): boolean {
    return !!this.data;
  }

  public destroy() {
    this.clearRenderSubscription();
    this.data = null;
    this.updated.complete();
  }

  /**
   * Push the caached data to clipbaord. This must be triggered from a click event
   */
  public pushToClipboard() {
    if (!this.data) {
      console.warn('No clipboard data available');
      return;
    }

    this.clipboardService.setClipboardData(this.data);
  }


  public clear() {
    this.clearRenderSubscription();
    this.setClipboardData(null);
  }

  public prepare(data: T) {
    if (this.data || this.renderSubscription) {
      return;
    }



    this.renderSubscription = this.clipboardService.renderData(data, this.url, this.serializer)
      .subscribe(
        res => {
          this.setClipboardData(res);
          this.clearRenderSubscription();

        },
        () => {
          this.clearRenderSubscription();
          console.error('Failed to download clipboard data');
        }
      );
  }

  private clearRenderSubscription() {
    if (!this.renderSubscription) {
      return;
    }

    this.renderSubscription.unsubscribe();
    this.renderSubscription = null;
  }

  private setClipboardData(data: IClipboardData) {
    if (this.data === data) {
      return;
    }

    this.data = data;
    this.updated.emit();

  }
}

@Injectable({providedIn: 'root'})
export class ClipboardService {

  constructor (private readonly toast: ToastService, private readonly client: ApiClient) {
  }

  public createDocumentHelper(): AsyncClipboardHelper<DocExportRequest> {
    return new AsyncClipboardHelper<DocExportRequest>(this, '/api/documents/clipboard', DocExportSerializer.default);
  }

  public createSnippetHelper(): AsyncClipboardHelper<Snippet>  {
    return new AsyncClipboardHelper(this, '/api/snippets/clipboard', SnippetSerializer.instance);
  }

  public renderData<T>(data: T, url: string, serializer: ISerializer<T>): Observable<IClipboardData> {

    if (!data) {
      return empty;
    }

    const args: IApiRequestArgs = {
      body: serializer.serialize(data),
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    return this.client.post<IClipboardData>(url, args)
      .pipe(map(res => res.data));
  }

  setClipboardData(data: IClipboardData) {
    console.log('setClipboardData', data);
    if (!data) {
      return Promise.reject('Invalid clipboard data');
    }

    try {
      const item = new clipboard.ClipboardItem({
        'text/plain': new Blob([data.plainText], { type: 'text/plain'}),
        'text/html': new Blob([data.html], { type: 'text/html'})
      });
      clipboard.write([item]).then(() => {
          const maxLength = 80;
          let toastText = data.plainText;

          if (toastText.length > maxLength) {
            toastText = toastText.substr(0, maxLength - 3).trim() + '...';
          }

          this.toast.info('Text copied to clipboard', toastText);
        }, (err) => {
          console.warn('clipboard error', err);
          this.toast.error(
            'Clipboard Error',
            'Failed to copy data to clipboard',
            6 // 6 seconds
          );
        }
      );
    } catch (err) {
      console.warn('clipboard error', err);
      this.toast.error(
        'Clipboard Disabled',
        'Please reload this page by pressing F5 and click<br>"Allow Access" when prompted to enable your clipboard',
        6 // 6 seconds
      );
    }

  }
}
