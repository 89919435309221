// external
import { Pipe, PipeTransform } from '@angular/core';

import { TreeStatus } from '@aa/common';
import { TreeStatusFormatter } from '../classes';

/*
 * Formats treeStatus on a selfserv meta object
 * Usage:
 *   meta.treeStatus | treeStatus
 */
@Pipe({name: 'treeStatus'})
export class TreeStatusPipe implements PipeTransform {

  transform(status: TreeStatus): string {
    return TreeStatusFormatter.formatName(status);
  }
}
