import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable,  Subscription,  concat,  of, throwError } from 'rxjs';
import { StreamProgressEvent } from '@aa/app/shared';
import { delay, switchMap } from 'rxjs/operators';


@Component({
  selector: 'aa-progress-report',
  templateUrl: './progress-report.component.html',
  styleUrls: ['./progress-report.component.css']
})
export class ProgressReportComponent implements OnDestroy {

  private sub: Subscription;

  /**
   * Progress value between 0-100
   * set to -1 to disable
   */
  public message: string = null;
  public error: string = null;

  @Input() set stream(stream: Observable<StreamProgressEvent>) {
    this.unsubscribe();

    this.reset();

    if (!stream) {
      return;
    }

    this.sub = stream.pipe(
      switchMap(event => {
        if (event.error) {
          return throwError(event.error);
        }

        if (event.complete) {

          // display 100% for a short time and then hide
          return concat(
            of(event.message || '100%'),
            of(null).pipe(delay(1000)
            ));
        }

        const percent = Math.ceil( 100 * event.loaded / event.total);

        return of(event.message || `${percent}%`);

      })
    ).subscribe(message => {

      this.message = message;
      this.error = null;

    }, error => {
      this.message = null;
      this.error = error;
    });
  }

  public get active() {
    return this.message !== null;
  }

  private reset() {
    this.message = null;
    this.error = null;
  }

  private unsubscribe() {
    if (!this.sub) {
      return;
    }

    this.sub.unsubscribe();
    this.sub = null;
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
