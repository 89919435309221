import { Component } from '@angular/core';

// core
import { SubscriberComponent } from '@aa/common';

// ui
import { Command } from '@aa/app/ui';

// shared
import { AppLibrary } from '../../models';
import { AppMetaDataService } from '../../services';
import { AppMetaData } from '../../classes';

@Component({
  selector: 'aa-library-select-menu',
  templateUrl: './library-select-menu.component.html',
  styleUrls: ['library-select-menu.component.css'],
  exportAs: 'aaLibrarySelect'
})
export class LibrarySelectMenuComponent extends SubscriberComponent {

  public meta: AppMetaData;
  public currentLibrary: AppLibrary;
  public readonly refreshCmd: Command;

  constructor(private readonly metaService: AppMetaDataService) {

    super();

    this.refreshCmd = metaService.refreshCommand;

    this.subscribe(metaService.metaData$, meta => {
      this.meta = meta;
    });

    this.subscribe(metaService.currentLibrary$, library => {
      this.currentLibrary = library;
    });
  }

  setLibrary(id: Guid) {
    this.metaService.setLibrary(id);
  }
}
