import { StringKey } from '@aa/common';
import { IFormatter } from '@aa/app/shared';

/**
 * Created by Tom Makin on 10/10/2016.
 */
export interface ITableFilter {
  filterString: string;
  placeholder: string;
}

export enum TableColumnType {
  Default= 0,
  Async= 1,
  Image= 2,
  Boolean= 3
}


export interface ITableColumn<T = any> {
  title: string;
  key: StringKey<T>;

  formatter?: IFormatter;

  className?: string | Array<string>;
  sort?: boolean | string;
  filtering?: ITableFilter;

  type?: TableColumnType;

  hidden?: boolean;
}
