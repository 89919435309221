import { Command } from '../models/command.model';
import { Icon } from '../models/icon.enum';


export abstract class CommandList {

  public commands: Command[] = [];

  /**
   * Register a new command
   * @param name
   * @param description
   * @param shortcut
   * @returns {Command}
   */
  protected register(name: string, description: string, shortcut: string = null, icon: Icon = null): Command {
    const cmd = new Command({
      name: name,
      description: description,
      shortcut: shortcut,
      icon: icon
    });
    this.commands.push(cmd);
    return cmd;
  }
}

