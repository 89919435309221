<mat-form-field>
  <input matInput autocomplete="off" [type]="inputType"   [placeholder]="label" [formControl]="control" [title]="control.value" >

  <button mat-icon-button matSuffix [disabled]="isEyeHidden" [ngClass]="{active: showText}"  aria-label="View" (click)="toggleText()">
    <i [aaIcon]="viewIcon"></i>
  </button>

  <mat-error *ngIf="hasError">{{errors[0]}}</mat-error>

</mat-form-field>
