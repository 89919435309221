import { Injectable } from '@angular/core';
import { Observable, EMPTY as empty } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map, catchError, switchMap, take } from 'rxjs/operators';

// common
import { AuthService, ISerializer, ApiResponse, IApiResponse, HttpUtil } from '@aa/common';

export interface IApiRequestArgs<T = any> {
  body?: T;
  serializer?: ISerializer<T>;
  params?: HttpParams;
  headers?: HttpHeaders;
}

@Injectable({providedIn: 'root'})
export class ApiClient {

  constructor (public readonly http: HttpClient,
               private readonly auth: AuthService) {
    // auth.loggedIn$.subscribe(loggedIn => console.log('ApiClient Loggedin', loggedIn));
  }

  public delete(url: string, headers?: HttpHeaders, params?: HttpParams): Observable<boolean> {
    return this.http.delete(url, { headers, params }).pipe(
      catchError(HttpUtil.handleError),
      map(res => true)
    );
  }

  public requestFile(method: string, url: string, args?: IApiRequestArgs): Observable<ApiResponse> {
    args = args || {};

    return this.http.request(method, url, {
      responseType: 'blob',
      observe: 'response',
      body: args.body,
      params: args.params,
      headers: args.headers
    }).pipe(
      map((res: HttpResponse<Blob>) => HttpUtil.parseBlobResponse(res)),
      catchError(HttpUtil.handleError)
    );
  }

  public request<T>(method: string, url: string, args?: IApiRequestArgs<T>): Observable<ApiResponse> {
    args = args || {};

    const requestStream = this.http.request<IApiResponse>(method, url, {
      responseType: 'json',
      observe: 'response',
      body: args.body,
      params: args.params,
      headers: args.headers
    }).pipe(
      map(res => HttpUtil.parseResponse<T>(res, args.serializer)),
      catchError(HttpUtil.handleError)
    );

    // create a composite stream that will cancel the request if user logs out
    return this.auth.loggedIn$.pipe(
      switchMap(user => user ? requestStream : empty),
      take(1) // this is crucial otherwise stream will not complete
    );
  }

  public get<T>(url: string, args?: IApiRequestArgs<T>): Observable<ApiResponse> {
    return this.request<T>('GET', url, args);
  }

  public post<T>(url: string, args?: IApiRequestArgs<T>): Observable<ApiResponse> {
    return this.request<T>('POST', url, args);
  }

  public put<T>(url: string, body: T, args?: IApiRequestArgs<T>): Observable<ApiResponse> {
    return this.request<T>('PUT', url, args);
  }
}
