import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// core
import { ConfirmService } from '@aa/app/ui';

// shared
import { ChannelItem, Snippet } from '../../models';
import { AppMetaData } from '../../classes';
import { AppMetaDataService, AbstractChannelItemApiService, SnippetApiService } from '../../services';

import { ChannelShareDialogComponent, IChannelShareDialogData } from './channel-share-dialog.component';


@Injectable({providedIn: 'root'})
export class ChannelShareService {

  private appMeta: AppMetaData;

  constructor(private dialog: MatDialog, metaService: AppMetaDataService,
              private readonly snippetService: SnippetApiService,
              private readonly confirmService: ConfirmService) {
    metaService.metaData$.subscribe(data => {

       this.appMeta = data;
    });
  }

  public share<T extends ChannelItem>(item: T, dataService: AbstractChannelItemApiService<T>): Observable<T> {

    if (!this.appMeta) {
      return;
    }

    const name = dataService.getName();

    const channels = this.appMeta.getSharedChannels(item.channelType);

    if (!channels.length) {
      return this.confirmService.showInfo({
        title: `Share ${name}`,
        content: 'No shared channels available'
      }).pipe(map(o => null));
    }

    const data: IChannelShareDialogData = {
      item: item,
      channels: channels,
      dataService: dataService,
      prompt: ''
    };

    const ref = this.dialog.open(ChannelShareDialogComponent, {data});
    return ref.afterClosed();
  }
}
