<div cdkDrag>
  <div class="tools drag-hide" [ngClass]="{visible: showToolbar}" contenteditable="false">
    <div class="icons">
      <div class="drag-handle" cdkDragHandle><i class="fa fa-arrows-v"></i></div>
      <div class="delete" (click)="delete()"><i class="fa fa-remove"></i></div>
    </div>
  </div>

  <div class="data">
    <input #input [(ngModel)]="item.data" (ngModelChange)="notify()">
  </div>
</div>
