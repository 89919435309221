// https://color.a11y.com/ContrastPair/
export class ContrastLimits {
  public static readonly Primary: number = 4.5;
  public static readonly Secondary: number = null;
  public static readonly Header: number = 4.5;
  public static readonly Hover: number = null;
}

export interface IContrastInfo {
  message: string;
  value?: number;
  warning?: boolean;
  limit?: number;
}

export class ColorContrast {

  private static readonly _cache = new Map<string, number>();

  public static getContrastInfo(color: string, limit: number = 0): IContrastInfo {

    if (!color) {
      return {message: 'Empty'};
    }

    const value = ColorContrast.calcContrast(color);

    const info: IContrastInfo = {
      message: null,
      warning: false,
      value: value,
      limit: limit
    };

    // invalid result
    if (value < 0) {
      info.message = '⚠ Failed to calculate contrast';
      info.warning = true;
      return info;
    }

    if (limit <= 0) {
      info.message = `Contrast ${value}`;
    } else if (value < limit) {
      info.message = `⚠ Contrast ${value} < ${limit}`;
      info.warning = true;
    } else {
      info.message = `✓ Contrast ${value} ≥ ${limit}`;
    }

    return info;
  }

  public static calcContrast(color: string): number {

    let contrast = ColorContrast._cache.get(color);

    if (contrast === undefined) {
      contrast = ColorContrast.calcContrastInternal(color);
      this._cache.set(color, contrast);
    }

    return contrast;
  }

  private static calcContrastInternal(str: string): number {
    try {
      const hex = ColorContrast.normalizeHexColor(str);
      const rgb = ColorContrast.formatRgb(hex);
      const contrast = ColorContrast.rgbContrast(rgb);


      // round to 2 dp
      return Math.floor(contrast * 100) / 100;
    } catch (err) {
      console.warn(`Failed to calc contrast ratio: ${str}`, err);
      return -1;
    }
  }

  // https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
  private static luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map(v => {
      v /= 255;
      return v <= 0.03928
        ? v / 12.92
        : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  public static rgbContrast(rgb: number[]): number {
    const l1 = 1.05; // white
    const l2 = ColorContrast.luminance(rgb[0], rgb[1], rgb[2]) + 0.05;

    return l1 / l2;
  }

  private static formatRgb(hex: string): number[] {

    function getRgbSubset(value, idx): number {
        const subHex = (value.substr(idx, 2));
        return parseInt(subHex, 16);
    }

    return [
      getRgbSubset(hex, 0),
      getRgbSubset(hex, 2),
      getRgbSubset(hex, 4)
    ];
  }



  public static normalizeHexColor(color: string): string {
    color = color.replace(/^#/, '');

    if (color.length === 3) {
      color = color.replace(
        /^([a-f0-9]{1})([a-f0-9]{1})([a-f0-9]{1})$/i,
        '$1$1$2$2$3$3'
      );
    }

    return color;
  }

}
