import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, EMPTY as empty, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService, AppConfig } from '@aa/common';

import { Team, IDataCollection } from '../models';
import { AbstractApiService } from './abstract-api.service';
import { TeamSerializer } from '../serializers';


@Injectable({providedIn: 'root'})
export class TeamApiService extends AbstractApiService<Team> {

  private readonly cache: IDataCollection<Team>;

  private currentTeamSource = new BehaviorSubject<Team>(null);
  public readonly currentTeam$: Observable<Team> = this.currentTeamSource.asObservable();

  public readonly themeColor$: Observable<string> = this.currentTeam$.pipe(map(o => (o && o.theme) ? o.theme.primary : null));

  constructor (injector: Injector, auth: AuthService, appConfig: AppConfig) {
    super(injector, 'teams', new TeamSerializer());

    this.cache = super.getView();

    // current team
    auth.loggedIn$.pipe(switchMap(user => user ? this.getCachedItem(user.teamId) : empty))
      .subscribe(this.currentTeamSource);

    this.currentTeam$.subscribe(team => {
      appConfig.setAnalyticsContext({teamName: team ? team.name : null});
    });
  }

  public getCachedItem(id: string): Observable<Team> {
    return this.cache.getItem(id);
  }
}

@Injectable({providedIn: 'root'})
export class ThemeCacheService {

  public themeColor: string = null;

  constructor(teamApi: TeamApiService) {
    teamApi.themeColor$.subscribe(themeColor => {
      this.themeColor = themeColor;
    });
  }
}
