import { Item, Role, Theme } from '@aa/common';

import { TeamPlanType } from './team-plan-type.enum';

export class Team extends Item {

  name: string;

  fullName: string;

  theme:  Theme;

  serviceName: string;

  domain: string;

  libraries: Guid[];

  subscriptions: Guid[];

  logoImage: string;

  headerImage: string;

  roles: Role[] = [];

  planType: TeamPlanType;

  expiry?: DateString;

  groupIds: Guid[];

  notificationsEnabled: boolean;

  /**
   * Checks if team has been assigned the given role
   * @param team Team
   * @param role Role to check for
   * @returns {boolean} True is role is present in the team role list
   */
  public static isInRole(team: Team, role: Role): boolean {
    if (!team || !team.roles) {
      return false;
    }

    return team.roles.includes(role);
  }
}
