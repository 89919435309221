<style>
  :host {
    padding-bottom: 20px;
  }
</style>

<mat-checkbox [formControl]="control" [color]="color">{{label}}</mat-checkbox>
<mat-hint *ngIf="hint">{{hint}}</mat-hint>
<mat-error *ngIf="hasError">{{errors[0]}}</mat-error>



