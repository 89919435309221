import { Injectable, NgZone } from '@angular/core';
import { Subscription,  interval } from 'rxjs';
import { take } from 'rxjs/operators';

enum GrooveMode {
  Unknown = 0,
  Widget = 1,
  Extenal = 2
}

type GrooveCmd = keyof GrooveWidget;

@Injectable({providedIn: 'root'})
export class GrooveWidgetService {

  private interval: any;
  public initialized: boolean = false;

  public supportUrl: string = 'http://support.adviceaid.uk';
  public mode = GrooveMode.Unknown;
  private subscription: Subscription = null;

  constructor(private readonly zone: NgZone) {
      this.load();
  }

  private executeCommand(cmd: GrooveCmd) {
    if (!window.groove || !window.groove.widget) {
      return;
    }

    const widget: GrooveWidget = window.groove.widget;

    this.zone.runOutsideAngular(() => {
      try {
        switch (cmd) {
          case 'init':
            widget.init('e4769cf0-c727-4d62-90ad-0f6084d292cd', {});
            break;
          case 'close':
            widget.close();
            break;
          case 'open':
            widget.open();
            break;
          case 'toggle':
            widget.toggle();
            break;
        }
      } catch (err) {
        console.warn('Groove widget error', cmd, err);
      }
    });
  }

  public load(): void {
    if (this.initialized) {
      return;
    }

    this.executeCommand('init');
    this.initialized = true;
  }

  public toggle(): void {
    if (this.mode === GrooveMode.Extenal) {
      this.openExternal();
      return;
    }

    this.executeCommand('toggle');

    // don't run the timer if we have had a result one way or the other
    if (this.mode !== GrooveMode.Unknown) {
      return;
    }

    // need to use small interval here in-case user opens and closes quickly
    this.subscription = interval(100)// 100ms
      .pipe(take(30)) // 3 seconds
      .subscribe(tick => {
        if (this.isOpen) {
          this.clearSubscription();
          this.mode = GrooveMode.Widget;
        }
      }, err => {},
        () => {
        this.clearSubscription();
        console.log('switching to external support site', this.loading);
        this.mode = GrooveMode.Extenal;
        this.openExternal();
      });
  }

  private clearSubscription() {
    if (!this.subscription) {
      return;
    }

    this.subscription.unsubscribe();
    this.subscription = null;
  }

  public openExternal() {

    const external = window.open(this.supportUrl, '_blank');
    if (external && external.focus) {
      external.focus();
    }
  }

  public get loading(): boolean {
    return this.subscription != null;
  }

  public get isOpen(): boolean {
    return !!this.getAppElement();
  }

  public close() {
    this.executeCommand('close');
  }

  private getAppElement(): HTMLIFrameElement {
    const result: HTMLElement = document.getElementById('groove-container-e4769cf0-c727-4d62-90ad-0f6084d292cd');

    if (!result) {
      return null;
    }

    return <HTMLIFrameElement>result;
  }
}
