import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

// core
import { AuthService } from '@aa/common';

// shared
import { AppChannel } from '../../models';

// local
import { ChannelInfoDialogComponent } from './channel-info-dialog.component';


@Injectable({providedIn: 'root'})
export class ChannelInfoDialogService {

  channels: AppChannel[] = [];

  constructor(private dialog: MatDialog, private auth: AuthService) {
  }

  get canView(): boolean {
    return this.auth.isAdmin;
  }

  showDialog(): Observable<any> {

    return this.dialog.open(ChannelInfoDialogComponent).afterClosed();
  }
}
