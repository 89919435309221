export class Theme {

  primary: string;

  secondary: string;

  header?: string;

  hover?: string;
}
