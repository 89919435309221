<div *ngIf="user">
  <div class="name">{{user | userName}}</div>
  <div class="email">{{user.email}}</div>
  <aa-team-logo [teamId]="user.teamId"></aa-team-logo>
</div>

<div *ngIf="!user">
  <div class="name">Not logged in</div>
</div>

