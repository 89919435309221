import { Role } from '@aa/common';

export class RoleUtil {

  private static readonly teamRoleMap = new Map<Role, Role>([
    [Role.Publisher, Role.Publisher],
    [Role.SiteAdmin, Role.SiteAdmin],
    [Role.Master, Role.SiteAdmin]
  ]);

  /**
   * Get roles which can be assigned to teams
   */
  static getTeamRoles(): Role[] {
    return [
      Role.DemoTeam,
      Role.Publisher,
      Role.SiteAdmin
    ];
  }

  /**
   * Get roles which can be assigned to channel share
   */
  static getChannelShareRoles(): Role[] {
    return [
      Role.Advisor,
      Role.TeamAuthor,
      Role.Publisher,
      Role.TeamAdmin,
    ];
  }

  /**
   * Roles can be assigned by team admin users
   */
  static getTeamAdminRoles(): Role[] {
    return [
      Role.Advisor,
      Role.TeamAdmin,
      Role.TeamAuthor,
      Role.Publisher];
  }

  /**
   * Get valid site admin roles
   */
  static getSiteAdmin(): Role[] {
    return [
      Role.Advisor,
      Role.TeamAdmin,
      Role.TeamAuthor,
      Role.Publisher,
      Role.Tester,
      Role.Workspace,
      Role.SiteAdmin
    ];
  }

  /**
   * Get all roles
   */
  static getAll(): Role[] {
    const all = RoleUtil.getSiteAdmin();
    all.push(Role.FreeTrial);
    all.push(Role.Master);
    return all;
  }

  static getRequiredTeamRole(role: Role): Role {
    return RoleUtil.teamRoleMap.get(role) || Role.None;
  }
}
