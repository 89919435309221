<div>
  <button tabindex="-1" mat-raised-button (click)="addNewItem()"  matTooltip="Add new item">
    Add
  </button>
  <button tabindex="-1" mat-raised-button (click)="sortItems()" matTooltip="Sort list alphabetically">
    Sort
  </button>
</div>

<div class="list" cdkDropList (cdkDropListDropped)="onDrop($event)">
  <aa-list-item class="list-item border" *ngFor="let p of list"
                  [item]="p"
                  (deleted)="deleteItem($event)"
                  (changed)="update()">
  </aa-list-item>
</div>

