import { Component, OnInit } from '@angular/core';

import { MicrosoftAuthService } from '@aa/app/shared';

@Component({
  template: '<div class="info-page">Authenticating...</div>'
})
export class OAuthRedirectComponent implements OnInit {
  constructor(private msAuth: MicrosoftAuthService) {}

  ngOnInit() {
    setTimeout(() => {
      window.close();
    }, 5000);
  }
}
