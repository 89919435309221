

<div *ngIf="snippet" class="content">
  <div class="description">{{snippet.description}}</div>

  <hr [style.border-color]="themeColor">

  <div class="snippet">
    <div [innerHTML]="snippet.data"></div>
    <div class="margin-right"></div>
  </div>
</div>
<!--
<div class="margin-bottom"></div>
-->
