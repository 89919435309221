import { Injectable } from '@angular/core';

import { Role, AuthService } from '@aa/common';

import { Icon } from '@aa/app/ui';

import { AppMetaDataService, } from './app-meta-data.service';
import { AppChannel, ChannelItem, ChannelType} from '../models';
import { AppMetaData, ChannelIdFormatter } from '../classes';
import { TeamApiService } from './team-api.service';
import { combineLatest } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ChannelResolverService {

  private readonly _list: AppChannel[] = [];
  private readonly _map = new Map<Guid, AppChannel>();
  private readonly _colorMap = new Map<Guid, string>();

  private localisationChannelId: Guid = null;

  constructor(private readonly auth: AuthService,
              appData: AppMetaDataService) {

    appData.metaData$
      .subscribe(meta => {
        this.buildChannelMap(meta);
        this.updateLocalisationInfo(meta);
      });
  }

  public getColorFromChannelType(groupType: ChannelType): string {
    switch (groupType) {
      case ChannelType.Private: return '#ccc';
      case ChannelType.Team:    return '#999';
      case ChannelType.Global:  return '#666';
      case ChannelType.Review:  return '#ff00ff';
      case ChannelType.Group:   return '#ffbb00';
      default: return '#ccc';
    }
  }

  private buildChannelMap(meta: AppMetaData) {
    this._map.clear();
    this._colorMap.clear();
    this._list.length = 0;

    if (!meta) {
      return;
    }

    for (const group of meta.groups) {
      this._colorMap.set(group.id, this.getColorFromChannelType(group.channelType));
    }

    for (const channel of meta.channelList) {
      this._list.push(channel);
      this._map.set(channel.id, channel);

      const groupColor = this._colorMap.get(channel.groupId);
      this._colorMap.set(channel.id, groupColor);
    }
  }


  private updateLocalisationInfo(meta: AppMetaData) {

    this.localisationChannelId = null;

    if (!meta || !this.auth.canLocalise) {
      return;
    }

    const channel = meta.getChannelByType(ChannelType.Localisation);
    if (!channel) {
      return;
    }

    this.localisationChannelId = channel.id;
  }


  public getLocalisationChannelId(): Guid {
    return this.localisationChannelId;
  }


  public getChannel(channelId: Guid): AppChannel {
    const channel = this._map.get(channelId);

    if (!channel) {
      console.warn('Channel not found', channelId);
    }

    return channel;
  }

  public getColor(channelOrGroupId: Guid): string {
    return this._colorMap.get(channelOrGroupId) || 'white';
  }

  public getChannelType(item: ChannelItem): ChannelType {

    if (!item) {
      return ChannelType.None;
    }

    const channel = this.getChannel(item.channelId);
    if (!channel) {
      return ChannelType.None;
    }

    return channel.channelType;
  }

  public getChannelIcon(item: ChannelItem): Icon {

    switch (this.getChannelType(item)) {
      case ChannelType.Private:
        return Icon.Private;

      case ChannelType.Team:
        return Icon.Team;

      default:
        return null;
    }
  }

  public getChannelDescription(channelId: Guid): string {
    const channel = this.getChannel(channelId);
    return ChannelIdFormatter.formatChannelName(channel);
  }

  /**
   * Clear the watermark image
   * @param {string} channelId
   */
  clearWatermarkImage(channelId: string) {
    const channel = this.getChannel(channelId);
    if (channel) {
      channel.watermarkImage = null;
    }
  }
}
