// external
import { Component, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// core
import { AuthService, IUser } from '@aa/common';

// forms
import { IFormDialogContent } from '@aa/app/forms';

// local
import { Team, UserProfile } from '../../models';
import { TeamApiService, UserProfileService } from '../../services';

import { ItemEditorComponent } from '../item-editor.component';
import { PasswordChangeDialogComponent } from './password_change.dialog';



@Component({
  selector: 'aa-profile-editor',
  templateUrl: './profile_editor.dialog.html',
})
export class ProfileEditorDialogComponent extends ItemEditorComponent<UserProfile> implements IFormDialogContent {

  public user: IUser;

  public team: Team;

  constructor(private readonly userService: UserProfileService,
              private readonly dialog: MatDialog,
              public readonly auth: AuthService,
              teamApi: TeamApiService,
              injector: Injector) {

    super(userService, injector);

    this.user = auth.user;

    super.initAsync(this.user.id);
    super.setTitle('User Profile');

    // resolve team
    super.subscribe(teamApi.getCachedItem(this.user.teamId), team => this.team = team );

  }

  // trigger token refresh on save to update profile data in other parts of the app
  protected onSave(item: UserProfile) {
    this.auth.invalidateToken();
  }

  changePassword() {
    this.dialog.open(PasswordChangeDialogComponent, {width: '400px'});
  }
}
