import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { MenuPositionX, MenuPositionY, MatMenuPanel } from '@angular/material/menu';

/**
 * Custom version of mat-menu
 * This was the only way to override the defualt animations
 * Can be revisited when material is released to see if there is a way to override
 * styles copied from the compiled material mat-menu directive
 */
@Component({
  selector: 'aa-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'aaDropdown'
})
export class DropdownComponent implements MatMenuPanel {

  public xPosition: MenuPositionX = 'after';

  public yPosition: MenuPositionY = 'below';

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input() overlapTrigger = false;

  /** Event emitted when the menu is closed. */
  @Output() close = new EventEmitter<void>();

  focusFirstItem(): void {}

  resetActiveItem(): void {}

  closeMenu() {
    this.close.emit();
  }
}


