import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// common
import { ApiResponse, Role } from '@aa/common';

// local
import { AbstractApiService } from './abstract-api.service';

import { User, UserProfile, PasswordData, IDataCollection, IDataCollectionArgs } from '../models';
import { ApiClient } from './api-client.service';
import { UserSerializer, UserProfileSerializer } from '../serializers';

@Injectable({providedIn: 'root'})
export class UserNameService {

  private _cache = new Map<Guid, string>();

  constructor (private readonly apiClient: ApiClient) {
  }

  public getUserName(id: Guid): Observable<string> {

    const cachedResult = this._cache.get(id);
    if (cachedResult) {
      return of(cachedResult);
    }

    const uri = `/api/users/${id}/name`;

    return this.apiClient.get(uri).pipe(
      map(res => res.data || null),
      tap(name => this.addCacheEntry(id, name)) // cache the name
    );
  }

  private addCacheEntry(id: Guid, name: string) {
    if (!name) {
      return;
    }

    this._cache.set(id, name);
  }

}

@Injectable({providedIn: 'root'})
export class UserProfileService extends AbstractApiService<UserProfile> {
  constructor (injector: Injector) {
    super(injector, 'users', new UserProfileSerializer());
  }

  public buildItemUrl(id: Guid): string {
    return super.buildItemUrl(id) + '/profile';
  }
}

@Injectable({providedIn: 'root'})
export class UserApiService extends AbstractApiService<User> {

  profiles: IDataCollection<UserProfile>;

  constructor (injector: Injector) {
    super(injector, 'users', new UserSerializer());

    this.profiles = new UserProfileService(injector).getView();
  }

  // allow team admins to create
  public canCreate(): boolean {
    return this.checkPermission([Role.SiteAdmin, Role.TeamAdmin]);
  }

  public getUserProfile(id: Guid): Observable<UserProfile> {
    return this.profiles.getItem(id);
  }

  public getTeamUserView() {
    const user = this.auth.user;
    if (!user) {
      throw new Error('User is not logged in');
    }

    const args: IDataCollectionArgs = {
      customUrl: this.generateApiUrl(`teams/${user.teamId}/users`)
    };
    return this.getView(args);
  }

  public updateUserProfile(item: UserProfile): Observable<UserProfile> {
    if (!item.id) {
      throw new Error('user id is undefined');
    }

    return this.profiles.saveItem(item);
  }

  public resendActivationEmail(id: Guid): Observable<ApiResponse> {
    const url = this.buildItemUrl(id) + '/resendactivationemail';
    const obs = this.apiClient.post(url);

    obs.subscribe(
      res => this.toast.success('OK', res.message || 'Activation email sent'),
      err => this.toast.error('Error', err)
    );

    return obs;
  }

  public resendWelcomeEmail(id: Guid): Observable<ApiResponse> {
    const url = this.buildItemUrl(id) + '/resendwelcomeemail';

    const obs = this.apiClient.request('POST', url);

    obs.subscribe(
      res => this.toast.success('OK', res.message || 'Welcome email sent'),
      err => this.toast.error('Error', err)
    );

    return obs;
  }

  /**
   * Submit password chaneg request
   * @param userId
   * @param passwordData
   * @returns {Observable<ApiResponse>}
   */
  public changePassword(userId: Guid, passwordData: PasswordData): Promise<ApiResponse> {
    const url = this.buildItemUrl(userId) + '/password';

    return this.apiClient.post(url, {body: passwordData})
      .pipe(tap(
      res => this.toast.success('OK', res.message || 'Password updated successfully'),
      err => this.toast.error('Error', err)
    )).toPromise();
  }
}
