import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AbstractInputComponent } from './abstract-input.component';

@Component({
  selector: 'aa-date-input',
  templateUrl: './date-input.component.html',
  host: {
    'class': 'form-control'
  }
})
export class DateInputComponent extends AbstractInputComponent {

  @Input() label: string;
  @Input() hint: string;
  @Input() control: AbstractControl;
}
