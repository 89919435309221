// external
import { ChangeDetectorRef, Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// common
import { Role, AuthService } from '@aa/common';

// forms
import { ISelectOption, SelectOptionMapper } from '@aa/app/forms';

// local
import { RoleFormatter, RoleUtil } from '../../classes';
import { Team, User, PasswordData } from '../../models';
import { UserApiService, TeamApiService } from '../../services';

import { ItemEditorComponent } from '../item-editor.component';


export interface IUserEditorDialogArgs {
  roleFilter: Role[];
  teams?: Team[];
}

@Component({
  selector: 'aa-user-editor-dialog',
  templateUrl: './user_editor.dialog.html',
})
export class UserEditorDialogComponent extends ItemEditorComponent<User> {
  readonly teamOptions: ISelectOption[] = [];
  roleOptions: ISelectOption[] = [];

  private readonly roles: Role[];
  private roleFilter: Role[];

  private team: Team = null;

  constructor(private userService: UserApiService,
              private teamDataService: TeamApiService,
              private changeDetectionRef: ChangeDetectorRef,
              private auth: AuthService,
              @Inject(MAT_DIALOG_DATA) private data: IUserEditorDialogArgs,
              injector: Injector) {
    super(userService, injector);

    this.roles = RoleUtil.getAll();
    this.roleFilter = data.roleFilter || RoleUtil.getSiteAdmin();
    this.rebuildRoleOptions();

    this.teamOptions = SelectOptionMapper.mapName(data.teams || []);
  }


  private rebuildRoleOptions(): void {
    this.roleOptions = this.roles.map(o => {
      return {
        value: o,
        label: RoleFormatter.default.formatItem(o),
        readonly: this.isRoleReadonly(o)
      };
    });
  }

  /**
   * Check if role can be edited
   */
  private isRoleReadonly(role: Role): boolean {
    // check role filter
    if (this.roleFilter && this.roleFilter.indexOf(role) < 0) {
      return true;
    }

    const teamRole = RoleUtil.getRequiredTeamRole(role);

    // check for team roles
    if (teamRole !== Role.None) {
      if (!this.team || !Team.isInRole(this.team, teamRole)) {
        return true;
      }
    }

    return false;
  }

  protected onFormUpdated(): void {
    this.updateTeamRoleOptions();
  }

  private updateTeamRoleOptions(): void {

    // only update if the current team does not match
    if (!this.item || !this.item.teamId ||
       (this.team && this.team.id === this.item.teamId)) {
      return;
    }

    this.teamDataService.getCachedItem(this.item.teamId).subscribe(team => {

      this.team = team;
      this.rebuildRoleOptions();

      // this is required to avoid "Expression changed after it was changed warning"
      // on the select component
      this.changeDetectionRef.detectChanges();
    });
  }

  public get activationRequired(): boolean {
    return !this.isNew && this.item && this.item.activated === false;
  }

  public factory(): User {
    const user = super.factory();

    user.teamId = this.auth.user.teamId;
    user.roles.push(Role.Advisor);
    user.jobTitle = 'Housing Officer';
    return user;
  }

  protected beforeShow(): boolean {

    // ensure roles are defined
    if (!this.item.roles) {
      this.item.roles = [];
    }

    const activationEmail = this.customControl('activationEmail');
    activationEmail.valueChanges.subscribe(sendActivation => {
      this.setControlEnabled('password', !sendActivation);
    });
    activationEmail.setValue(true);
    this.setControlEnabled('password', false);

    if (this.isNew) {
      this.item.password = new PasswordData();
    }

    this.setControlEnabled('email', this.isNew);
    this.setControlEnabled('teamId', this.isNew);

    this.updateTeamRoleOptions();

    return true;
  }
}
