<mat-form-field floatPlaceholder="auto">
    <input type="text" matInput autocomplete="off" [placeholder]="label" [formControl]="control" >

    <span matSuffix>
      <span class="color-icon" [ngStyle]="{'background-color': control.value}" [ngClass]="{warning:contrastWarning}" [matTooltip]="contrastMessage">
          <!--<i *ngIf="contrastWarning" [aaIcon]="alertIcon"></i>-->
      </span>
    </span>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
    <mat-error *ngIf="hasError">{{errors[0]}}</mat-error>
</mat-form-field>
