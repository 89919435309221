<div class="row">
  <div class="col-left">
    <div class="dialog-header">
      <!-- action menu -->
      <div class="header-actions" *ngIf="hasMenu; else closeButton">
        <ng-content select="aa-dialog-menu"></ng-content>
      </div>

      <!-- close button -->
      <ng-template #closeButton>
        <button tabindex="-1" *ngIf="!hasMenu"  class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </ng-template>

      <div class="dialog-title">
        <ng-content select="aa-dialog-title"></ng-content>
      </div>

      <div class="dialog-subtitle">
        <ng-content select="aa-dialog-subtitle"></ng-content>
      </div>

    </div>

    <div class="dialog-content" [ngClass]="{hidden: contentHidden}" #contentContainer>
      <ng-content select="aa-dialog-content"></ng-content>
    </div>

    <!-- hide the footer if it has no main -->
    <div class="dialog-footer" [hidden]="footer.children.length == 0" #footer>
      <div class="float-left">
        <ng-content select="aa-dialog-footer"></ng-content>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="40"></mat-progress-spinner>
      </div>

      <ng-content select="aa-dialog-actions"></ng-content>
    </div>
  </div>

  <div class="col-right">
    <ng-content select="aa-dialog-right"></ng-content>
  </div>
</div>
