import sortBy from 'lodash-es/sortBy';

import { Guid, ITag, ITaggedItem, ITagMap, TagType } from '../models';

export interface ITaggedItemGroup<T> {
  id: Guid;
  name: string;
  description: string;
  items: T[];
  order: number;
}

export class TaggedItemLibrary<T extends ITaggedItem> {

  private readonly groupMap: Map<Guid, ITaggedItemGroup<T>> = new Map<Guid, ITaggedItemGroup<T>>();
  public readonly groups: ITaggedItemGroup<T>[] = [];

  constructor(public readonly data: T[],
              public tagMap: ITagMap,
              nameFunc: (item: T) => string,
              defaultTag: ITag = null
              ) {

    // take tagged items only, and sort by name
    const groupData = sortBy(data, nameFunc);

    defaultTag = defaultTag || {
      id: 'default',
      name: 'Untagged',
      tagType: TagType.None
    };

    // build groups
    for (const item of groupData) {

      const groupId = item.tagId || defaultTag.id;

      let group = this.groupMap.get(groupId);

      // crate group if not defined
      if (!group) {
        const tag = tagMap.findTag( groupId) || defaultTag;

        group = <ITaggedItemGroup<T>>{
          id: groupId,
          name: tag.name,
          description: tag.description,
          items: [],
          order: tag.order
        };

        this.groupMap.set(group.id, group);
        this.groups.push(group);
      }

      // append item to group
      group.items.push(item);
    }
  }

  find(id: Guid): T {
    return this.data.find(o => o.id === id);
  }

  findGroupByItemId(id: Guid): ITaggedItemGroup<T> {
    return this.groups.find(group => group.items.some(o => o.id === id));
  }
}

