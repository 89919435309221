
import { ComponentType } from '@angular/cdk/portal';

import { Item } from '@aa/common';

import { ICommand } from '@aa/app/ui';
import { IDataCollection } from '@aa/app/shared';
import { IFormDialogContent } from '@aa/app/forms';

import { ITableColumn } from './table-column.interface';

export interface ITableAction extends ICommand {
  callback: (item: any) => void;
}

export enum TableRowType {
  Default = 0,
  Highlight = 1
}

export interface ITableConfig<T extends Item> {
  columns: ITableColumn<T>[];
  // details?: ITableColumn<T>[];
  actions?: ITableAction[];

  clickToEdit?: boolean;
  cloneAction?: (item: T) => void;
  canDelete?: boolean;

  rowType?: (item: T) => TableRowType;
}

export interface ITableViewConfig<T extends Item = any, TEditorData = any> extends ITableConfig<T> {
  name: string;
  data: IDataCollection<T>;
  editor: ComponentType<IFormDialogContent>;
  // editAction?: (item: T) => void;
  directEdit?: boolean; // edit the item without reloading from server
  editorData?: () => TEditorData;

  newItemText?: string;
  newItemTooltip?: string;

  /**
   * Specifiy dialog width
   * e.g. '1000px'
   */
  editorWidth?: string;
}
