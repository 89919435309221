<style>
  :host {
    margin-top: 10px;
  }

  .prefix {
    font-weight: bold;
  }
</style>

<div class="form-control" *ngIf="error">
  <div class="mat-error">
    <span class="prefix">{{prefix}}: </span><span class="message">{{error}}</span>

    <ul *ngIf="details">
      <li *ngFor="let item of details">
        {{details}}
      </li>
    </ul>
  </div>
</div>
