import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// core
import { EnumHelper, SubscriberComponent } from '@aa/common';

// shared
import { AppChannelGroupData, AppMetaData } from '../../classes';
import { AppChannel, ChannelAccess } from '../../models';
import { AppMetaDataService } from '../../services';

@Component({
  selector: 'aa-channel-info-dialog',
  templateUrl: './channel-info-dialog.component.html',
  styleUrls: ['./channel-info-dialog.component.scss']
})
export class ChannelInfoDialogComponent extends SubscriberComponent {

  public readonly prompt: string;
  public channelGroups: AppChannelGroupData[] = [];

  constructor(private dialogRef: MatDialogRef<ChannelInfoDialogComponent>,
              appMeta: AppMetaDataService) {
    super();

    super.subscribe<AppMetaData>(appMeta.metaData$, data => {
      this.channelGroups = data ? data.buildGroupDataList() : [];
    });
  }

  canRead(channel: AppChannel): boolean {
    return EnumHelper.hasFlag(channel.access, ChannelAccess.Read);
  }

  canShare(channel: AppChannel): boolean {
    return EnumHelper.hasFlag(channel.access, ChannelAccess.Share);
  }

  close() {
    this.dialogRef.close();
  }
}
