
// common
import { TreeType } from '@aa/common';

// local
import { AbstractFormatter } from './abstract_formatter.class';

export class TreeTypeFormatter extends AbstractFormatter<TreeType> {

  public static readonly nameMap = new Map<TreeType, string>([
    [TreeType.Public, 'Public'],
    [TreeType.Advisor, 'Advisor'],
    [TreeType.AdvisorFreemium, 'Advisor Freemium']
  ]);

  public static getTreeTypes(): TreeType[] {
    return [
      TreeType.Public, TreeType.Advisor, TreeType.AdvisorFreemium
    ];
  }

  public static formatName(value: TreeType): string {
    if (!value) {
      value = TreeType.Public;
    }

    const name = TreeTypeFormatter.nameMap.get(value);
    return name || `Unknown ${value}`;
  }

  protected formatItemInternal(value: TreeType): string {
    return TreeTypeFormatter.formatName(value);
  }
}
