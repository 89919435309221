/**
 * Bitwise flags describing channel access permissions
 */
/* tslint:disable:no-bitwise */
export enum ChannelAccess {
  None    = 0,
  Read    = 1,
  Create  = 2,
  Update  = 4,
  Delete  = 8,

  Share   = 16,

  CRUD = Read | Create | Update | Delete,
  All = CRUD | Share
}
