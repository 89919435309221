import { Item } from '@aa/common';

export class UserProfile extends Item  {

  email: string;

  firstName: string;

  lastName: string;

  jobTitle: string;

  phoneNumber: string;

  constructor(source: UserProfile = null) {
    super(source);

    if (!source) {
      return;
    }

    this.firstName   = source.firstName;
    this.lastName    = source.lastName;
    this.phoneNumber = source.phoneNumber;
    this.jobTitle    = source.jobTitle;
  }
}
