
<div>
  <table mat-table #table [dataSource]="dataSource" matSort [hidden]="!visibleColumns.length">

    <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.title}} </th>

      <td mat-cell *matCellDef="let row" [innerHTML]="row[column.key]" (dblclick)="clickRow(row['id'])"></td>
    </ng-container>

    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <span>
          <button tabindex="-1" mat-icon-button [matMenuTriggerFor]="actionMenu" (click)="setSelectedItemId(row['id'])">
          <i [aaIcon]="actionIcon"></i></button>
        </span>

        <aa-dropdown #actionMenu x-position="before">
          <aa-dropdown-cmd *ngFor="let action of actions"
                            class="dropdown-item"
                            [cmd]="action"
                            [args]="selectedItem">
          </aa-dropdown-cmd>
        </aa-dropdown>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: visibleColumns;" [attr.class]="row._class"></tr>

  </table>

  <div *ngIf="!hasResults" class="no-results" >

    <span *ngIf="!filter && visibleColumns.length">
      No items available
    </span>

    <span *ngIf="filter && visibleColumns.length">
      No items found matching filter
    </span>

    <span *ngIf="!visibleColumns.length">
      No columns selected
    </span>
  </div>

  <mat-paginator [hidden]="!hasResults"
                [length]="pagedData.length"
                [pageIndex]="pagedData.pageIndex"
                [pageSize]="pagedData.pageSize"
                [pageSizeOptions]="[5, 10, 20, 50, 100]"
                (page)="onPage($event)">
  </mat-paginator>
</div>

