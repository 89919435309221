<style>
  aa-dialog { width: 400px; }
</style>

<aa-dialog (keyup.escape)="cancel()" [loading]="loading">
  <aa-dialog-title>Change Password</aa-dialog-title>

  <aa-dialog-content>
    <aa-form-error [error]="error"></aa-form-error>

    <aa-password-input label="Old Password" [control]="form.get('oldPassword')"></aa-password-input>

    <aa-password-input label="New Password" [control]="form.get('newPassword')"></aa-password-input>

    <aa-password-input label="Confirm Password" [control]="form.get('confirmPassword')"></aa-password-input>

  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button color="primary" *ngIf="form?.dirty" [disabled]="loading"
            (click)="submit()">
      Submit
    </button>

    <button mat-raised-button (click)="close()">
      Cancel
    </button>
  </aa-dialog-actions>
</aa-dialog>


