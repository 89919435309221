// angular
import { Component } from '@angular/core';

// ui
import { ToastService } from '@aa/app/ui';

// shared
import { SelfServConfigApiService, LibraryResolverService } from '../../services';


@Component({
  selector: 'aa-purge-selfserv-button',
  templateUrl: './purge-selfserv-button.component.html'
})
export class PurgeSelfservButtonComponent {
  constructor(private readonly selfServConfig: SelfServConfigApiService,
              private readonly toastService: ToastService,
              private readonly libraryResolver: LibraryResolverService) {
  }

  public purge() {
    const library = this.libraryResolver.getCurrentLibrary();

    if (!library) {
      return;
    }

    this.selfServConfig.purge(library.id).then(res => {
      this.toastService.info('Done', `SelfServ cache cleared for ${library.name}`);
    }, err => {
      this.toastService.error('Error', 'Purge failed');
    });
  }
}

