

import { Tree } from '../models';
import { NamedItemFormatter } from './named-item.formatter';

export class TreeFormatter extends NamedItemFormatter<Tree> {

  constructor(public trees: Tree[]) {
    super(trees);
  }
}
