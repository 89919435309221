import { Guid } from '../models';

/**
 * Generate pseudo random guid
 * https://stackoverflow.com/questions/26501688/a-typescript-guid-class
 */

export class GuidTools {
  static newGuid(): Guid {
    const regex = /[xy]/g;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(regex, function(c) {
      /* tslint:disable-next-line:no-bitwise */
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static stripDashes(guid: Guid): string {
    if (!guid) { return null; }

    return guid.replace(/-/g, '');
  }
}
