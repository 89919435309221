import { TeamPlanType } from '../models';
import { AbstractFormatter } from './abstract_formatter.class';

export class TeamPlanTypeFormatter extends AbstractFormatter<TeamPlanType> {

  private static readonly nameMap = new Map<TeamPlanType, string>([
    [TeamPlanType.StandardTrial, 'Free Trial (Standard)'],
    [TeamPlanType.PlusTrial, 'Free Trial (Plus)'],
    [TeamPlanType.StandardPlan, 'Standard'],
    [TeamPlanType.PlusPlan, 'Plus'],
  ]);

  public static formatName(value: TeamPlanType): string {
    if (!value) {
      value = TeamPlanType.StandardTrial;
    }

    const name = TeamPlanTypeFormatter.nameMap.get(value);
    return name || `TeamPlanType ${value}`;
  }

  protected formatItemInternal(value: TeamPlanType): string {
    return TeamPlanTypeFormatter.formatName(value);
  }
}
