export interface IApiResponse {
  message: string;
  data: any;
}

export class ApiResponse implements IApiResponse {

  public static Error(status: number, message: string, errors: Map<string, string> = null) {
    return new ApiResponse(status, message, null, errors);
  }

  public static Success(status: number, message: string, data: any = null) {
    return new ApiResponse(status, message, data, null);
  }

  public static File(status: number, message: string, blob: Blob) {
    return new ApiResponse(status, message, null, null, blob);
  }

  private constructor(
    public readonly status: number,
    public readonly message: string,
    public readonly data: any = null,
    public readonly errors: any = null,
    public readonly blob: Blob = null) {
  }

  public getErrorList(): string[]  {
    if (!this.errors) {
      return null;
    }

    return Object.values(this.errors);
  }

  public get isSuccess(): boolean {
    return this.status >= 200 && this.status < 400;
  }

  public get isFile(): boolean {
    return this.blob != null;
  }

  public getData<T>(flatten: boolean = false) {
    let data = this.data;

    // flatten array to single element
    if (flatten && data && data.length > 0) {
      data = data[0];
    }

    return <T>(data);
  }

  toString(): string {
    return this.message;
  }
}
