// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { LocalisationMode } from '../models';

/*
 * Formats localisationMode values
 * Usage:
 *   item.localisationMode | localisationMode
 */
@Pipe({name: 'localisationMode'})
export class LocalisationModePipe implements PipeTransform {

  public transform(value: LocalisationMode): string {
    switch (value) {
      case LocalisationMode.Replace: return 'Replace';
      default:
        return 'Append';
    }
  }
}
