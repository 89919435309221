import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

// local
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { InfoDialogComponent } from './info-dialog.component';

import { IConfirmDialogData } from './confirm-dialog-data.interface';
import { ComponentType } from '@angular/cdk/portal';

export interface IConfirmService {
  show(data: IConfirmDialogData): Observable<boolean>;
  showInfo(data: IConfirmDialogData): Observable<boolean>;
}

@Injectable({providedIn: 'root'})
export class ConfirmService implements IConfirmService {

  constructor(private dialog: MatDialog) {
  }

  show(data: IConfirmDialogData): Observable<boolean> {
    return this.showInternal(ConfirmDialogComponent, data);
  }

  showInfo(data: IConfirmDialogData): Observable<boolean> {
    return this.showInternal(InfoDialogComponent, data);
  }

  private showInternal<T>(type: ComponentType<T>, data: IConfirmDialogData): Observable<boolean> {

    return this.dialog.open(type, {data, width: '550px'})
      .afterClosed();
  }
}
