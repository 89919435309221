// external
import { Injector } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, Subject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// common
import { GuidTools, EnumHelper, ApiResponse } from '@aa/common';

// local
import { AppMetaData, IFormSerializer } from '../classes';
import {
  ITokenData,
  IChannelShareRequest,
  ChannelItem,
  AppLibrary,
  AppChannel,
  IDataCollectionArgs,
  ChannelAccess,
  ChannelType,
  ShareRef,
  ISaveArgs
} from '../models';

import { AbstractApiService, RESOURCE_TOKEN_HEADER } from './abstract-api.service';

import { AppMetaDataService } from './app-meta-data.service';
import { IApiRequestArgs } from './api-client.service';

/**
 * Base class for channel data access services
 */
export abstract class AbstractChannelItemApiService<T extends ChannelItem> extends AbstractApiService<T> {

  protected appMeta: AppMetaData = null;
  private readonly metaService: AppMetaDataService;

  private readonly channelInvalidatedSource = new Subject<Guid>();
  public readonly channelInvalidated$ = this.channelInvalidatedSource.asObservable();

  /**
   * meta data exposed as separate observable to ensure clients process
   * updates in the correct order
   * i.e. we need to the ApiService to pick up the change first
   * @type {Subject<AppMetaData>}
   */
  private readonly metaDataSource = new Subject<AppMetaData>();
  public readonly metaDataUpdated$ = this.metaDataSource.asObservable();

  constructor(injector: Injector, collection: string, serializer: IFormSerializer<T>) {
    super(injector, collection, serializer);

    this.metaService = injector.get(AppMetaDataService);

    this.metaService.metaData$.subscribe(data => {
      this.appMeta = data;
      this.metaDataSource.next(data);
    });
  }

  protected getItemHeaders(item: T): HttpHeaders {

    if (!this.appMeta) {
      throw Error('App meta is not defined');
    }

    const tokens: ITokenData[] = [
      this.appMeta.getLibraryOrThrow(item.libraryId),
      this.appMeta.getChannelOrThrow(item.channelId)
    ];

    // get optional parent channel (required for managing shared items)
    if (item.parentChannelId) {
      const parentChannel = this.appMeta.getChannel(item.parentChannelId);

      if (parentChannel) {
        tokens.push(parentChannel);
      }
    }

    return AbstractApiService.getTokenHeaders(...tokens);
  }

  public getChannelItemsByToken(library: AppLibrary, channel: AppChannel): Observable<T[]> {
    const headers = AbstractApiService.getTokenHeaders(library, channel);
    return this.getChannelItems(library.id, channel.id, headers);
  }

  /**
   * Request admin access to the resource
   * @param libraryId
   * @param channelId
   */
  public getChannelItemsAsAdmin(libraryId: Guid, channelId: Guid): Observable<T[]> {
    const params = new HttpParams()
      .set('admin', 'true');

    return this.getChannelItems(libraryId, channelId, null, params);
  }

  public getChannelItems(libraryId: Guid, channelId: Guid, headers: HttpHeaders = null,  params: HttpParams = new HttpParams()): Observable<T[]> {
    params = (params || new HttpParams())
      .set('libraryId', GuidTools.stripDashes(libraryId))
      .set('channelId', GuidTools.stripDashes(channelId));

    const args: IDataCollectionArgs = {
      params: params,
      headers: headers
    };

    return super.getList(args);
  }

  getParentItem(item: T): Observable<T> {
    if (!this.appMeta) {
      return throwError('Channel data unavailable');
    }

    const parentChannel = this.appMeta.getChannel(item.parentChannelId);

    if (!parentChannel) {
      return throwError('Failed to resolve parent channel');
    }

    const library = this.appMeta.getLibrary((item.libraryId));

    const headers = AbstractApiService.getTokenHeaders(library, parentChannel);

    return this.getItem(item.parentId, {headers});
  }

  // TODO: add expiry check here
  public checkChannelAccess(item: T, requiredAccess: ChannelAccess): boolean {
    if (!this.appMeta || !item || !item.channelId) {
      return false;
    }

    // check that library is present in user list
    const library = this.appMeta.getLibrary((item.libraryId));
    if (!library) {
      console.warn('Library not found', item.libraryId);
      return false;
    }

    const channel = this.appMeta.getChannel(item.channelId);
    if (!channel) {
      console.warn('Channel not found', item.channelId);
      return false;
    }

    return EnumHelper.hasFlag(channel.access, requiredAccess);
  }

  public canDelete(item: T): boolean {
    return this.checkChannelAccess(item, ChannelAccess.Delete);
  }

  public canEdit(item: T): boolean {
    return this.checkChannelAccess(item, ChannelAccess.Update);
  }

  protected getConfirmDeleteMessage(item: T): string {

    if (item.channelType === ChannelType.Global) {
      return `Are you sure you want to unpublish this Snippet?`;
    }

    if (item.refs && item.refs.length) {
      return `Are you sure you want to delete this ${this.getName()} and the associated shared versions?`;
    }

    return 'Are you sure you want to delete this Snippet?';
  }

  protected onDelete(item: T) {

    super.onDelete(item);

    this.invalidateChannel(item.channelId);

    // Snippet Ref : invalidate parent channel
    if (item.parentChannelId) {
      this.invalidateChannel(item.parentChannelId);
    }
  }

  invalidateChannel(channelId: Guid) {
    console.log('Channel invalidated', channelId);
    this.channelInvalidatedSource.next(channelId);
  }

  private getSharedItemHeaders(item: T, sharedChannel: AppChannel): HttpHeaders {

    if (!item || !item.id) {
      throw new Error('Invalid item');
    }

    if (!sharedChannel) {
      throw new Error('Invalid shared channel');
    }

    return this.getItemHeaders(item)
      .append(RESOURCE_TOKEN_HEADER, sharedChannel.token);
  }

  private getSharedItemUrl(parentItem: T, sharedChannel: AppChannel = null): string {
    let uri = this.buildItemUrl(parentItem.id) + '/refs';

    if (sharedChannel) {
      uri += '/' + sharedChannel.id;
    }

    return uri;
  }

  /**
   * Share the item to the given channel and return the newly created ref
   * @param {T} parentItem
   * @param {AppChannel} sharedChannel
   * @param {ISaveArgs} args additional arguments controlling save process
   * @returns {Promise<T extends ChannelItem>}
   */
  public createSharedItem(parentItem: T, sharedChannel: AppChannel, args?: ISaveArgs): Promise<ShareRef> {

    args = args || {toast: true, invalidate: true};

    const shareRequest: IChannelShareRequest = {
      notes: null
    };

    const url = this.getSharedItemUrl(parentItem, sharedChannel);

    const requestArgs: IApiRequestArgs = {
      headers: this.getSharedItemHeaders(parentItem, sharedChannel),
      body: shareRequest
    };

    return lastValueFrom(this.apiClient.post<ShareRef>(url, requestArgs).pipe(
      tap(
        (res: ApiResponse) => {
          if (args.toast) {
            this.toast.success('OK', res.message || `${this.getName()} shared successfully`);
          }

          if (args.invalidate) {
            this.invalidateChannel(sharedChannel.id);
            this.invalidateChannel(parentItem.channelId);
          }
      }, (err: ApiResponse) => {
          console.error(err);
          this.toast.error('Error', err.message);
        }),
      map(o => o.getData<ShareRef>())
    ));
  }

  protected onSave(item: T, args: ISaveArgs): void {
    super.onSave(item, args);

    if (args.invalidate) {
      this.invalidateChannel(item.channelId);
    }
  }

  getSharedItem(parentItem: T, sharedChannel: AppChannel): Observable<T> {

    const headers = this.getSharedItemHeaders(parentItem, sharedChannel);
    const url = this.getSharedItemUrl(parentItem, sharedChannel);

    const args: IApiRequestArgs = {
      serializer: this.serializer,
      headers: this.getSharedItemHeaders(parentItem, sharedChannel)
    };

    return this.apiClient.get<T>(url, args).pipe(
      map(o => o.getData<T>())
    );
  }

  deleteSharedItem(parentItem: T, sharedChannel: AppChannel): Promise<boolean> {

    const headers = this.getSharedItemHeaders(parentItem, sharedChannel);
    const url = this.getSharedItemUrl(parentItem, sharedChannel);

    const args: IApiRequestArgs = {
      serializer: this.serializer,
      headers: headers
    };

    return lastValueFrom(this.apiClient.delete(url, headers).pipe(
      tap(() => {
        this.toast.info('Deleted', 'Shared item deleted');

        this.invalidateChannel(sharedChannel.id);
        this.invalidateChannel(parentItem.channelId);

      }, err => {
        this.toast.error('Errror', 'Failed to delete shared item');
        console.log(err);
      })
    ));
  }

  private getChannel(channelId: Guid): AppChannel {
    if (this.appMeta == null) {
      return null;
    }

    return this.appMeta.getChannel(channelId);
  }
}
