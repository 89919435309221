import { Component, Input } from '@angular/core';


@Component({
  selector: 'aa-form-info',
  templateUrl: './form-info.component.html',
  host: {
    'class': 'form-control'
  }
})
export class FormInfoComponent {
  @Input() label: string;
}
