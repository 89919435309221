import { Injectable, Injector } from '@angular/core';

import { Observable, of } from 'rxjs';

// common
import { TagType } from '@aa/common';

import { AppLibrary, Tag } from '../models';
import { TagSerializer } from '../serializers';

import { AbstractApiService } from './abstract-api.service';
import { LibraryResolverService } from './library-resolver.service';


@Injectable({providedIn: 'root'})
export class TagApiService extends AbstractApiService<Tag> {

  /**
   * Only Topic should be used for snippets now, but the others remain for backwards compatiblity
   */
  public static readonly snippetTagTypes: TagType[] = [
    TagType.Topic
  ];

  constructor (injector: Injector, private readonly libraryResolver: LibraryResolverService) {
    super(injector, 'tags', new TagSerializer());
  }

  getLibraryTagsById(libraryId: Guid): Observable<Tag[]> {

    const library = this.libraryResolver.getLibrary(libraryId);

    if (!library) {
      console.warn('Failed to resolve library', libraryId);
      return of([]);
    }

    return this.getLibraryTags(library);
  }

  getLibraryTags(library: AppLibrary): Observable<Tag[]> {
    const args = AbstractApiService.getLibraryArgs(library);
    return super.getList(args);
  }
}


