import { AbstractFormatter } from './abstract_formatter.class';
import { ChannelType } from '../models';

export class ChannelTypeFormatter extends AbstractFormatter<ChannelType> {

  public static readonly nameMap = new Map<ChannelType, string>([
    [ChannelType.None, 'None'],
    [ChannelType.Private, 'Private'],
    [ChannelType.Review, 'Review'],
    [ChannelType.Group, 'Group'],
    [ChannelType.Team, 'Team'],
    [ChannelType.Global, 'Global'],
    [ChannelType.Localisation, 'Localisation']
  ]);

  private static readonly descriptionMap = new Map<ChannelType, string>([
    [ChannelType.None, 'None'],
    [ChannelType.Private, 'Private channel'],
    [ChannelType.Review, 'Review channel'],
    [ChannelType.Group, 'Group channel'],
    [ChannelType.Team, 'Team channel'],
    [ChannelType.Global, 'Global channel'],
    [ChannelType.Localisation, 'Localisation channel']
  ]);

  static getChannelTypes(): ChannelType[] {
    return [
      ChannelType.Private,
      ChannelType.Team,
      ChannelType.Group,
      ChannelType.Global,
      ChannelType.Review,
      ChannelType.Localisation
    ];
  }

  static getNonSharedChannelTypes(): ChannelType[] {
    return [
      ChannelType.Private, ChannelType.Team
    ];
  }

  static getSharedChannelTypes(): ChannelType[] {
    return [
      ChannelType.Group, ChannelType.Global, ChannelType.Review
    ];
  }

  public static formatName(value: ChannelType): string {
    if (!value) {
      return '-';
    }

    const name = ChannelTypeFormatter.nameMap.get(value);
    return name || value.toString();
  }

  public static formatDescription(value: ChannelType): string {
    if (!value) {
      return '-';
    }

    const description = ChannelTypeFormatter.descriptionMap.get(value);
    return description || ChannelTypeFormatter.formatName(value);
  }

  protected formatItemInternal(value: ChannelType): string {
    return ChannelTypeFormatter.formatName(value);

  }
}
