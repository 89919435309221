// external
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// local
import { AuthService } from './auth.service';
import { INotification, NotificationType } from '../models';

export interface IAppStorageEvent {
  key: string;
  value?: any;
  storageType: string;
}

@Injectable({providedIn: 'root'})
export class AppStorageService {
  public isSupported: boolean = false;

  private readonly removeItems = new Subject<IAppStorageEvent>();
  public readonly removeItems$ = this.removeItems.asObservable();

  private readonly setItems = new Subject<IAppStorageEvent>();
  public readonly setItems$ = this.setItems.asObservable();

  private storageType: 'sessionStorage' | 'localStorage' = 'localStorage';
  private webStorage: Storage;
  private prefix: string = null;
  private isEnabled: boolean = false;
  private warningFlag: boolean = false;


  private readonly notificationSource = new Subject<INotification>();
  public readonly notification$ = this.notificationSource.asObservable();

  constructor (auth: AuthService) {
    this.isSupported = this.checkSupport();

    // set prefix using currently logged in usernpm start
    auth.user$.subscribe(user => {
      this.prefix = user ? user.id + '.' : null;
      this.isEnabled = this.prefix && this.isSupported;

      if (user && !this.isSupported && !this.warningFlag) {
        this.warningFlag = true; // only show warning once

        const msg = 'Local storage is not enabled in your browser.<br>Settings and document drafts will not be saved.';
        this.notify('Storage Unavailable', msg);
      }

      // this.notify('Debug', "Notification test. Delete this if happy");
    });
  }

  private notify(title: string, message: string) {
    this.notificationSource.next({
      type: NotificationType.Alert,
      title,
      message
    });
  }

  public clear(): boolean {

    if (!this.isEnabled) {
      return false;
    }

    const keys = this.getKeys();
    return this.remove(...keys);
  }

  public deriveKey(key: string): string {
    if (!this.prefix) {
      throw Error('Prefix not set');
    }

    return `${this.prefix}${key}`;
  }
  public get<T>(key: string): T {

    const item = this.getString(key);

    try {
      return JSON.parse(item);
    } catch (e) {
      console.warn('get failed', key, item);
      return null;
    }
  }

  public getString(key: string): string {
    if (!this.isEnabled) {
      return null;
    }

    const item = this.webStorage ? this.webStorage.getItem(this.deriveKey(key)) : null;
    if (!item || item === 'null') {
      return null;
    }

    return item;
  }

  public getKeys(): Array<string> {
    if (!this.isEnabled) {
      return [];
    }

    const prefixLength = this.prefix.length;

    const keys: Array<string> = [];
    for (const key in this.webStorage) {
      // Only return keys that are for this app
      if (key.substr(0, prefixLength) === this.prefix) {
        try {
          keys.push(key.substr(prefixLength));
        } catch (e) {
          return [];
        }
      }
    }
    return keys;
  }

  public length (): number {
    let count = 0;
    const storage = this.webStorage;
    for (let i = 0; i < storage.length; i++) {
      if (storage.key(i).indexOf(this.prefix) === 0) {
        count++;
      }
    }
    return count;
  }

  public remove (...keys: Array<string>): boolean {
    if (!this.isEnabled) {
      return false;
    }

    let result = true;

    keys.forEach((key: string) => {
      try {
        this.webStorage.removeItem(this.deriveKey(key));
        this.removeItems.next({
          key: key,
          storageType: this.storageType
        });
      } catch (e) {
        console.warn('Failed to remove item');
        result = false;
      }
    });
    return result;
  }

  public set<T> (key: string, value: T): boolean {
    if (!this.isEnabled) {
      return false;
    }

    const strValue = value ? JSON.stringify(value) : null;
    return this.setString(key, strValue);
  }

  public setString (key: string, value: string): boolean {
    if (!this.isEnabled) {
      return false;
    }

    // Let's convert `undefined` values to `null` to get the value consistent
    if (value === undefined) {
      value = null;
    }

    try {
      const storageKey = this.deriveKey(key);
      if (this.webStorage) {
        this.webStorage.setItem(storageKey, value);
      }

      this.setItems.next({
        key: key,
        value: value,
        storageType: this.storageType
      });

    } catch (e) {
      console.warn('setString failed', e);
      return false;
    }
    return true;
  }

  private checkSupport (): boolean {
    try {

      const supported = this.storageType in window
        && window[this.storageType] !== null;

      if (supported) {
        this.webStorage = window[this.storageType];

        // When Safari (OS X or iOS) is in private browsing mode, it
        // appears as though localStorage is available, but trying to
        // call .setItem throws an exception.
        //
        // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made
        // to add something to storage that exceeded the quota."
        const key = `__${Math.round(Math.random() * 1e7)}`;
        this.webStorage.setItem(key, '');
        this.webStorage.removeItem(key);
      }

      return supported;
    } catch (e) {
      console.warn('localStorage not supported', e);
      return false;
    }
  }
}
