
<a [matMenuTriggerFor]="libraryMenu" class="dropdown-toggle">{{currentLibrary?.name ||
  "Loading..."}}</a>

<aa-dropdown #libraryMenu>
  <h6 class="dropdown-header">Library Select</h6>

  <div mat-menu-item *ngFor="let library of meta?.libraryList" (click)="setLibrary(library.id)">
    <mat-radio-button class="mat-primary" [value]="library.id" [checked]="library.id === currentLibrary?.id">
      {{library.name}}
    </mat-radio-button>
  </div>
  <div class="dropdown-divider"></div>
  <aa-dropdown-cmd [cmd]="refreshCmd"></aa-dropdown-cmd>
</aa-dropdown>

