import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// components
import { CopyrightComponent } from './components/copyright.component';
import { AdviceFooterComponent } from './components/advice-footer.component';

import { DetailsDirective } from './directives/details.directive';
import { ImageSrcPipe } from './directives/image-src.pipe';

const COMPONENTS = [AdviceFooterComponent, CopyrightComponent, DetailsDirective, ImageSrcPipe];

@NgModule({
  imports: [ CommonModule, RouterModule, HttpClientModule,
  ],
  declarations: COMPONENTS,
  exports: [
    ...COMPONENTS,
    HttpClientModule
  ]
})
export class AdviceAidCommonModule { }

export { AdviceFooterComponent, CopyrightComponent, DetailsDirective, ImageSrcPipe };
