// external
import { Component, Input, HostListener, OnInit } from '@angular/core';

// core
import { Command } from '@aa/app/ui';

/**
 * Used to render a command instance in a dropdown menu
 */
@Component({
  templateUrl: './dropdown-cmd.component.html',
  styleUrls: ['dropdown-cmd.component.css'],
  selector: 'aa-dropdown-cmd'
})
export class DropdownCmdComponent implements OnInit {
  @Input() cmd: Command;
  @Input() args: any = null;
  @Input() label: string;
  @Input() stopPropagation: boolean = false;

  @HostListener('click', ['$event']) onClick(evt: MouseEvent) {
    this.cmd.execute(this.args);

    if (this.stopPropagation) {
      evt.stopPropagation();
    }
  }

  public get tooltip(): string {
    if (this.cmd.enabled) {
      return this.cmd.tooltip;
    }

    return this.cmd.tooltipDisabled;
  }

  ngOnInit() {
    if (!this.cmd) {
      throw new Error('Invalid command passed to DropdownItemComponent');
    }
    this.label = this.label || this.cmd.name;
  }
}
