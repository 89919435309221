// external
import { AbstractControl, FormGroup } from '@angular/forms';

// common
import { ApiResponse, SubscriberComponent } from '@aa/common';

// local
import { FormUtil } from './form_util.class';

export abstract class AbstractForm<TControl extends {
  [K in keyof TControl]: AbstractControl
} = any> extends SubscriberComponent {

  private _error: string = null;
  private _errorDetails: string[] = null;
  private _loading: boolean = false;
  private _title: string = 'Loading...';

  protected constructor(public readonly form: FormGroup<TControl>) {
    super();

    if (!form) {
      throw Error('Form is not defined');
    }
  }

  public get title(): string {
    return this._title;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public get error(): string {
    return this._error;
  }

  public get errorDetails(): string[] {
    return this._errorDetails;
  }

  public get dirty(): boolean {
    return this.form && this.form.dirty;
  }

  /**
   * Submit the current form
   */
  public abstract submit();

  /**
   * Start and async operation (loading => true)
   */
  protected startAsync() {
    this._loading = true;
  }

  /**
   * End an ansync operation (loading => false)
   */
  protected endAsync() {
    this._loading = false;
  }

  protected setTitle(title: string) {
    this._title = title;
  }

  protected setError(error: string|ApiResponse) {

    if (!error) {
      this.clearError();
      return;
    }

    const errorMessage = error.toString();

    if (error instanceof ApiResponse) {

      this._errorDetails = (error as ApiResponse).getErrorList();
    }

    this._error = errorMessage;

  }

  protected clearError() {
    this._error = null;
    this._errorDetails = null;
  }

  public clearDirtyFlag() {
    this.form.markAsPristine();
    this.clearError();
  }

  public setDirtyFlag() {
    if (!this.form) {
      return;
    }

    this.form.markAsDirty();
    this.clearError();
    console.log('form marked as dirty');
  }

  protected checkForm(): boolean {

    const errors = FormUtil.checkForm(this.form);
    if (errors.length) {
      this.setError('Form is invalid');
      return false;
    }

    return true;
  }

  protected patchValue(item: any) {
    if (!this.form) {
      return;
    }

    FormUtil.patchValue(this.form, item);
  }
}
