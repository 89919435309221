/**
 * Created by Tom Makin on 15/12/2016.
 */


import { Component, Input } from '@angular/core';

@Component({
  selector: 'aa-spinner',
  template: `<i class="fa fa-spinner fa-spin fa-fw" [ngClass]="{'fa-2x':large}"></i>`,
  styles: [':host {padding: 2px; display: inline-block; vertical-align: middle; }']
})
export class SpinnerComponent {
  @Input() large: boolean = false;
}
