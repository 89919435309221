import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { VariableType } from '@aa/common';
import { IDeleteArgs, IVariableLocalisation, Variable } from '../models';

import { VariableSerializer } from '../serializers';
import { DataCollection } from '../classes';

import { AbstractApiService } from './abstract-api.service';
import { LibraryResolverService } from './library-resolver.service';
import { TeamApiService } from './team-api.service';
import { AbstractTaggedItemCache } from './abstract-tagged-item-cache.service';
import { TagCacheService } from './tag-cache.service';

@Injectable({providedIn: 'root'})
export class VariableApiService extends AbstractApiService<Variable> {

  private teamMap: Map<Guid, string>;

  constructor (injector: Injector, private readonly libraryResolver: LibraryResolverService, teams: TeamApiService) {
    super(injector, 'variables', new VariableSerializer());

    teams.getList().subscribe(data => {
      if (data) {
        this.teamMap = new Map<Guid, string>(
          data.map(x => [x.id, x.name] as [string, string])
        );
      }
    });
  }

  public deleteItem(item: Variable): Observable<boolean> {
    const subject = new Subject<boolean>();

    this.getChildCount(item.id).pipe(switchMap(count => {

      const args: IDeleteArgs = {
        confirm: true,
        toast: true
      };

      if (count > 0) {
        args.confirmMessage = `Variable ${item.key} is referenced by ${count} teams.<br>Are you sure want to delete it?`;
        args.confirmKey = item.key;
        args.force = true;
      }

      return super.deleteItem(item, args);
    })).subscribe(subject);

    return subject.asObservable();
  }

  private resolveTeamName(item: Variable) {
    if (!item) {
      return '';
    }
    return this.teamMap?.get(item.teamId) || item.teamId;
  }

  public displayUsageStats(item: Variable) {

    const buildMessage = (children: Variable[]) => {
      const count = children.length;

      if (!count) {
        return `Variable ${item.key} has not been localised by any teams`;
      }

      let msg = `Variable ${item.key} has been localised by ${count} teams.`;

      const displayCount = Math.min(count, 10);

      msg += '<ul>';
      for (let i = 0 ; i < displayCount; i++) {
        const name = this.resolveTeamName(children[i]);
        msg += `<li>${name}</li>`;
      }
      const remainder = (count - displayCount);
      if (remainder > 0) {
        msg += `<br> ... plus ${remainder} more`;
      }
      msg += '</ul>';

      return msg;
    };

    return this.getChildren(item.id).subscribe(children => {
      const content = buildMessage(children);
      this.confirmService.showInfo({title: 'Variable Usage Stats', content});
    });
  }

  public getChildren(id: Guid): Observable<Variable[]>  {
    const url = super.buildItemUrl(id) + '/children';
    return this.apiClient.get<Variable[]>(url).pipe(
      map(res => res.getData<Variable[]>()),
    );
  }

  public getChildCount(id: Guid): Observable<number>  {
    const url = super.buildItemUrl(id) + '/children/count';
    return this.apiClient.get<number>(url).pipe(
      map(res => res.getData<number>()),
    );
  }

  public saveLocalisation(data: IVariableLocalisation): Observable<Variable> {

    const url = this.buildCollectionUrl() + '/localisation';

    const requestArgs = {
      body: data
    };

    return this.apiClient.post(url, requestArgs).pipe(
      map(res => res.getData<Variable>())
    );
  }

  public getTeamView(libraryId: Guid, teamId: Guid): DataCollection<Variable> {

    const params = new HttpParams()
      .set('libraryId', libraryId)
      .set('teamId', teamId);

    return new DataCollection(this, {params});
  }
}

/**
 * Stateful service for caching organisation variables associated with current library
 */
@Injectable({providedIn: 'root'})
export class OrganisationVariableCacheService extends AbstractTaggedItemCache<Variable> {

  protected constructor(tagCache: TagCacheService, apiService: VariableApiService) {
    super(tagCache, apiService);
  }

  filterItems(items: Variable[]) {
    return items.filter(o => o.varType === VariableType.Organisation);
  }
}

