import { IFactory, Serializer } from '@aa/common';

import { AppSettings } from '../models';

export class AppSettingsSerializer extends Serializer<AppSettings> {

  public static instance = new AppSettingsSerializer();

  public getType(): IFactory<AppSettings> {
    return AppSettings;
  }

  public getName() {
    return 'AppSettings';
  }
}
