import { EnumHelper } from '@aa/common';

import { LocalisationFlag } from '../models';
import { AbstractFormatter } from './abstract_formatter.class';

export class LocalisationFlagFormatter extends AbstractFormatter<LocalisationFlag> {

  public static formatName(value: LocalisationFlag): string {

    if (!value) {
      return 'None';
    }

    let result = '';

    if (EnumHelper.hasFlag(value, LocalisationFlag.Optional)) {
      result = 'Optional';
    } else if (EnumHelper.hasFlag(value, LocalisationFlag.Recommended)) {
      result = 'Recommended';
    } else if (EnumHelper.hasFlag(value, LocalisationFlag.Required)) {
      result = 'Required';
    }

    // add tick to end if done flag is set
    if (EnumHelper.hasFlag(value, LocalisationFlag.Done)) {
      result += ' ✓';
    }

    return result || `Unknown(${value})`;
  }

  protected formatItemInternal(value: LocalisationFlag): string {
    return LocalisationFlagFormatter.formatName(value);
  }
}
