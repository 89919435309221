import { ITag, ITagMap, Item, TagType } from '@aa/common';

import { AppLibrary, Tag } from '../models';

import { TagUtil } from './tag-util.class';

export class TagData<T extends ITag = ITag> implements ITagMap {

  private readonly tagMap: Map<Guid, T>;
  private readonly keywordMap: Map<Guid, string[]> = new Map<Guid, string[]>();

  constructor(public readonly tags: T[]) {

    this.tagMap = Item.createIdMap(tags);

    // build keyword map
    for (const tag of tags) {

      // concat keywords + tag name for search
      let keywordsCsv = tag.name;
      if (tag.keywords) {
        keywordsCsv += ',' + tag.keywords;
      }
      const normalizedKeywords = TagUtil.normalizeKeywordList(keywordsCsv);

      if (normalizedKeywords && normalizedKeywords.length) {
        this.keywordMap.set(tag.id, normalizedKeywords);
      }
    }
  }

  /**
   * Find list of tags by id. Any unresolved tags are skipped
   * @param {Guid[]} ids
   * @returns {Tag[]}
   */
  public findTags(ids: Guid[]): Tag[] {

    const result = [];

    if (!ids || !ids.length) {
      return result;
    }

    for (const id of ids) {
      const tag = this.findTag(id);
      if (tag) {
        result.push(tag);
      }
    }

    return result;
  }

  public findTag(id: Guid): T {
    if (!id) {
      return null;
    }
    return this.tagMap.get(id);
  }

  public findKeywords(id: Guid): string[] {
    if (!id) {
      return null;
    }
    return this.keywordMap.get(id);
  }

  public compileKeywords(tagIds: Guid[]): string[] {
    if (!tagIds || !tagIds.length) {
      return null;
    }

    const result: string[] = [];
    for (const id of tagIds) {
      const keywords: string[] = this.findKeywords(id);

      if (!keywords) {
        continue;
      }

      for (const keyword of keywords) {
        if (result.includes(keyword)) {
          continue;
        }

        result.push(keyword);
      }
    }

    return result;
  }
}

/**
 * Convenience class for storing a library and associated tags from that library
 */
export class LibraryTagData extends TagData<Tag> {

  public readonly visibleTags: Tag[];

  constructor(readonly library: AppLibrary, tags: Tag[]) {
    super(tags);

    this.visibleTags = tags.filter(o => o != null && o.tagType !== TagType.Group);
  }

  public findTagsByType(tagType: TagType): Tag[] {
    return this.tags.filter(o => o.tagType === tagType);
  }
}


