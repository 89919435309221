import { Injectable, Injector } from '@angular/core';

import { SelfServConfig } from '../models';
import { SelfServConfigSerializer } from '../serializers';

import { AbstractApiService } from './abstract-api.service';


@Injectable({providedIn: 'root'})
export class SelfServConfigApiService extends AbstractApiService<SelfServConfig> {
  constructor (injector: Injector) {
    super(injector, 'selfserv/configs', new SelfServConfigSerializer());
  }

  public purge(libraryId: Guid): Promise<boolean> {
    if (!libraryId) {
      return Promise.reject('Invalid libraryId');
    }

    const url = this.generateApiUrl(`selfserv/meta?libraryId=${libraryId}`);
    return this.apiClient.delete(url).toPromise();
  }
}
