import { Role } from '@aa/common';

import { AbstractFormatter } from './abstract_formatter.class';

export class RoleFormatter extends AbstractFormatter<Role> {

  public static default: RoleFormatter = new RoleFormatter();

  private static readonly nameMap = new Map<Role, string>([
    [Role.Advisor, 'Advisor'],
    [Role.TeamAuthor, 'Team Author'],
    [Role.TeamAdmin, 'Team Admin'],
    [Role.Publisher, 'Publisher'],
    [Role.Tester, 'Tester'],
    [Role.FreeTrial, 'Free Trial'],
    [Role.Compose, 'Compose'],
    [Role.Workspace, 'Workspace'],

    [Role.DemoTeam, 'Demo Team'],
    [Role.SiteAdmin, 'Site Admin'],
    [Role.Master, 'Master']
  ]);

  private static readonly descriptionMap = new Map<Role, string>([
    [Role.Advisor, 'Provides access to the dashboard'],
    [Role.TeamAuthor, 'Allows user to create snippets for the team + content localisation'],
    [Role.TeamAdmin, 'Team administrator role + content localisation'],
    [Role.Publisher, 'Allows user to publish snippets to global team channel'],

    [Role.Tester, 'Specialised role for app testers'],
    [Role.FreeTrial, 'Indicates user is on a free trial'],
    [Role.Compose, 'Allows access to the compose page'],
    [Role.Workspace, 'Allows access to the workspace page'],

    [Role.DemoTeam, 'Team role indicating that team is for demo/testing only'],
    [Role.SiteAdmin, 'Site administrator role'],
    [Role.Master, 'Master of the universe']
  ]);

  public static formatName(value: Role): string {
    if (!value) {
      return '-';
    }

    const name = RoleFormatter.nameMap.get(value);
    return name || `Role ${value}`;
  }

  public static formatDescription(value: Role): string {
    if (!value) {
      return '-';
    }

    const description = RoleFormatter.descriptionMap.get(value);
    return description || RoleFormatter.formatName(value);
  }

  protected formatItemInternal(value: Role): string {
    return RoleFormatter.formatName(value);
  }
}
