import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';

// common
import { Role, AuthService } from '@aa/common';

// local
import {
  TrialExpiryDialogComponent
} from './trial-expiry-dialog.component';
import { Subscription } from 'rxjs';

@Injectable({providedIn: 'root'})
export class TrialExpiryDialogService implements OnDestroy {

  // 3 minutes

  constructor(private dialog: MatDialog,
              private readonly auth: AuthService) {

    // check expiry when ever user is updated
    this.subscription = auth.user$.subscribe(user => this.checkDialog());

    this.checkDialog();
  }
  public static lastShown: number = null;

  private dialogRef: MatDialogRef<TrialExpiryDialogComponent> = null;

  private readonly showInterval: number = 3 * 60 * 1000;
  private readonly subscription: Subscription;

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkDialog() {

    if (!this.auth.hasRequiredRole(Role.FreeTrial) || this.auth.user.daysRemaining > 0) {
      this.close();
      return;
    }

    // check time since last show
    const now = new Date().getTime();
    if (now - TrialExpiryDialogService.lastShown < this.showInterval) {
      return;
    }

    this.open();
  }

  public close() {
    if (!this.dialogRef) {
      return;
    }

    this.dialogRef.close();
  }

  public open<T>() {

    // check if already open
    if (this.dialogRef) {
      return;
    }

    const config: MatDialogConfig = {
      width: '500px'
    };

    this.dialogRef = this.dialog.open(TrialExpiryDialogComponent, config);
    this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
        TrialExpiryDialogService.lastShown = new Date().getTime(); // save date on close
    });
  }
}
