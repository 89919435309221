import { TextEditor, TextEditorSettings } from './text_editor.class';

type CanExecuteFunc = (selection: string) => boolean;

interface CommandInfo {
  name: string;
  canExecute: CanExecuteFunc;
}

export class CkTextEditor extends TextEditor {

  static initializedStatic = false;

  static noToolbarConfig: CKEDITOR.config = {
    removePlugins: 'toolbar scayt',
    allowedContent: 'p h1 h2 strong em; a[!href]; img[!src,width,height];',
    startupFocus: false,
    disableNativeSpellChecker: false
  };

  public editor: CKEDITOR.editor = null;

  private readonly commands: CommandInfo[] = [];

  static buildConfig(settings: TextEditorSettings): CKEDITOR.config {
    if (!settings.showToolbar) {
      return CkTextEditor.noToolbarConfig;
    }

    const config: CKEDITOR.config = {
      toolbarGroups: [
        {name: 'clipboard', groups: ['clipboard', 'undo']},
        {name: 'editing', groups: ['find', 'selection', 'editing']},
        {name: 'links', groups: ['links']},
        {name: 'insert', groups: ['insert']},
        // {name: 'forms', groups: ['forms']},
        {name: 'tools', groups: ['tools']},
        {name: 'document', groups: ['mode', 'document', 'doctools']},
        {name: 'others', groups: ['others']},
        // {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
        {name: 'basicstyles', groups: ['basicstyles']}, // cleanup not necessary
        {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
        {name: 'styles', groups: ['styles']},
        {name: 'colors', groups: ['colors']}
      ],

      disableNativeSpellChecker: false,

      dataIndentationChars: '', // disable indentation

      title: false,

      forcePasteAsPlainText: true,

      removePlugins: 'scayt, dragdrop, showborders',

      baseFloatZIndex: 1100,

      allowedContent: true,

      removeButtons: 'Cut,Copy,SpecialChar,Underline,Subscript,Superscript,About,PasteFromWord,Anchor,Image,HorizontalRule,Maximize,Strike,Indent,Blockquote,Styles',

      format_tags: 'p;h1;h2;h3',

      startupFocus: false
    };

    if (settings.toolbarContainer) {

      config.extraPlugins = 'sharedspace';

      config.removePlugins += ',floatingspace,resize';

      config.sharedSpaces = {
        top: settings.toolbarContainer
        // top: 'toolbar-container'
      };
    }

    if (!settings.tablesEnabled) {
      config.removeButtons += ',Table';
    }


    return config;
  }

  static initStatic(): boolean {
    if (CkTextEditor.initializedStatic) {
      return true;
    }

    if (!CKEDITOR) {
      console.error('CKEditor script not loaded');
      return false;
    }

    // static config
    CKEDITOR.disableAutoInline = true;

    // Set defaults for tables
    CKEDITOR.on('dialogDefinition', (evt) => {
      // Take the dialog name and its definition from the event
      // data.
      const dialogName = evt.data.name;
      const dialogDefinition = evt.data.definition;

      // Check if the definition is from the dialog we're
      // interested on (the "Table" dialog).
      if (dialogName === 'table') {

        dialogDefinition.minWidth = 400;
        dialogDefinition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;

        // Get a reference to the "Table Info" tab.
        const info = dialogDefinition.getContents('info');

        // see table plugin source code for variable names
        // https://github.com/ckeditor/ckeditor-dev/blob/master/plugins/table/dialogs/table.js
        info.get('txtWidth')['default'] = '100%';       // Set default width to 100%
        info.get('txtBorder')['default'] = '1';
        info.get('txtCellPad')['default'] = '0';
        info.get('txtCellSpace')['default'] = '0';
      }
    });


    CkTextEditor.initializedStatic = true;
    return true;
  }


  constructor(settings: TextEditorSettings) {
    super(settings);
    CkTextEditor.initStatic();
  }

  init(el: HTMLElement) {
    if (this.editor) {
      throw new Error('CkEditor already initialized');
    }

    const config = CkTextEditor.buildConfig(this.settings);

    this.editor = CKEDITOR.inline(el, config);

    // listen for instanceReady event
    this.editor.on('instanceReady', (evt) => {

      // remove line break chars
      const processor = <any>evt.editor.dataProcessor;
      processor.writer.lineBreakChars = '';

      this.initContextMenu();

      this.initialized = true;
      super.notifyInit();
    });

    // CKEditor change event
    this.editor.on('change', (evt) => {
      super.notifyChange();
    });

    this.editor.on('paste', (evt) => {
      super.notifyPaste(evt.data.dataValue);
    });

    /*
    this.editor.on('selectionChange', (evt) => {
      this.notifySelectionChanged(evt);
    });
    */
  }

  /**
   * Helper util for selecting last element
   * @param el
   */
  placeCaretAtEnd(el: HTMLElement) {
    el.focus();
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  getData(): string {
    if (!this.editor) { return null; }
    return this.editor.getData();
  }

  setData(data: string): void {
    if (!this.editor) { return; }
    this.editor.setData(data);
  }

  getSelection(): string {
    if (!this.editor) { return null; }

    const range = this.editor.getSelection()?.getRanges()[0];
    if (!range) {
      return null;
    }

    // if range has zero length then select word under cursor
    if (range.startOffset === range.endOffset) {
      const word = this.getWordAtOffset(range.startContainer.getText(), range.startOffset);
      if (word) {
        return word;
      }
    }

    return <string>this.editor.getSelectedHtml(true);
  }

  getWordAtOffset(text: string, offset: number): string {
    if (!text) {
      return null;
    }
    const n = text.length;

    if (n < 2) {
      return null;
    }

    if (offset < 0) {
      offset = 0;
    } else if (offset > n) {
      offset = n;
    }

    // get start offset
    let start = offset;
    for (; start > 0; start--) {
      if (text[start] === ' ') {
        break;
      }
    }

    // get end offset
    let end = start + 1;
    for (; end < n; end++) {
      if (text[end] === ' ') {
        break;
      }
    }



    return text.substr(start, end - start + 1).trim();
  }

  /**
   * unwrap a dom node
   * https: // plainjs.com/javascript/manipulation/unwrap-a-dom-element-35/
   */
  unwrap(selector: string): boolean {
    if (!this.editor) {
      return false;
    }

    const nodeList: CKEDITOR.dom.nodeList = this.editor.element.find(selector);

    const count = nodeList.count();
    if (count === 0) {
      return false;
    }

    for (let i = 0; i < count; i++) {
      // now convert to native dom element
      const el: HTMLElement = nodeList.getItem(i).$;

      // get the element's parent node
      const parent = el.parentNode;

      // move all children out of the element
      while (el.firstChild) {
        parent.insertBefore(el.firstChild, el);
      }

      // remove the empty element
      parent.removeChild(el);
    }

    this.notifyChange();
    return true;
  }

  replaceSelection(html: string): boolean {
    if (!this.editor) {
      return false;
    }

    const sel: CKEDITOR.dom.selection = this.editor.getSelection();
    if (!sel) {
      return false;
    }

    this.editor.insertHtml(html);
    this.notifyChange();
    return true;
  }

  wrapSelection(html: string): boolean {
    if (!this.editor) {
      return false;
    }

    const sel: CKEDITOR.dom.selection = this.editor.getSelection();
    if (!sel) {
      return false;
    }

    const range: CKEDITOR.dom.range = sel.getRanges()[0];
    range.trim(); // remove whitespace

    const fragment: CKEDITOR.dom.documentFragment = range.extractContents();
    if (!fragment) {
      return false;
    }

    const container = CKEDITOR.dom.element.createFromHtml(html);
    fragment.appendTo(container);
    this.editor.insertElement(container);
    this.notifyChange();
    return true;
  }

  destroy() {
    super.destroy();

    if (!this.editor) {
      return;
    }

    const name = this.editor.name;

    this.editor.removeAllListeners();
    this.editor.destroy(true);
    this.editor = null;
    this.initialized = false;

    // we need to manually remove the ckeditor div instance
    const elementId = 'cke_' + name;
    const el = document.getElementById(elementId);

    if (el) {
      el.parentElement.removeChild(el);
    }
  }


  private addCommand(label: string, callback: () => boolean, canExecute?: CanExecuteFunc) {

    const count = this.commands.length;
    if (count === 0) {
      this.editor.addMenuGroup('myGroup');
    }

    const name = 'cmd_' + count;
    this.commands.push({name, canExecute});

    this.editor.addCommand(name, {
      exec: editor => {
        return callback();
      }
    });

    // see: http://docs.ckeditor.com/#!/guide/plugin_sdk_sample_2
    this.editor.addMenuItem(name, {
      label: label,
      order: 1,
      command: name,
      group: 'myGroup',
      icon: null
    });
  }

  private splitPara() {
    const sel = this.editor.getSelection();
    if (!sel) {
      return;
    }

    const cursor = sel.getRanges()[0];

    // get the container
    const root = this.editor.container;


    // split it
    const newElement = cursor.splitElement(root);

    // get the innerHTML from the native element
    const html = newElement.$.innerHTML;

    // now kill the new element
    newElement.remove(false);

    // finally trigger insertion of new data
    this.insertParagraph(html);
  }

  private initContextMenu() {
    if (!this.editor || !this.editor.contextMenu) {
      return;
    }
    this.editor.addMenuGroup('myGroup');

    /*
    const checkSelection: CanExecuteFunc = (sel: string): boolean => {
      return sel && sel.length > 5;
    };

    const checkNoSelection: CanExecuteFunc = (sel: string): boolean => {
      return !sel || sel.length === 0;
    };

    if (this.settings.createSnippetEnabled) {
      this.addCommand('Create Snippet', () => {
        super.createSnippet();
        return true;
      }, checkSelection);
    }

    if (this.settings.splitParaEnabled) {
      this.addCommand('Split Para', () => {
        this.splitPara();
        return true;
      }, checkNoSelection);
    }
    */

    if (this.commands.length === 0) {
      return;
    }

    // toggle visibility of context menu entries
    this.editor.contextMenu.addListener((element, selection) => {
      const sel = selection.getSelectedText();

      const state = {};
      for (const cmd of this.commands) {

        // check if command can execute
        if (cmd.canExecute && !cmd.canExecute(sel)) {
          continue;
        }

        state[cmd.name] = CKEDITOR.TRISTATE_OFF;
      }
      return state;
    });
  }
}
