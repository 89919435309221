// external
import { Pipe, PipeTransform } from '@angular/core';

import { Channel } from '../models';
import { ChannelIdFormatter } from '../classes';

/*
 * Formats a channel using name property, or falling back to ID if undefined
 * Usage:
 *   channel | channelName
 */
@Pipe({name: 'channelName'})
export class ChannelNamePipe implements PipeTransform {
  transform(channel: Channel): string {
    return ChannelIdFormatter.formatChannelName(channel);
  }
}
