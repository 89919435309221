<nav class="secondary" >

  <ul *ngIf="config; else noConfig">
    <li class="text">
      {{config.name}}
    </li>

    <li *ngIf="newItemCmd.enabled">
      <aa-command [cmd]="newItemCmd"></aa-command>
    </li>

    <li>
      <aa-command [cmd]="refreshCmd"></aa-command>
    </li>

    <li>
      <a #columnMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="columnMenu" class="dropdown-toggle">Columns</a>

      <aa-dropdown #columnMenu>

        <aa-dropdown-checkbox [checked]="!column.hidden" *ngFor="let column of config.columns" (click)="$event.stopPropagation();toggleColumnVisiblity(column);" [label]="column.title">
          {{column.title}}
        </aa-dropdown-checkbox>

        <div class="dropdown-divider"></div>
        <aa-dropdown-cmd [cmd]="resetColumnsCmd">
        </aa-dropdown-cmd>

      </aa-dropdown>
    </li>

    <li>
      <mat-form-field floatLabel="never" class="filter-input invert">
        <input matInput #filterInput (keyup)="updateFilter($event.target.value)" [value]="initialFilter" placeholder="Filter" name="filter4f2g" autocomplete="no">
        <button mat-button mat-icon-button matSuffix *ngIf="filterInput.value"  aria-label="Clear" (click)="updateFilter(null);filterInput.value='';">
          <i style="opacity: 0.75" [aaIcon]="closeIcon"></i>
        </button>
      </mat-form-field>
    </li>
  </ul>

  <ul class="right">
    <ng-content></ng-content>
  </ul>

  <ng-template #noConfig>
    <ul>
      <li class="text">
        Loading...
      </li>
    </ul>
  </ng-template>
</nav>


<div class="table-container" *ngIf="config">
  <aa-form-error [error]="error"></aa-form-error>

  <aa-table (cellClick)="onCellClick($event)"
            (rowEdit)="onRowEdit($event)"
            (rowDelete)="onRowDelete($event)"
            [filter]="filter"
            [selectedColumns]="selectedColumns"
            [items]="items" [config]="config">
  </aa-table>
</div>

