// external
import { FormGroup, Validators } from '@angular/forms';

// common
import { IFactory, Serializer } from '@aa/common';

// local
import { CustomValidators, FormConfigBuilder, IFormSerializer } from '../classes';
import { UserProfile } from '../models';

const fb = new FormConfigBuilder<UserProfile>([
  { key: 'email' },
  { key: 'firstName', validators: [Validators.required] },
  { key: 'lastName', validators: [Validators.required] },

  { key: 'jobTitle', validators: [Validators.required] },
  { key: 'phoneNumber', validators: [Validators.minLength(9), CustomValidators.phone] }
]);

export class UserProfileSerializer extends Serializer<UserProfile> implements IFormSerializer<UserProfile> {

  public getType(): IFactory<UserProfile> {
    return UserProfile;
  }

  public getName(): string {
    return 'User Profile';
  }

  public buildForm(): FormGroup {

    const group = fb.build();
    group.get('email').disable();
    return group;
  }
}
