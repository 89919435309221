import { TreeType } from '@aa/common';

import { LibraryItem } from './library-item.model';

export class Tree extends LibraryItem {

  type: TreeType;

  name: string;

  default: boolean;

  // Can contain {{VARIABLES}}
  startText: string;

  // Can contain {{VARIABLES}}
  contactText?: string;

  // No variables allowed
  disclaimer?: string;
}
