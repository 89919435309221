// external
import { Injectable, Injector } from '@angular/core';

// shared
import { Library } from '../models';
import { LibrarySerializer } from '../serializers';
import { AbstractApiService} from './abstract-api.service';

@Injectable({providedIn: 'root'})
export class LibraryApiService extends AbstractApiService<Library> {

  constructor (injector: Injector) {
    super(injector, 'libraries', new LibrarySerializer());
  }

  public getType() {
    return Library;
  }
}

