import { Snippet } from './snippet.model';

export interface IFeedbackDialogData {

  type?: FeedbackType;
  info?: string;
  snippet?: Snippet;
}

export enum FeedbackType {
  None = 0,
  AppFeatureIdea = 1,
  AppBugReport = 2,
  AppHelp = 3,
  AppAccountSetup = 4,

  EmptySearch = 9,

  SnippetTypo = 11,
  SnippetOutOfDate = 12,
  SnippetBadAdvice = 13,
  SnippetInappropriate = 14,

  Other = 99
}

/*
Data structure for submitting user activity reports to server
 */
export class FeedbackModel {

  version: string;

  feedbackType: FeedbackType;

  info: string;

  snippetId?: Guid;
}
