<aa-dialog (keyup.escape)="close()">

  <aa-dialog-title>Trial Expired</aa-dialog-title>

  <aa-dialog-content>
      <h2>Thank you for trying out AdviceAid!</h2>
      <br>
      <div>Your trial period has now ended.</div>
      <br>
      <div>Please contact support to upgrade your plan and continue providing your clients with tailored, quality advice, every time.</div>
      <br>
      <a mat-raised-button color="accent" href="mailTo:{{supportEmail}}">{{supportEmail}}</a>
  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button (click)="close()">
      OK
    </button>
  </aa-dialog-actions>
</aa-dialog>
