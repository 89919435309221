// external
import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

// local
import { Channel } from '../models';
import { AbstractApiService } from './abstract-api.service';
import { ChannelSerializer } from '../serializers';

@Injectable({providedIn: 'root'})
export class ChannelApiService extends AbstractApiService<Channel> {

  constructor (injector: Injector) {
    super(injector, 'channels', new ChannelSerializer());
  }

  public getType() {
    return Channel;
  }

  protected getConfirmDeleteMessage(item: Channel): string {
    return 'Are you sure you want to delete this Channel?';
  }

  getSubscribableChannels(): Observable<Channel[]> {

    const params = new HttpParams().append('subscribable', 'true');

    return this.getList({params});
  }
}

