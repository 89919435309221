// external
import { Pipe, PipeTransform } from '@angular/core';

// local
import { IUser } from '@aa/common';

/*
 * Formats a user name as "First" "Last"
 * Usage:
 *   user | userName
 */
@Pipe({name: 'userName'})
export class UserNamePipe implements PipeTransform {

  transform(user: IUser): string {

    if (!user) {
      return 'Invalid User';
    }

    let name = user.firstName || '';

    // append last name if defined
    if (user.lastName) {
      if (name.length > 0) {
        name += ' ';
      }
      name += user.lastName;
    }

    return name;
  }
}
