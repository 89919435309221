// lodash
import remove from 'lodash-es/remove';
import sortBy from 'lodash-es/sortBy';

// angular
import { Component, forwardRef, QueryList, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

// local
import { IListItem } from '../../models';
import { ListItemComponent } from './list-item.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  /*tslint:disable-next-line:no-use-before-declare*/
  useExisting: forwardRef(() => ListEditorComponent),
  multi: true
};

@Component({
  selector: 'aa-list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['./list-editor.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class ListEditorComponent implements ControlValueAccessor {

  public list: IListItem[] = [];

  @ViewChildren(ListItemComponent) items: QueryList<ListItemComponent>;

  public static validateString(item: string): boolean {
    // string is not empty and not just whitespace
    return (item && /\S/.test(item));
  }

  private onChange = (arg) => {};

  private onTouched = () => {};

  public onDrop(event: CdkDragDrop<IListItem[]>) {
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
    this.update();
  }

  public deleteItem(item: IListItem) {
    remove(this.list, item);
    this.update();
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    this.setList(value);
  }

  public registerOnChange(fn: (arg: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private update() {
    const output: string[] = [];
    for (const item of this.list) {
      if (!ListEditorComponent.validateString(item.data)) {
        continue;
      }

      output.push(item.data);
    }
    this.onChange(output);
    this.onTouched();
  }

  public addNewItem() {
    this.list.push(<IListItem>{
      data: ''
    });

    // set focus to late item after render
    setTimeout(() => {
      this.focusLastItem();
    }, 100);

    // Don't call update here. Wait for the paragraph changed event to trigger the update
  }

  public focusLastItem() {
    this.items.last.focus();
  }

  public sortItems() {
    this.list = sortBy(this.list, o => o.data);
    this.update();
  }

  private setList(list: string[]) {
    this.list.length = 0;

    if (!list || !list.length) {
      return;
    }

    for (const item of list) {
      if (!ListEditorComponent.validateString(item)) {
        continue;
      }

      this.list.push(<IListItem>{
        data: item
      });
    }
  }
}
