import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { IFactory, Serializer } from '@aa/common';

// local
import { IFormSerializer } from '../classes';
import { PasswordData } from '../models';

const fb = new FormBuilder();

export class PasswordDataSerializer extends Serializer<PasswordData> implements IFormSerializer<PasswordData> {

  private static buildFormInternal(oldPassword: boolean = false) {

    const map = {
      newPassword: '',
      confirmPassword:  ''
    };

    if (oldPassword) {
      map['oldPassword'] = '';
    }

    return fb.group(map, {
      validator: PasswordDataSerializer.validatePassword
    });
  }

  private static validatePassword(group: FormGroup): any {

    if (!group.enabled) {
      return null;
    }

    // get references to the individual fields
    const oldPassword = group.controls['oldPassword']; // optional
    const newPassword = group.controls['newPassword'];
    const confirmPassword = group.controls['confirmPassword'];

    let errorCount = 0;

    // helper function
    function setError(control: AbstractControl, text: string) {
      control.setErrors({ validatePassword: text });
      errorCount++;
    }

    // Check old password (for password change)
    if (oldPassword && oldPassword.value.length < 6) {
      setError(oldPassword, 'Old password is required');
    }

    // Check new password
    if (!newPassword.value) {
      setError(newPassword, 'New password is required');
    } else if (newPassword.value.length < 6) {
      setError(newPassword, 'Password must be at least 6 characters');
    } else {
      // add additional password validation as required
    }

    // Check that passwords are equal
    if (newPassword.value !== confirmPassword.value) {
      setError(confirmPassword, 'Passwords do not match');
    }

    if (errorCount === 0) {
      return null;
    }

    return {
      validatePassword: 'Password data is invalid'
    };
  }

  public getType(): IFactory<PasswordData> {
    return PasswordData;
  }

  public getName(): string {
    return 'Password Data';
  }

  public buildPasswordChangeForm(): FormGroup {
    return PasswordDataSerializer.buildFormInternal(true);
  }

  public buildForm(): FormGroup {
    return PasswordDataSerializer.buildFormInternal(false);
  }
}
