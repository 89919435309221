<aa-dialog (keyup.escape)="content.cancel()" [loading]="content.loading">

  <aa-dialog-title>{{content.title}}</aa-dialog-title>

  <aa-dialog-content>
    <aa-form-error [error]="content.error" [details]="content.errorDetails"></aa-form-error>

    <form *ngIf="content.form" [formGroup]="content.form">
      <ng-content></ng-content>
    </form>
  </aa-dialog-content>

  <aa-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="content.loading || !content.dirty" (click)="save($event)">
      Save
    </button>

    <button mat-raised-button (click)="content.cancel()">
      Cancel
    </button>
  </aa-dialog-actions>
</aa-dialog>

