import { Organisation } from "@aa/common";

export class TextUtil {

  /**
   * Extract the host and path from a url stripping off the http and any query/hash at the end
   * @param href
   */
  public static getLinkLabel(href: string) {
    if (!href) {
      return null;
    }

    // strip off protocol
    const pos = href.indexOf('//');
    href = pos < 0 ? href : href.substr(pos + 2);

    // chop off trailing slash
    if (href.length > 0 && href[href.length - 1] === '/') {
      href = href.substr(0, href.length - 1);
    }

    // strip query and anchor
    return href.split('?')[0].split('#')[0];
  }

  public static formatLink(href: string, text: string = null) {
    if (!href) {
      return null;
    }

    // create text from href if not defined
    if (!text) {
      text  = TextUtil.getLinkLabel(href);
    }

    return `<a href="${href}">${text}</a>`;
  }

  public static wrapLinks(value: string) {

    if (!value) {
      return value;
    }

    const re = /(?:\w+:)?\/\/[^\s\/$.?#].[^\s]*/;

    return value.replace(re, href => TextUtil.formatLink(href));
  }

  /**
   * Extract mustache variables from string
   * {{ SOME_VAR }} => SOME_VAR
   * {{SOME_VAR}}   => SOME_VAR
   * @param str Input
   */
  public static parseVariableKey(str: string): string | null {
    if (!str) {
      return null;
    }

    const re = /{{\s*(\S+)\s*}}/;
    const result = str.match(re);
    return result && result[1];
  }

  public static extractVariables(str: string): string[] {
    if (!str) {
      return null;
    }

    const re = /{{\s*(\S+)\s*}}/g;

    const result = [];
    for(const match of str.matchAll(re)) {
      result.push(match[1].toUpperCase());
    }
    return result;
  }

  getWordAtOffset(text: string, offset: number): string {
    if (!text) {
      return null;
    }
    const n = text.length;

    if (n < 2) {
      return null;
    }

    if (offset < 0) {
      offset = 0;
    } else if (offset > n) {
      offset = n;
    }

    // get start offset
    let start = offset;
    for (; start > 0; start--) {
      if (text[start] === ' ') {
        break;
      }
    }

    // get end offset
    let end = start + 1;
    for (; end < n; end++) {
      if (text[end] === ' ') {
        break;
      }
    }



    return text.substr(start, end - start + 1).trim();
  }

  public static formatOrganisationList(organisations: Organisation[])
  {
      var result = "";
      function append(label: string, value?: string)
      {
          if (!value)
          {
              return;
          }

          result += `<b>${label}</b>: ${value}</br>`;
      }

      function appendLink(label: string, href?: string)
      {
          if (!href)
          {
              return;
          }
          result += `<b>${label}</b>: <a href=\"${href}\" target=\"_blank\">${href}</a></br>`;
      }

      for (const org of organisations)
      {
        result += "<p>";
          {
              append("Team Name", org.teamName);
              append("Address", org.address);
              append("Telephone", org.phone);
              append("Email", org.email);
              appendLink("Website", org.website);
          }
          result += "</p>";
      }

      return result;
  }
}
