import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AbstractInputComponent } from './abstract-input.component';

@Component({
  selector: 'aa-text-input',
  templateUrl: './text-input.component.html',
  host: {
    'class': 'form-control'
  }
})
export class TextInputComponent extends AbstractInputComponent {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() control: AbstractControl;

  @Input() type: string = 'text';

  onPaste(e: ClipboardEvent) {

    // fixUp after delay
    setTimeout(() => this.fixUp());
  }

  private fixUp() {
    const value = this.control.value;
    if (!value) {
      return;
    }

    // trim
    const sanitizedValue = value.trim();
    if (sanitizedValue === value) {
      return;
    }

    // update control
    this.control.setValue(sanitizedValue, {onlySelf: true});
  }
}
