
<a [matMenuTriggerFor]="libraryMenu" class="dropdown-toggle"><span class="label">{{currentItem?.label || "Loading..."}}</span></a>

<aa-dropdown #libraryMenu>
  <h6 class="dropdown-header">Domain Select</h6>

  <ng-container *ngFor="let item of itemList" >
    <!-- <h6  class="dropdown-header">{{meta.treeName}}</h6> -->
    <div mat-menu-item (click)="setDomain(item.domain)" [matTooltip]="item.description">
      <mat-radio-button class="mat-primary" [value]="item.domain" [checked]="item.domain === currentItem?.domain">
        {{item.label}}
      </mat-radio-button>
    </div>
  </ng-container>

  <ng-container *ngIf="currentItem">
    <div class="dropdown-divider"></div>
    <a mat-menu-item matTooltip="Navigate to your public SelfServ web app" matTooltipPosition="right" [href]="currentItem.url" target="_blank">
      <i [aaIcon]="linkIcon" aria-hidden="true"></i>
      <span>Open SelfServ</span>
    </a>
  </ng-container>

  <div class="dropdown-divider"></div>
  <aa-dropdown-cmd [cmd]="refreshCmd"></aa-dropdown-cmd>
</aa-dropdown>

