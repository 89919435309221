import { Component, Input } from '@angular/core';

import { ThemeCacheService } from '../../services';
import { Snippet } from '../../models';

// shared

@Component({
  selector: 'aa-snippet-preview',
  templateUrl: './snippet-preview.component.html',
  styleUrls: ['./snippet-preview.component.scss']
})
export class SnippetPreviewComponent {

  private _snippet: Snippet = new Snippet();

  constructor(private readonly themeCache: ThemeCacheService) {
  }

  @Input() width: string = '600px';
  @Input() showChannelInfo: boolean = false;

  @Input() set snippet(snippet: Snippet) {
    if (!snippet) {
      this._snippet = null;
      return;
    }

    this._snippet = snippet;
  }

  get themeColor() {
    return this.themeCache.themeColor;
  }

  get snippet() {
    return this._snippet;
  }
}
