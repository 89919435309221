import { Item } from '@aa/common';

export class Group extends Item {

  name: string;

  libraries: Guid[];

  subscriptions: Guid[];

  logoImage: string;

  syncTeamChannels: boolean;
}
