import { EventEmitter } from '@angular/core';

export abstract class UndoStack<T> {

  private readonly _size: number;

  private _items: T[] = [];
  private _count; number = 0;
  private _index: number = -1;

  public updated = new EventEmitter();

  protected constructor(size: number = 10) {
    this._size = size;

    // initialize the items array
    for (let i = 0; i < size; i++) {
      this._items.push(null);
    }
  }

  protected abstract clone(item: T): T;

  /**
   * Push a clone of the current state onto the stack every time the
   * target changes
   *
   * #Example : Partial stack at tip
   * before: items=[A, B, C], index=3
   *                      ^
   * after:  items=[A, B, C, D], index=4
   *
   * -----------                        ^
   *
   * #Example : Partial stack after not at tip
   * before: items=[A, B, C], index=3
   *                ^
   * after:  items=[A, B ], index=2
   *                   ^
   * -----------
   *
   * #Example - Full stack requires shift
   * before: items=[A, B, C, D], index=4
   *                         ^
   * after:  items=[B, C, D, E], index=4
   *                         ^
   * @param item
   */
  push(item: T) {

    if (!item) {

      return;
    }


    // check if we have enough space
    if (this._index < this._size - 1) {
      this._index++;
      this._count = this._index + 1;
    } else {
      // shift up the array and leave count and index unchanged
      this._items.shift();
    }



    this._items[this._index] = this.clone(item);

    this.notify();
  }

  /**
   * #Example
   * before: items=[A, B, C, D], index=4
   *                         ^
   * after:  items=[A, B, C, D], index=3
   *                      ^
   * returned: C
   *
   * #Example
   * before: items=[A, B, C, D], index=-1
   *                ^
   * after:  items=[A, B, C, D], index=-1
   *                ^
   * returned: null
   *
   * @returns {T}
   */
  undo(): T {
    if (!this.canUndo) {

      return null;
    }

    this._index--;
    const item = this._items[this._index];

    this.notify();
    return this.clone(item);
  }

  private notify() {

    this.updated.emit();
  }

  /**
   * #Example
   * before: items=[A, B, C, D], index=4
   *                         ^
   * after:  items=[A, B, C, D], index=4
   *                         ^
   * returned: null
   *
   * #Example
   * before: items=[A, B, C, D], index=-1
   *                ^
   * after:  items=[A, B, C, D], index=-2
   *                   ^
   * returned: null
   *
   * @returns {B}
   */
  redo(): T {
    if (!this.canRedo) {
      return null;
    }

    this._index++;
    const item = this._items[this._index];

    this.notify();
    return this.clone(item);
  }

  /**
   * At least 2 items must be placed on the stack before undo is allowed
   * @returns {boolean}
   */
  get canUndo(): boolean {
    return this._index > 0;
  }

  get canRedo(): boolean {

    return this._index < this._count - 1;
  }
}
