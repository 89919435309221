import { TagType } from '@aa/common';
import { AbstractFormatter } from './abstract_formatter.class';

export class TagTypeFormatter extends AbstractFormatter<TagType> {

  protected formatItemInternal(value: TagType): string {

    switch (value) {
      case TagType.Cat:      return 'Category';
      case TagType.Section:  return 'Section';
      case TagType.Topic:    return 'Topic';
      case TagType.Group:    return 'Group';
      case TagType.Variable: return 'Variable';
      default: return `Unknown(${value})`;
    }
  }
}
