import { Injectable } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

/**
 * Helper service for redirecting links to a configurable target
 * _blank by default
 */
@Injectable({
  providedIn: 'root'
})
export class LinkNavigationService {

  public target: string = '_blank';
  private readonly origin: string;

  constructor(private readonly analytics: AnalyticsService,
              private readonly router: Router) {

    // baseUrl
    this.origin = window.location.origin;
  }

  /**
   * Checks if element is a valid Anchor tag with an href defined
   * @param el
   */
  public isValidLinkElement(el: HTMLElement): boolean {
    return el.tagName === 'A' && el.hasAttribute('href');
  }

  /**
   * Returns true if the event was handled
   * @param e MouseEvent
   */
  public handleClick(e: MouseEvent): boolean {

    if (!window || e.button !== 0) {
      return false;
    }

    const el = <HTMLAnchorElement>e.target;
    let href = el.href;


    // use default behaviour if no href defined
    if (!this.isValidLinkElement(el)) {
      return false;
    }

    // prevent default before we do anything else
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    e.stopPropagation();

    if (href.startsWith(this.origin)) {
      href = href.substring(this.origin.length); // strip off origin but leave initial slash
    }

    if (href.startsWith('/')) {
      this.router.navigate([href]);
      return true;
    }

    // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#transport
    const useBeacon = (this.target !== '_blank');
    this.analytics.trackOutboundLink(el.href, useBeacon);

    // handle link manually
    window.open(el.href, this.target);
    return true;
  }
}
