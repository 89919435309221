
export enum TagType {
  None     = 0,
  Variable = 1,
  Cat      = 2, /* Obsolete */
  Section  = 4, /* Obsolete */
  Topic    = 8,
  Group    = 16
}

export const tagTypeCollection = [
  TagType.Cat,
  TagType.Section,
  TagType.Topic,
  TagType.Group,
  TagType.Variable
];
