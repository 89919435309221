
// external
import { Component, Input, OnInit } from '@angular/core';

// core
import { Role } from '@aa/common';

// local
import { RoleFormatter } from '../../classes';


interface RoleInfo {
  name: string;
  description: string;
  suffix: string;
}

/**
 * Display roles in a formatted list with popups
 */
@Component({
  selector: 'aa-role-list',
  template: `
    <style>span { cursor: help }</style>
    <span *ngFor="let info of roleInfo" [matTooltip]="info.description">{{info.name}}{{info.suffix}}</span>
  `
})
export class RoleListComponent implements OnInit {

  private static readonly emptyRoleInfo: RoleInfo = {
    name: 'None',
    suffix: '',
    description: 'No roles defined'
  };

  @Input() roles: Role[];

  public readonly roleInfo: RoleInfo[] = [];

  public readonly formatter = new RoleFormatter();

  constructor() {
  }

  ngOnInit() {
    if (!this.roles || this.roles.length === 0) {
      this.roleInfo.push(RoleListComponent.emptyRoleInfo);
      return;
    }

    const count = 0;

    for (let i = 0 ; i < this.roles.length; i++) {

      const role = this.roles[i];
      const name = RoleFormatter.formatName(role);
      let suffix = '';

      if (i < this.roles.length - 1) {
        suffix = ', ';
      }

      this.roleInfo.push({
        name: name,
        suffix: suffix,
        description: RoleFormatter.formatDescription(role),
      });
    }
  }
}
