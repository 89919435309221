import { ChannelItem, AppLibrary, AppChannel } from '../models';
import { ItemFilter } from './item-filter.class';

export class ChannelQueryResultClass<T extends ChannelItem> {

  error: string = null;
  data: T[] = [];
  disabled: boolean;

  constructor(readonly library: AppLibrary, readonly channel: AppChannel, readonly itemFilter: ItemFilter<T> = null) {
  }

  setData(data: T[]) {
    data = data || [];

    if (this.itemFilter) {
      data = this.itemFilter.apply(data);
    }

    this.data = data;
  }
}

export class ChannelQueryResultList<T extends ChannelItem> {
  public readonly items: ChannelQueryResultClass<T>[] = [];

  getItem(channelId: Guid): ChannelQueryResultClass<T> {
    return this.items.find(o => o.channel.id === channelId);
  }

  getAllData(): T[] {
    const list = [];

    for (const item of this.items) {
      list.push(...item.data);
    }

    return list;
  }
}
