import { FormGroup, Validators } from '@angular/forms';

// common
import { IFactory, Serializer } from '@aa/common';

// local
import { IFormSerializer, RoleUtil, CustomValidators, FormConfigBuilder } from '../classes';
import { User } from '../models';
import { PasswordDataSerializer } from './password_data.serializer';

const password = new PasswordDataSerializer().buildForm();
password.disable({onlySelf: true});

const fb = new FormConfigBuilder<User>([
  { key: 'email', validators: [Validators.required, Validators.minLength(4), CustomValidators.email] },
  { key: 'firstName', validators: [Validators.required] },
  { key: 'lastName', validators: [Validators.required] },

  { key: 'jobTitle', validators: [Validators.required] },
  { key: 'phoneNumber', validators: [Validators.minLength(9), CustomValidators.phone] },

  { key: 'teamId', validators: [Validators.required] },
  { key: 'roles', defaultValue: RoleUtil.getTeamAdminRoles() },
  { key: 'password', control: password },

  { key: 'activationEmail' }
]);

export class UserSerializer extends Serializer<User> implements IFormSerializer<User> {

  public getType(): IFactory<User> {
    return User;
  }

  public getName(): string {
    return 'User';
  }

  public buildForm(): FormGroup {
    return fb.build();
  }
}
