import { Directive, Input, OnInit, HostBinding } from '@angular/core';

import { Icon } from '../models/icon.enum';

@Directive({
  selector: '[aaIcon]'
})
export class IconDirective implements OnInit {

  @Input('aaIcon') set icon(value: Icon) {
    this._icon = value;

    if (this.initialized) {
      this.ngOnInit();
    }
  }

  static readonly iconMap: Map<Icon, string> = new Map([
    [Icon.View,    'eye'],
    [Icon.Upload,  'upload'],
    [Icon.Spinner, 'spinner'],
    [Icon.Help,    'question'],
    [Icon.Send,    'paper-plane'],

    [Icon.File,   'file'],
    [Icon.Print,  'print'],
    [Icon.Word,   'file-word-o'],
    [Icon.Pdf,    'file-pdf-o'],
    [Icon.Code,   'code'],
    [Icon.Save,   'floppy-o'],
    [Icon.Clone,  'clone'],
    [Icon.Hide,   'times'],
    [Icon.Clear,  'times'],

    [Icon.Search,  'search'],
    [Icon.Copy,    'clipboard'],

    [Icon.More,    'chevron-circle-down'],
    [Icon.Less,    'chevron-circle-up'],

    [Icon.Undo,    'undo'],
    [Icon.Redo,    'repeat'],
    [Icon.Edit,    'pencil'],
    [Icon.Delete,  'trash'],
    [Icon.Action,  'ellipsis-v'], // vertical ellipsis for dropdown menus
    [Icon.Table,   'table'],
    [Icon.Image,   'picture-o'],
    [Icon.Intro,   'address-card-o'],
    [Icon.Signature, 'pencil'],
    [Icon.Plus,    'plus'],
    [Icon.Refresh, 'refresh'],
    [Icon.Feedback, 'comment'],

    [Icon.Private, 'user'],
    [Icon.Team,    'users'],

    [Icon.External,  'external-link'],

    [Icon.Share,     'share-alt'],

    [Icon.Publish,   'cloud-upload'],
    [Icon.Unpublish, 'cloud-download'],

    [Icon.Checked,   'check-square-o'],
    [Icon.Unchecked, 'square-o'],

    [Icon.Success, 'check'],
    [Icon.Alert,   'exclamation-triangle'],
    [Icon.Info,    'info-circle'],
    [Icon.Error,   'times'],

    [Icon.List, 'list'],
    [Icon.Channel, 'hashtag'],
    [Icon.Tag, 'tag'],

    [Icon.Localise, 'map-marker'],
    [Icon.AdviceSet, 'file-alt'],
  ]);


  @HostBinding('class') classNames: string = '';
  @HostBinding('hidden') hidden: boolean = true;

  private _icon: Icon = null;
  private initialized: boolean = false;

  @Input('class') originalClass: string = '';

  public static find(icon: Icon): string {
    if (!icon) {
      return null;
    }
    return IconDirective.iconMap.get(icon);
  }

  ngOnInit() {

    this.initialized  = true;

    const iconName = IconDirective.find(this._icon);
    if (iconName == null) {
      console.warn(`Invalid icon ${this._icon}`);
      return;
    }

    this.hidden = false;

    // use original class if available
    this.classNames = this.originalClass ? this.originalClass + ' ' : '';

    this.classNames += 'fa fa-' + iconName;

    // add spin
    if (this._icon === Icon.Spinner) {
      this.classNames += ' fa-spin';
    }
  }

}

