// angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { concatMap, map, retryWhen, switchMap } from 'rxjs/operators';

// local
import { Guid, IApiResponse, ISelfServData, ISelfServMeta, SelfServData, SelfServMetaStatus } from '../models';

// TODO can we make this a setting
const rootUrl = '/api/selfserv';

@Injectable({providedIn: 'root'})
export class SelfServDataService {

  constructor(private readonly http: HttpClient) {}

  getMetaListByTeamId(teamId: Guid): Observable<ISelfServMeta[]> {

    const url = `${rootUrl}/meta?teamId=${teamId}`;

    return this.http.get<IApiResponse>(url).pipe(
      map(res => <ISelfServMeta[]>(res.data))
    );
  }

  getMeta(selfServDomain: string, treeId: string = null): Observable<ISelfServMeta> {

    let url = `${rootUrl}/meta/${selfServDomain}`;

    if (treeId) {
      url += '?treeId=' + treeId;
    }

    return this.http.get<IApiResponse>(url).pipe(
      map(res => <ISelfServMeta>(res.data))
    );
  }

  getDataFromMeta(meta: ISelfServMeta): Observable<SelfServData> {

    // 30 seconds in total
    const maxRetries = 10;
    const retryInterval = 3000;

    // check if the given meta data value is valid
    const checkMeta = (value: ISelfServMeta) => {

      switch (value.metaStatus) {

        case SelfServMetaStatus.Pending:
          throw new Error('retry');

        case SelfServMetaStatus.Ok:
          return value;

        default:
        case SelfServMetaStatus.Error:
          throw new Error('Unknown error');
      }
    };

    // first try the initial meta
    try {
      return this.getDataFromUrl(checkMeta(meta));
    } catch (err) {
      console.log(`initial meta invalid. Will retry ${maxRetries} times`);
    }

    // Retry strategy which will execute if the error contains 'retry' message
    // and the number of attempts had not reached the maximum
    const retryStrategy = (errors: Observable<any>) => errors.pipe(
      concatMap((e, i) => {

        if (i >= maxRetries) {
          const message = `Failed to download meta after ${maxRetries} attempts`;
          console.error(message);
          return throwError(message);
        }

        if (e.message === 'retry') {
          console.log(`Meta retry ${i}`);
          return timer(retryInterval);
        }

        console.error(`Invalid meta`, e.message);
        return throwError(e);
      })
    );

    // re-fetch meta with conditional retry
    return this.getMeta(meta.domain).pipe(
      map(checkMeta), // check validity
      retryWhen(retryStrategy), // handler errors
      switchMap(finalMeta => this.getDataFromUrl(finalMeta)) // finally get data
    );
  }

  private getDataFromUrl(meta: ISelfServMeta): Observable<SelfServData> {
    return this.http.get<ISelfServData>(meta.dataUrl).pipe(
      map(res => {
        return new SelfServData(meta, res);
      }));
  }

    /**
   * Resolve meta and data ina single call
   * @param selfServDomain SelfServ domain e.g. epicforest
   * @param treeId Optional tree id
   */
  getData(selfServDomain: string, treeId: string = null): Observable<SelfServData> {
    return this.getMeta(selfServDomain, treeId).pipe(
      switchMap(meta => this.getDataFromMeta(meta)
    ));
  }
}
