// external
import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// common
import { AuthService, IUser } from '@aa/common';

@Injectable({providedIn: 'root'})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // only handle api requests
    const url = req.url;

    if (!url.startsWith('/api/')) {
      return next.handle(req);
    }

    // add jwt token to api requests
    const token = this.auth.token;

    if (!token) {
      if (this.checkTokenRequired(url)) {
        console.warn('api request without valid user token');
      }
    } else {
      req = req.clone({ setHeaders: {
        'Authorization': 'Bearer ' + token
      }});
    }

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          this.setTokenFromResponse(evt);
        }
      })
    );
  }

  private checkTokenRequired(url: string) {

    // check routes where token is NOT required
    if (url.startsWith('/api/auth/login') || url.startsWith('/api/config')) {
      return false;
    }

    return true;
  }

  // parse response and check for presence of token
  private setTokenFromResponse(res: HttpResponseBase): boolean {
    if (!res || !res.headers) {
      return false;
    }

    if (res.status === 0 || res.status === 401) {
      this.auth.logout();
      return false;
    }

    try {
      const user = this.parseToken(res);
      return this.auth.setUser(user);
    } catch (err) {
      return false;
    }
  }

  private parseToken(res: HttpResponseBase): IUser {
    if (!res || !res.headers) {
      throw new Error(('Invalid response'));
    }

    const token = res.headers.get('x-access-token');

    if (!token) {
      throw new Error(('No token found'));
    }

    const user = AuthService.decodeUserToken(token);

    if (!user) {
      throw new Error(('Invalid token'));
    }

    return user;
  }
}
