// angular
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReplaySubject } from 'rxjs';

// @aa/common
import { Item } from '@aa/common';

// @aa/app/ui
import { Command, ICommand, Icon } from '@aa/app/ui';

// local
import { ITableAction, ITableColumn, ITableConfig } from '../../models';
import { TableDataSource } from './table-data-source.class';
import { DetailsDialogService } from '../details-dialog';
import { PaginatedDataSet } from '../../classes';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'aa-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  readonly actionIcon: Icon = Icon.Action;

  public clickToEdit: boolean = true;
  public selectedItem: any = null;
  public visibleColumns: string[] = [];

  // static = true because matSort is not conditional on bindings and we need it during onInit
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public dataSource: TableDataSource<Item>;
  private itemSource = new ReplaySubject<Item[]>(1);

  // actions
  readonly actions: Array<ICommand> = [];
  private readonly detailsCommand: Command;
  private readonly editCommand: Command;
  private readonly deleteCommand: Command;
  private readonly cloneCommand: Command;

  private _initialized: boolean = false;
  private _filter: string = null;

  readonly pagedData = new PaginatedDataSet<Item>();

  @Output() readonly rowEdit   = new EventEmitter();
  @Output() readonly rowDelete = new EventEmitter();

  @Input() set filter(filter: string) {
    this._filter = filter;

    // update data source if available
    if (this.dataSource) {
      this.dataSource.filter = this._filter;
    }
  }

  @Input() public set items(items: Item[]) {
    this.itemSource.next(items);
  }

  _selectedColumns: string[] = null;
  _columns: ITableColumn<Item>[] = [];
  _config: ITableConfig<any>;

  @Input()
  public set config(value: ITableConfig<any>) {
    this._columns = value.columns;
    this._config = value;
    this.clickToEdit = value.clickToEdit !== false;
    this.initDataSource();
    this.initActions(value);
  }

  @Input()
  public set selectedColumns(value: string[]) {
    this._selectedColumns = value;
    this.initVisibleColumns();
  }


  private static createCommand(action: ITableAction): Command {
    const cmd = new Command(action);

    cmd.subscribe(args => {
      if (args.data) {
        action.callback(args.data);
      }
    });

    return cmd;
  }

  public constructor(private readonly detailsDialogService: DetailsDialogService,
                     private readonly sanitizer: DomSanitizer) {

    this.detailsCommand = TableComponent.createCommand({
      name: 'Details',
      description: 'Show additional details',
      icon: Icon.List,
      callback: item => this.showDetails(item)
    });

    this.editCommand = TableComponent.createCommand({
      name: 'Edit',
      description: 'Edit item',
      icon: Icon.Edit,
      callback: item => this.editItem(item)
    });

    this.deleteCommand = TableComponent.createCommand({
      name: 'Delete',
      description: 'Delete item',
      icon: Icon.Delete,
      callback: item => this.deleteItem(item)
    });

    this.cloneCommand = TableComponent.createCommand({
      name: 'Clone',
      description: 'Clone item',
      icon: Icon.Copy,
      callback: item => this.cloneItem(item)
    });
  }


  ngOnInit() {
    this._initialized = true;
    this.initDataSource();
  }

  public get hasResults(): boolean {
    return this.visibleColumns.length > 0 && this.pagedData.length > 0 ;
  }

  private getItem(id: Guid) {
    return this.dataSource ? this.dataSource.items.find(o => o.id === id) : null;
  }

  public setSelectedItemId(id: Guid) {
    // lookup the item from the current paged data
    this.selectedItem = this.getItem(id);
    this.syncActions();
  }

  get columns(): ITableColumn<Item>[] {
    return this._columns;
  }

  public clickRow(id: Guid): void {
    if (this.clickToEdit) {
      this.editItem(this.getItem(id));
    }
  }

  public editItem(item: any): void {
    if (!item) {
      return;
    }

    this.rowEdit.emit(item);
  }

  public deleteItem(item: any): void {
    this.rowDelete.emit(item);
  }

  public cloneItem(item: any): void {
    const cb = this._config.cloneAction;
    if (cb) {
      cb(item);
    }
  }

  public showDetails(item: any): void {
    this.detailsDialogService.openFromColumnDef(item, this._columns);
  }

  /**
   * Synchronise the action menu for the given item
   * @param item
   * @returns {Array<ICommand>}
   */
  private syncActions() {
    // this.actions[0].enabled = !this.actions[0].enabled;
  }

  private initActions(config: ITableConfig<any>) {

    this.actions.length = 0;

    this.actions.push(this.detailsCommand);
    this.actions.push(this.editCommand);

    if (config.canDelete !== false) {
      this.actions.push(this.deleteCommand);
    }

    if (config.cloneAction) {
      this.actions.push(this.cloneCommand);
    }

    if (config.actions) {
      for (const action of config.actions) {
        this.actions.push(TableComponent.createCommand(action));
      }
    }
  }

  private initVisibleColumns() {
    if (!this._initialized || !this._columns) {
      return;
    }

    this.visibleColumns = this._selectedColumns ?
      this._selectedColumns.slice() :
      this._columns.map(o => o.key);

    // add menu icon if one or more columns are visible
    if (this.visibleColumns.length > 0) {
      this.visibleColumns.push('menu');
    }
  }

  private initDataSource() {
    if (!this._initialized || !this._columns) {
      this.dataSource = null;
      return;
    }

    if (!this.sort) {
      console.warn('matSort directive is undefined', this.sort);
      return;
    }

    this.dataSource = new TableDataSource(this.sanitizer, this.itemSource, this._columns, this.pagedData, this.sort, this._config.rowType);
    this.dataSource.filter = this._filter;

    this.initVisibleColumns();
  }

  public onPage(event: PageEvent) {
    this.pagedData.setPage(event.pageIndex, event.pageSize);
  }
}
