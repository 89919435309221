// angular
import { Injectable, Injector } from '@angular/core';

// local
import { AdviceSet } from '../models';
import { AdviceSetSerializer } from '../serializers';
import { AbstractApiService } from './abstract-api.service';
import { TagCacheService } from './tag-cache.service';
import { AbstractTaggedItemCache } from './abstract-tagged-item-cache.service';


@Injectable({providedIn: 'root'})
export class AdviceSetApiService extends AbstractApiService<AdviceSet> {
  constructor (injector: Injector) {
    super(injector, 'advicesets', new AdviceSetSerializer());
  }
}

/**
 * Stateful service for caching advicesets associated with current library
 */
@Injectable({providedIn: 'root'})
export class AdviceSetCacheService extends AbstractTaggedItemCache<AdviceSet> {

  constructor(tagCache: TagCacheService, api: AdviceSetApiService) {
    super(tagCache, api);
  }
}
