
/**
 * Selfserv header alignment mode
 */
export enum HeaderAlignment {
  Right   = 0,
  Left    = 1,
  Center  = 2
}

export const HeaderAlignmentCollection = [
  HeaderAlignment.Left,
  HeaderAlignment.Center,
  HeaderAlignment.Right];
