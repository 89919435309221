import { VariableType } from '@aa/common';
import { AbstractFormatter } from './abstract_formatter.class';

export class VariableTypeFormatter extends AbstractFormatter<VariableType> {

  protected formatItemInternal(value: VariableType): string {

    switch (value) {
      // case TagType.Template:  return 'Template';
      case VariableType.Text:    return 'Text';
      case VariableType.Boolean: return 'True/False';
      case VariableType.Url:     return 'Url';
      case VariableType.Organisation: return 'Organisation';
      default: return `Unknown(${value})`;
    }
  }
}
