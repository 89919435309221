export enum ChannelType {

  None    = 0,
  Private = 1,
  Team    = 2,
  Global  = 4,
  Group   = 8,
  Review  = 16,

  Localisation  = 32,

  All = 63
}
