import { TagType } from '@aa/common';

// local
import { Tag } from '../models';
import { TagTypeFormatter } from './tag-type-formatter.class';
import { TagUtil } from './tag-util.class';

const formatter = new TagTypeFormatter();

export class TagGroup {
  public readonly type: TagType;
  public readonly name: string;
  public items: Tag[] = [];

  constructor(type: TagType, name: string = null) {
    this.type = type;
    this.name = name || formatter.formatItem(type);
  }

  public sort() {
    this.items = TagUtil.sort(this.items);
  }

  push(tag: Tag) {
    this.items.push(tag);
  }
}

export class TagGroupMap {

  private readonly map = new Map<TagType, TagGroup>();
  private readonly otherGroup: TagGroup;

  public readonly list: TagGroup[] = [];

  constructor(public readonly tagTypes: TagType[]) {

    // init groups
    for (const type of tagTypes) {
      const group = new TagGroup(type);
      this.map.set(type, group);
      this.list.push(group);
    }

    // catch all group
    this.otherGroup = new TagGroup(TagType.None, 'Other');
    this.list.push(this.otherGroup);
  }

  public get(tagType: TagType): TagGroup {
    return this.map.get(tagType) || this.otherGroup;
  }
}
