<aa-dialog (keyup.escape)="close()">

  <aa-dialog-title>Channel Share
    <div class="prompt">{{prompt}}</div>
  </aa-dialog-title>

  <aa-dialog-content>

    <ng-template #noChannels>
      <div>No shared channels available</div>
    </ng-template>


    <div class="channel-info-list" *ngIf="shareInfo; else noChannels">
      <div *ngFor="let info of shareInfo" class="share-info">

        <div class="channel-type">{{info.channel | channelType}}</div>
        <div class="channel-name">
          {{info.channel.name}}
        </div>

        <div *ngIf="info.canShare">
          <!-- Previously shared -->
          <div *ngIf="info.ref; else notShared">
            <div>Shared at <aa-date-stamp [date]="info.ref.shared" [userId]="info.ref.sharedUserId"></aa-date-stamp></div>
            <div>
              <button mat-raised-button color="primary"
                      (click)="share(info)"
                      [disabled]="!info.outOfSync"
                      matTooltip="Sync the shared item this channel">
                Sync
              </button>

              <button mat-raised-button color="warn" (click)="unshare(info)">
                Unshare
              </button>
            </div>
          </div>
        </div>

        <!-- Not shared -->
        <ng-template #notShared>
          <div>
            <button mat-raised-button color="primary" (click)="share(info)" matTooltip="Share to this channel">
              Share
            </button>
          </div>
        </ng-template>
      </div>
    </div>


  </aa-dialog-content>

  <aa-dialog-actions>
    <button type="submit" mat-raised-button color="primary" (click)="close()">
      Done
    </button>
  </aa-dialog-actions>

  <aa-dialog>


