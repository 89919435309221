import { Component, Input } from '@angular/core';

// common
import { AuthService } from '@aa/common';

// core
import { Icon } from '@aa/app/ui';

// local
import { ChannelItem, LocalisationFlag, Snippet } from '../../models';
import { ChannelResolverService } from '../../services';

@Component({
  selector:    'aa-channel-item-info',
  templateUrl: './channel-item-info.component.html',
  styleUrls:  ['./channel-item-info.component.scss']
})
export class ChannelItemInfoComponent {

  private _item: ChannelItem = null;

  readonly channelIcon: Icon = Icon.Channel;
  readonly shareIcon: Icon = Icon.Share;
  readonly localiseIcon: Icon = Icon.Localise;

  public showAdviceSheetMessage: boolean  = false;
  public isShared: boolean  = false;
  public localisation: LocalisationFlag = null;
  public channelColor: string;
  public readonly showChannelInfo: boolean;

  constructor(auth: AuthService, private readonly channelResolver: ChannelResolverService) {
    this.showChannelInfo = auth.isTeamAuthor;
  }

  @Input() set item(item: ChannelItem) {
    this._item = item;

    if (!this.item) {
      this.showAdviceSheetMessage = false;
      this.isShared = false;
      this.channelColor = '';
      return;
    }

    this.showAdviceSheetMessage = !!item['isAdviceSetPreview'];

    this.isShared = item.refs && item.refs.length > 0;
    this.channelColor = this.channelResolver.getColor(item.channelId);

    // cast to snippet for localisation info
    this.localisation = (<Snippet>item).localisation;
  }

  get item() {
    return this._item;
  }
}
