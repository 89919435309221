import { AbstractControl, ValidatorFn } from '@angular/forms';

const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
const HTTPS_REGEXP = /^https:\/\//;
const PHONE_REGEXP = /^[0-9-+ ]+$/;
const COLOR_REGEXP = /^#([A-Fa-f0-9]{6})$/;

export class CustomValidators {

  public static readonly email: ValidatorFn = (c: AbstractControl) => {
    if (!c || !c.value) {
      return null;
    }
    return EMAIL_REGEXP.test(c.value) ? null : {
      email: false
    };
  }

  public static readonly https: ValidatorFn = (c: AbstractControl) => {
    if (!c || !c.value) {
      return null;
    }

    return HTTPS_REGEXP.test(c.value) ? null : {
      https: false
    };
  }

  public static readonly phone: ValidatorFn = (c: AbstractControl) => {
    if (!c || !c.value) {
      return null;
    }

    return PHONE_REGEXP.test(c.value) ? null : {
        phone: false
      };
  }

  public static readonly color: ValidatorFn = (c: AbstractControl) => {
    if (!c || !c.value) {
      return null;
    }

    return COLOR_REGEXP.test(c.value) ? null : {
      color: false
    };
  }

  /**
   * Validate a list of keywords in CSV format
   * Only alphanumeric chars are allowed + comma, space and apostrophe
   * Keyword must be at least 3 chars long
   * e.g. Notice, Eviction, Section 21, S21
   * @returns {ValidatorFn}
   */
  public static readonly keywordList: ValidatorFn = (c: AbstractControl) => {
    const KEYWORD_REGEXP = /^[a-z0-9,'\-\s]+$/i; // case insensitive
    const MIN_LENGTH = 3;

    if (!c || !c.value) {
      return null;
    }

    return null;

    /*
    const isValid = c.value.length >= MIN_LENGTH && KEYWORD_REGEXP.test(c.value);

    return isValid ? null : {
      keyword: false
    };
    */
  }
}



