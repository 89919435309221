import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AbstractInputComponent } from './abstract-input.component';

@Component({
  selector: 'aa-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  host: {
    'class': 'form-control'
  }
})
export class TextAreaInputComponent extends AbstractInputComponent {

  @Input() label: string;
  @Input() hint: string;
  @Input() control: AbstractControl;

  @Input() maxLength: number = 3000;
  @Input() minRows: number = 2;
  @Input() maxRows: number = 10;

  @Input() monospace: boolean = false;

  get charsRemaining() {
    const val = this.control.value;

    if (!val) {
      return this.maxLength;
    }

    return this.maxLength - val.length;
  }
}
