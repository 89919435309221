import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'aa-trial-expiry-dialog',
  templateUrl: './trial-expiry-dialog.component.html',
  styleUrls: ['./trial-expiry-dialog.component.scss']
})
export class TrialExpiryDialogComponent implements OnInit {

  public readonly supportEmail: string = 'support@adviceaid.uk';

  constructor(private readonly dialogRef: MatDialogRef<TrialExpiryDialogComponent>) {
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
